import React, { useState } from 'react';

type Title = 'create-content' | 'studio-intro' | 'evergreen' | 'schedule';

export const useSelectVideo = () => {
  const [showModal, setShowModal] = useState(false);
  const [video, setVideo] = useState<React.ReactNode>(null);

  const handleSelectVideo = (title: Title) => {
    toggleModal();
    setVideo(selectVideo(title));
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return { handleSelectVideo, video, showModal, toggleModal };
};

export const selectVideo = (title: Title) => {
  switch (title) {
    case 'create-content':
      return (
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/HlrwGuBvB7U'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      );
    case 'studio-intro':
      return (
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/V4Z1lWaovAE'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      );
    case 'evergreen':
      return (
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/hI0HCqhWEeY'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      );
    case 'schedule':
      return (
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/962UTB-cuWE'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      );
    default:
      return;
  }
};
