import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCurrentCta from '../../../hooks/useCurrentCta';
import useGetInstance from '../../../hooks/useGetInstance';
import { setCurrentCtaId } from '../../../redux/ctaSlice';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setActiveSidebarView } from '../../../redux/sidebarViewSlice';

export const useSelect = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const instance = useGetInstance();
  const currentCta = useCurrentCta();

  const handleChange = (value) => {
    dispatch(setCurrentCtaId({ ctaId: value }));

    if (location.pathname.match(/dashboard/gi)) {
      navigate(`/dashboard?instance=${instance}&cta-id=${value}`);
    } else {
      navigate(`/design?instance=${instance}&cta-id=${value}`);
    }
    dispatch(setActiveSidebarView({ id: 1 }));
  };
  const [value, setValue] = useState<string | null>(null);
  useEffect(() => {
    if (currentCta && currentCta.id) {
      setValue(currentCta.id);
    }
  }, [currentCta]);

  return { value, handleChange };
};
