import { CtaList } from "../types";
import { useEffect, useState } from "react";
import moment from "moment";

const useCategories = (
  compId: string,
  ctaList
): [CtaList, CtaList, CtaList, CtaList] => {
  const [activeCtas, setActiveCtas] = useState<CtaList>([]);
  const [drafts, setDrafts] = useState<CtaList>([]);
  const [upcoming, setUpcoming] = useState<CtaList>([]);
  const [expired, setExpired] = useState<CtaList>([]);
  const [filteredList, setFilteredList] = useState<CtaList>([]);

  useEffect(() => {
    if (ctaList) {
      setFilteredList(ctaList.filter((cta) => cta.compId === compId));
    }
  }, [ctaList, compId]);

  useEffect(() => {
    setActiveCtas(
      [...filteredList.filter((cta) => cta.isActive || cta.isEvergreen)].sort(
        (a, b) => b.updatedAt - a.updatedAt
      )
    );
  }, [filteredList]);

  useEffect(() => {
    setDrafts(
      [
        ...filteredList.filter(
          (cta) =>
            (!cta.schedule.start || !cta.schedule.end) &&
            !cta.isEvergreen &&
            !cta.isActive
        ),
      ].sort((a, b) => b.updatedAt - a.updatedAt)
    );
  }, [filteredList]);

  useEffect(() => {
    setUpcoming(
      [
        ...filteredList.filter(
          (cta) =>
            moment(cta.schedule.start).utc().local().valueOf() >
            new Date().getTime()
        ),
      ].sort((a, b) => b.updatedAt - a.updatedAt)
    );
  }, [filteredList]);

  useEffect(() => {
    setExpired(
      [
        ...filteredList.filter(
          (cta) =>
            cta.schedule.end &&
            moment(cta.schedule.end).utc().local().valueOf() <
              new Date().getTime()
        ),
      ].sort((a, b) => b.updatedAt - a.updatedAt)
    );
  }, [filteredList]);

  return [activeCtas, drafts, upcoming, expired];
};

export default useCategories;
