import React, { useLayoutEffect, useState } from 'react';
import { Text, Container } from './DisabledInfoBubble.styles';
import { useLocation } from 'react-router-dom';

interface Props {
  style?: React.CSSProperties;
}

const DisabledInfoBubble: React.FC<Props> = ({ style }) => {
  const [width, setWidth] = useState(0);
  const location = useLocation();
  useLayoutEffect(() => {
    if (location.pathname.match(/settings/gi)) {
      setWidth(18);
    }
  }, [location]);

  return (
    <Container width={width} style={style}>
      <Text>To keep using this App, upgrade now</Text>
    </Container>
  );
};

export default DisabledInfoBubble;
