import React from 'react';
import useGetViewMode from '../../hooks/useGetViewMode';
import MobileWidget from '../MobileWidget/MobileWidget.component';
import MobileEditorWidget from '../MobileEditorWidget/MobileEditorWidget.component';

const MobileRouter = () => {
  const viewMode = useGetViewMode();

  if (viewMode === 'editor') {
    return <MobileEditorWidget />;
  } else {
    return <MobileWidget />;
  }
};

export default MobileRouter;
