import { useEffect, useState } from "react";
import { CalendarEvent, CalendarEvents, CtaList } from "../types";

const useCalendarEvents = (ctaList: CtaList) => {
  const [events, setEvents] = useState<CalendarEvents>([]);
  useEffect(() => {
    if (ctaList.length > 0) {
      const scheduledCtas: CalendarEvents = [];
      ctaList.forEach((cta) => {
        let tmpCta = {} as CalendarEvent;
        if (cta.schedule.start && cta.schedule.end) {
          tmpCta.start = new Date(cta.schedule.start);
          tmpCta.end = new Date(cta.schedule.end);

          tmpCta.notes = cta.notes;
          tmpCta.id = cta.id;
          tmpCta.title = cta.title;
          tmpCta.color = "#7597ee";
          scheduledCtas.push(tmpCta);
        }
      });

      setEvents(scheduledCtas);
    }
  }, [ctaList]);

  return events;
};

export default useCalendarEvents;
