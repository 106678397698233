import axios from "axios";

const updateCompSettings = async (
  instance: string,
  compId: string,
  controller?: AbortController,
  settings?: { layout?: number; boxShadow?: number }
) => {
  if (instance && compId) {
    if (controller) {
      try {
        await axios.post(
          "/api/instance/update-comp-settings",
          {
            instance,
            compId,
            settings,
          },
          { signal: controller.signal }
        );
      } catch (error: unknown) {
        if (error instanceof Error && error.message !== "canceled")
          console.error(error.message);
      }
    } else {
      try {
        await axios.post("/api/instance/update-comp-settings", {
          instance,
          compId,
          settings,
        });
      } catch (error: unknown) {
        if (error instanceof Error && error.message !== "canceled")
          console.error(error.message);
      }
    }
  }
};

const getCompSettings = async (instance: string, compId: string) => {
  try {
    return await axios.post("/api/instance/comp-settings", {
      instance,
      compId,
    });
  } catch (error) {
    if (error instanceof Error && error.message !== "canceled")
      console.error(error.message);
  }
};

export default { updateCompSettings, getCompSettings };
