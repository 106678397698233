import React, { useEffect, useState } from 'react';
import { CTAContainer } from './Widget.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useSetInstance from '../../hooks/useSetInstance';
import useGetActiveCta from '../../hooks/useGetActiveCta';
import useGetPreviewCta from '../../hooks/useGetPreviewCta';
import CTA from '../../components/Widget/CTA/CTA.component';
import useLoading from '../../hooks/useLoading';
import useAppendFontScripts from '../../hooks/useAppendFontScripts';
import { Cta } from '../../types';
import useGetInstance from '../../hooks/useGetInstance';
import useSetCompId from '../../hooks/useSetCompId';
import useGetCompId from '../../hooks/useGetCompId';
import { useLocation } from 'react-router-dom';
import useSetUser from '../../hooks/useSetUser';
import useSetPlanId from '../../hooks/useSetPlanId';
import SkeletonComponent from '../../components/General/SkeletonComponent/SkeletonComponent.component';
import useHideWidget from '../../hooks/useHideWidget';

const Widget = () => {
  const [media, setMedia] = useState<'desktop' | 'mobile'>('desktop');
  const [currentCta, setCurrentCta] = useState<Cta>({} as Cta);

  const desktop = useMediaQuery('(min-width:501px)');
  const mobile = useMediaQuery('(max-width:500px)');

  useSetInstance();
  useSetCompId();

  useAppendFontScripts(currentCta as Cta);

  const location = useLocation();
  const instance = useGetInstance();
  const compId = useGetCompId();

  const loading = useLoading();
  const activeCta = useGetActiveCta(instance, compId);
  const previewCta = useGetPreviewCta(instance, compId, location);
  useSetUser(instance);
  useSetPlanId(instance);

  // Store currentCta (preview or active) in state
  useEffect(() => {
    if (previewCta.id) {
      setCurrentCta(previewCta);
    } else if (activeCta.id) {
      setCurrentCta(activeCta);
    }
  }, [previewCta, activeCta]);

  useEffect(() => {
    if (desktop) {
      setMedia('desktop');
    } else if (mobile) {
      setMedia('mobile');
    }
  }, [desktop, mobile]);

  useHideWidget();

  if (loading) {
    return <SkeletonComponent />;
  } else {
    if (activeCta) {
      return <CTA currentCta={activeCta} media={media} />;
    } else {
      return <CTAContainer data-testid='widget'></CTAContainer>;
    }
  }
};

export default Widget;
