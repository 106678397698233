// When elements are changed in the design studio, this hook updates ctaList with those changes.

import { useLayoutEffect, useRef, useState } from "react";
import { setCtaList } from "../redux/ctaSlice";
import useElements from "./useElements";
import useGetCtas from "./useGetCtas";
import useGetCurrentCtaId from "./useGetCurrentCtaId";
import { useAppDispatch } from "../redux/reduxHooks";
import { CtaList, Elements, ResponsiveStyles } from "../types";
import { ensure } from "../util/functions";

const useUpdateCtaList = () => {
  const elements = useElements().present;
  const ctaId = useGetCurrentCtaId();
  const ctaList = useGetCtas();
  const dispatch = useAppDispatch();

  const [ctaListContainer, setCtaListContainer] = useState<CtaList>([]);
  const ctaListRef = useRef<CtaList>(ctaList);

  useLayoutEffect(() => {
    ctaListRef.current = JSON.parse(JSON.stringify(ctaList));
  }, [ctaList]);

  useLayoutEffect(() => {
    if (elements.length > 0 && ctaListRef.current.length > 0 && ctaId) {
      let currentCta = ensure(
        ctaListRef.current.find((cta) => cta.id === ctaId)
      );

      const elementsArray: Elements = [];

      elements.forEach((el) => {
        if (el.type !== "cta") {
          elementsArray.push(el);
        } else {
          if (el.title) currentCta.title = el.title;

          currentCta.editor.style = el.style as ResponsiveStyles;

          if (el.backgroundImg) {
            currentCta.editor.backgroundImg = el.backgroundImg;
          } else {
            currentCta.editor.backgroundImg = {
              desktop: {
                src: "",
                top: 0,
                left: 0,
              },
              mobile: { src: "", top: 0, left: 0 },
            };
          }
        }
      });

      currentCta.editor.elements = elements.filter((el) => el.type !== "cta");

      setCtaListContainer([
        currentCta,
        ...ctaListRef.current.filter((cta) => cta.id !== ctaId),
      ]);
    }
  }, [elements, ctaId]);

  useLayoutEffect(() => {
    if (ctaListContainer.length > 0) {
      const timer = setTimeout(() => {
        dispatch(setCtaList({ ctaList: ctaListContainer }));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [dispatch, ctaListContainer]);
};

export default useUpdateCtaList;
