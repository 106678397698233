import styled from 'styled-components';

export const SidebarMenuStyles = styled.div`
  min-width: 7.1rem;
  background-color: ${({ theme }) => theme.color.blue900};
  height: calc(100vh - 4.8rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MenuButton = styled.button<{ view?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: 'roboto', sans-serif;
  color: ${(props) =>
    props.view === props.id ? props.theme.color.black : '#FFF'};

  font-size: 1.2rem;
  background-color: ${(props) =>
    props.view === props.id
      ? '#FFF'
      : props.id === '10'
      ? '#8C21AF'
      : 'transparent'};
  height: 7.1rem;
  width: 7.1rem;

  cursor: pointer;
  position: relative;
  z-index: 1000;

  &::before {
    display: ${(props) =>
      props.id !== '9' && props.id !== '10' ? 'flex' : 'none'};
    pointer-events: none;
    content: '';
    position: absolute;
    background-color: transparent;
    bottom: -50px;
    height: 50px;
    width: 25px;
    left: 46px;
    border-top-right-radius: 5px;
    box-shadow: ${(props) =>
      props.view === props.id ? '0 -5px 0 0 #FFF' : ''};
  }

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    background-color: transparent;
    bottom: 71px;
    height: 50px;
    width: 25px;
    left: 46px;
    border-bottom-right-radius: 5px;
    box-shadow: ${(props) => (props.view === props.id ? '0 5px 0 0 #FFF' : '')};
  }
`;
