const LAYOUT = {
  1: {
    width: 980,
    height: 490,
  },
  2: {
    width: 525,
    height: 400,
  },
  3: {
    width: 315,
    height: 450,
  },
};

const BOXSHADOW = {
  1: "none",
  2: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  3: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  4: "rgb(38, 57, 77) 0px 20px 30px -10px",
};

export default { boxShadow: BOXSHADOW, layout: LAYOUT };
