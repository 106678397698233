import { useEffect, useState } from "react";
import { Delta, TargetStartPos } from "../types";

const useSetTargetDelta = (
  target: { x: 0; y: 0; id: null | string },
  setTarget: (target: { x: 0; y: 0; id: null | string }) => void,
  targetStartPos: TargetStartPos
): [Delta, (delta: Delta) => void] => {
  const [delta, setDelta] = useState<Delta>({ x: 0, y: 0 });
  // Set delta of position of target
  useEffect(() => {
    if (target.id === targetStartPos.id && target.id !== null) {
      setDelta({
        x: target.x - targetStartPos.x,
        y: target.y - targetStartPos.y,
      });
    }
  }, [target, targetStartPos]);

  return [delta, setDelta];
};

export default useSetTargetDelta;
