import React, { useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAppendFontScripts from '../../hooks/useAppendFontScripts';
import {
  cafeTemplate,
  cityTemplate,
  cliffTemplate,
  comoTemplate,
  joshuaTreeTemplate,
  otterTemplate,
  pumpkinTemplate,
  templateFive,
  templateFour,
  templateOne,
  templateSix,
  templateThree,
  waveTemplate,
} from '../../templateEnums';
import CTA from '../../components/Widget/CTA/CTA.component';

const TemplatePreview = () => {
  const query = useQuery();
  const id = query.get('id');

  const [templatePreview, setTemplatePreview] = useState<any>({} as any);
  const [media, setMedia] = useState<'desktop' | 'mobile'>('desktop');

  const desktop = useMediaQuery('(min-width:501px)');
  const mobile = useMediaQuery('(max-width:500px)');

  useAppendFontScripts(templatePreview as any);

  const generateContent = (templateId: number): any => {
    const content: any = {
      editor: {
        elements: [],
        style: {
          desktop: {},
          mobile: {},
        },
        backgroundImg: {
          desktop: { src: '', top: 0, left: 0 },
          mobile: { src: '', top: 0, left: 0 },
        },
      },
    };
    let cta, ctaStyle, elements;

    switch (templateId) {
      case 1:
        cta = templateOne.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = templateOne.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;

        return content;

      case 3:
        cta = templateThree.find((entry) => entry.type === 'cta');
        console.log('cta', cta);
        ctaStyle = cta?.style;
        elements = templateThree.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;

      case 4:
        cta = templateFour.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = templateFour.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 5:
        cta = templateFive.find((entry) => entry.type === 'cta');

        ctaStyle = cta?.style;
        elements = templateFive.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 6:
        cta = templateSix.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = templateSix.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 7:
        cta = waveTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = waveTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 8:
        cta = cliffTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = cliffTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 9:
        cta = joshuaTreeTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = joshuaTreeTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 10:
        cta = otterTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = otterTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 11:
        cta = pumpkinTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = pumpkinTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 12:
        cta = comoTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = comoTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 13:
        cta = cityTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = cityTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      case 14:
        cta = cafeTemplate.find((entry) => entry.type === 'cta');
        ctaStyle = cta?.style;
        elements = cafeTemplate.filter((entry) => entry.type !== 'cta');
        if (ctaStyle) content.editor.style = ctaStyle;
        if (elements) content.editor.elements = elements;
        if (cta?.backgroundImg)
          content.editor.backgroundImg = cta.backgroundImg;
        return content;
      default:
        break;
    }

    return content;
  };

  useEffect(() => {
    if (id) {
      setTemplatePreview(generateContent(parseInt(id)));
    }
    console.log('templateId', id);
  }, [id]);

  useEffect(() => {
    console.log('content', templatePreview);
  }, [templatePreview]);

  useEffect(() => {
    if (desktop) {
      setMedia('desktop');
    } else if (mobile) {
      setMedia('mobile');
    }
  }, [desktop, mobile]);

  if (templatePreview?.editor?.elements.length > 0) {
    return <CTA preview={true} currentCta={templatePreview} media={media} />;
  } else {
    return <></>;
  }
};

export default TemplatePreview;
