import styled from 'styled-components';

export const TitleStyles = styled.input`
  cursor: pointer;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.color.gray700};
  border: none;
  background: transparent;
  outline: none;
  max-width: fit-content;
`;

export const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.5rem;
  border-radius: 50%;
  color: #fff;
  background: ${(props) => props.theme.color.btnPrimary};
  position: absolute;
  left: -3rem;
  top: -2.5rem;
  cursor: pointer;
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
`;

export const Container = styled.div`
  position: absolute;
  top: -25px;
  left: 30px;
`;
