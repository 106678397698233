import React from 'react';
import { ButtonStyles } from './Button.styles';

interface Props {
  id?: string;
  label?: string;
  icon?: React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  variant: 'primary' | 'secondary' | 'tertiary';
}

const Button: React.FC<Props> = ({
  id,
  label,
  onClick,
  style,
  icon,
  variant,
}) => {
  return (
    <ButtonStyles id={id} variant={variant} onClick={onClick} style={style}>
      {label}
      {icon && icon}
    </ButtonStyles>
  );
};

export default Button;
