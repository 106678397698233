import styled from 'styled-components';

export const Container = styled.div<{
  width: number;
}>`
  padding: ${(props) => props.theme.spacing[3]};
  position: absolute;
  background: #fff;
  box-shadow: ${(props) => props.theme.shadow[2]};
  z-index: 100;
  width: ${(props) => (props.width ? props.width + 'rem' : 'auto')};
  border: 1px solid ${(props) => props.theme.color.gray200};
  border-radius: 3px;

  &::before {
    content: '';
    position: absolute;
    left: 45px;
    top: -10px;
    width: 20px;
    height: 10px;
    background: ${(props) => props.theme.color.gray200};
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    z-index: 99;
  }

  &::after {
    content: '';
    position: absolute;
    left: 46px;
    top: -9px;
    width: 18px;
    height: 9px;
    background: ${(props) => props.theme.color.white};
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    z-index: 100;
  }
`;

export const Text = styled.p`
  margin-bottom: 0;
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.settings.text};
`;
