import React from 'react';
import { ModalStyles, Message, Backdrop } from './ConfirmModal.styles';
import MenuButton from '../MenuButton/MenuButton.component';

interface Props {
  handleCloseModal: () => void;
  message: string;
}

const ConfirmModal: React.FC<Props> = ({ message, handleCloseModal }) => {
  return (
    <>
      <ModalStyles>
        <Message>{message}</Message>
        <MenuButton
          onClick={handleCloseModal}
          label={'Confirm'}
          variant={'secondary'}
        />
      </ModalStyles>
      <Backdrop onClick={handleCloseModal} />
    </>
  );
};

export default ConfirmModal;
