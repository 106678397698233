import React from 'react';
import { SvgContainer, SvgStyles } from './ImageSVG.styles';
import { Style } from '../../../types';

interface Props {
  id: string;
  elstyle: Style;
  alt: string | undefined;
  src: string | undefined;
}

const ImageSVG: React.FC<Props> = ({ id, elstyle, alt, src }) => {
  return (
    <SvgContainer elstyle={elstyle}>
      <SvgStyles id={id} elstyle={elstyle} src={src} alt={alt} />
    </SvgContainer>
  );
};

export default ImageSVG;
