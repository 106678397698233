import styled from 'styled-components';
import { Style } from '../../../types';

export const CTAContainer = styled.div<{
  elstyle: Style;
  scale: number;
  category?: 'active' | 'drafts' | 'upcoming' | 'expired';
}>`
  position: relative;
  overflow: hidden;
  opacity: 1;

  height: ${(props) =>
    props.scale < 1 || props.category
      ? props.elstyle.height?.value + 'px'
      : 'auto'};
  width: ${(props) =>
    props.scale < 1 || props.category
      ? props.elstyle.width?.value + 'px'
      : '100%'};

  // scale(.305) is used for the "thumbnails" in the carousel in the dashboard
  transform: ${(props) =>
    props.category ? `scale(.305)` : `scale(${props.scale})`};
  transform-origin: top left;

  display: flex;
  justify-content: center;
  pointer-events: ${(props) => props.category && 'none'};
`;

export const InnerContainer = styled.div<{
  width?: number | string;
  height?: number | string;

  category?: 'active' | 'drafts' | 'upcoming' | 'expired';
}>`
  position: relative;
  height: ${(props) =>
    typeof props.height === 'string' ? props.height : props.height + 'px'};
  width: ${(props) =>
    typeof props.width === 'string' ? props.width : props.width + 'px'};
  overflow: hidden;
`;
