import { useEffect, useState } from "react";
import CompSettings from "../enums/compSettings";

interface Settings {
  width: number;
  height: number;
  boxShadow: string;
}

const useBuildCompSettings = (settings?: {
  layout: number;
  boxShadow: number;
}) => {
  const [result, setResult] = useState<Settings>({} as Settings);

  useEffect(() => {
    if (settings) {
      switch (settings.layout) {
        case 1:
          setResult((prev) => ({
            ...prev,
            width: CompSettings.layout["1"].width,
            height: CompSettings.layout["1"].height,
          }));
          break;
        case 2:
          setResult((prev) => ({
            ...prev,
            width: CompSettings.layout["2"].width,
            height: CompSettings.layout["2"].height,
          }));
          break;
        case 3:
          setResult((prev) => ({
            ...prev,
            width: CompSettings.layout["3"].width,
            height: CompSettings.layout["3"].height,
          }));
          break;
        default:
          break;
      }

      switch (settings.boxShadow) {
        case 1:
          setResult((prev) => ({
            ...prev,
            boxShadow: CompSettings.boxShadow["1"],
          }));
          break;
        case 2:
          setResult((prev) => ({
            ...prev,
            boxShadow: CompSettings.boxShadow["2"],
          }));
          break;
        case 3:
          setResult((prev) => ({
            ...prev,
            boxShadow: CompSettings.boxShadow["3"],
          }));
          break;
        case 4:
          setResult((prev) => ({
            ...prev,
            boxShadow: CompSettings.boxShadow["4"],
          }));
          break;
        default:
          break;
      }
    }
  }, [settings]);

  return result;
};

export default useBuildCompSettings;
