import styled from 'styled-components';

export const IconStyles = styled.button<{ isUppercase: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease-in;
  border-radius: 2px;
  padding: 0.6rem;
  border: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.isUppercase ? props.theme.color.gray200 : '#FFF'};
  &:hover {
    background-color: ${(props) =>
      props.isUppercase
        ? props.theme.color.gray200
        : props.theme.color.gray100};
  }
  &:active {
    background-color: ${({ theme }) => theme.color.gray200};
  }
`;
