import React from 'react';
import {
  ErrorContainer,
  ErrorStyles,
  IconStyles,
} from './ScheduleError.styles';
import useGetScheduleError from '../../../hooks/useGetScheduleError';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ScheduleError = () => {
  const error = useGetScheduleError();

  return (
    <ErrorContainer>
      <IconStyles>
        <WarningAmberIcon fontSize='medium' style={{ color: 'inherit' }} />
      </IconStyles>
      <ErrorStyles>{error}</ErrorStyles>
    </ErrorContainer>
  );
};

export default ScheduleError;
