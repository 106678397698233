import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  font-size: 1.2rem;
  overflow-y: auto;
  width: calc(35rem - 7.1rem);
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[3]};

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing[3]};
  }
`;
