import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.gray100};
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[4]};
  padding: ${(props) => props.theme.spacing[5]};
  width: 100%;
  height: 50rem;
  max-width: 126rem;

  @media screen and (min-width: 1100px) {
    height: 70rem;
  }
`;
