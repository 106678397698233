import React, { Fragment } from 'react';
import Settings from './views/Settings/Settings.component';
import DesignStudio from './views/DesignStudio/DesignStudio.component';
import Dashboard from './views/Dashboard/Dashboard.component';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TemplatePreview from './views/TemplatePreview/TemplatePreview.component';
import WidgetRouter from './views/WidgetRouter/WidgetRouter.component';
import MobileRouter from './views/MobileRouter/MobileRouter.component';
import ConfirmPublish from './views/ConfirmPublish/ConfirmPublish.component';
import LogRocket from 'logrocket';

const App: React.FC = () => {
  LogRocket.init('vfbj9z/content-scheduler');
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path='/widget' element={<WidgetRouter />} />
          <Route path='/mobile' element={<MobileRouter />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/design' element={<DesignStudio />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/template-preview' element={<TemplatePreview />} />
          <Route path={'/publish-notice'} element={<ConfirmPublish />} />
        </Routes>
      </Fragment>
    </Router>
  );
};

export default App;
