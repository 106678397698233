import React, { useRef, useState } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContextMenu from '../ContextMenu/ContextMenu.component';
import { Cover } from '../../../styles/util';
import { IconContainer, Icon, Title, CtaItemStyle } from './CtaItem.styles';
import useGetInstance from '../../../hooks/useGetInstance';
import { getAllCtasFromDB, updateCtaInDB } from '../../../util/services';
import { handleClose } from '../../../util/functions';
import { setCtaList } from '../../../redux/ctaSlice';
import { Tag } from 'antd';
import { Wix } from '../../../util/Wix';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useGetCompId from '../../../hooks/useGetCompId';
import { Pos } from '../../../types';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  ctaId: string;
  ctaTitle: string;
  activeItem?: { id: string | null };
  setActiveItem?: React.Dispatch<
    React.SetStateAction<{
      id: null | string;
    }>
  >;
  isEvergreen?: boolean;
  isActive?: boolean;
}

const CtaItem: React.FC<Props> = ({
  ctaId,
  ctaTitle,
  activeItem,
  setActiveItem,
  isEvergreen,
  isActive,
}) => {
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [pos, setPos] = useState<Pos>({ x: 0, y: 0 });
  const instance = useGetInstance();
  const compId = useGetCompId();
  const titleRef = useRef<HTMLSpanElement>(null);
  const dispatch = useAppDispatch();

  const editUrl =
    process.env.REACT_APP_ENV === 'staging'
      ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`
      : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`;
  const testEditUrl = `/design?instance=${instance}&cta-id=${ctaId}`;

  const scheduleUrl =
    process.env.REACT_APP_ENV === 'staging'
      ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}&view=schedule`
      : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}&view=schedule`;
  const testScheduleUrl = `/design?instance=${instance}&cta-id=${ctaId}&view=schedule`;

  const url =
    process.env.REACT_APP_ENV === 'staging'
      ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`
      : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`;
  const testUrl = `/design?instance=${instance}&cta-id=${ctaId}`;

  const handleShowContextMenu: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setPos({
      x: e.clientX,
      y: e.clientY,
    });
    setShowContextMenu(true);
  };

  const handleTitleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> = (
    e
  ) => {
    if (
      e.key === 'Enter' &&
      titleRef.current &&
      titleRef.current.contentEditable !== 'true'
    ) {
      titleRef.current.contentEditable = 'true';
    } else if (e.key === 'Enter' && titleRef.current) {
      titleRef.current.blur();
    } else if (e.key === 'Escape' && titleRef.current) {
      e.currentTarget.innerText = '';
      titleRef.current.blur();
    }
  };

  const handleBlur = async (e) => {
    e.target.contentEditable = false;

    if (e.target.innerText !== '') {
      try {
        const cta = {
          id: ctaId,
          title: e.target.innerText,
        };

        await updateCtaInDB(instance, compId, cta);
        const result = await getAllCtasFromDB(
          instance,
          undefined,
          compId
        );

        if (result) {
          dispatch(setCtaList({ ctaList: result }));
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    } else {
      e.target.innerText = ctaTitle;
    }
  };

  const handleCloseContextMenu: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setShowContextMenu(false);
  };

  const handleSchedule: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      Wix.Settings.openModal(
        scheduleUrl,
        '85%',
        '85%',
        'Content Scheduler - Design Studio',
        () => handleClose()
      );
    } else {
      const w = window.open(testScheduleUrl, '_blank');
      if (w) {
        w.focus();
      }
    }
  };

  const handleDoubleClick = () => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      Wix.Settings.openModal(
        url,
        '85%',
        '85%',
        'Content Scheduler - Design Studio',
        () => handleClose()
      );
    } else {
      const w = window.open(testUrl, '_blank');
      if (w) {
        w.focus();
      }
    }
  };

  const handleEdit = () => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      Wix.Settings.openModal(
        editUrl,
        '85%',
        '85%',
        'Content Scheduler - Design Studio',
        () => handleClose()
      );
    } else {
      const w = window.open(testEditUrl, '_blank');
      if (w) {
        w.focus();
      }
    }
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (setActiveItem) setActiveItem({ id: ctaId });
  };

  return (
    <CtaItemStyle
      data-testid='cta-item'
      onDoubleClick={handleDoubleClick}
      active={activeItem?.id}
      ctaId={ctaId}
      onClick={handleClick}>
      <Title
        tabIndex={0}
        onKeyDown={handleTitleKeyDown}
        onBlur={handleBlur}
        ref={titleRef}>
        {ctaTitle}
      </Title>

      <IconContainer>
        {isEvergreen ? (
          <Tag color={'green'}>Evergreen</Tag>
        ) : isActive ? (
          <Tag color={'blue'}>Active</Tag>
        ) : undefined}
        <Icon aria-label='Edit' title={'Edit'} onClick={handleEdit}>
          <EditIcon
            style={{ fontSize: 24, color: '#325166', cursor: 'pointer' }}
          />
        </Icon>
        <Icon aria-label='Schedule' title={'Schedule'} onClick={handleSchedule}>
          <AccessTimeIcon
            style={{ fontSize: 24, color: '#325166', cursor: 'pointer' }}
          />
        </Icon>
        <Icon
          showContextMenu={showContextMenu}
          title={'More Options'}
          aria-label='More Options'
          onClick={handleShowContextMenu}>
          <MoreVertIcon
            style={{ fontSize: 24, color: '#325166', cursor: 'pointer' }}
            onClick={handleShowContextMenu}
          />
        </Icon>
      </IconContainer>
      {showContextMenu && (
        <>
          <Cover
            onClick={(e) => {
              e.stopPropagation();
              setShowContextMenu(false);
            }}
          />
          <ContextMenu
            ctaId={ctaId}
            closeMenu={handleCloseContextMenu}
            titleRef={titleRef}
            pos={pos}
          />
        </>
      )}
    </CtaItemStyle>
  );
};

export default CtaItem;
