import React, { useState } from 'react';
import TextInput from '../TextInput/TextInput.component';
import TextInputWithOptions from '../TextInputWithOptions/TextInputWithOptions.component';
import SelectInput from '../SelectInput/SelectInput.component';
import SliderInput from '../SliderInput/SliderInput.component';
import Tab from '../Tab/Tab.component';
import ShadowSelectMenu from '../ShadowSelectMenu/ShadowSelectMenu.component';
import { shadows } from '../../../enums';
import ColorPicker from '../ColorPicker/ColorPicker.component';
import { MenuContainer } from './ButtonProperties.styles';
import useShowSidebar from '../../../hooks/useShowSidebar';

const ButtonProperties = () => {
  const [buttonState, setButtonState] = useState('normal');
  const [shadow, setShadow] = useState({ key: 'none', value: 'none' });
  const showSidebar = useShowSidebar();

  return (
    <MenuContainer showSidebar={showSidebar} data-testid='button-properties'>
      <Tab
        setButtonState={setButtonState}
        style={{ marginTop: '-2rem', marginBottom: '1rem' }}
      />
      <TextInput id='buttonLabel' label='Button Label' isRequired={true} />
      <TextInputWithOptions id='buttonLink' label='Button Link' />
      <ColorPicker
        params={{ buttonState }}
        testId='background-color-picker'
        label='Font Color'
        id='buttonFontColor'
      />

      <ColorPicker
        params={{ buttonState }}
        testId='background-color-picker'
        label='Background Color'
        id='buttonBackgroundColor'
      />
      <SelectInput
        params={{ buttonState }}
        label='Border Style'
        id='buttonBorderStyle'
        options={['none', 'solid', 'dashed']}
      />
      <ColorPicker
        params={{ buttonState }}
        label='Border Color'
        testId='border-color-picker'
        id='buttonBorderColor'
      />
      <SliderInput
        params={{ buttonState }}
        label='Border Width'
        id='buttonBorderWidth'
        variant='block'
        max={10}
      />
      <SliderInput
        variant='block'
        params={{ buttonState }}
        label='Border Radius'
        id='buttonBorderRadius'
      />

      <ShadowSelectMenu
        id='buttonShadow'
        setVal={setShadow}
        val={shadow}
        label='Drop Shadow'
        options={shadows}
        buttonState={buttonState}
      />
    </MenuContainer>
  );
};

export default ButtonProperties;
