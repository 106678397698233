import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 3.2rem;
  border-radius: 25px;
  margin: ${(props) => props.theme.spacing[3]};
  background: ${(props) => props.theme.color.settings.blue200};
  color: ${(props) => props.theme.color.settings.text};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.color.settings.blue150};
  }
`;

export const SaveButton = styled.button<{
  loading: { state: boolean; success: boolean | null };
}>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 3.2rem;
  border-radius: 25px;
  margin: ${(props) => props.theme.spacing[3]};
  background: ${(props) => props.theme.color.settings.blue200};
  color: ${(props) => props.theme.color.settings.text};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.color.settings.blue150};
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing[3]};
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[3]};
  }
`;

export const Rotate = styled.div`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 1s infinite linear;
`;
