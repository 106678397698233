import styled from 'styled-components';

export const TemplateItemStyles = styled.div<{
  restricted: boolean;
  premium: boolean;
}>`
  margin-bottom: ${(props) => props.theme.spacing[4]};
  box-shadow: ${(props) => props.theme.shadow[4]};
  position: relative;
  background-color: #000;
  pointer-events: ${(props) =>
    props.restricted && props.premium ? 'none' : 'auto'};
`;

export const Image = styled.img<{ restricted: boolean; premium: boolean }>`
  width: 24rem;
  cursor: pointer;
  opacity: ${(props) => (props.premium && props.restricted ? '.8' : '1')};
  z-index: 2;
`;

export const CrownContainer = styled.div<{
  showSidebar?: boolean;
}>`
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 2;
  animation: ${(props) =>
    props.showSidebar ? `show 150ms forwards` : `hide 0ms forwards`};
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
