import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  SettingsContainer,
  SettingsRightColumn,
  Sidebar,
  SideBarMenuList,
  Divider,
  Upper,
} from './Settings.styles';
import SidebarMenuItem from '../../components/Settings/SidebarMenuItem/SidebarMenuItem.component';
import ContentView from '../../components/Settings/ContentView/ContentView.component';
import useSetInstance from '../../hooks/useSetInstance';
import useSetCtaListFromDB from '../../hooks/useSetCtaListFromDB';
import MainView from '../../components/Settings/MainView/MainView.component';
import { handleClose } from '../../util/functions';
import useGetInstance from '../../hooks/useGetInstance';
import useGetCompId from '../../hooks/useGetCompId';
import { Wix } from '../../util/Wix';
import TemplatesView from '../../components/Settings/TemplatesView/TemplatesView.component';
import SettingsView from '../../components/Settings/SettingsView/SettingsView.component';
import SupportView from '../../components/Settings/SupportView/SupportView.component';
import NicknameView from '../../components/Settings/NicknameView/NicknameView.component';
import EvergreenView from '../../components/Settings/EvergreenView/EvergreenView.component';
import useSetCompId from '../../hooks/useSetCompId';
import useSetUser from '../../hooks/useSetUser';
import useUser from '../../hooks/useUser';
import useDisableApp from '../../hooks/useDisableApp';
import TrialExpiredView from '../../components/Settings/TrialExpiredView/TrialExpiredView.component';
import useSetPlanId from '../../hooks/useSetPlanId';

const sidebarUpperMenuItems = [
  { id: 1, label: 'Main' },
  { id: 2, label: 'My Content' },
  { id: 3, label: 'Templates' },
  { id: 4, label: 'Settings' },
];
const sidebarLowerMenuItems = [
  { id: 5, label: 'Support' },
  { id: 99, label: 'Upgrade' },
];

const Settings = () => {
  const [view, setView] = useState(1);
  useSetCompId();
  const instance = useGetInstance();
  useSetUser(instance);
  useSetPlanId(instance);

  const user = useUser();
  const disabled = useDisableApp(user);
  const contentId = localStorage.getItem('contentId');
  const compIdParam = localStorage.getItem('compId');
  const openStudio = localStorage.getItem('openStudio');
  const schedule = localStorage.getItem('schedule');

  useEffect(() => {
    let url;
    if (openStudio && contentId && instance && compIdParam && schedule) {
      url =
        process.env.REACT_APP_ENV === 'staging'
          ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${contentId}&comp-id=${compIdParam}&view=schedule`
          : `https://contentscheduler.app/design?instance=${instance}&cta-id=${contentId}&comp-id=${compIdParam}&view=schedule`;
    } else if (openStudio && contentId && instance && compIdParam) {
      url =
        process.env.REACT_APP_ENV === 'staging'
          ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${contentId}&comp-id=${compIdParam}`
          : `https://contentscheduler.app/design?instance=${instance}&cta-id=${contentId}&comp-id=${compIdParam}`;
    }

    if (openStudio && contentId && instance && compIdParam) {
      if (
        process.env.NODE_ENV === 'production' ||
        process.env.REACT_APP_ENV === 'staging'
      ) {
        Wix.Settings.openModal(
          url,
          '85%',
          '85%',
          'Content Scheduler - Design Studio',
          () => handleClose()
        );
      }
    }
  }, [openStudio, contentId, instance, compIdParam, schedule]);

  useSetInstance();
  const compId = useGetCompId();

  useSetCtaListFromDB(instance, compId);
  useSetPlanId(instance);

  const handleActive = (id) => {
    setView(id);
  };

  useLayoutEffect(() => {
    if (disabled) {
      setView(0);
    } else {
      setView(1);
    }
  }, [disabled]);

  const renderView = () => {
      switch (view) {
        case 0:
          return <TrialExpiredView />;
        case 1:
          return <MainView setView={setView} />;
        case 2:
          return <ContentView />;
        case 3:
          return <TemplatesView />;
        case 4:
          return <SettingsView setView={setView} />;
        case 5:
          return <SupportView />;
        case 6:
          return <NicknameView setView={setView} />;
        case 7:
          return <EvergreenView setView={setView} />;
        default:
          return;
      }

  };

  return (
    <SettingsContainer data-testid='settings'>
      <Sidebar data-testid='sidebar'>
        <SideBarMenuList>
          <Upper>
            {sidebarUpperMenuItems.map((item) => (
              <SidebarMenuItem
                active={view}
                id={item.id}
                key={item.id}
                label={item.label}
                handleActive={handleActive}
                disabled={disabled}
              />
            ))}
            <Divider />
            {sidebarLowerMenuItems.map((item) => (
              <SidebarMenuItem
                active={view}
                id={item.id}
                key={item.id}
                label={item.label}
                handleActive={handleActive}
                disabled={disabled}
              />
            ))}
          </Upper>
        </SideBarMenuList>
      </Sidebar>
      <SettingsRightColumn data-testid='right-column-view'>

        {renderView()}
      </SettingsRightColumn>
    </SettingsContainer>
  );
};

export default Settings;
