import React, { useEffect } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import { Cta, ScheduleList } from '../../../types';
import moment from 'moment-timezone';

const { RangePicker } = AntDatePicker;

interface Props {
  id: string;
  value: [moment.Moment, moment.Moment] | null;
  setValue: (
    value:
      | [moment.Moment, moment.Moment]
      | null
      | ((
          prevVar: [moment.Moment, moment.Moment] | null
        ) => [moment.Moment, moment.Moment] | null)
  ) => void;
  setCta: (value: Cta | ((prev: Cta | null) => Cta)) => void;
  setSessionStorageSchedule: (
    value: ScheduleList | ((prev: ScheduleList) => ScheduleList)
  ) => void;
  sessionStorageSchedule: ScheduleList;
  status: string;
  setScheduleChanged: (
    value: boolean | ((prev: boolean | null) => boolean)
  ) => void;
  scheduleChanged: boolean;
  ctaId: string;
}

const DatePicker: React.FC<Props> = ({
  id,
  value,
  setValue,
  setCta,
  status,
  setScheduleChanged,
}) => {
  const acceptChanges = (dates: [moment.Moment, moment.Moment]) => {
    setScheduleChanged(true);

    if (dates[0] !== null && dates[1] !== null) {
      const validatedDates = dates.map((item) => {
        const min = item.minute();
        const hour = item.hour();

        if (hour === 23 && min > 44) {
          return item.add(1, 'd').hour(0).minutes(0);
        }

        if (min < 30) {
          if (min - 15 > 0) {
            item.minutes(30);
          } else {
            item.minutes(0);
          }
        } else {
          if (min - 15 > 30) {
            item.add(1, 'h').minutes(0);
          } else {
            item.minutes(30);
          }
        }

        return item;
      });

      setCta(
        (cta) =>
          ({
            ...cta,
            schedule: {
              start: validatedDates[0].utc().valueOf(),
              end: validatedDates[1].utc().valueOf(),
            },
          } as Cta)
      );
    }
  };

  const onChange = (value: [moment.Moment, moment.Moment]) => {
    if (value) {
      setValue([value[0], value[1]]);
    } else {
      setScheduleChanged(true);
      setValue(null);
    }
  };

  useEffect(() => {
    if (value === null) {
      setCta(
        (cta) =>
          ({
            ...cta,
            schedule: { start: 0, end: 0 },
          } as Cta)
      );
    }
  }, [value, setCta]);

  return (
    <>
      <RangePicker
        showTime={{ format: 'HH:mm' }}
        format='YYYY-MM-DD HH:mm'
        onChange={onChange}
        onOk={acceptChanges}
        minuteStep={30}
        value={value as [moment.Moment, moment.Moment]}
        status={status ? 'error' : ''}
        id={id}
      />
    </>
  );
};

export default DatePicker;
