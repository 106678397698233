import { useEffect, useState } from "react";
import { duplicateInstance } from "../util/services";

const useDuplicateInstance = (
  instance: string,
  compId: string,
  origCompId: string | null | -1,
  viewMode: string | null | -1
) => {
  const [loading, setLoading] = useState<boolean>(true);
  // In some cases, this was running at the same time as another widget in another k8s pod and saving duplicate data
  // The random number make it unlikely they run simultaneously and unlikely to duplicate data.

  useEffect(() => {
    const controller = new AbortController();
    // If origCompId param exists, the widget was duplicated, and we want to prevent this from running
    if (instance && origCompId === -1 && viewMode === "editor" && compId) {
      setLoading(true);

      duplicateInstance(instance, compId, controller);

      return () => {
        setLoading(false);
        controller.abort();
      };
    }
  }, [instance, origCompId, viewMode, compId]);

  return loading;
};

export default useDuplicateInstance;
