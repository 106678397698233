import {
  ButtonElement,
  Cta,
  ImageElement,
  KeyVal,
  SvgImageElement,
  SvgRawElement,
  SVGVariation,
  TypographyElement,
} from "./types";

import Circle from "./assets/CircleFull.svg";
import Rectangle from "./assets/rectangle.svg";
import SquareFrame from "./assets/squareframe.svg";
import CircleFrameSmall from "./assets/CircleSmallOpen.svg";
import CircleFrameMedium from "./assets/CircleMediumOpen.svg";
import CircleFrameLarge from "./assets/CircleLargeOpen.svg";
import OffsetSquareRightSvg from "./assets/offset_square_right.svg";
import RhombusSvg from "./assets/rhombus.svg";
import StylishFrame from "./assets/StylishFrame.svg";
import RightArrow from "./assets/RightArrow.svg";
import ClipMaskBG1 from "./assets/SVG/SVG/curved-edge.svg";
import WavyMobile from "./assets/SVG/SVG/background-wavy-top.svg";

export const ThemeBtn1: ButtonElement = {
  id: null,
  type: "button",
  content: "Click Me!",
  style: {
    desktop: {
      position: { value: "absolute" },
      top: { value: 268 },
      left: { value: 58.8 },
      fontSize: { value: 16, default: true },
      fontWeight: { value: "normal", default: true },
      paddingTop: { value: 12 },
      paddingBottom: { value: 12 },
      paddingRight: { value: 20 },
      paddingLeft: { value: 20 },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "center", default: true },
      borderWidth: {
        value: 1,
        default: true,
      },
      borderStyle: {
        value: "none",
        default: true,
      },

      borderRadius: {
        value: 0,
        default: true,
      },

      lineHeight: { value: 1.2, default: true },
      width: { value: 170 },
      height: { value: 40 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      boxShadow: {
        value: "none",
        key: "none",
        default: true,
      },

      hover: {
        borderStyle: {
          value: "none",
          default: true,
        },

        borderWidth: {
          value: 1,
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },
        boxShadow: {
          value: "none",
          key: "none",
          default: true,
        },
      },
    },

    mobile: {
      position: { value: "absolute" },
      top: { value: 268 },
      left: { value: 58.8 },
      fontSize: { value: 16, default: true },
      fontWeight: { value: "normal", default: true },
      paddingTop: { value: 12 },
      paddingBottom: { value: 12 },
      paddingRight: { value: 20 },
      paddingLeft: { value: 20 },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "center", default: true },
      borderWidth: {
        value: 1,
        default: true,
      },
      borderStyle: {
        value: "none",
        default: true,
      },

      borderRadius: {
        value: 0,
        default: true,
      },

      lineHeight: { value: 1.2, default: true },
      width: { value: 170 },
      height: { value: 40 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      boxShadow: {
        value: "none",
        key: "none",
        default: true,
      },

      hover: {
        borderStyle: {
          value: "none",
          default: true,
        },

        borderWidth: {
          value: 1,
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },
        boxShadow: {
          value: "none",
          key: "none",
          default: true,
        },
      },
    },
  },
  link: {
    url: "",
    options: "_blank",
  },
};

export const HEADING: TypographyElement = {
  id: "",
  type: "typography",
  content: "Heading",
  style: {
    desktop: {
      background: { value: "transparent", default: true },
      position: { value: "absolute" },
      top: { value: 50 },
      left: { value: 20 },
      color: { value: "#333333", default: true },
      lineHeight: { value: 1, default: true },
      fontSize: { value: 50, default: true },
      fontStyle: { value: "normal", default: true },
      fontWeight: { value: "bold", default: true },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "left", default: true },
      borderWidth: { value: 1, default: true },
      borderStyle: { value: "solid", default: true },
      borderColor: { value: "transparent", default: true },
      borderRadius: { value: 0, default: true },
      paddingLeft: { value: 0 },
      paddingRight: { value: 0 },
      paddingTop: { value: 0 },
      paddingBottom: { value: 0 },
      width: { value: 220 },
      height: { value: 70 },
      zIndex: { value: 2 },
    },

    mobile: {
      background: { value: "transparent", default: true },
      position: { value: "absolute" },
      top: { value: 50 },
      left: { value: 20 },
      color: { value: "#333333", default: true },
      lineHeight: { value: 1, default: true },
      fontSize: { value: 50, default: true },
      fontStyle: { value: "normal", default: true },
      fontWeight: { value: "bold", default: true },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "left", default: true },
      borderWidth: { value: 1, default: true },
      borderStyle: { value: "solid", default: true },
      borderColor: { value: "transparent", default: true },
      borderRadius: { value: 0, default: true },
      paddingLeft: { value: 0 },
      paddingRight: { value: 0 },
      paddingTop: { value: 0 },
      paddingBottom: { value: 0 },
      width: { value: 220 },
      height: { value: 70 },
      zIndex: { value: 2 },
    },
  },
};

export const SUBHEADING: TypographyElement = {
  id: "",
  type: "typography",
  content: "Subheader",
  style: {
    desktop: {
      background: { value: "transparent", default: true },
      position: { value: "absolute" },
      top: { value: 50 },
      left: { value: 20 },
      color: { value: "#333333", default: true },
      lineHeight: { value: 1, default: true },
      fontSize: { value: 50, default: true },
      fontStyle: { value: "normal", default: true },
      fontWeight: { value: "normal", default: true },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "left", default: true },
      borderWidth: { value: 1, default: true },
      borderStyle: { value: "solid", default: true },
      borderColor: { value: "transparent", default: true },
      borderRadius: { value: 0, default: true },
      paddingLeft: { value: 0 },
      paddingRight: { value: 0 },
      paddingTop: { value: 0 },
      paddingBottom: { value: 0 },
      width: { value: 270 },
      height: { value: 70 },
      zIndex: { value: 2 },
    },

    mobile: {
      background: { value: "transparent", default: true },
      position: { value: "absolute" },
      top: { value: 50 },
      left: { value: 20 },
      color: { value: "#333333", default: true },
      lineHeight: { value: 1, default: true },
      fontSize: { value: 24, default: true },
      fontStyle: { value: "normal", default: true },
      fontWeight: { value: "normal", default: true },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "left", default: true },
      borderWidth: { value: 1, default: true },
      borderStyle: { value: "solid", default: true },
      borderColor: { value: "transparent", default: true },
      borderRadius: { value: 0, default: true },
      paddingLeft: { value: 0 },
      paddingRight: { value: 0 },
      paddingTop: { value: 0 },
      paddingBottom: { value: 0 },
      width: { value: 270 },
      height: { value: 70 },
      zIndex: { value: 2 },
    },
  },
};
export const BODYTEXT: TypographyElement = {
  id: "",
  type: "typography",
  content: "Body text",
  style: {
    desktop: {
      background: { value: "transparent", default: true },
      position: { value: "absolute" },
      top: { value: 50 },
      left: { value: 20 },
      color: { value: "#333333", default: true },
      lineHeight: { value: 1, default: true },
      fontSize: { value: 16, default: true },
      fontStyle: { value: "normal", default: true },
      fontWeight: { value: "normal", default: true },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "left", default: true },
      borderWidth: { value: 1, default: true },
      borderStyle: { value: "solid", default: true },
      borderColor: { value: "transparent", default: true },
      borderRadius: { value: 0, default: true },
      paddingLeft: { value: 0 },
      paddingRight: { value: 0 },
      paddingTop: { value: 0 },
      paddingBottom: { value: 0 },
      width: { value: 100 },
      height: { value: 70 },
      zIndex: { value: 2 },
    },

    mobile: {
      background: { value: "transparent", default: true },
      position: { value: "absolute" },
      top: { value: 50 },
      left: { value: 20 },
      color: { value: "#333333", default: true },
      lineHeight: { value: 1, default: true },
      fontSize: { value: 16, default: true },
      fontStyle: { value: "normal", default: true },
      fontWeight: { value: "normal", default: true },
      textDecoration: { value: "none", default: true },
      textAlign: { value: "left", default: true },
      borderWidth: { value: 1, default: true },
      borderStyle: { value: "solid", default: true },
      borderColor: { value: "transparent", default: true },
      borderRadius: { value: 0, default: true },
      paddingLeft: { value: 0 },
      paddingRight: { value: 0 },
      paddingTop: { value: 0 },
      paddingBottom: { value: 0 },
      width: { value: 100 },
      height: { value: 70 },
      zIndex: { value: 2 },
    },
  },
};

export const NEW_CTA: Cta = {
  id: "",
  title: "Untitled",
  ownerId: "",
  compId: "",
  schedule: {
    start: 0,
    end: 0,
  },
  live: {
    elements: [],
    style: {
      desktop: {},

      mobile: {},
    },
    backgroundImg: {
      desktop: { src: "", top: 0, left: 0 },
      mobile: {
        src: "",
        top: 0,
        left: 0,
      },
    },
  },
  editor: {
    backgroundImg: {
      desktop: { src: "", top: 0, left: 0 },
      mobile: {
        src: "",
        top: 0,
        left: 0,
      },
    },

    elements: [],
    style: {
      desktop: {
        background: { value: "#FFF", default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: "#FFF", default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
  },
  thumbnailUrl: "",
  notes: "",
  isActive: false,
  isEvergreen: false,
  updatedAt: new Date().getTime(),
  createdAt: new Date().getTime(),
};

export const IMAGE: ImageElement = {
  id: "",
  type: "image",
  style: {
    desktop: {
      width: { value: 320 },
      height: { value: 180 },
      objectFit: { value: "cover" },
      position: { value: "absolute" },
      top: { value: 10 },
      left: { value: 10 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      borderRadius: { value: 0, default: true },
      borderTopLeftRadius: { value: 0 },
      borderBottomLeftRadius: { value: 0 },
      borderTopRightRadius: { value: 0 },
      borderBottomRightRadius: { value: 0 },
    },

    mobile: {
      width: { value: 160 },
      height: { value: 90 },
      objectFit: { value: "cover" },
      position: { value: "absolute" },
      top: { value: 10 },
      left: { value: 10 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      borderRadius: { value: 0, default: true },
      borderTopLeftRadius: { value: 0 },
      borderBottomLeftRadius: { value: 0 },
      borderTopRightRadius: { value: 0 },
      borderBottomRightRadius: { value: 0 },
    },
  },
  src: "",
  alt: "",
};

// ELEMENTS
export const ICON: SvgImageElement = {
  id: "",
  type: "imageSvg",
  src: "",
  alt: "",
  style: {
    desktop: {
      width: { value: 200 },
      height: { value: 200 },
      objectFit: { value: "cover" },
      position: { value: "absolute" },
      top: { value: 10 },
      left: { value: 10 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      borderRadius: { value: 0, default: true },
      borderTopLeftRadius: { value: 0 },
      borderBottomLeftRadius: { value: 0 },
      borderTopRightRadius: { value: 0 },
      borderBottomRightRadius: { value: 0 },
    },

    mobile: {
      width: { value: 200 },
      height: { value: 200 },
      objectFit: { value: "cover" },
      position: { value: "absolute" },
      top: { value: 10 },
      left: { value: 10 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      borderRadius: { value: 0, default: true },
      borderTopLeftRadius: { value: 0 },
      borderBottomLeftRadius: { value: 0 },
      borderTopRightRadius: { value: 0 },
      borderBottomRightRadius: { value: 0 },
    },
  },
};

export const SHAPE: SvgRawElement = {
  id: "",
  type: "rawSvg",
  variation: null,
  title: "",
  style: {
    desktop: {
      width: { value: 200 },
      height: { value: 150 },
      position: { value: "absolute" },
      top: { value: 10 },
      left: { value: 10 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      borderTopLeftRadius: { value: 0 },
      borderBottomLeftRadius: { value: 0 },
      borderTopRightRadius: { value: 0 },
      borderBottomRightRadius: { value: 0 },
      background: { value: "#000d30", default: true },
    },

    mobile: {
      width: { value: 200 },
      height: { value: 150 },
      position: { value: "absolute" },
      top: { value: 10 },
      left: { value: 10 },
      zIndex: { value: 2 },
      opacity: { value: 1 },
      borderTopLeftRadius: { value: 0 },
      borderBottomLeftRadius: { value: 0 },
      borderTopRightRadius: { value: 0 },
      borderBottomRightRadius: { value: 0 },
      background: { value: "#000d30", default: true },
    },
  },
};

// BOX SHADOW ========
const shadowOne: KeyVal = {
  key: "Tailwind 1",
  value: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
};
const shadowTwo: KeyVal = {
  key: "Tailwind 2",
  value:
    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
};
const shadowThree: KeyVal = {
  key: "Tailwind 3",
  value:
    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
};
const shadowFour: KeyVal = {
  key: "Tailwind 4",
  value:
    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
};
const shadowFive: KeyVal = {
  key: "Tailwind 5",
  value:
    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
};
const shadowSix: KeyVal = {
  key: "Tailwind 6",
  value: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
};
const shadowInner: KeyVal = {
  key: "Tailwind inset",
  value: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
};
const shadowSeven: KeyVal = {
  key: "Material 1",
  value: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
};
const shadowEight: KeyVal = {
  key: "Material 2",
  value: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
};
const shadowNine: KeyVal = {
  key: "Material 3",
  value: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
};
const shadowTen: KeyVal = {
  key: "Material 4",
  value: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
};
const shadowEleven: KeyVal = {
  key: "Material 5",
  value: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
};
const none: KeyVal = {
  key: "None",
  value: "none",
};

export const shadows: KeyVal[] = [
  none,
  shadowOne,
  shadowTwo,
  shadowThree,
  shadowFour,
  shadowFive,
  shadowSix,
  shadowInner,
  shadowSeven,
  shadowEight,
  shadowNine,
  shadowTen,
  shadowEleven,
];

export const WEEK_IN_MS = 604800000;
export const DAY_IN_MS = 86400000;

export const SVGVariants: {
  variant: SVGVariation;
  premium: boolean;
  svg: string;
  alt: string;
}[] = [
  { variant: "fullCircle", premium: false, svg: Circle, alt: "circle" },
  { variant: "rectangle", premium: false, svg: Rectangle, alt: "rectangle" },
  {
    variant: "squareFrame",
    premium: true,
    svg: SquareFrame,
    alt: "square frame",
  },
  {
    variant: "circleFrameSmall",
    premium: true,
    svg: CircleFrameSmall,
    alt: "small circle frame",
  },
  {
    variant: "circleFrameMedium",
    premium: true,
    svg: CircleFrameMedium,
    alt: "medium circle frame",
  },
  {
    variant: "circleFrameLarge",
    premium: true,
    svg: CircleFrameLarge,
    alt: "large circle frame",
  },
  { variant: "rhombus", premium: true, svg: RhombusSvg, alt: "rhombus" },
  {
    variant: "stylishFrame",
    premium: true,
    svg: StylishFrame,
    alt: "stylish frame",
  },
  { variant: "rightArrow", premium: true, svg: RightArrow, alt: "right arrow" },
  {
    variant: "offsetSquareRight",
    premium: true,
    svg: OffsetSquareRightSvg,
    alt: "square with side sloped to right",
  },
  {
    variant: "clipmaskBG1",
    premium: true,
    svg: ClipMaskBG1,
    alt: "clipmaskBG1",
  },
  {
    variant: "wavyMobile",
    premium: true,
    svg: WavyMobile,
    alt: "wavy rectangle",
  },
];

export const FAQ_LIST: { question: string; answer: string }[] = [
  {
    question: "How many copies of Content Scheduler can I add to my website?",
    answer: "You can add unlimited Content Scheduler widgets to your website.",
  },
  {
    question: "What happens when my scheduled content expires?",
    answer:
      "We recommend setting-up an Evergreen Campaign in each widget on your site so that you never have a blank section on your website.  Once set, your Evergreen Campaign content will be featured between your scheduled announcements. If there is no content scheduled, the section will appear blank on your live website.",
  },
  {
    question: "What's an Evergreen Campaign?",
    answer:
      "The Evergreen Campaign is designed for you to always be able to engage your audience between big announcements and campaigns. Once set, your Evergreen Campaign will be featured between your scheduled announcements so that there is never a gap in your scheduled content. A favorite example is creating a sign-up Campaign to increase your email contact list that can be featured between fundraising campaigns or scheduled events.",
  },
  {
    question: "How do I set my Evergreen Campaign?",
    answer:
      "You can set the Evergreen Campaign by going to the Schedule tab in the Design Studio. Find the content you would like to set as your Evergreen Campaign, click the options button to open the menu, and then select 'Set Evergreen'.",
  },
  {
    question: "Where do I schedule my content?",
    answer:
      "Go to the Schedule tab in the Design Studio and find your content you'd like to schedule. Either click the schedule icon or click the options icon and select Schedule in the menu.",
  },
  {
    question: "How often can I schedule content?",
    answer:
      "Our scheduler updates content every 30 minutes, so you can have a different update on your site every 30 minutes!",
  },
  {
    question: "Can I choose what time my content will go live on my page?",
    answer:
      "Yes! Schedule your content at the time of your choosing in increments of 30 minutes and Content Scheduler will automatically update on your page. Do you need your restaurant menu to update every day at 3pm? No problem! Perhaps a new release coming out at 12am! Easy! Just schedule your update ahead of time and content scheduler will update your live site automatically.",
  },
  {
    question: "Will my content be responsive?",
    answer:
      "Yes! The Content Scheduler widget is fully responsive! Create versions of your content for mobile and desktop, including capability to hide content on mobile.",
  },
  {
    question: "Can I add my theme colors to Content Scheduler?",
    answer:
      "Yes! In order to make sure your colors are loaded into the Content Scheduler Design Studio, re-save your theme colors in the Wix editor. Re-saving your styles allows the Design Studio to load and save your website styles.",
  },
  {
    question:
      "What Keyboard Shortcuts can I use in the Content Scheduler Design Studio?",
    answer: `Use the arrow keys to change the position of an element by one pixel, backspace to delete an element, Shift + click to add or remove an element from a group.`,
  },
  {
    question:
      "How do I rename my widgets so I can tell them apart in the Dashboard?",
    answer:
      "You can rename your widgets from the Content Scheduler Settings panel in the Wix editor. Click the Settings button in the sidebar of the Settings window and then click ‘Widget nickname’",
  },
  {
    question: "I scheduled my content but it’s not showing as upcoming!",
    answer:
      "Make sure you click publish in the Wix editor after scheduling your content so that your schedule will be saved for your live site. Once you publish your site your scheduled content will show as upcoming in the Design Studio and your dashboard.",
  },
  {
    question: "Why is some of my scheduled content gray in the calendar?",
    answer:
      "Make sure you click publish in the Wix editor after scheduling your content so that your schedule will be saved for your live site. Schedule changes will remain pending until you publish your site. If you closed the editor, you may have to reschedule your content in the Design Studio before publishing.",
  },
];
