import styled from 'styled-components';

export const InfoBubbleContainer = styled.div`
  width: 100%;
  border-radius: 5px 5px 0 0;
  background-color: rgba(0, 17, 61, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[3]};
  z-index: 2;
`;

export const InfoTitle = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  color: ${(props) => props.theme.color.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoSchedule = styled.p<{ hasValue: boolean }>`
  font-size: 1.4rem;
  font-weight: normal;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  color: ${(props) => props.theme.color.white};
  opacity: ${(props) => (props.hasValue ? 1 : 0)};
`;
