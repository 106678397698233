import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const useMedia = () => {
  const [media, setMedia] = useState<"desktop" | "mobile">();
  const desktop = useMediaQuery("(min-width:501px)");
  const mobile = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    if (desktop) {
      setMedia("desktop");
    } else if (mobile) {
      setMedia("mobile");
    }
  }, [desktop, mobile]);

  return media;
};

export default useMedia;
