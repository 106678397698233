import React, { useEffect, useRef, useState } from 'react';
import {
  TitleStyles,
  IconContainer,
  InnerContainer,
  Container,
} from './Title.styles';
import { setCtaList } from '../../../redux/ctaSlice';
import { useDispatch } from 'react-redux';
import { getAllCtasFromDB, updateCtaInDB } from '../../../util/services';
import useGetInstance from '../../../hooks/useGetInstance';
import { setTitle } from '../../../redux/elementSlice';
import useGetCompId from '../../../hooks/useGetCompId';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  title: string;
  ctaId: string;
}

interface Cta {
  title: string;
  id: string;
}

const Title: React.FC<Props> = ({ title, ctaId }) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const instance = useGetInstance();
  const compId = useGetCompId();

  const [value, setValue] = useState(title);

  const handleTitleBlur = async (
    e: React.FocusEvent<HTMLInputElement>
  ): Promise<void> => {
    if (title !== value) {
      try {
        const cta: Cta = {
          id: ctaId,
          title: value,
        };
        dispatch(setTitle({ id: ctaId, title: value }));
        await updateCtaInDB(instance, compId, cta);
        const result = await getAllCtasFromDB(
          instance,
          undefined,
          compId
        );

        if (result) dispatch(setCtaList({ ctaList: result }));
      } catch (error: unknown) {
        if (error instanceof Error) console.error(error.message);
        return;
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && titleRef.current) {
      titleRef.current.blur();
    }
  };

  const handleIconKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter' && titleRef.current) {
      titleRef.current.select();
    }
  };

  const selectTitle = (e) => {
    if (titleRef.current) {
      titleRef.current.select();
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (title) setValue(title);
  }, [title]);

  return (
    <Container>
      <TitleStyles
        aria-label='Content Title'
        onKeyDown={handleKeyDown}
        ref={titleRef}
        value={value}
        onChange={handleChange}
        onBlur={handleTitleBlur}
      />
      <InnerContainer>
        <IconContainer
          title={'Edit title'}
          aria-label={'Edit title'}
          contentEditable={false}
          onClick={selectTitle}
          onKeyDown={handleIconKeyDown}>
          <EditIcon style={{ fontSize: '1.2rem', color: 'inherit' }} />
        </IconContainer>
      </InnerContainer>
    </Container>
  );
};

export default Title;
