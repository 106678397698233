import styled from 'styled-components';

export const ViewContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: auto;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  flex-direction: column;

  &:first-child {
    margin-bottom: 1rem;
  }
`;

export const Text = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.settings.text};
  text-align: center;
  line-height: 1.4;
`;

export const UpperSection = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
