import styled from 'styled-components';

export const AnnouncementContainer = styled.div`
  border: 1px solid ${(props) => props.theme.color.gray200};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  width: 30rem;
  min-width: 30rem;
  margin-left: 0;
  overflow: hidden;
`;

export const ThumbnailContainer = styled.div`
  height: 14.5rem;
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  display: flex;

  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing[3]};
  border-top: 1px solid ${(props) => props.theme.color.gray200};
  background: ${(props) => props.theme.color.white};
  border-radius: 0 0 5px 5px;

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing[3]};
  }
`;
