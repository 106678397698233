import { configureStore } from "@reduxjs/toolkit";
import sidebarViewReducer from "./sidebarViewSlice";
import currentComponentReducer from "./currentComponentSlice";
import elementReducer from "./elementSlice";
import calendarReducer from "./calendarSlice";
import unsavedStatusReducer from "./unsavedSlice";
import ctaReducer from "./ctaSlice";
import dragReducer from "./dragSlice";
import viewReducer from "./viewSlice";
import themeReducer from "./themeSlice";
import loadingReducer from "./loadingSlice";
import guidelineReducer from "./guidelineSlice";
import dashboardReducer from "./dashboardSlice";
import userReducer from "./userSlice";

const store = configureStore({
  reducer: {
    sidebarView: sidebarViewReducer,
    currentComponent: currentComponentReducer,
    elements: elementReducer,
    calendar: calendarReducer,
    unsavedStatus: unsavedStatusReducer,
    ctas: ctaReducer,
    drag: dragReducer,
    view: viewReducer,
    theme: themeReducer,
    loading: loadingReducer,
    guidelines: guidelineReducer,
    dashboard: dashboardReducer,
    user: userReducer,
  },
});

export const createStore = () => {
  return configureStore({
    reducer: {
      sidebarView: sidebarViewReducer,
      currentComponent: currentComponentReducer,
      elements: elementReducer,
      calendar: calendarReducer,
      unsavedStatus: unsavedStatusReducer,
      ctas: ctaReducer,
      drag: dragReducer,
      view: viewReducer,
      theme: themeReducer,
      loading: loadingReducer,
      guidelines: guidelineReducer,
      dashboard: dashboardReducer,
      user: userReducer,
    },
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
