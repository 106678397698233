import React from 'react';
import useGetViewMode from '../../hooks/useGetViewMode';
import EditorWidget from '../EditorWidget/EditorWidget.component';
import Widget from '../Widget/Widget.component';

const WidgetRouter = () => {
  const viewMode = useGetViewMode();
console.log('viewMode: ', process.env.NODE_ENV)
  if (viewMode === 'editor') {
    return <EditorWidget />;
  } else if (viewMode === 'site' || process.env.NODE_ENV === 'development') {
    return <Widget />;

  } else {
    return <div></div>;
  }
};

export default WidgetRouter;
