import styled from 'styled-components';

export const Container = styled.button<{
  restricted: boolean;
  premium: boolean;
}>`
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: all 0.1s ease-in-out;
  position: relative;
  pointer-events: ${(props) =>
    props.restricted && props.premium ? 'none' : 'auto'};
  background: ${(props) =>
    props.restricted && props.premium ? props.theme.color.gray200 : '#FFF'};
  border: none;

  &:hover {
    background: ${(props) => props.theme.color.gray100};
  }
`;

export const Icon = styled.img`
  max-width: 5rem;
  max-height: 5rem;
  min-width: 5rem;
  min-width: 5rem;
`;

export const CrownContainer = styled.div<{
  restricted?: boolean;
  showSidebar?: boolean;
}>`
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 2;
  animation: ${(props) =>
    props.showSidebar ? `show 150ms forwards` : `hide 0ms forwards`};
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
