import React from 'react';
import { useTemplate } from '../../../hooks/useTemplate';
import MenuButton from '../MenuButton/MenuButton.component';
import { PlusCircleOutlined } from '@ant-design/icons';
import { createNewCTA } from '../../../util/services';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCurrentCta from '../../../hooks/useGetCurrentCta';
import { NEW_CTA } from '../../../enums';
import { MenuContainer, ButtonContainer } from './TemplateMenu.styles';
import { Title } from '../../../styles/util';
import useGetCompId from '../../../hooks/useGetCompId';
import { templateList } from '../../../templateEnums';
import TemplateItem from '../TemplateItem/TemplateItem.component';

const TemplateMenu: React.FC = () => {
  const handleTemplate = useTemplate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const instance = useGetInstance();
  const currentCta = useGetCurrentCta();
  const compId = useGetCompId();

  const handleNewCTA = () => {
      createNewCTA(
        instance,
        compId,
        currentCta,
        NEW_CTA,
        dispatch,
        navigate
      );
  };

  return (
    <MenuContainer data-testid='template-menu'>
      <ButtonContainer>
        <MenuButton
          fullWidth={true}
          label='Create New Design'
          variant={'secondary'}
          onClick={handleNewCTA}
          icon={
            <PlusCircleOutlined
              style={{ fontSize: '16px', verticalAlign: 'center' }}
            />
          }
        />
      </ButtonContainer>

      <Title>Templates</Title>
      <ButtonContainer>
        <MenuButton
          fullWidth={true}
          label='Blank Template'
          variant={'outline'}
          onClick={() => handleTemplate(0)}
          icon={
            <PlusCircleOutlined
              style={{ fontSize: '16px', verticalAlign: 'center' }}
            />
          }
        />
      </ButtonContainer>
      {templateList &&
        templateList
          .sort((a, b) => Number(a.premium) - Number(b.premium))
          .map((item) => (
            <TemplateItem
              key={item.id}
              premium={item.premium}
              id={item.id}
              src={item.src}
              alt={item.alt}
            />
          ))}
    </MenuContainer>
  );
};

export default TemplateMenu;
