const spacer = 1;

export const theme = {
  text: {
    xs: "1rem",
    sm: "1.2rem",
    base: "1.4rem",
    md: "1.6rem",
    lg: "1.8rem",
    xl: "2rem",
    xxl: "2.4rem",
    xxxl: "3.2rem",
    xxxxl: "3.6rem",
    huge: "4.2rem",
    xhuge: "4.8rem",
    xxhuge: "5.2rem",
    xxxhuge: "6rem",
    massive: "7.2rem",
  },
  color: {
    backdrop: "#f0f2f6",
    primary: "#00113D",
    btnPrimaryLight: "#deebff",
    btnPrimary: "#7597EE",
    btnPrimaryHover: "#6788DA",
    secondary: "#00FDFD",
    secondaryHover: "#00EEEE",
    gray50: "#f5f5f5",
    gray100: "rgb(243 244 246)",
    gray200: "rgb(229 231 235)",
    gray300: "rgb(209 213 219)",
    gray400: "rgb(156 163 175)",
    gray500: "rgb(107 114 128)",
    gray600: "rgb(75 85 99)",
    gray700: "rgb(55 65 81)",
    gray800: "rgb(31 41 55)",
    gray900: "rgb(17 24 39)",
    orange500: "rgb(255,217,142)",
    red50: "rgb(254 242 242)",
    red100: "rgb(254 226 226)",
    red200: "rgb(254 202 202)",
    red300: "rgb(252 165 165)",
    red400: "rgb(248 113 113)",
    red500: "rgb(239 68 68)",
    red600: "rgb(220 38 38)",
    red700: "rgb(185 28 28)",
    red800: "rgb(153 27 27)",
    red900: "rgb(127 29 29)",
    green50: "#f0fdf4",
    green100: "#dcfce7",
    green200: "#bbf7d0",
    green300: "#86efac",
    green400: "#4ade80",
    green500: "#22c55e",
    green600: "#16a34a",
    green700: "#15803d",
    green800: "#166534",
    green900: "#14532d",
    blue500: "#1A49CC",
    blue600: "#1349ff",
    blue700: "#22335d",
    blue800: "#1B1D3D",
    blue900: "#00113d",
    white: "#FFF",
    black: "#222",
    purple: "#8C21AF",
    settings: {
      blue50: "#F6FBFF",
      blue100: "#e1f4fc",
      blue150: "#d0e7fb",
      blue200: "#c7e2fa",
      blue300: "#a2cff6",
      blue400: "rgba(56,152,236,0.91)",
      blue500: "#3898EC",
      blue600: "#1f8bea",
      blue700: "#1376cd",
      blue800: "#1061a8",
      blue900: "#oc4b83",
      pink50: "#f2e4f7",
      pink100: "#EEDBF4",
      pink200: "#A658BE",
      gray50: "#F0F3F5",
      gray100: "#C1CFD8",
      text: "#325166",
    },
  },
  spacing: {
    0: "0",
    1: spacer * 0.25 + "rem",
    2: spacer * 0.5 + "rem",
    3: spacer + "rem",
    4: spacer * 1.5 + "rem",
    5: spacer * 2 + "rem",
    6: spacer * 3 + "rem",
    7: spacer * 4.5 + "rem",
    8: spacer * 6 + "rem",
    9: spacer * 9 + "rem",
    10: spacer * 12 + "rem",
  },
  shadow: {
    1: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    2: "0 3px 3px 0 rgb(0 0 0 / 0.05), 0 1px 2px -1px rgb(0 0 0 / 0.05)",
    3: "0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)",
    4: "0 10px 15px -3px rgb(0 0 0 / 0.05), 0 4px 6px -4px rgb(0 0 0 / 0.05)",
    5: "0 20px 25px -5px rgb(0 0 0 / 0.05), 0 8px 10px -6px rgb(0 0 0 / 0.05)",
    6: "0 25px 50px -12px rgb(0 0 0 / 0.05)",
    7: "0 4px 42px 11px rgb(0 0 0 / 0.1)",
    inner: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
};
