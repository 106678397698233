import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Container,
  QuestionContainer,
  Question,
  IconContainer,
  AnswerContainer,
  Answer,
  BottomBorder,
} from './Accordion.styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface Props {
  question: string;
  answer: string;
}

const Accordion: React.FC<Props> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  const [answerHeight, setAnswerHeight] = useState(0);
  const answerRef = useRef<HTMLDivElement | null>(null);
  const [bgColor, setBgColor] = useState<string>('#FFFFFF');

  useLayoutEffect(() => {
    if (answerRef.current) {
      setAnswerHeight(answerRef.current?.getBoundingClientRect().height);
    }
  }, [setAnswerHeight]);

  useLayoutEffect(() => {
    if (window.location.pathname.match(/design/gi)) {
      setBgColor('#FFFFFF');
    } else {
      setBgColor('#f5f5f5');
    }
  }, []);

  return (
    <Container>
      <QuestionContainer
        bgColor={bgColor}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setOpen(!open);
        }}
        onClick={() => setOpen(!open)}>
        <Question>{question}</Question>
        <IconContainer>
          {!open && (
            <AddCircleOutlineIcon
              style={{ fontSize: '2.4rem', color: 'inherit' }}
            />
          )}
          {open && (
            <RemoveCircleOutlineIcon
              style={{ fontSize: '2.4rem', color: 'inherit' }}
            />
          )}
        </IconContainer>
      </QuestionContainer>
      <AnswerContainer height={answerHeight} open={open}>
        <Answer ref={answerRef}>{answer}</Answer>
      </AnswerContainer>
      <BottomBorder />
    </Container>
  );
};

export default Accordion;
