import styled from 'styled-components';

export const ContextMenuItemStyles = styled.button<{
  btnId: number;
  onClick: React.MouseEventHandler;
  disable?: boolean;
}>`
  position: relative;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${(props) => props.theme.spacing[3]};
  border: none;
  background-color: #fff;
  color: ${(props) =>
    props.disable && props.btnId === 4
      ? props.theme.color.gray300
      : props.theme.color.gray600};
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${(props) =>
      props.btnId === 7
        ? props.theme.color.red100
        : props.btnId === 4 && props.disable
        ? '#FFF'
        : props.theme.color.gray100};
    color: ${(props) =>
      props.btnId === 7
        ? props.theme.color.red500
        : props.btnId === 4 && props.disable
        ? props.theme.color.gray300
        : props.theme.color.gray600};
  }

  & > *:first-child {
    margin-right: ${(props) => props.theme.spacing[3]};
  }
`;
