import React from 'react';
import {
  Container,
  UpperSection,
  LowerSection,
  LinkText,
} from './SupportView.styles';
import Button from '../Button/Button.component';
import { Text } from '../../../styles/settings/components';
import { Wix } from '../../../util/Wix';
import { handleClose, handleOpenBillingPage } from '../../../util/functions';
import usePlanId from '../../../hooks/usePlanId';
import useGetInstance from '../../../hooks/useGetInstance';
import useSetPlanId from '../../../hooks/useSetPlanId';

const SupportView: React.FC = () => {
  const instance = useGetInstance();
  useSetPlanId(instance);
  const planId = usePlanId();

  const handleReview = () => {
    Wix.Settings.openReviewInfo();
  };

  const handleTutorials = () => {
    const url =
      process.env.REACT_APP_ENV === 'staging'
        ? `https://staging.contentscheduler.app/design?instance=${instance}&view=help`
        : `https://contentscheduler.app/design?instance=${instance}&view=help`; // Production URL
    const testUrl = `/design?instance=${instance}&view=help`;

    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      Wix.Settings.openModal(
        url,
        '85%',
        '85%',
        'Content Scheduler - Design Studio',
        () => handleClose()
      );
    } else {
      const w = window.open(testUrl, '_blank');
      if (w) {
        w.focus();
      }
    }
  };

  return (
    <Container>
      <UpperSection>
        <Text>
          If you've enjoyed Content Scheduler, please take a moment to leave us
          a review.
        </Text>

        <Button
          variant={'primary'}
          label={'Leave a review'}
          onClick={handleReview}
        />
        <Text>
          If you have any questions, you can contact us at{' '}
          <a href='mailto:support@oceanapps.com'>support@oceanapps.com</a>, or
          visit the Help tab in the Content Scheduler Dashboard to view our FAQ
          and tutorial videos.
        </Text>
        <Button
          variant={'primary'}
          label={'View Tutorials'}
          onClick={handleTutorials}
        />
      </UpperSection>

      {planId !== 'professional' && (
        <LowerSection>
          <LinkText onClick={handleOpenBillingPage}>
            Get more templates and unlimited scheduled content!
          </LinkText>
          <Button
            label='Upgrade Now'
            variant='tertiary'
            onClick={handleOpenBillingPage}
          />
        </LowerSection>
      )}
    </Container>
  );
};

export default SupportView;
