import React from 'react';
import { Container, MenuItem } from './TemplateContextMenu.styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createNewCTA } from '../../../util/services';
import { NEW_CTA } from '../../../enums';
import { Wix } from '../../../util/Wix';
import { getTemplateStyles, handleClose } from '../../../util/functions';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCompId from '../../../hooks/useGetCompId';

interface Props {
  templateId: number;
  setShowMenu: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const TemplateContextMenu: React.FC<Props> = ({ templateId, setShowMenu }) => {
  const dispatch = useAppDispatch();
  const instance = useGetInstance();
  const compId = useGetCompId();

  const handleTemplate = async (id: number) => {
    await createCta(id);
    setShowMenu(false);
  };

  const createCta = async (templateId) => {
      try {
        const ctaId = await createNewCTA(
          instance,
          compId,
          null,
          NEW_CTA,
          dispatch,
          null
        );

        const url =
          process.env.REACT_APP_ENV === 'staging'
            ? `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}&template-id=${templateId}`
            : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}&template-id=${templateId}`; // Production URL
        const testUrl = `/design?instance=${instance}&cta-id=${ctaId}&template-id=${templateId}`;

        if (
          process.env.NODE_ENV === 'production' ||
          process.env.REACT_APP_ENV === 'staging'
        ) {
          Wix.Settings.openModal(
            url,
            '85%',
            '85%',
            'Content Scheduler - Design Studio',
            () => handleClose()
          );
        } else {
          const w = window.open(testUrl, '_blank');
          if (w) {
            w.focus();
          }
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }

  };

  const handlePreview = (templateId: number) => {
    const url =
      process.env.REACT_APP_ENV === 'staging'
        ? `https://staging.contentscheduler.app/template-preview?&id=${templateId}`
        : `https://contentscheduler.app/template-preview?&id=${templateId}`; // Production URL
    const testUrl = `/template-preview?&id=${templateId}`;
    const templateCtaStyles = getTemplateStyles(templateId);
    setShowMenu(false);
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      Wix.Settings.openModal(
        url,
        templateCtaStyles.width,
        templateCtaStyles.height,
        'Content Scheduler - Template Preview',
        () => Wix.Settings.closeWindow({ target: 'ALL' })
      );
    } else {
      const w = window.open(testUrl, '_blank');
      if (w) {
        w.focus();
      }
    }
  };
  return (
    <Container>
      <MenuItem onClick={() => handleTemplate(templateId)} id={'create'}>
        <AddCircleIcon style={{ fontSize: 18, color: 'inherit' }} />
        Create new
      </MenuItem>
      <MenuItem onClick={() => handlePreview(templateId)} id={'preview'}>
        <VisibilityIcon style={{ fontSize: 18, color: 'inherit' }} />
        Preview
      </MenuItem>
    </Container>
  );
};

export default TemplateContextMenu;
