import React, { useEffect, useLayoutEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import useGetCtas from '../../../hooks/useGetCtas';
import { useDispatch } from 'react-redux';
import {
  setCTAId,
  setCTATitle,
  setShowModal,
} from '../../../redux/calendarSlice';
import CalendarPopover from '../CalendarPopover/CalendarPopover.component';
import { Title } from './Calendar.styles';
import useShowSidebar from '../../../hooks/useShowSidebar';
import useForceUpdate from '../../../hooks/useForceUpdate';
import useCalendarEvents from '../../../hooks/useCalendarEvents';
import usePendingEvents from '../../../hooks/usePendingEvents';

interface ShowPopover {
  show: boolean;
  event: any;
  x: number;
  y: number;
}

const Calendar: React.FC = () => {
  const [showPopover, setShowPopover] = useState<ShowPopover>({
    show: false,
  } as ShowPopover);
  const showSidebar = useShowSidebar();
  const forceUpdate = useForceUpdate();

  const ctaList = useGetCtas();

  const events = useCalendarEvents(ctaList);
  const pendingEvents = usePendingEvents();

  const dispatch = useDispatch();

  const handleEventClick = (e) => {
    dispatch(setCTAId({ id: e.event.id }));
    dispatch(setCTATitle({ title: e.event.title }));
    dispatch(setShowModal({ showModal: true }));
  };

  const handleEventMount = (arg) => {
    if (arg.event.groupId === 'pending') {
      const el = arg.el.querySelector('.fc-event-time');
      const img = document.createElement('img');
      img.src = 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/pending.png';
      img.alt = 'pending';
      img.width = 18;
      img.height = 18;
      img.className = 'pending-icon';
      if (!el.classList.contains('pending')) {
        el.prepend(img);
        el.classList.add('pending');
      }
    }
  };

  const handlePopover = (arg) => {
    setShowPopover({
      show: true,
      event: arg.event,
      x: arg.jsEvent.pageX,
      y: arg.jsEvent.pageY,
    });
  };

  const handleClosePopover = () => {
    setShowPopover((args) => ({ ...args, show: false }));
  };

  // Force update when sidebar is shown or hidden
  useEffect(() => {
    setTimeout(() => forceUpdate(), 250);
  }, [showSidebar, forceUpdate]);

  useLayoutEffect(() => {}, [events, pendingEvents]);

  return (
    <>
      <Title>My Schedule</Title>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        height={'80vh'}
        fixedWeekCount={false}
        eventSources={[events, pendingEvents]}
        eventClick={handleEventClick}
        eventDidMount={handleEventMount}
        eventMouseEnter={handlePopover}
        eventMouseLeave={handleClosePopover}
      />

      {showPopover.show && (
        <CalendarPopover
          popoverEvent={showPopover.event}
          x={showPopover.x && showPopover.x}
          y={showPopover.y && showPopover.y}
        />
      )}
    </>
  );
};

export default Calendar;
