import {
  TemplateItemStyles,
  Image,
  CrownContainer,
} from './TemplateItem.styles';
import React from 'react';
import { useTemplate } from '../../../hooks/useTemplate';
import Crown from '../../General/Crown/Crown.component';
import useShowSidebar from '../../../hooks/useShowSidebar';

import useUser from '../../../hooks/useUser';
import useRestrictUser from '../../../hooks/useRestrictUser';

interface Props {
  src: string;
  alt: string;
  id: number;
  premium: boolean;
}

const TemplateItem: React.FC<Props> = ({ src, alt, id, premium }) => {
  const handleTemplate = useTemplate();
  const showSidebar = useShowSidebar();

  const user = useUser();
  const restricted = useRestrictUser(user);

  return (
    <TemplateItemStyles restricted={restricted} premium={premium}>
      {restricted && premium && (
        <CrownContainer showSidebar={showSidebar}>
          <Crown />
        </CrownContainer>
      )}

      <Image
        aria-label={'template ' + id}
        restricted={restricted}
        premium={premium}
        src={src}
        alt={alt}
        onClick={() => handleTemplate(id)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleTemplate(id);
        }}
      />
    </TemplateItemStyles>
  );
};

export default TemplateItem;
