import React, { useEffect, useState } from 'react';
import { TopBarStyles, Flex, Divider } from './TopBar.styles';
import useCurrentComponent from '../../../hooks/useCurrentComponent';
import NumberInput from '../NumberInput/NumberInput.component';
import CustomColorPicker from '../ColorPicker/ColorPicker.component';
import LineHeightInput from '../LineHeightInput/LineHeightInput.component';
import LetterSpacing from '../LetterSpacing/LetterSpacing.component';
import FontFormat from '../FontFormat/FontFormat.component';
import TextAlign from '../TextAlign/TextAlign.component';
import DeleteIcon from '../DeleteIcon/DeleteIcon.component';
import DuplicateIcon from '../DuplicateIcon/DuplicateIcon.component';
import SendLayerBackIcon from '../SendLayerBackIcon/SendLayerBackIcon.component';
import SendLayerForwardIcon from '../SendLayerForwardIcon/SendLayerForwardIcon.component';
import UppercaseButton from '../UppercaseButton/UppercaseButton.component';
import BordersMenu from '../BordersMenu/BordersMenu.component';
import OpacityMenu from '../OpacityMenu/OpacityMenu.component';
import MenuButton from '../MenuButton/MenuButton.component';
import MoreOptions from '../MoreOptions/MoreOptions.component';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopBarMenu from '../TopBarMenu/TopBarMenu.component';
import { Cover } from '../../../styles/util';
import FontPickerWrapper from '../FontPicker/FontPickerWrapper';
import useElements from '../../../hooks/useElements';
import VisibilityButton from '../VisibilityButton/VisibilityButton.component';
import LinkIconBtn from '../LinkIconBtn/LinkIconBtn.component';
import useGetSelectedItems from '../../../hooks/useGetSelectedItems';

const TopBar: React.FC = () => {
  const currentComponent = useCurrentComponent();
  const [showBordersMenu, setShowBordersMenu] = useState<boolean>(false);
  const [showOpacityMenu, setShowOpacityMenu] = useState<boolean>(false);

  const [showResponsiveMenu, setShowResponsiveMenu] = useState<boolean>(false);
  const [backgroundImgExists, setBackgroundImgExists] =
    useState<boolean>(false);
  const [media, setMedia] = useState<string>('desktop');
  const elements = useElements().present;
  const selected = useGetSelectedItems();

  // Holds Icons that are placed into drop menu when screen is too small to fit all of them
  const [items, setItems] = useState<React.ReactElement[]>([]);

  const xs = useMediaQuery('(max-width: 835px)');
  const sm = useMediaQuery('(max-width: 1008px)');
  const md = useMediaQuery('(max-width: 1090px)');
  const lg = useMediaQuery('(max-width: 1138px)');
  const xl = useMediaQuery('(max-width: 1298px)');
  const xxl = useMediaQuery('(max-width: 1343px)');
  const desktop = useMediaQuery('(min-width: 1343px)');

  // Set backgroundImg in state if it exists
  useEffect(() => {
    if (elements) {
      const cta = elements.find((element) => element.type === 'cta');
      if (cta?.backgroundImg?.desktop.src || cta?.backgroundImg?.mobile.src) {
        setBackgroundImgExists(true);
      }
    }
  }, [elements]);

  useEffect(() => {
    if (xs) {
      setMedia('xs');
    } else if (sm) {
      setMedia('sm');
    } else if (md) {
      setMedia('md');
    } else if (lg) {
      setMedia('lg');
    } else if (xl) {
      setMedia('xl');
    } else if (xxl) {
      setMedia('xxl');
    } else if (desktop) {
      setMedia('desktop');
    }
  }, [xs, sm, md, lg, xl, xxl, desktop]);

  // Set items[] for TopBarMenu based on media query
  useEffect(() => {
    switch (media) {
      case 'desktop':
        setItems([]);
        break;
      case 'xxl':
        setItems([
          <DuplicateIcon key={'duplicate-icon'} />,
          <DeleteIcon key={'delete-icon'} />,
        ]);
        break;
      case 'xl':
        if (currentComponent.type === 'typography') {
          setItems([
            <LinkIconBtn />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        } else {
          setItems([
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        }

        break;
      case 'lg':
        if (currentComponent.type === 'typography') {
          setItems([
            <UppercaseButton key={'uppercase-button'} />,
            <LinkIconBtn />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        } else {
          setItems([
            <UppercaseButton key={'uppercase-button'} />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        }

        break;
      case 'md':
        if (currentComponent.type === 'typography') {
          setItems([
            <LineHeightInput
              id='lineHeight'
              label='Line Spacing'
              key={'line-height-input'}
            />,
            <LetterSpacing
              id='letterSpacing'
              key={'letterSpacing'}
              label='Letter Spacing'
            />,
            <UppercaseButton key={'uppercase-button'} />,
            <LinkIconBtn />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        } else {
          setItems([
            <LineHeightInput
              id='lineHeight'
              label='Line Spacing'
              key={'line-height-input'}
            />,
            <LetterSpacing
              id='letterSpacing'
              key={'letterSpacing'}
              label='Letter Spacing'
            />,
            <UppercaseButton key={'uppercase-button'} />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        }

        break;
      case 'sm':
        if (currentComponent.type === 'button') {
          setItems([
            <LineHeightInput
              id='lineHeight'
              label='Line Spacing'
              key={'line-height-input'}
            />,
            <LetterSpacing
              id='letterSpacing'
              key={'letterSpacing'}
              label='Letter Spacing'
            />,
            <UppercaseButton key={'uppercase-button'} />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        } else if (currentComponent.type === 'typography') {
          setItems([
            <TextAlign key='text-align' />,
            <LineHeightInput
              id='lineHeight'
              label='Line Spacing'
              key={'line-height-input'}
            />,
            <LetterSpacing
              id='letterSpacing'
              key={'letterSpacing'}
              label='Letter Spacing'
            />,
            <UppercaseButton key={'uppercase-button'} />,
            <LinkIconBtn />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        } else {
          setItems([
            <TextAlign key='text-align' />,
            <LineHeightInput
              id='lineHeight'
              label='Line Spacing'
              key={'line-height-input'}
            />,
            <LetterSpacing
              id='letterSpacing'
              key={'letterSpacing'}
              label='Letter Spacing'
            />,
            <UppercaseButton key={'uppercase-button'} />,
            <VisibilityButton key={'visibility-icon'} />,
            <SendLayerBackIcon key={'send-layer-back-icon'} />,
            <SendLayerForwardIcon key={'send-layer-forward-icon'} />,
            <DuplicateIcon key={'duplicate-icon'} />,
            <DeleteIcon key={'delete-icon'} />,
          ]);
        }

        break;
      default:
        return;
    }
  }, [media, currentComponent]);

  // Close TopBarMenu when screen is full width
  useEffect(() => {
    if (media === 'desktop') {
      setShowResponsiveMenu(false);
    }
  }, [media]);

  // Show/hide borders menu
  const handleBorders = () => {
    if (showOpacityMenu) {
      setShowOpacityMenu(false);
    }

    setShowBordersMenu(!showBordersMenu);
  };

  if (currentComponent.type === 'typography' && selected.length < 2) {
    return (
      <TopBarStyles data-testid='top-bar'>
        <FontPickerWrapper />
        <NumberInput
          id='fontSize'
          style={{ maxWidth: '7rem', minWidth: '7rem' }}
        />
        <CustomColorPicker id={'fontColor'} style={{ marginBottom: '0' }} />
        <FontFormat />
        {(media === 'desktop' ||
          media === 'xxl' ||
          media === 'xl' ||
          media === 'lg' ||
          media === 'md') && <TextAlign />}

        {(media === 'desktop' ||
          media === 'xxl' ||
          media === 'xl' ||
          media === 'lg') && (
          <>
            <LineHeightInput id='lineHeight' label='Line Spacing' />
            <LetterSpacing id='letterSpacing' label='Letter Spacing' />
          </>
        )}
        {(media === 'desktop' || media === 'xxl' || media === 'xl') && (
          <UppercaseButton />
        )}

        {(media === 'desktop' || media === 'xxl') && (
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <LinkIconBtn />
            <VisibilityButton style={{ marginRight: '14px' }} />
            <SendLayerBackIcon />
            <SendLayerForwardIcon />
          </div>
        )}

        {media === 'desktop' && (
          <>
            <Divider />
            <div style={{ display: 'flex' }}>
              <DuplicateIcon />
              <DeleteIcon />
            </div>
          </>
        )}
        {media !== 'desktop' && (
          <Flex
            style={{
              justifyContent: 'flex-end',
              width: '100%',
              marginRight: '0 ',
            }}>
            <MoreOptions
              active={showResponsiveMenu}
              onClick={() => setShowResponsiveMenu(!showResponsiveMenu)}
            />
          </Flex>
        )}
        {showResponsiveMenu && (
          <>
            <TopBarMenu items={items} />
            <Cover onClick={() => setShowResponsiveMenu(false)} />
          </>
        )}
      </TopBarStyles>
    );
  } else if (currentComponent.type === 'cta' && selected.length < 2) {
    return (
      <TopBarStyles data-testid='top-bar'>
        <CustomColorPicker id={'background'} style={{ marginBottom: '0' }} />
        <MenuButton
          variant='text'
          label='Opacity'
          active={showOpacityMenu}
          onClick={() => setShowOpacityMenu(!showOpacityMenu)}
        />
        {showOpacityMenu && (
          <>
            <OpacityMenu setShowOpacityMenu={setShowOpacityMenu} />
          </>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          {backgroundImgExists && <DeleteIcon />}
        </div>
      </TopBarStyles>
    );
  } else if (
    (currentComponent.type === 'image' ||
      currentComponent.type === 'imageSvg') &&
    selected.length < 2
  ) {
    return (
      <TopBarStyles data-testid='top-bar'>
        <MenuButton
          id='bordersButton'
          label='Borders'
          variant='text'
          active={showBordersMenu}
          onClick={handleBorders}
        />
        {showBordersMenu && (
          <>
            <BordersMenu setShowBordersMenu={setShowBordersMenu} />
          </>
        )}
        <MenuButton
          variant='text'
          label='Opacity'
          active={showOpacityMenu}
          onClick={() => setShowOpacityMenu(!showOpacityMenu)}
        />

        {showOpacityMenu && (
          <>
            <OpacityMenu setShowOpacityMenu={setShowOpacityMenu} />
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',

            width: '100%',
          }}>
          {/*<LinkIconBtn onClick={() => {}} id="linkButton" />*/}
          <Divider />
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <VisibilityButton style={{ marginRight: '14px' }} />
          <SendLayerBackIcon />
          <SendLayerForwardIcon />
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <DuplicateIcon />
          <DeleteIcon />
        </div>
      </TopBarStyles>
    );
  } else if (currentComponent.type === 'rawSvg' && selected.length < 2) {
    return (
      <TopBarStyles data-testid='top-bar'>
        <CustomColorPicker id={'background'} style={{ marginBottom: '0' }} />
        {/*<MenuButton*/}
        {/*  id="bordersButton"*/}
        {/*  label="Borders"*/}
        {/*  variant="text"*/}
        {/*  active={showBordersMenu}*/}
        {/*  onClick={handleBorders}*/}
        {/*/>*/}
        {showBordersMenu && (
          <>
            <BordersMenu setShowBordersMenu={setShowBordersMenu} />
          </>
        )}
        <MenuButton
          id='effectsButton'
          variant='text'
          label='Opacity'
          active={showOpacityMenu}
          onClick={() => setShowOpacityMenu(!showOpacityMenu)}
        />

        {showOpacityMenu && (
          <>
            <OpacityMenu setShowOpacityMenu={setShowOpacityMenu} />
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',

            width: '100%',
          }}>
          {/*<LinkIconBtn onClick={() => {}} id="linkButton" />*/}
          <Divider />
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <VisibilityButton style={{ marginRight: '14px' }} />
          <SendLayerBackIcon />
          <SendLayerForwardIcon />
        </div>
        <Divider />
        <div style={{ display: 'flex' }}>
          <DuplicateIcon />
          <DeleteIcon />
        </div>
      </TopBarStyles>
    );
  } else if (currentComponent.type === 'button' && selected.length < 2) {
    return (
      <TopBarStyles data-testid='top-bar'>
        <FontPickerWrapper />
        <NumberInput
          id='fontSize'
          style={{ maxWidth: '7rem', minWidth: '7rem' }}
        />

        <FontFormat />

        {media === 'desktop' ||
          media === 'xxl' ||
          media === 'xl' ||
          media === 'lg' ||
          media === 'md'}
        {(media === 'desktop' ||
          media === 'xxl' ||
          media === 'xl' ||
          media === 'lg') && (
          <>
            <LineHeightInput id='lineHeight' label='Line Spacing' />
            <LetterSpacing id='letterSpacing' label='Letter Spacing' />
          </>
        )}
        {(media === 'desktop' || media === 'xxl' || media === 'xl') && (
          <UppercaseButton />
        )}
        {(media === 'desktop' || media === 'xxl') && (
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <VisibilityButton style={{ marginRight: '14px' }} />
            <SendLayerBackIcon />
            <SendLayerForwardIcon />
          </div>
        )}

        {media === 'desktop' && (
          <>
            <Divider />
            <div style={{ display: 'flex' }}>
              <DuplicateIcon />
              <DeleteIcon />
            </div>
          </>
        )}
        {media !== 'desktop' && (
          <Flex
            style={{
              justifyContent: 'flex-end',
              width: '100%',
              marginRight: '0 ',
            }}>
            <MoreOptions
              active={showResponsiveMenu}
              onClick={() => setShowResponsiveMenu(!showResponsiveMenu)}
            />
          </Flex>
        )}
        {showResponsiveMenu && (
          <>
            <TopBarMenu items={items} />
            <Cover onClick={() => setShowResponsiveMenu(false)} />
          </>
        )}
      </TopBarStyles>
    );
  } else {
    return <TopBarStyles data-testid='top-bar'></TopBarStyles>;
  }
};

export default TopBar;
