import { useEffect, useLayoutEffect, useState } from "react";
import { getActiveCtaFromDB } from "../util/services";

import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { setLoading } from "../redux/loadingSlice";
import { Cta } from "../types";

const useGetActiveCta = (instance: string, compId?: string): Cta => {
  const dispatch = useAppDispatch();
  const [activeCta, setActiveCta] = useState<Cta>({} as Cta);

  const [retries, setRetries] = useState(0);
  const previewCta = useAppSelector((state) => state.ctas.previewCta);

  useLayoutEffect(() => {
    const controller = new AbortController();

    if (instance && compId && !previewCta.id) {
      dispatch(setLoading({ loading: true }));

      getActiveCtaFromDB(instance, compId).then((res) => {
        if (res) {
          setActiveCta(res);
        } else {
          if (retries < 2) {
            setTimeout(() => {
              setRetries(retries + 1);
            }, 1000);
          } else {
            dispatch(setLoading({ loading: false }));
          }
        }
      });
    }

    return () => {
      controller.abort();
    };
  }, [instance, dispatch, compId, retries, previewCta]);

  useEffect(() => {
    if (activeCta.id) {
      dispatch(setLoading({ loading: false }));
    }
  }, [activeCta, dispatch]);

  return activeCta;
};

export default useGetActiveCta;
