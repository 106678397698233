import React, { useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { Container, CTAContainer } from './CTA.styles';
import { setCurrentComponent } from '../../../redux/currentComponentSlice';
import { setSelected, setTargetStartPos } from '../../../redux/dragSlice';
import { setActiveSidebarView } from '../../../redux/sidebarViewSlice';
import useElements from '../../../hooks/useElements';
import useGetCurrentCtaId from '../../../hooks/useGetCurrentCtaId';
import useGetCurrentCta from '../../../hooks/useGetCurrentCta';
import useGetEditingMode from '../../../hooks/useGetEditingMode';
import useGetSelectedItems from '../../../hooks/useGetSelectedItems';
import Title from '../Title/Title.component';
import BackgroundColor from '../BackgroundColor/BackgroundColor.component';
import useZoom from '../../../hooks/useZoom';
import useGuidelines from '../../../hooks/useGuidelines';
import { elementMap } from '../../../util/componentMaps';
import BackgroundImage from '../BackgroundImage/BackgroundImage.component';
import useCurrentComponent from '../../../hooks/useCurrentComponent';
import useSidebarView from '../../../hooks/useSidebarView';
import useLoading from '../../../hooks/useLoading';
import SkeletonComponent from '../../General/SkeletonComponent/SkeletonComponent.component';
import useSetElStyle from '../../../hooks/useSetElStyle';
import useSetTargetDelta from '../../../hooks/useSetTargetDelta';
import useGetCanvas from '../../../hooks/useGetCanvas';
import useGetElementRect from '../../../hooks/useGetElementRect';

interface Props {
  ref: React.ForwardedRef<HTMLDivElement>;
}

const CTA: React.FC<Props> = React.forwardRef(({ ...props }, ref) => {
  const dispatch = useAppDispatch();
  const elements = useElements().present;
  const editingMode = useGetEditingMode();
  const selected = useGetSelectedItems();
  const scale = useZoom();
  const currentComponent = useCurrentComponent();
  const sidebarView = useSidebarView();
  const loading = useLoading();
  const canvasId = useGetCurrentCtaId();
  const currentCta = useGetCurrentCta();
  const myRef = useRef<HTMLDivElement | null>(null);
  const [showHorizontalGuideline, showVerticalGuideline] = useGuidelines();
  const [target, setTarget] = useState<{ x: 0; y: 0; id: null | string }>({
    x: 0,
    y: 0,
    id: null,
  });
  const targetStartPos = useAppSelector((state) => state.drag.targetStartPos);
  const canvasElement = useGetCanvas(canvasId, elements);
  const [delta, setDelta] = useSetTargetDelta(
    target,
    setTarget,
    targetStartPos
  );
  const elStyle = useSetElStyle(canvasElement, editingMode);
  const rect = useGetElementRect(elStyle);

  // Passed to MasterElement to Draggable component onStart event. If not holding shift key, set targetStartPos to current position, and set target to current position.
  const startEvent = useCallback(
    (e, data) => {
      const { x, y, node } = data;
      const { id } = node;

      if (!e.shiftKey) {
        dispatch(setTargetStartPos({ pos: { x, y }, id }));

        setTarget({ x, y, id });
      }
    },
    [dispatch]
  );

  const handleOnClick: React.MouseEventHandler = useCallback(() => {
    if (canvasElement !== null && currentComponent.type !== 'cta')
      dispatch(setCurrentComponent({ id: canvasElement.id, type: 'cta' }));

    if (sidebarView !== 5) dispatch(setActiveSidebarView({ id: 5 }));

    if (selected.length > 0) dispatch(setSelected({ selected: [] }));
  }, [dispatch, canvasElement, selected, sidebarView, currentComponent]);

  const addSelected: React.MouseEventHandler<HTMLElement> = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (delta.x !== 0 || delta.y !== 0) {
        setDelta({ x: 0, y: 0 });
        return;
      }

      if (e.shiftKey && !selected.some((elID) => elID === e.currentTarget.id)) {
        dispatch(
          setSelected({ selected: selected.concat(e.currentTarget.id) })
        );
      } else {
        dispatch(setSelected({ selected: [e.currentTarget.id] }));
      }
    },
    [dispatch, delta.x, delta.y, selected, setDelta]
  );

  const handleMouseDown: React.MouseEventHandler = useCallback(
    (e: React.MouseEvent) => {
      if (
        !selected.some((elID) => elID === e.currentTarget.id) &&
        !e.shiftKey
      ) {
        dispatch(setSelected({ selected: [e.currentTarget.id] }));
      }
    },
    [dispatch, selected]
  );

  return (
    <Container zoom={scale} data-testid='cta'>
      <Title ctaId={canvasId} title={currentCta?.title || ''} />

      {!loading ? (
        <CTAContainer
          showHorizontalGuideline={showHorizontalGuideline}
          showVerticalGuideline={showVerticalGuideline}
          elStyle={elStyle}
          ref={(node) => {
            myRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          style={{ position: 'relative', top: '0', left: '0' }}
          id={canvasId}
          onClick={handleOnClick}>
          <BackgroundColor color={elStyle.background?.value as string} />
          {(canvasElement?.backgroundImg?.desktop?.src ||
            canvasElement?.backgroundImg?.mobile?.src) && (
            <BackgroundImage
              img={canvasElement.backgroundImg}
              opacity={elStyle.opacity?.value as number}
              elStyle={elStyle}
              media={editingMode}
            />
          )}
          {elements &&
            elements.length > 0 &&
            elements
              .filter((el) => el.type !== 'cta')
              .map((el) =>
                elementMap(
                  el,
                  editingMode,
                  addSelected,
                  handleMouseDown,
                  selected,
                  setTarget,
                  startEvent,
                  targetStartPos,
                  delta,
                  rect,
                  ref,
                  setDelta,
                  el.variation,
                  el.title
                )
              )}
        </CTAContainer>
      ) : (
        <CTAContainer loading={loading} elStyle={elStyle}>
          <SkeletonComponent />
        </CTAContainer>
      )}
    </Container>
  );
});

export default CTA;
