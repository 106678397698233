import { useLayoutEffect, useState } from "react";
import usePlanId from "./usePlanId";
import { UserType } from "../types";
import useTrialExpiration from "./useTrialExpiration";

const useDisableApp = (user: UserType | null) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const isTrialExpired = useTrialExpiration(user);
  const planId = usePlanId();

  useLayoutEffect(() => {
    if (isTrialExpired && planId === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isTrialExpired, planId]);

  return disabled;
};

export default useDisableApp;
