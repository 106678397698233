import styled from 'styled-components';

export const AnnouncementModalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing[4]};
  padding-left: ${(props) => props.theme.spacing[6]};
`;

export const Headline = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-right: ${(props) => props.theme.spacing[3]};
  margin-bottom: 0;
  line-height: 2.7rem;
`;

export const Footer = styled.div`
  background-color: ${(props) => props.theme.color.gray100};
  border-radius: 0 0 5px 5px;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[5]};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${(props) => props.theme.spacing[4]};

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing[4]};
  }
`;
