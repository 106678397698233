import React from 'react';
import { ButtonStyles } from './Button.styles';
import { Style } from '../../../types';

interface ButtonProps {
  content: string | undefined;
  id: string;
  elstyle: Style;
  url: string | undefined;
  options: boolean;
}

const Button: React.FC<ButtonProps> = ({
  content,
  id,
  elstyle,
  url,
  options,
}) => {
  return (
    <ButtonStyles id={id} data-testid='widget-button' elstyle={elstyle}>
      <a
        style={{ color: 'inherit', textDecoration: 'none' }}
        href={url}
        rel={'noreferrer'}
        target={options ? '_blank' : ''}>
        {JSON.parse(JSON.stringify(content))}
      </a>
    </ButtonStyles>
  );
};

export default Button;
