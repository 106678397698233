import styled from 'styled-components';

export const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 490px;
  width: 100%;
  overflow: hidden;
`;

export const Text = styled.h2`
  z-index: 1;
  font-size: 2rem;
  width: 70%;
  text-align: center;
`;

export const ResourceNotFoundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  z-index: 0;
`;

export const InnerContainer = styled.div`
  width: 980px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
