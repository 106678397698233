import {
  Container,
  Cover,
  Title,
  CardsContainer,
  FAQContainer,
} from './TutorialsView.styles';
import React from 'react';
import TutorialCard from '../../TutorialCard/TutorialCard.component';
import Accordion from '../../../General/Accordion/Accordion.component';
import { FAQ_LIST } from '../../../../enums';
import VideoModal from '../../VideoModal/VideoModal.component';
import { useSelectVideo } from './TutorialsView.logic';

const TutorialsView = () => {
  const { handleSelectVideo, video, showModal, toggleModal } = useSelectVideo();

  return (
    <Container>
      <Title>Tutorials</Title>
      <CardsContainer>
        <TutorialCard
          onClick={() => handleSelectVideo('create-content')}
          thumbnail={
            'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/tutorial/create-new-content.jpg'
          }
          title={'Creating New Content'}
          description={'Create your first content to get started!'}
        />
        <TutorialCard
          onClick={() => handleSelectVideo('studio-intro')}
          thumbnail={
            'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/tutorial/design-studio-intro.jpg'
          }
          title={'Intro to the Design Studio'}
          description={
            'Use our full-feature design studio to design engaging content.'
          }
        />
        <TutorialCard
          onClick={() => handleSelectVideo('evergreen')}
          thumbnail={
            'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/tutorial/evergreen-campaign.jpg'
          }
          title={'What is an Evergreen Campaign?'}
          description={
            'Learn about your Evergreen Campaign to keep your audience engaged in-between your campaigns.'
          }
        />
        <TutorialCard
          onClick={() => handleSelectVideo('schedule')}
          thumbnail={
            'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/tutorial/schedule-your-announcements.jpg'
          }
          title={'Schedule Your Content'}
          description={
            'Learn how to schedule content to increase conversions with your audience.'
          }
        />
      </CardsContainer>
      <Title>Frequently Asked Questions</Title>
      <FAQContainer>
        {FAQ_LIST.map((item, i) => (
          <Accordion
            question={item.question}
            answer={item.answer}
            key={item.question + i}
          />
        ))}
      </FAQContainer>
      {showModal && (
        <>
          <Cover onClick={toggleModal} />
          <VideoModal iframe={video} />
        </>
      )}
    </Container>
  );
};

export default TutorialsView;
