import React from 'react';
import { Container, Section, Text } from './TrialExpiredView.styles';
import Button from '../Button/Button.component';
import { handleOpenBillingPage } from '../../../util/functions';

const TrialExpiredView: React.FC = () => {
  return (
    <Container>
      <Section>
        <Text>
          Oh no! Your free trial has expired. View our available plans and
          upgrade to access this content.
        </Text>
        <Button
          label='Upgrade Now'
          variant='tertiary'
          onClick={handleOpenBillingPage}
        />
      </Section>
      <Section>
        <Text>
          Still undecided? Check out our website to learn more about Content
          Scheduler.
        </Text>
        <a
          style={{ fontSize: '1.4rem' }}
          href={'https://www.oceanapps.com'}
          target='_blank'
          rel='noreferrer'>
          www.oceanapps.com
        </a>
      </Section>
    </Container>
  );
};

export default TrialExpiredView;
