import React from 'react';
import {
  ViewContainer,
  ButtonContainer,
  Text,
  UpperSection,
} from './MainView.styles';
import Button from '../Button/Button.component';
import useGetInstance from '../../../hooks/useGetInstance';
import { NEW_CTA } from '../../../enums';
import { createNewCTA } from '../../../util/services';
import { handleClose } from '../../../util/functions';
import { Wix } from '../../../util/Wix';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useGetCompId from '../../../hooks/useGetCompId';
import UpgradeInfo from '../UpgradeInfo/UpgradeInfo.component';
import useSetPlanId from '../../../hooks/useSetPlanId';
import usePlanId from '../../../hooks/usePlanId';

interface Props {
  setView: React.Dispatch<React.SetStateAction<number>>;
}

const MainView: React.FC<Props> = ({ setView }) => {
  const instance = useGetInstance();
  const compId = useGetCompId();
  const dispatch = useAppDispatch();

  useSetPlanId(instance);
  const planId = usePlanId();

  const handleCreateCta = async () => {
      try {
        const ctaId = await createNewCTA(
          instance,
          compId,
          null,
          NEW_CTA,
          dispatch,
          null
        );

        const url =
          process.env.REACT_APP_ENV === 'staging'
            ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`
            : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`; // Production URL
        const testUrl = `/design?instance=${instance}&cta-id=${ctaId}`;

        if (
          process.env.NODE_ENV === 'production' ||
          process.env.REACT_APP_ENV === 'staging'
        ) {
          Wix.Settings.openModal(
            url,
            '85%',
            '85%',
            'Content Scheduler - Design Studio',
            () => handleClose()
          );
        } else {
          const w = window.open(testUrl, '_blank');
          if (w) {
            w.focus();
          }
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }

  };

  const handleViewCTAs = () => {
    setView(2);
  };

  return (
    <ViewContainer data-testid='main-view'>
      <UpperSection>
        <Text>
          Welcome to Content Scheduler! Content scheduler will display your
          featured content according to your schedule.
        </Text>

        <ButtonContainer>
          <Button
            label='Create New Content'
            variant='primary'
            onClick={handleCreateCta}
            id={'create-new-content'}
          />
          <Button
            label='My Content'
            variant='secondary'
            onClick={handleViewCTAs}
          />
        </ButtonContainer>
      </UpperSection>

      {planId !== 'professional' && <UpgradeInfo />}
    </ViewContainer>
  );
};

export default MainView;
