import React from 'react';
import { Text, LinkStyles } from './Typography.styles';
import { Link, Style } from '../../../types';

interface Props {
  content: string | undefined;
  id: string;
  elstyle: Style;
  link?: Link;
}

const Typography: React.FC<Props> = ({ content, id, elstyle, link }) => {
  const render = () => {
    if (link?.url) {
      return (
        <LinkStyles
          color={elstyle.color?.value}
          href={link.url}
          rel={'noreferrer'}
          target={link.options}>
          <Text id={id} elstyle={elstyle}>
            {content}
          </Text>
        </LinkStyles>
      );
    } else {
      return (
        <Text id={id} elstyle={elstyle}>
          {content}
        </Text>
      );
    }
  };

  return render();
};

export default Typography;
