import React, { useState, useEffect } from 'react';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { IconStyles } from './UppercaseButton.styles';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setFontUppercase } from '../../../redux/elementSlice';
import useTargetElement from '../../../hooks/useTargetElement';

const UppercaseButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const target = useTargetElement();

  const [isUppercase, setIsUppercase] = useState<boolean>(false);

  const handleClick = () => {
    if (!isUppercase && target) {
      dispatch(
        setFontUppercase({
          id: target.id,
          textTransform: 'uppercase',
        })
      );
    } else if (target) {
      dispatch(setFontUppercase({ id: target.id, textTransform: 'none' }));
    }
  };

  useEffect(() => {
    if (target) {
      if (target.style.desktop.textTransform?.value === 'uppercase') {
        setIsUppercase(true);
      } else {
        setIsUppercase(false);
      }
    }
  }, [target]);

  return (
    <IconStyles
      title={'Uppercase/Lowercase'}
      isUppercase={isUppercase}
      aria-label='Uppercase/lowercase'
      onClick={handleClick}>
      <FormatSizeIcon fontSize='large' />
    </IconStyles>
  );
};

export default UppercaseButton;
