// Used to limit access to premium features if user is on basic plan
// Not used to identify users with zero access; see useBlockUser.ts

import { useEffect, useState } from "react";
import useTrialExpiration from "./useTrialExpiration";
import usePlanId from "./usePlanId";
import { UserType } from "../types";

const useRestrictUser = (user: UserType | null): boolean => {
  const [restricted, setRestricted] = useState(false);
  const isTrialExpired = useTrialExpiration(user);
  const userPlanId = usePlanId();

  useEffect(() => {
    if (userPlanId === "basic" || userPlanId === "professional") {
      setRestricted(true);
    } else {
      setRestricted(false);
    }
  }, [isTrialExpired, userPlanId]);

  return restricted;
};

export default useRestrictUser;
