import React from 'react';
import { Select } from 'antd';
import { SelectContainer } from './AnnouncementSelector.styles';
import useGetCtas from '../../../hooks/useGetCtas';
import { useSelect } from './AnnouncementSelector.logic';

const { Option } = Select;

interface Props {
  style?: React.CSSProperties;
}

const AnnouncementSelector: React.FC<Props> = ({ style }) => {
  const { value, handleChange } = useSelect();
  const ctaList = useGetCtas();

  return (
    <SelectContainer style={style}>
      <Select
        placeholder='Select Announcement'
        style={{
          width: '235px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        onChange={handleChange}
        value={value}>
        {ctaList.map((cta) => (
          <Option key={cta.id} value={cta.id}>
            {cta.title}
          </Option>
        ))}
      </Select>
    </SelectContainer>
  );
};

export default AnnouncementSelector;
