import React from 'react';
import { Title } from '../../../styles/util';
import { MenuContainer, Section, SectionTitle, Row } from './ButtonMenu.styles';
import ThemedButton from '../ThemedButton/ThemedButton.component';
import TextIconButton from '../TextIconButton/TextIconButton.component';
import {
  TextIconBtnEight,
  TextIconBtnFive,
  TextIconBtnFour,
  TextIconBtnOne,
  TextIconBtnSeven,
  TextIconBtnSix,
  TextIconBtnThree,
  TextIconBtnTwo,
} from '../../../styles/ButtonEnums';

const ButtonMenu: React.FC = () => {
  return (
    <MenuContainer data-testid='button-menu'>
      <Title>Buttons</Title>

      <Section>
        <SectionTitle>Themed Buttons</SectionTitle>
        <Row>
          <ThemedButton type={'ThemeBtnOne'}>Click Me</ThemedButton>
          <ThemedButton type={'ThemeBtnTwo'}>Click Me</ThemedButton>
        </Row>
        <Row>
          <ThemedButton type={'ThemeBtnThree'}>Click Me</ThemedButton>
          <ThemedButton type={'ThemeBtnFour'}>Click Me</ThemedButton>
        </Row>
      </Section>
      <Section>
        <SectionTitle>Text & Icon Buttons</SectionTitle>
        <Row>
          <TextIconButton type={1} style={TextIconBtnOne}>
            Click Me
          </TextIconButton>
          <TextIconButton type={2} style={TextIconBtnTwo}>
            Click Me
          </TextIconButton>
        </Row>
        <Row>
          <TextIconButton type={3} style={TextIconBtnThree}>
            Click Me
          </TextIconButton>
          <TextIconButton type={4} style={TextIconBtnFour}>
            Click Me
          </TextIconButton>
        </Row>
        <Row>
          <TextIconButton type={5} style={TextIconBtnFive}>
            Click Me
          </TextIconButton>
          <TextIconButton type={6} style={TextIconBtnSix}>
            Click Me
          </TextIconButton>
        </Row>
        <Row>
          <TextIconButton type={7} style={TextIconBtnSeven}>
            Click Me
          </TextIconButton>
          <TextIconButton type={8} style={TextIconBtnEight}>
            Click Me
          </TextIconButton>
        </Row>
      </Section>
    </MenuContainer>
  );
};

export default ButtonMenu;
