import { useEffect, useState } from "react";
import { Element, Elements } from "../types";

const useGetCanvas = (ctaId: string, elements: Elements): Element => {
  const [canvasElement, setCanvasElement] = useState<Element>({} as Element);

  useEffect(() => {
    let el: Element | undefined;
    if (ctaId && elements && elements.length > 0) {
      el = elements.find((el) => el.id === ctaId);
    }
    if (el) setCanvasElement(el);
  }, [elements, ctaId]);

  return canvasElement;
};

export default useGetCanvas;
