import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomColors } from "../types";
import { setCustomColors } from "../redux/themeSlice";
import { getCustomColors } from "../util/services";
import useGetInstance from "./useGetInstance";

const useUpdateCustomColors = () => {
  const dispatch = useDispatch();
  const instance = useGetInstance();

  const [colors, setColors] = useState<CustomColors>([]);

  useEffect(() => {
    const controller = new AbortController();
    getCustomColors(instance, controller).then((res) => {
      if (res) setColors([...res.data]);
    });

    return () => controller.abort();
  }, [instance]);

  useEffect(() => {
    if (colors.length > 0) {
      dispatch(setCustomColors({ customColors: colors }));
    }
  }, [colors, dispatch]);
};

export default useUpdateCustomColors;
