import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.gray100};
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[5]};
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing[5]};
  min-width: 43rem;
  max-width: 43rem;
  overflow: hidden;

  @media screen and (min-width: 1100px) {
    min-width: 74.6rem;
    max-width: 74.6rem;
  }

  @media screen and (min-width: 1430px) {
    min-width: 106.2rem;
    max-width: 106.2rem;
  }

  @media screen and (min-width: 1760px) {
    min-width: 137.8rem;
    max-width: 137.8rem;
  }

  @media screen and (min-width: 2090px) {
    min-width: 169.4rem;
    max-width: 169.4rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.gray100};
  padding: 0 ${(props) => props.theme.spacing[3]};
`;

export const HeaderTitle = styled.p`
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${(props) => props.theme.color.blue900};
`;

export const Frame = styled.div`
  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const PrevButton = styled.button`
  height: 25rem;
  border: none;

  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  transition: background 250ms ease-in-out;
  z-index: 2;

  &:hover {
    background: ${(props) => props.theme.color.gray100};
  }

  &:active {
    background: ${(props) => props.theme.color.gray200};
  }
`;

export const NextButton = styled.button`
  height: 25rem;
  border: none;

  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  transition: background 250ms ease-in-out;
  z-index: 2;

  &:hover {
    background: ${(props) => props.theme.color.gray100};
  }

  &:active {
    background: ${(props) => props.theme.color.gray200};
  }
`;

export const Row = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) =>
    props.show ? `${props.theme.spacing[5]} ${props.theme.spacing[3]}` : '0'};

  height: ${(props) => (props.show ? '29rem' : '0')};
  transition: height 250ms ease-in-out;
  overflow: hidden;

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing[3]};
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  border-radius: 50px;
  width: 2.4rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing[5]};
  color: ${(props) => props.theme.color.text};
  cursor: pointer;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
