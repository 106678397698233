import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlanId, UserType } from "../types";

const initialState: UserType = {
  email: null,
  instanceId: null,
  trialExpiration: null,
  planId: null,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setPlanId: (
      state,
      action: PayloadAction<{
        planId: PlanId;
      }>
    ) => {
      const { planId } = action.payload;
      state.planId = planId;
    },
    setUserEmail: (
      state,
      action: PayloadAction<{
        email: string | null;
      }>
    ) => {
      const { email } = action.payload;
      state.email = email;
    },
    setTrialExpiration: (
      state,
      action: PayloadAction<{ trialExpiration: number }>
    ) => {
      const { trialExpiration } = action.payload;
      if (trialExpiration) {
        state.trialExpiration = trialExpiration;
      }
    },
    setInstanceId: (state, action: PayloadAction<{ instanceId: string }>) => {
      const { instanceId } = action.payload;
      if (instanceId) {
        state.instanceId = instanceId;
      }
    },
  },
});

export const {
  setInstanceId,
  setPlanId,
  setTrialExpiration,
  setUserEmail,
} = userSlice.actions;

export default userSlice.reducer;
