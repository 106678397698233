import React, { memo } from 'react';
import { getButtonStyles } from '../../../util/functions';
import ResizeHandles from '../ResizeHandles/ResizeHandles.component';
import { ButtonStyles } from './Button.styles';
import { Style, Pos } from '../../../types';

interface Props {
  content: string;
  id: string;
  style?: React.CSSProperties;
  elstyle: Style;
  onClick: React.MouseEventHandler<Element>;
  editable: React.RefObject<HTMLDivElement>;
  cursor: 'text' | 'move';
  handleKeyDown: React.KeyboardEventHandler;
  myRef: any;
  setPos: (value: Pos | ((prevVar: Pos) => Pos)) => void;
  handleBlur: React.FocusEventHandler;
  handleDoubleClick: React.MouseEventHandler;
  ref: any;
  pos: Pos;
  isSelected: boolean;
  setBounds: any;
  rect: any;
}

const Button: React.FC<Props> = memo(
  React.forwardRef(({ ...props }, ref) => {
    return (
      <ButtonStyles
        data-testid='button'
        {...props}
        hover={props.elstyle.hover}
        id={props.id}
        cursor={props.cursor}
        elstyle={props.elstyle}
        isSelected={props.isSelected}
        style={{
          ...getButtonStyles(props.elstyle, props.style?.transform),
        }}
        onKeyDown={props.handleKeyDown}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(e);
        }}
        ref={ref as React.RefObject<HTMLButtonElement>}
        className='resizable'>
        <div
          onBlur={props.handleBlur}
          onDoubleClick={props.handleDoubleClick}
          ref={props.editable}
          suppressContentEditableWarning={true}>
          {props.content}
        </div>

        <ResizeHandles
          setPos={props.setPos}
          pos={props.pos}
          myRef={props.myRef}
          id={props.id}
          setBounds={props.setBounds}
          rect={props.rect}
        />
      </ButtonStyles>
    );
  })
);

export default Button;
