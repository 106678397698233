import React, { useState } from 'react';
import { CustomColorStyles, DeleteIcon } from './CustomColor.styles';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import useGetInstance from '../../../hooks/useGetInstance';
import { deleteCustomColor, getCustomColors } from '../../../util/services';
import { setCustomColors } from '../../../redux/themeSlice';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { ThemeColor } from '../../../types';

interface Props {
  id: string;
  color: string;
  selectedColor: ThemeColor | {};
  onClick: Function;
}

const CustomColor: React.FC<Props> = ({
  id,
  color,
  selectedColor,
  onClick,
}) => {
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const instance = useGetInstance();
  const dispatch = useAppDispatch();

  const handleDeleteColor = async (e: React.UIEvent, id: string) => {
    e.stopPropagation();
    if (id && instance) {
      await deleteCustomColor(id, instance);
      const colors = await getCustomColors(instance);

      if (colors) dispatch(setCustomColors({ customColors: colors.data }));
    }
  };

  return (
    <CustomColorStyles
      onMouseOver={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      onClick={onClick as React.MouseEventHandler}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick();
      }}
      aria-label={color}
      title={color}
      tabIndex={0}
      selectedColor={selectedColor}
      color={color}
      id={id}>
      {showDelete && (
        <DeleteIcon
          tabIndex={0}
          aria-label={'Delete color'}
          title={'Delete color'}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleDeleteColor(e, id);
          }}
          onClick={(e) => handleDeleteColor(e, id)}>
          <DoDisturbOnIcon style={{ color: 'inherit' }} />
        </DeleteIcon>
      )}
    </CustomColorStyles>
  );
};

export default CustomColor;
