import styled from 'styled-components';
import { Pos } from '../../../types';

export const Cover = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const ContextMenuStyles = styled.div<{
  pos: Pos;
  menuPosition: string;
  offsetWidth: number;
  offsetHeight: number;
}>`
  height: fit-content;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${(props) =>
    props.menuPosition === 'bottom'
      ? props.pos.y + 'px'
      : props.pos.y - props.offsetHeight + 'px'};
  left: ${(props) =>
    props.menuPosition === 'left'
      ? props.pos.x + 'px'
      : props.pos.x - props.offsetWidth + 'px'};
  background-color: ${(props) => props.theme.color.white};
  z-index: 1000;
  box-shadow: ${(props) => props.theme.shadow[2]};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.color.gray100};
`;

export const MenuItemsUpper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #eeecec;
  margin-bottom: 0;

  & > :first-child {
    border-radius: 2px 2px 0 0;
  }
`;

export const MenuItem = styled.li<{ disableButton?: boolean }>`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: none;
  color: ${(props) =>
    props.disableButton
      ? props.theme.color.gray400
      : props.theme.color.gray600};
  cursor: ${(props) => (props.disableButton ? 'not-allowed' : 'pointer')};
  font-size: 1.4rem;

  &:hover {
    background-color: ${(props) =>
      props.id === 'delete'
        ? props.theme.color.red100
        : props.disableButton
        ? '#FFF'
        : props.theme.color.gray100};
    color: ${(props) =>
      props.id === 'delete'
        ? props.theme.color.red500
        : !props.disableButton && props.theme.color.gray600};
  }

  & > * {
    margin-right: 1rem;
  }
`;

export const MenuItemsLower = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;

  & > :last-child {
    border-radius: 0 0 5px 5px;
  }
`;
