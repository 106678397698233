import React, { useRef, useState } from 'react';
import useGetCtas from '../../../hooks/useGetCtas';
import {
  ViewContainer,
  Text,
  Label,
  CreateCTAButton,
  Icon,
} from './ContentView.styles';
import CtaItem from '../CtaItem/CtaItem.component';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useGetInstance from '../../../hooks/useGetInstance';
import { createNewCTA } from '../../../util/services';
import { handleClose } from '../../../util/functions';
import { NEW_CTA } from '../../../enums';
import { Wix } from '../../../util/Wix';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useGetCompId from '../../../hooks/useGetCompId';
import useCategories from '../../../hooks/useCategories';
import { SectionHeader } from '../../../styles/settings/components';

const ContentView: React.FC = () => {
  const ctaList = useGetCtas();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [activeCtas, drafts, upcoming, expired] = useCategories(
    compId,
    ctaList
  );

  // Used for highlighting ctaItem when clicked
  const [activeItem, setActiveItem] = useState<{ id: null | string }>({
    id: null,
  });

  const handleNewCTA: React.MouseEventHandler<HTMLButtonElement> = async () => {
      const ctaId = await createNewCTA(
        instance,
        compId,
        null,
        NEW_CTA,
        dispatch,
        null
      );

      const url =
        process.env.REACT_APP_ENV === 'staging'
          ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`
          : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`; // Production URL
      const testUrl = `/design?instance=${instance}&cta-id=${ctaId}`;

      if (
        process.env.NODE_ENV === 'production' ||
        process.env.REACT_APP_ENV === 'staging'
      ) {
        Wix.Settings.openModal(
          url,
          '85%',
          '85%',
          'Content Scheduler - Design Studio',
          () => handleClose()
        );
      } else {
        const w = window.open(testUrl, '_blank');
        if (w) {
          w.focus();
        }
      }
  };

  return (
    <ViewContainer ref={containerRef} data-testid='ctas-view'>
      <CreateCTAButton onClick={handleNewCTA}>
        <Label>Create New</Label>
        <Icon>
          <AddCircleOutlineIcon fontSize='large' style={{ color: '#325166' }} />
        </Icon>
      </CreateCTAButton>
      <SectionHeader>
        <Text>Active</Text>
      </SectionHeader>
      {activeCtas.length > 0 &&
        activeCtas
          .filter((cta) => cta.isActive)
          .map((cta) => (
            <CtaItem
              key={cta.id}
              isActive={cta.isActive}
              isEvergreen={cta.isEvergreen}
              ctaId={cta.id}
              ctaTitle={cta.title}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          ))}
      <SectionHeader>
        <Text>Evergreen</Text>
      </SectionHeader>
      {activeCtas.length > 0 &&
        activeCtas
          .filter((cta) => cta.isEvergreen)
          .map((cta) => (
            <CtaItem
              key={cta.id}
              isActive={cta.isActive}
              isEvergreen={cta.isEvergreen}
              ctaId={cta.id}
              ctaTitle={cta.title}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          ))}
      <SectionHeader>
        <Text>Upcoming</Text>
      </SectionHeader>
      {upcoming.length > 0 &&
        upcoming.map((upcoming) => {
          return (
            <CtaItem
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              key={upcoming.id}
              ctaId={upcoming.id}
              ctaTitle={upcoming.title}
            />
          );
        })}
      <SectionHeader>
        <Text>Drafts</Text>
      </SectionHeader>
      {drafts.length > 0 &&
        drafts.map((draft) => {
          return (
            <CtaItem
              key={draft.id}
              ctaId={draft.id}
              ctaTitle={draft.title}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          );
        })}

      <SectionHeader>
        <Text>Expired</Text>
      </SectionHeader>
      {expired.length > 0 &&
        expired.map((expired) => {
          return (
            <CtaItem
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              key={expired.id}
              ctaId={expired.id}
              ctaTitle={expired.title}
            />
          );
        })}
    </ViewContainer>
  );
};

export default ContentView;
