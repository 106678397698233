import React from 'react';
import { Container, Title, Section } from './ImageInfo.styles';
import TextInput from '../TextInput/TextInput.component';

const ImageInfo = () => {
  return (
    <Container>
      <Title>Image Info</Title>
      <Section>
        <TextInput id={'alt-text'} label={'Alt text'} />
      </Section>
    </Container>
  );
};

export default ImageInfo;
