import styled from 'styled-components';

export const IconStyles = styled.button<{
  italic?: boolean;
  bold?: boolean;
  underline?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease-in;
  border-radius: 2px;
  padding: 0.6rem;
  border: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.underline || props.bold || props.italic
      ? props.theme.color.gray200
      : '#FFF'};
  &:hover {
    background-color: ${(props) =>
      props.underline || props.bold || props.italic
        ? props.theme.color.gray200
        : props.theme.color.gray100};
  }
`;

export const Container = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 0.3rem;
  }
`;
