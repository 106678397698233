import styled from 'styled-components';

export const TopBarStyles = styled.div<{ showMenu?: boolean }>`
  height: 4rem;
  width: 100%;

  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  padding: 1rem 1.8rem;
  z-index: 10;

  & > *:not(:last-child) {
    margin-right: ${(props) => (props.showMenu ? '0' : props.theme.spacing[3])};
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  height: 100%;
  background: ${(props) => props.theme.color.gray200};
  width: 2px;
`;
