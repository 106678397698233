import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.min.css';
import Sidebar from '../../components/DesignStudio/Sidebar/Sidebar.component';
import Header from '../../components/DesignStudio/Header/Header.component';
import Stage from '../../components/DesignStudio/Stage/Stage.component';
import { useAppDispatch } from '../../redux/reduxHooks';
import { initFromCta } from '../../redux/elementSlice';
import useSetCurrentCtaId from '../../hooks/useSetCurrentCtaId';
import useSetCtaListFromDB from '../../hooks/useSetCtaListFromDB';
import useSetInstance from '../../hooks/useSetInstance';
import useGetCurrentCta from '../../hooks/useGetCurrentCta';
import useGetCurrentCtaId from '../../hooks/useGetCurrentCtaId';
import useUpdateCtaList from '../../hooks/useUpdateCtaList';
import useUpdateCustomColors from '../../hooks/useUpdateCustomColors';
import { Container, DesignStudioStyles } from './DesignStudio.styles';
import SidebarMenu from '../../components/DesignStudio/SidebarMenu/SidebarMenu.component';
import { Wix } from '../../util/Wix';
import useAppendFontScripts from '../../hooks/useAppendFontScripts';
import useRemoveFontScripts from '../../hooks/useRemoveFontScripts';
import useGetInstance from '../../hooks/useGetInstance';
import useGetCompId from '../../hooks/useGetCompId';
import { setCompId } from '../../redux/ctaSlice';
import DevTools from '../../components/DesignStudio/DevTools/DevTools.component';
import useQuery from '../../hooks/useQuery';
import { useTemplate } from '../../hooks/useTemplate';
import { addToContentList } from '../../util/functions';
import useSetUser from '../../hooks/useSetUser';
import useSetPlanId from '../../hooks/useSetPlanId';
import { getThemeColors } from '../../util/services';
import { setTheme } from '../../redux/themeSlice';
import {
  setActiveSidebarView,
  setShowSidebar,
} from '../../redux/sidebarViewSlice';

const DesignStudio: React.FC = () => {
  const [hasCtaIdChanged, setHasCtaIdChanged] = useState<boolean>(false);
  useSetInstance();
  useSetCurrentCtaId();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const dispatch = useAppDispatch();
  useSetCtaListFromDB(instance, compId);
  const ctaId = useGetCurrentCtaId();
  useUpdateCtaList();
  useUpdateCustomColors();
  const currentCta = useGetCurrentCta();
  useAppendFontScripts(currentCta);
  useRemoveFontScripts();
  useSetUser(instance);
  useSetPlanId(instance);
  const query = useQuery();
  const view = query.get('view');
  const templateId = query.get('template-id');
  const handleTemplate = useTemplate();
  const templateHandled = useRef(false);

  useEffect(() => {
    if (currentCta?.id && compId && instance) {
      addToContentList(instance, currentCta.id, compId);
    }
  }, [currentCta, compId, instance]);

  useEffect(() => {
    if (templateId && !templateHandled.current) {
      setTimeout(() => {
        handleTemplate(parseInt(templateId));
        templateHandled.current = true;
      }, 1000);
    }
  }, [templateId, handleTemplate]);

  useEffect(() => {
    if (view === 'help') {
      dispatch(setActiveSidebarView({ id: 9 }));
      dispatch(setShowSidebar(false));
    }
  }, [view, dispatch]);

  useEffect(() => {
    const compId = localStorage.getItem('compId');
    if (compId) {
      dispatch(setCompId({ compId }));
      localStorage.removeItem('compId');
    } else if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      dispatch(setCompId({ compId: Wix.Utils.getOrigCompId() }));
    } else {
      dispatch(setCompId({ compId: process.env.REACT_APP_COMP_ID as string }));
    }
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();

    const getTheme = async () => {
      return await getThemeColors(instance, controller);
    };

    getTheme().then((res) => {
      if (res) dispatch(setTheme({ themeColors: res.data }));
    });
  }, [dispatch, instance]);

  useEffect(() => {
    if (ctaId) {
      setHasCtaIdChanged(true);
    }
  }, [ctaId]);

  useEffect(() => {
    // Only initialize components if a new CTA has been selected
    if (
      currentCta &&
      Object.keys(currentCta).length > 0 &&
      hasCtaIdChanged &&
      currentCta.id
    ) {
      dispatch(initFromCta({ cta: currentCta }));
      setHasCtaIdChanged(false);
    }
  }, [currentCta, dispatch, ctaId, hasCtaIdChanged]);

  return (
    <DesignStudioStyles>
      <Header />
      <Container>
        <div style={{ zIndex: 1000 }}>
          <SidebarMenu />
        </div>

        <Sidebar />

        <Stage />
        {process.env.NODE_ENV === 'development' && <DevTools />}
      </Container>
    </DesignStudioStyles>
  );
};

export default DesignStudio;
