import { useAppDispatch, useAppSelector } from '../redux/reduxHooks';
import { useLayoutEffect, useState } from 'react';
import { setPreviewCta } from '../redux/ctaSlice';
import useQuery from './useQuery';
import { getCtaById } from '../util/services';

const useGetPreviewCta = (instance, compId, location) => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const [previewId, setPreviewId] = useState<string | null>(
    query.get('previewId') || ''
  );

  useLayoutEffect(() => {
    setPreviewId(query.get('previewId'));
  }, [location, query]);

  useLayoutEffect(() => {
    const controller = new AbortController();
    if (previewId) {
      getCtaById( instance, compId, previewId, controller).then(
        (res) => {
          if (res) dispatch(setPreviewCta({ previewCta: res }));
        }
      );
    }

    return () => controller.abort();
  }, [previewId, dispatch, instance, compId]);

  return useAppSelector((state) => state.ctas.previewCta);
};

export default useGetPreviewCta;
