import React, { useCallback } from 'react';
import { Slider } from './AnnouncementCarousel.styles';
import Announcement from '../Announcement';
import Placeholder from '../Placeholder/Placeholder.component';
import { CtaList, DashboardTab } from '../../../types';

interface Props {
  activeTab: DashboardTab;
  activeCtas: CtaList;
  upcoming: CtaList;
  drafts: CtaList;
  expired: CtaList;
  pageIndex: number;
  frameWidth: number;
  handleOptionsClick: any;
  titleRef: React.RefObject<HTMLSpanElement | null>;
}

const AnnouncementCarousel: React.FC<Props> = ({
  activeTab,
  activeCtas,
  upcoming,
  expired,
  drafts,
  pageIndex,
  frameWidth,
  handleOptionsClick,
  titleRef,
}) => {
  const renderList = useCallback(() => {
    switch (activeTab) {
      case 'active':
        return activeCtas.length > 0 ? (
          activeCtas.map((cta) => (
            <Announcement
              key={cta.id}
              cta={cta}
              category={'active'}
              title={cta.title}
              schedule={cta.schedule}
              ctaId={cta.id}
              compId={cta.compId}
              handleOptionsClick={handleOptionsClick}
              titleRef={titleRef}
            />
          ))
        ) : (
          <Placeholder currentTab={activeTab} />
        );
      case 'upcoming':
        return upcoming.length > 0 ? (
          upcoming.map((cta) => (
            <Announcement
              key={cta.id}
              cta={cta}
              title={cta.title}
              category={'upcoming'}
              schedule={cta.schedule}
              ctaId={cta.id}
              compId={cta.compId}
              handleOptionsClick={handleOptionsClick}
              titleRef={titleRef}
            />
          ))
        ) : (
          <Placeholder currentTab={activeTab} />
        );
      case 'drafts':
        return drafts.length > 0 ? (
          drafts.map((cta) => (
            <Announcement
              key={cta.id}
              title={cta.title}
              cta={cta}
              schedule={cta.schedule}
              category={'drafts'}
              ctaId={cta.id}
              compId={cta.compId}
              handleOptionsClick={handleOptionsClick}
              titleRef={titleRef}
            />
          ))
        ) : (
          <Placeholder currentTab={activeTab} />
        );
      case 'expired':
        return expired.length > 0 ? (
          expired.map((cta) => (
            <Announcement
              key={cta.id}
              title={cta.title}
              cta={cta}
              category={'expired'}
              schedule={cta.schedule}
              ctaId={cta.id}
              compId={cta.compId}
              handleOptionsClick={handleOptionsClick}
              titleRef={titleRef}
            />
          ))
        ) : (
          <Placeholder currentTab={activeTab} />
        );
      default:
        break;
    }
  }, [
    activeTab,
    drafts,
    expired,
    upcoming,
    activeCtas,
    handleOptionsClick,
    titleRef,
  ]);

  return (
    <Slider frameWidth={frameWidth} pageIndex={pageIndex}>
      {renderList()}
    </Slider>
  );
};

export default AnnouncementCarousel;
