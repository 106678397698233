import React, { useCallback } from 'react';
import { Container, Message } from './Placeholder.styles';

interface Props {
  currentTab?: string;
}

const Placeholder: React.FC<Props> = ({ currentTab = 'upcoming' }) => {
  const renderMessage = useCallback(() => {
    switch (currentTab) {
      case 'upcoming':
        return (
          <Message>
            You don’t have any upcoming scheduled content! Schedule an existing
            draft or create something new.
          </Message>
        );
      case 'drafts':
        return <Message>You don’t have any drafts!</Message>;
      case 'expired':
        return <Message>Nothing to see here, folks. Move along.</Message>;
      case 'active':
        return (
          <Message>
            Oops! You don't have anything scheduled! Set up your evergreen
            campaign or schedule a new design.
          </Message>
        );
    }
  }, [currentTab]);

  return <Container>{renderMessage()}</Container>;
};

export default Placeholder;
