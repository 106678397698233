import React from 'react';
import { Tooltip } from './UpgradeAppTooltip.styles';
import Crown from '../../General/Crown/Crown.component';

interface Props {
  text: string;
  style?: React.CSSProperties;
}

const UpgradeAppTooltip: React.FC<Props> = ({ text, style }) => {
  return (
    <Tooltip style={style}>
      <Crown
        fill={'#8C21AF'}
        style={{
          width: '32px',
          height: '32px',
          background: 'rgba(255,255,255,.7)',
        }}
      />
      {text}
    </Tooltip>
  );
};

export default UpgradeAppTooltip;
