import Button from './Button/Button.component';
import Typography from './Typography/Typography.component';
import Image from './Image/Image.component';
import React from 'react';
import { Element } from '../../types';
import ImageSVG from './ImageSVG/ImageSVG.component';
import RawSVG from './RawSVG/RawSVG.component';

export const buttonMap = (el: Element, media: string) => {
  let elStyle;

  switch (media) {
    case 'desktop':
      elStyle = el.style.desktop;
      break;

    case 'mobile':
      elStyle = el.style.mobile;
      break;
    default:
      break;
  }

  return (
    <Button
      key={el.id}
      id={el.id}
      url={el.link && el.link.url}
      options={!!(el.link && el.link.options)}
      elstyle={elStyle}
      content={el.content}
    />
  );
};

export const typographyMap = (el: Element, media: string) => {
  let elStyle;

  switch (media) {
    case 'desktop':
      elStyle = el.style.desktop;
      break;

    case 'mobile':
      elStyle = el.style.mobile;
      break;
    default:
      break;
  }

  return (
    <Typography
      key={el.id}
      id={el.id}
      elstyle={elStyle}
      content={el.content}
      link={el.link}
    />
  );
};

export const imageMap = (el: Element, media: string) => {
  let elStyle;

  switch (media) {
    case 'desktop':
      elStyle = el.style.desktop;
      break;

    case 'mobile':
      elStyle = el.style.mobile;
      break;
    default:
      break;
  }

  return (
    <Image key={el.id} id={el.id} elstyle={elStyle} src={el.src} alt={el.alt} />
  );
};

export const svgMap = (el: Element, media: string) => {
  let elStyle;

  switch (media) {
    case 'desktop':
      elStyle = el.style.desktop;
      break;

    case 'mobile':
      elStyle = el.style.mobile;
      break;
    default:
      break;
  }

  return (
    <ImageSVG
      key={el.id}
      id={el.id}
      elstyle={elStyle}
      src={el.src}
      alt={el.alt}
    />
  );
};

export const shapeMap = (el: Element, media: string, site: boolean = false) => {
  let elStyle;

  switch (media) {
    case 'desktop':
      elStyle = el.style.desktop;
      break;

    case 'mobile':
      elStyle = el.style.mobile;
      break;
    default:
      break;
  }

  return (
    <RawSVG
      key={el.id}
      id={el.id}
      elstyle={elStyle}
      variation={el.variation}
      site={site}
    />
  );
};
