import { UserType } from "../types";
import { useLayoutEffect, useState } from "react";

const useTrialExpiration = (user: UserType | null): boolean => {
  const [isTrialExpired, setIsTrialExpired] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (user?.trialExpiration) {
      const currentTimestamp = new Date().getTime();

      if (currentTimestamp < user.trialExpiration) {
        setIsTrialExpired(false);
      } else {
        setIsTrialExpired(true);
      }
    }
  }, [user]);

  return isTrialExpired;
};

export default useTrialExpiration;
