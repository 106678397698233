import React, { memo } from 'react';
import ResizeHandles from '../ResizeHandles/ResizeHandles.component';
import { ImageContainer, ImageStyles } from './Image.styles';
import { getImageStyles } from '../../../util/functions';
import { Pos, Style } from '../../../types';

interface Props {
  src: string;
  id: string;
  style?: React.CSSProperties;
  elstyle: Style;
  alt: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  cursor: 'move' | 'text';
  setCursor: (value: string | ((prevVar: string) => string)) => void;
  handleKeyDown: React.KeyboardEventHandler;
  myRef: any;
  setPos: (value: Pos | ((prevVar: Pos) => Pos)) => void;
  ref: any;
  pos: Pos;
  isSelected: boolean;
  setBounds: any;
  rect: any;
}

const Image: React.FC<Props> = memo(
  React.forwardRef(({ ...props }, ref) => {
    const handleMouseOver: React.MouseEventHandler = () => {
      props.setCursor('move');
    };

    const handleMouseLeave: React.MouseEventHandler = () => {
      props.setCursor('pointer');
    };

    return (
      <ImageContainer
        {...props}
        data-testid='image'
        id={props.id}
        className='resizable'
        onKeyDown={props.handleKeyDown}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        isSelected={props.isSelected}
        tabIndex={-1}
        ref={ref as React.RefObject<HTMLDivElement>}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(e);
        }}
        cursor={props.cursor}
        style={{
          ...(getImageStyles(
            props.elstyle,
            props.style?.transform
          ) as React.CSSProperties),
        }}>
        <ImageStyles
          draggable={false}
          src={props.src}
          alt={props.alt}
          style={{
            opacity: props.elstyle.opacity?.value,

            borderTopLeftRadius: props.elstyle.borderTopLeftRadius?.value + '%',
            borderTopRightRadius:
              props.elstyle.borderTopRightRadius?.value + '%',
            borderBottomLeftRadius:
              props.elstyle.borderBottomLeftRadius?.value + '%',
            borderBottomRightRadius:
              props.elstyle.borderBottomRightRadius?.value + '%',
          }}
        />

        <ResizeHandles
          setPos={props.setPos}
          pos={props.pos}
          myRef={props.myRef}
          id={props.id}
          setBounds={props.setBounds}
          rect={props.rect}
        />
      </ImageContainer>
    );
  })
);

export default Image;
