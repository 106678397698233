import styled from 'styled-components';
import { Style } from '../../../types';

export const ButtonStyles = styled.button<{ btnStyle: Style }>`
  height: ${({ btnStyle }) => btnStyle.height?.value}px;
  width: ${({ btnStyle }) => btnStyle.width?.value}px;
  color: ${({ btnStyle }) => btnStyle.color?.value};
  background: ${({ btnStyle }) => btnStyle.background?.value};
  background-image: ${(props) => props.btnStyle.backgroundImage?.value};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.btnStyle.borderRadius?.value}px;
  border-style: ${(props) => props.btnStyle.borderStyle?.value};
  border-color: ${(props) => props.btnStyle.borderColor?.value};
  border-width: ${(props) => props.btnStyle.borderWidth?.value}px;
  font-weight: ${(props) => props.btnStyle.fontWeight?.value};
  font-size: ${(props) => props.btnStyle.fontSize?.value}px;
  transition: ${({ btnStyle }) =>
    btnStyle.transition || 'all 200ms ease-in-out'};
  cursor: pointer;
  user-select: none;
  position: relative;
  box-shadow: ${(props) => props.btnStyle.boxShadow?.value};

  &:hover {
    color: ${({ btnStyle }) => btnStyle.hover?.color?.value};
    background-image: ${(props) => props.btnStyle.backgroundImage?.value};
    background: ${({ btnStyle }) => btnStyle.hover?.background?.value};
    border-style: ${(props) => props.btnStyle.hover?.borderStyle?.value};
    border-color: ${(props) => props.btnStyle.hover?.borderColor?.value};
    border-width: ${(props) => props.btnStyle.hover?.borderWidth?.value}px;
    border-radius: ${(props) => props.btnStyle.hover?.borderRadius?.value}px;
    text-decoration: ${({ btnStyle }) => btnStyle.hover?.textDecoration?.value};
    letter-spacing: ${({ btnStyle }) => btnStyle.hover?.letterSpacing?.value}px;
    font-weight: ${(props) => props.btnStyle.hover?.fontWeight?.value};
    box-shadow: ${(props) => props.btnStyle.hover?.boxShadow?.value};
  }

  &::after {
    position: absolute;
    content: '';
    top: -4px;
    left: -4px;
    height: ${({ btnStyle }) => btnStyle.after?.height}px;
    width: ${({ btnStyle }) => btnStyle.after?.width}px;
    border-style: ${(props) => props.btnStyle.after?.borderStyle?.value};
    border-color: ${(props) => props.btnStyle.after?.borderColor?.value};
    border-width: ${(props) => props.btnStyle.after?.borderWidth?.value}px;
  }
`;
