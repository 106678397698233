import styled from 'styled-components';

export const SelectContainer = styled.ul`
  height: 19rem;
  margin: 0 ${(props) => props.theme.spacing[6]};
  border: 1px solid ${(props) => props.theme.color.gray100};
  box-sizing: border-box;
  border-radius: 5px;
  list-style: none;
`;

export const SelectItem = styled.li<{ active: string | null; id: string }>`
  width: 100%;
  padding: ${(props) => props.theme.spacing[3]};
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.text};
  cursor: pointer;
  background-color: ${(props) =>
    props.active === props.id
      ? props.theme.color.btnPrimaryLight
      : props.theme.color.white};

  &:hover {
    background-color: ${(props) =>
      props.active !== props.id
        ? props.theme.color.gray100
        : props.theme.color.btnPrimaryLight};
  }
`;
