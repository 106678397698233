import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  min-height: 3.2rem;
  border-radius: 25px;
  margin: ${(props) => props.theme.spacing[3]};
  background: ${(props) => props.theme.color.settings.blue200};
  color: ${(props) => props.theme.color.settings.text};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.color.settings.blue150};
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.color.settings.text};
  padding: ${(props) => props.theme.spacing[3]};
  text-align: center;
  margin-bottom: 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[4]};
`;

export const Bold = styled.p`
  color: ${(props) => props.theme.color.settings.text};
  font-weight: bold;
`;

export const List = styled.ol`
  padding-left: ${(props) => props.theme.spacing[5]};

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[3]};
  }
`;

export const ListItem = styled.li`
  color: ${(props) => props.theme.color.settings.text};
`;
