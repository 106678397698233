import { useEffect, useState } from "react";
import { getUser } from "../util/services";
import { useAppDispatch } from "../redux/reduxHooks";
import { setInstanceId, setTrialExpiration } from "../redux/userSlice";

const useSetUser = (instance: string) => {
  const dispatch = useAppDispatch();
  const [retries, setRetries] = useState<number>(0);

  useEffect(() => {
    const controller = new AbortController();
    if (retries < 3) {
      getUser(instance, controller).then((res) => {
        const data = res?.data;

        if (data) {
          dispatch(setInstanceId({ instanceId: data.instanceId }));
          dispatch(
            setTrialExpiration({ trialExpiration: data.trialExpiration })
          );
        } else {
          setRetries((prev) => prev + 1);
        }
      });
    }
  }, [instance, dispatch, retries]);
};

export default useSetUser;
