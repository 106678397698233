import React from 'react';
import {
  AnnouncementContainer,
  ThumbnailContainer,
  ButtonContainer,
} from './Announcement.styles';
import InfoBubble from '../InfoBubble/InfoBubble.component';
import MenuButton from '../../DesignStudio/MenuButton/MenuButton.component';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import {
  openCtaInEditorFromDashboard,
  scheduleFromDashboard,
} from '../../../util/functions';
import useGetInstance from '../../../hooks/useGetInstance';
import useUser from '../../../hooks/useUser';
import useDisableApp from '../../../hooks/useDisableApp';
import CTA from '../../Widget/CTA/CTA.component';
import useAppendFontScripts from '../../../hooks/useAppendFontScripts';

interface Props {
  title: string;
  schedule: {
    start: number;
    end: number;
  };
  ctaId: string;
  compId: string;
  handleOptionsClick: any;
  titleRef: React.RefObject<HTMLSpanElement | null>;
  cta: any;
  category: 'active' | 'drafts' | 'upcoming' | 'expired';
}

const Announcement: React.FC<Props> = ({
  title,
  schedule,
  ctaId,
  compId,
  handleOptionsClick,
  titleRef,
  cta,
  category,
}) => {
  const handleSchedule: React.MouseEventHandler = () => {
    scheduleFromDashboard(ctaId, compId, instance);
  };

  const instance = useGetInstance();
  const user = useUser();
  const disabled = useDisableApp(user);
  useAppendFontScripts(cta);

  return (
    <>
      <AnnouncementContainer className={'announcement'}>
        <InfoBubble
          compId={compId}
          contentId={ctaId}
          instance={instance}
          ref={titleRef}
          title={title}
          schedule={schedule}
        />
        <ThumbnailContainer>
          <CTA media={'desktop'} currentCta={cta} category={category} />
        </ThumbnailContainer>
        <ButtonContainer>
          <MenuButton
            icon={
              <AccessTimeIcon style={{ fontSize: '24px', color: '#FFF' }} />
            }
            variant={'secondary'}
            isDisabled={disabled}
            onClick={handleSchedule}
          />
          <MenuButton
            icon={<EditIcon style={{ fontSize: '24px', color: '#FFF' }} />}
            variant={'secondary'}
            isDisabled={disabled}
            onClick={() =>
              openCtaInEditorFromDashboard(ctaId, compId, instance)
            }
          />
          <MenuButton
            icon={<MoreVertIcon style={{ fontSize: '24px', color: '#222' }} />}
            variant={'outline'}
            isDisabled={disabled}
            onClick={(e) => handleOptionsClick(e, ctaId, compId)}
          />
        </ButtonContainer>
      </AnnouncementContainer>
    </>
  );
};

export default Announcement;
