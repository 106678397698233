import styled from 'styled-components';
import { Style } from '../../../types';

export const ImageStyles = styled.img<{ elstyle: Style }>`
  height: ${({ elstyle }) => elstyle.height?.value}px;
  width: ${(props) => props.elstyle?.width?.value}px;
  left: ${(props) => props.elstyle.left?.value}px;
  top: ${(props) => props.elstyle.top?.value}px;
  position: absolute;
  z-index: ${(props) => props.elstyle.zIndex?.value};
  transform: ${(props) => props.elstyle.transform?.value};
  border-radius: ${(props) => props.elstyle.borderRadius?.value}%;
  border-top-left-radius: ${(props) =>
    props.elstyle.borderTopLeftRadius?.value}%;
  border-top-right-radius: ${(props) =>
    props.elstyle.borderTopRightRadius?.value}%;
  border-bottom-right-radius: ${(props) =>
    props.elstyle.borderBottomRightRadius?.value}%;
  border-bottom-left-radius: ${(props) =>
    props.elstyle.borderBottomLeftRadius?.value}%;
  opacity: ${(props) => props.elstyle.opacity?.value};
  display: ${(props) => props.elstyle.display?.value};
  object-fit: cover;
`;
