import styled from 'styled-components';

export const PreviewButtonStyles: React.CSSProperties = {
  position: 'absolute',
  right: '.5rem',
};

export const HeaderContainer = styled.header`
  height: 4.8rem;
  background-color: ${({ theme }) => theme.color.blue900};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
`;

export const Separator = styled.div`
  width: 3px;
  background: #fff;
  height: 70%;
  border-radius: 10px;
  margin: 0 ${(props) => props.theme.spacing[2]};
`;

export const HeaderIcon = styled.button<{ editingMode?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  border-color: transparent;
  border-radius: 3px;
  background-color: ${(props) =>
    props.id === props.editingMode + '-button'
      ? props.theme.color.btnPrimary
      : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.color.btnPrimary};
  }
`;

export const IconsContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing[2]};
  }
`;
