import React from 'react';
import { Container, Title, CardsContainer } from './SubscriptionView.styles';
import PricingCard from '../../PricingCard/PricingCard.component';
import usePlanId from '../../../../hooks/usePlanId';

const SubscriptionView: React.FC = () => {
  const planId = usePlanId();
  return (
    <Container>
      <Title>Pricing</Title>
      <CardsContainer>
        <PricingCard variation={'basic'} planId={planId} />
        <PricingCard variation={'professional'} planId={planId} />
        <PricingCard variation={'enterprise'} planId={planId} />
      </CardsContainer>
    </Container>
  );
};

export default SubscriptionView;
