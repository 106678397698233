import React from 'react';
import { Container, ButtonStyles } from './HideSidebarButton.styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setShowSidebar } from '../../../redux/sidebarViewSlice';
import useShowSidebar from '../../../hooks/useShowSidebar';

const HideSidebarButton = () => {
  const dispatch = useAppDispatch();
  const showSidebar = useShowSidebar();

  const handleHideSidebar = () => {
    if (showSidebar) {
      dispatch(setShowSidebar(false));
    } else {
      dispatch(setShowSidebar(true));
    }
  };

  return (
    <Container>
      <ButtonStyles onClick={handleHideSidebar}>
        {showSidebar ? (
          <ArrowBackIosNewIcon style={{ fontSize: '18px', color: '#aaa' }} />
        ) : (
          <ArrowForwardIosIcon style={{ fontSize: '18px', color: '#aaa' }} />
        )}
      </ButtonStyles>
    </Container>
  );
};

export default HideSidebarButton;
