import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  width: 16rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  left: 8rem;
  background-color: #fff;
  z-index: 2;
  box-shadow: ${(props) => props.theme.shadow[2]};
  border-radius: 5px;
`;

export const MenuItem = styled.button<{ id: string }>`
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[3]};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.gray600};
  border: none;
  background: #fff;

  &:hover {
    background: ${(props) => props.theme.color.settings.gray50};
    border-radius: ${(props) =>
      props.id === 'create' ? `5px 5px 0 0` : `0 0 5px 5px`};
  }

  & > *:first-child {
    margin-right: ${(props) => props.theme.spacing[3]};
  }
`;
