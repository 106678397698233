import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  CTAContainer,
  Text,
  ResourceNotFoundImage,
} from './MobileEditorWidget.styles';
import useSetInstance from '../../hooks/useSetInstance';
import useGetActiveCta from '../../hooks/useGetActiveCta';
import useGetPreviewCta from '../../hooks/useGetPreviewCta';
import CTA from '../../components/Widget/CTA/CTA.component';
import useLoading from '../../hooks/useLoading';
import { Wix } from '../../util/Wix';
import useAppendFontScripts from '../../hooks/useAppendFontScripts';
import ResourceNotFound from '../../assets/resource-not-found.svg';
import { Cta } from '../../types';
import useGetInstance from '../../hooks/useGetInstance';
import useGetViewMode from '../../hooks/useGetViewMode';
import useSetCompId from '../../hooks/useSetCompId';
import useGetCompId from '../../hooks/useGetCompId';
import { useLocation } from 'react-router-dom';
import useGetOrigCompId from '../../hooks/useGetOrigCompId';
import useAddCompId from '../../hooks/useAddCompId';
import useDuplicateWidget from '../../hooks/useDuplicateWidget';
import useDuplicateInstance from '../../hooks/useDuplicateInstance';
import { updateSchedule } from '../../util/functions';
import useSetUser from '../../hooks/useSetUser';
import { updateLiveData, updateThemeColors } from '../../util/services';
import useSetPlanId from '../../hooks/useSetPlanId';
import useUser from '../../hooks/useUser';
import useDisableApp from '../../hooks/useDisableApp';
import UpgradeAppTooltip from '../../components/Dashboard/UpgradeAppTooltip/UpgradeAppTooltip.component';
import SkeletonComponent from '../../components/General/SkeletonComponent/SkeletonComponent.component';
import { useAppDispatch } from '../../redux/reduxHooks';
import useForceUpdate from '../../hooks/useForceUpdate';

const MobileEditorWidget: React.FC = () => {
  const media = 'mobile';

  const [currentCta, setCurrentCta] = useState<Cta>({} as Cta);
  const [wixEditMode, setWixEditMode] = useState<'editor' | 'preview'>(
    'editor'
  );

  const viewMode = useGetViewMode();
  const forceUpdate = useForceUpdate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const origCompId = useGetOrigCompId();
  const loading = useLoading();
  const activeCta = useGetActiveCta(instance, compId);
  const previewCta = useGetPreviewCta(instance, compId, location);
  useSetInstance();
  useSetCompId();
  useAppendFontScripts(currentCta as Cta);
  useSetUser(instance);
  useSetPlanId(instance);
  useAddCompId(instance, compId, origCompId, viewMode);
  useDuplicateWidget(instance, compId, origCompId, viewMode);
  useDuplicateInstance(instance, compId, origCompId, viewMode);
  const user = useUser();
  const disabled = useDisableApp(user);

  useEffect(() => {
    setWixEditMode(Wix.Utils.getViewMode());
  }, []);

  // Store currentCta (preview or active) in state
  useLayoutEffect(() => {
    if (previewCta.id && wixEditMode === 'editor') {
      setCurrentCta(previewCta);
    } else if (activeCta.id) {
      setCurrentCta(activeCta);
    }
  }, [previewCta, activeCta, wixEditMode]);

  const publishEvent = useCallback(async () => {
    if (instance) {
      await updateSchedule(instance);
      await updateLiveData(instance);
      forceUpdate();
    }
  }, [instance, forceUpdate]);

  useEffect(() => {
    Wix.addEventListener(Wix.Events.SITE_PUBLISHED, publishEvent);

    return () => {
      Wix.removeEventListener(Wix.Events.SITE_PUBLISHED, publishEvent);
    };
  }, [publishEvent]);

  useEffect(() => {
    const controller = new AbortController();

    Wix.addEventListener(Wix.Events.THEME_CHANGE, (event) => {
        updateThemeColors(
          event.siteColors,
          instance,
          controller
        );
    });
  }, [instance, dispatch]);

  if (loading) {
    return <SkeletonComponent />;
  } else if (disabled) {
    return (
      <CTAContainer data-testid='widget'>
        <UpgradeAppTooltip text={'Sign up for a paid plan to use this app'} />
      </CTAContainer>
    );
  } else {
    if (previewCta?.id) {
      return <CTA currentCta={previewCta} media={media} />;
    } else if (activeCta?.id) {
      return <CTA currentCta={activeCta} media={media} />;
    } else {
      return (
        <CTAContainer data-testid='widget'>
          <Text>
            Oops! You don't have anything scheduled. Double-click this window to
            open the settings panel create a new announcement!
          </Text>
          <ResourceNotFoundImage
            src={ResourceNotFound}
            alt='No resource found'
          />
        </CTAContainer>
      );
    }
  }
};

export default MobileEditorWidget;
