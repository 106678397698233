import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  padding: ${(props) => props.theme.spacing[3]};
  background: ${(props) => props.theme.color.gray600};
  color: ${(props) => props.theme.color.white};
  left: 50%;
  top: 13rem;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[5]};
  z-index: 2;
`;
