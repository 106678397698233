import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import {
  PopoverContainer,
  FlexEnd,
  ThemeSwatchContainer,
  CustomSwatchContainer,
  SwatchGrid,
  CustomSwatchGrid,
  ThemeColorStyles,
  Title,
  CustomColorButton,
  PickerContainer,
  ColorPickerContainer,
  PickerStyle,
} from './ColorPicker.styles';
import AddIcon from '@mui/icons-material/Add';
import CustomColorComponent from '../CustomColor/CustomColor.component';
import MenuButton from '../MenuButton/MenuButton.component';
import { Cover, Label } from '../../../styles/util';
import { addCustomColor, getCustomColors } from '../../../util/services';
import { useDispatch } from 'react-redux';
import {
  setBackgroundColor,
  setBorderColor,
  setButtonBackgroundColor,
  setButtonBorderColor,
  setButtonFontColor,
  setFontColor,
} from '../../../redux/elementSlice';
import { setCustomColors } from '../../../redux/themeSlice';
import useCurrentComponent from '../../../hooks/useCurrentComponent';
import useTargetElement from '../../../hooks/useTargetElement';
import useGetEditingMode from '../../../hooks/useGetEditingMode';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCustomColors from '../../../hooks/useGetCustomColors';
import useGetTheme from '../../../hooks/useGetTheme';
import { v4 as uuidv4 } from 'uuid';
import { CustomColor, Style, ThemeColor } from '../../../types';

const tinyColor = require('tinycolor2');

interface Props {
  params?: { buttonState: string };
  id?: string;
  style?: React.CSSProperties;
  label?: string;
  testId?: string;
}

const ColorPicker: React.FC<Props> = ({ params, id, style, label, testId }) => {
  const target = useTargetElement();
  const currentComponent = useCurrentComponent();
  const customColors = useGetCustomColors();
  const themeColors = useGetTheme();
  const editingMode = useGetEditingMode();
  const { buttonState } = params || {};
  const instance = useGetInstance();
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerY, setContainerY] = useState<number>(0);
  const [top, setTop] = useState<number>(70);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [color, setColor] = useState<string>('rgb(255, 255, 255)');
  const [selectedColor, setSelectedColor] = useState<
    ThemeColor | { reference: null; value: null } | CustomColor
  >({ reference: null, value: null });

  const dispatch = useDispatch();

  const handleChange = ({ rgb }) => {
    if (currentComponent.id)
      switch (id) {
        case 'background':
          dispatch(
            setBackgroundColor({
              id: currentComponent.id,
              color: tinyColor(rgb).toRgbString(),

              undo: false,
            })
          );
          break;
        case 'buttonBackgroundColor':
          if (buttonState)
            dispatch(
              setButtonBackgroundColor({
                id: currentComponent.id,
                color: tinyColor(rgb).toRgbString(),
                undo: false,
                buttonState,
              })
            );
          break;
        case 'fontColor':
          dispatch(
            setFontColor({
              id: currentComponent.id,
              color: tinyColor(rgb).toRgbString(),
              undo: false,
            })
          );
          break;
        case 'buttonFontColor':
          if (buttonState)
            dispatch(
              setButtonFontColor({
                id: currentComponent.id,
                color: tinyColor(rgb).toRgbString(),
                undo: false,
                buttonState,
              })
            );
          break;
        case 'borderColor':
          dispatch(
            setBorderColor({
              id: currentComponent.id,
              color: tinyColor(rgb).toRgbString(),
              undo: false,
            })
          );
          break;
        case 'buttonBorderColor':
          if (buttonState)
            dispatch(
              setButtonBorderColor({
                id: currentComponent.id,
                color: tinyColor(rgb).toRgbString(),
                undo: false,
                buttonState,
              })
            );
          break;
        default:
          break;
      }
  };

  // Only calls redux after color is selected for a short period of time, or mouseUp
  const handleChangeComplete = ({ rgb }) => {
    if (currentComponent.id)
      switch (id) {
        case 'background':
          dispatch(
            setBackgroundColor({
              id: currentComponent.id,
              color: tinyColor(rgb).toRgbString(),

              undo: true,
            })
          );
          break;
        case 'buttonBackgroundColor':
          if (buttonState)
            dispatch(
              setButtonBackgroundColor({
                id: currentComponent.id,
                color: tinyColor(rgb).toRgbString(),
                undo: true,
                buttonState,
              })
            );
          break;
        case 'fontColor':
          dispatch(
            setFontColor({
              id: currentComponent.id,
              color: tinyColor(rgb).toRgbString(),
              undo: true,
            })
          );
          break;
        case 'buttonFontColor':
          if (buttonState)
            dispatch(
              setButtonFontColor({
                id: currentComponent.id,
                color: tinyColor(rgb).toRgbString(),
                undo: true,
                buttonState,
              })
            );
          break;
        case 'borderColor':
          dispatch(
            setBorderColor({
              id: currentComponent.id,
              color: tinyColor(rgb).toRgbString(),
              undo: true,
            })
          );
          break;
        case 'buttonBorderColor':
          if (buttonState)
            dispatch(
              setButtonBorderColor({
                id: currentComponent.id,
                color: tinyColor(rgb).toRgbString(),
                undo: true,
                buttonState,
              })
            );
          break;
        default:
          break;
      }
  };

  useEffect(() => {
    if (
      themeColors.some(
        (col) => col.value === `#${tinyColor(color).toHex()}`.toUpperCase()
      )
    ) {
      let col: ThemeColor | undefined = themeColors.find(
        (col) => col.value === `#${tinyColor(color).toHex()}`.toUpperCase()
      );
      if (col) setSelectedColor(col);
    }
  }, [color, themeColors]);

  // Update color state when target or id changes
  useEffect(() => {
    if (target) {
      const buttonStyle = target.style.desktop as Style;
      const elementStyle = target.style.desktop as Style;
      switch (id) {
        case 'background':
          if (elementStyle.background?.value) {
            setColor(tinyColor(elementStyle.background?.value).toRgb());
          } else {
            setColor('rgba(255,255,255, 1)');
          }
          break;

        case 'buttonBackgroundColor':
          if (buttonState === 'normal') {
            if (buttonStyle.background?.value) {
              setColor(tinyColor(buttonStyle.background.value).toRgb());
            } else {
              setColor('rgba(255,255,255, 1)');
            }
          } else if (buttonState === 'hover') {
            if (buttonStyle.hover?.background?.value) {
              setColor(tinyColor(buttonStyle.hover.background.value).toRgb());
            } else {
              setColor('rgba(255,255,255, 1)');
            }
          }
          break;
        case 'fontColor':
          if (elementStyle.color?.value) {
            setColor(tinyColor(elementStyle.color.value).toRgb());
          } else {
            setColor('rgba(255,255,255, 1)');
          }
          break;
        case 'buttonFontColor':
          if (buttonState === 'normal') {
            if (buttonStyle.color?.value) {
              setColor(tinyColor(buttonStyle.color.value).toRgb());
            } else {
              setColor('rgba(255,255,255, 1)');
            }
          } else if (buttonState === 'hover') {
            if (buttonStyle.hover?.color?.value) {
              setColor(tinyColor(buttonStyle.hover.color.value).toRgb());
            } else {
              setColor('rgba(255,255,255, 1)');
            }
          }
          break;
        case 'borderColor':
          if (elementStyle.borderColor?.value) {
            setColor(tinyColor(elementStyle.borderColor.value).toRgb());
          } else {
            setColor('rgba(255,255,255, 1)');
          }
          break;
        case 'buttonBorderColor':
          if (buttonState === 'normal') {
            if (buttonStyle.borderColor?.value) {
              setColor(tinyColor(buttonStyle.borderColor.value).toRgb());
            } else {
              setColor('rgba(255,255,255, 1)');
            }
          } else if (buttonState === 'hover') {
            if (buttonStyle.hover?.borderColor?.value) {
              setColor(tinyColor(buttonStyle.hover.borderColor.value).toRgb());
            } else {
              setColor('rgba(255,255,255, 1)');
            }
          }
          break;
        default:
          break;
      }
    }
  }, [target, editingMode, id, buttonState]);

  const handleAddColor: React.MouseEventHandler<
    HTMLButtonElement
  > = async (): Promise<void> => {
      let colors;
      try {
        await addCustomColor(
          { value: tinyColor(color).toRgbString(), id: uuidv4() },
          instance,
        );
      } catch (e) {
        console.error('Error getting custom colors');
      }

      try {
        colors = await getCustomColors(instance);
      } catch (e) {
        console.error('Error getting custom colors');
      }

      if (colors && colors.data) {
        dispatch(setCustomColors({ customColors: colors.data }));
      }
      setShowPicker(false);
      setSelectedColor({ reference: null, value: null });

  };

  const handleCustom = () => {
    setShowPicker(true);
  };

  const handleSelectColor = (
    color: CustomColor | ThemeColor | { reference: null; value: null }
  ) => {
    setSelectedColor(color);

    const rgb = tinyColor(color.value).toRgb();
    handleChangeComplete({ rgb });
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerY(containerRef.current.getBoundingClientRect().y);
    }
  }, [containerRef, colorPickerRef]);

  useLayoutEffect(() => {
    const pickerHeight = 380;
    if (showPopover)
      if (window.innerHeight < containerY + 70 + pickerHeight) {
        const availableSpace = window.innerHeight - containerY;
        const offset = pickerHeight - availableSpace;

        setTop(-Math.abs(offset));
      } else if (label) {
        setTop(70);
      } else {
        setTop(40);
      }
  }, [id, label, containerY, showPopover]);

  return (
    <ColorPickerContainer ref={containerRef} data-testid={testId}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <PickerStyle
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setShowPopover(!showPopover);
        }}
        showColorPicker={showPopover}
        data-testid='picker'
        aria-label={'Select a color'}
        title={'Select color'}
        background={tinyColor(color).toHexString()}
        onClick={() => setShowPopover(!showPopover)}
      />
      {showPopover && (
        <>
          <PopoverContainer
            ref={colorPickerRef}
            style={{ ...style }}
            top={top}
            label={label}>
            <ThemeSwatchContainer>
              <Title>Theme Colors</Title>
              <SwatchGrid>
                {themeColors.map((color, i) => (
                  <ThemeColorStyles
                    id={color.reference}
                    tabIndex={0}
                    key={`${color.reference}-${i}`}
                    color={color.value}
                    selectedColor={
                      'reference' in selectedColor
                        ? (selectedColor as ThemeColor)
                        : { reference: null }
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSelectColor(color);
                    }}
                    onClick={() => handleSelectColor(color)}
                  />
                ))}
              </SwatchGrid>
            </ThemeSwatchContainer>

            <CustomSwatchContainer>
              <Title>Custom Colors</Title>
              <CustomSwatchGrid>
                <CustomColorButton
                  aria-label={'Add custom color'}
                  title={'Add custom color'}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleCustom();
                  }}
                  onClick={handleCustom}>
                  <AddIcon />
                </CustomColorButton>
                {customColors.map((item, i) => (
                  <CustomColorComponent
                    key={`${item.value}-${i}`}
                    id={item.id}
                    color={item.value}
                    selectedColor={selectedColor}
                    onClick={() => handleSelectColor(item)}
                  />
                ))}
              </CustomSwatchGrid>
            </CustomSwatchContainer>

            <div
              style={{
                padding: '2rem',
                paddingTop: '0',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <div
                style={{ color: 'blue', cursor: 'pointer' }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleCustom();
                }}
                onClick={handleCustom}>
                + Choose color
              </div>
              {tinyColor(color).toHexString().toUpperCase()}
            </div>
            {showPicker && (
              <>
                <PickerContainer>
                  <ChromePicker
                    id={id}
                    name={id}
                    color={color}
                    onChange={handleChange}
                    onChangeComplete={handleChangeComplete}
                    className='chromepicker'
                  />
                  <FlexEnd>
                    <MenuButton
                      label={'Save'}
                      variant='secondary'
                      style={{ padding: '.5rem 1.2rem', fontSize: '1.2rem' }}
                      onClick={handleAddColor}
                    />
                  </FlexEnd>
                </PickerContainer>
                <Cover onClick={() => setShowPicker(false)} />
              </>
            )}
          </PopoverContainer>
          <Cover onClick={() => setShowPopover(false)} />
        </>
      )}
    </ColorPickerContainer>
  );
};

export default ColorPicker;
