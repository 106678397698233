import { useEffect, useState } from "react";
import { getCompIds } from "../util/services";
import { CompId } from "../types";

const useGetCompIdsFromDB = (instance: string): CompId[] => {
  const [compIds, setCompIds] = useState<CompId[]>([]);

  useEffect(() => {
    const controller = new AbortController();
    getCompIds(instance, controller).then((data) => {
      if (data) setCompIds(data);
    });

    return () => controller.abort();
  }, [instance]);

  return compIds;
};

export default useGetCompIdsFromDB;
