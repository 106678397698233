import styled from 'styled-components';
import { Style } from '../../../types';

export const Text = styled.p<{ elstyle: Style }>`
  font-family: ${(props) => props.elstyle.fontFamily?.value};
  background: ${(props) => props.elstyle.background?.value};
  display: ${(props) => props.elstyle.display?.value};
  border-color: ${(props) => props.elstyle.borderColor?.value};
  border-radius: ${(props) => props.elstyle.borderRadius?.value}px;
  border-style: ${(props) => props.elstyle.borderStyle?.value};
  border-width: ${(props) => props.elstyle.borderWidth?.value}px;
  color: ${(props) => props.elstyle.color?.value};
  font-size: ${(props) => props.elstyle.fontSize?.value}px;
  font-style: ${(props) => props.elstyle.fontStyle?.value};
  font-weight: ${(props) => props.elstyle.fontWeight?.value};
  height: ${(props) =>
    typeof props.elstyle.height?.value === 'string'
      ? props.elstyle.height?.value
      : props.elstyle.height?.value + 'px'};
  margin: 0;
  line-height: ${(props) => props.elstyle.lineHeight?.value};
  position: absolute;
  top: ${(props) => props.elstyle?.top?.value}px;
  left: ${(props) => props.elstyle.left?.value && props.elstyle.left.value}px;
  text-align: ${(props) => props.elstyle.textAlign?.value};
  text-decoration: ${(props) => props.elstyle.textDecoration?.value};
  width: ${(props) =>
    typeof props.elstyle.width?.value === 'string'
      ? props.elstyle.width?.value
      : props.elstyle.width?.value + 'px'};
  z-index: ${(props) => props.elstyle.zIndex?.value};
  text-transform: ${(props) => props.elstyle.textTransform?.value};
`;

export const LinkStyles = styled.a<{ color?: string }>`
  text-decoration: none;
  color: ${(props) => props.theme.color};
`;
