import { useLayoutEffect, useState } from 'react';

export const useUpgradeModal = (disabled: boolean) => {
  const [messageConfirmed, setMessageConfirmed] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useLayoutEffect(() => {
    if (disabled && !messageConfirmed) {
      setShowUpgradeModal(true);
    } else {
      setShowUpgradeModal(false);
    }
  }, [disabled, messageConfirmed]);

  return { showUpgradeModal, setShowUpgradeModal, setMessageConfirmed };
};
