import styled from 'styled-components';

export const Text = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.orange500};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[5]};
`;
