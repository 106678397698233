import React from 'react';
import {
  Container,
  Thumbnail,
  Title,
  Description,
} from './TutorialCard.styles';

interface Props {
  thumbnail: string;
  title: string;
  description: string;
  onClick: React.MouseEventHandler<HTMLImageElement>;
}

const TutorialCard: React.FC<Props> = ({
  thumbnail,
  title,
  description,
  onClick,
}) => {
  return (
    <Container>
      <Thumbnail src={thumbnail} onClick={onClick} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default TutorialCard;
