import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconStyles } from './MoreOptions.styles';

interface Props {
  onClick: React.MouseEventHandler;
  active: boolean;
}

const MoreOptions: React.FC<Props> = ({ onClick, active }) => {
  return (
    <IconStyles
      active={active}
      onClick={onClick}
      aria-label='More Options'
      title={'More options'}>
      <MoreHorizIcon fontSize='large' />
    </IconStyles>
  );
};

export default MoreOptions;
