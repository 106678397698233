import styled from 'styled-components';
import { DashboardView } from '../../types';

export const DashboardContainer = styled.div`
  display: flex;
  width: 100vw;
`;

export const Sidebar = styled.div<{ currentView: DashboardView }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 25rem;
  min-height: 100vh;
  max-height: 100vh;
  background-color: ${(props) =>
    props.currentView === 'design'
      ? props.theme.color.blue900
      : props.theme.color.blue700};
  margin-left: ${(props) => (props.currentView === 'design' ? '0' : '0')};
  transition: margin-left 0.5s ease-in-out, background-color 0.5s ease-in-out;
`;

export const Logo = styled.img`
  width: 13.4rem;
  margin: 0 auto;
  display: block;
  padding-top: ${(props) => props.theme.spacing[5]};
`;

export const LogoTitle = styled.h2<{ currentView: DashboardView }>`
  font-size: 2.4rem;
  font-weight: bold;
  display: block;
  text-align: center;
  color: #fff;
  margin-top: -${(props) => props.theme.spacing[5]};
  margin-bottom: ${(props) => props.theme.spacing[6]};
`;

export const ViewContainer = styled.div<{ currentView: DashboardView }>`
  background-color: ${(props) => props.theme.color.gray50};
  width: calc(100vw - 25rem);
  overflow: auto;
  height: 100vh;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[5]};
`;
