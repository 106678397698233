import { useEffect, useState } from 'react';
import { formatUtcToDate } from '../../../util/functions';

export const useFormatDate = (schedule: { start: number; end: number }) => {
  const [formattedDate, setFormattedDate] = useState<null | string>(null);
  useEffect(() => {
    if (schedule.start && schedule.end) {
      setFormattedDate(
        `${formatUtcToDate(schedule.start)} - ${formatUtcToDate(schedule.end)}`
      );
    }
  }, [schedule]);

  return { formattedDate };
};
