import { useEffect, useState } from "react";
import { Cta, Style } from "../types";
import useMedia from "./useMedia";
import useGetViewMode from "./useGetViewMode";
import useGetCompSettings from "./useGetCompSettings";
import useGetInstance from "./useGetInstance";
import useGetCompId from "./useGetCompId";
import useBuildCompSettings from "./useBuildCompSettings";

const useCanvasStyle = (
  category: "active" | "drafts" | "upcoming" | "expired" | undefined,
  currentCta: Cta,
  preview: boolean | undefined
) => {
  const [style, setStyle] = useState<Style>({});
  const media = useMedia();
  const viewMode = useGetViewMode();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const compSettingsVals = useGetCompSettings(instance, compId);
  const compSettings = useBuildCompSettings(compSettingsVals);

  useEffect(() => {
    switch (media) {
      case "desktop":
        if (viewMode === "editor" || category === "drafts" || preview) {
          if (
            compSettings.height &&
            compSettings.width &&
            compSettings.boxShadow
          ) {
            setStyle({
              ...currentCta.editor.style.desktop,
              height: { value: compSettings.height },
              width: { value: compSettings.width },
              boxShadow: { value: compSettings.boxShadow, default: true },
            });
          } else {
            setStyle(currentCta.editor.style.desktop);
          }
        } else if (currentCta.live?.style?.desktop && viewMode !== null) {
          if (
            compSettings.height &&
            compSettings.width &&
            compSettings.boxShadow
          ) {
            setStyle({
              ...currentCta.live.style.desktop,
              height: { value: compSettings.height },
              width: { value: compSettings.width },
              boxShadow: { value: compSettings.boxShadow, default: true },
            });
          } else {
            setStyle(currentCta.live.style.desktop);
          }
        }

        break;

      case "mobile":
        if (viewMode === "editor" || preview) {
          if (compSettings.boxShadow) {
            setStyle({
              ...currentCta.editor.style.mobile,
              boxShadow: { value: compSettings.boxShadow, default: true },
            });
          } else {
            setStyle(currentCta.editor.style.mobile);
          }
        } else if (currentCta.live?.style?.mobile && viewMode !== "null") {
          if (compSettings.boxShadow) {
            setStyle({
              ...currentCta.live.style.mobile,
              boxShadow: { value: compSettings.boxShadow, default: true },
            });
          } else {
            setStyle(currentCta.live.style.mobile);
          }
        }
        break;
      default:
        break;
    }
  }, [media, currentCta, viewMode, category, preview, compSettings]);

  return style;
};

export default useCanvasStyle;
