import React, { memo } from 'react';
import { getStyles } from '../../../util/functions';
import ResizeHandles from '../ResizeHandles/ResizeHandles.component';
import { TypographyStyles, Content } from './Typography.styles';
import { Link, Pos, Style } from '../../../types';

interface Props {
  content: string;
  id: string;
  elstyle: Style;
  link?: Link;
  style?: React.CSSProperties;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  editable: React.RefObject<HTMLDivElement>;
  cursor: 'text' | 'move';
  handleKeyDown: React.KeyboardEventHandler;
  setPos: (value: Pos | ((prevVar: Pos) => Pos)) => void;
  pos: Pos;
  handleBlur: React.FocusEventHandler;
  handleDoubleClick: React.MouseEventHandler;
  myRef: any;
  ref: any;
  isSelected: boolean;
  setBounds: any;
  rect: any;
  setDisabled: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Typography: React.FC<Props> = memo(
  React.forwardRef(({ ...props }, ref) => {
    const handleKeyDown = (e) => {
      props.handleKeyDown(e);
    };

    return (
      <TypographyStyles
        data-testid='typography'
        {...props}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        ref={ref as React.RefObject<HTMLDivElement>}
        cursor={props.cursor}
        isSelected={props.isSelected}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(e);
        }}
        id={props.id}
        style={{
          ...(getStyles(
            props.elstyle,
            props.style?.transform,
            true
          ) as React.CSSProperties),
        }}
        className='resizable'>
        <Content
          onBlur={props.handleBlur}
          onDoubleClick={props.handleDoubleClick}
          ref={props.editable}
          // onKeyDown={handleClientHeight}
        >
          {props.content}
        </Content>

        <ResizeHandles
          type={'typography'}
          setPos={props.setPos}
          pos={props.pos}
          myRef={props.myRef}
          id={props.id}
          setBounds={props.setBounds}
          rect={props.rect}
          setDisabled={props.setDisabled}
        />
      </TypographyStyles>
    );
  })
);

export default Typography;
