import { useEffect, useRef } from 'react';
import { setCtaList } from '../redux/ctaSlice';
import { getAllCtasFromDB } from '../util/services';
import { useAppDispatch } from '../redux/reduxHooks';

const useSetCtaListFromDB = (instance: string, compId?: string) => {
  const dispatch = useAppDispatch();
  const hasRendered = useRef<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();
    if (instance) {
      console.log('is this running?')
      getAllCtasFromDB(instance, controller, compId).then((res) => {
        if (res) {
          dispatch(setCtaList({ ctaList: res }));
          hasRendered.current = true;
        }
      });
    }

    return () => controller.abort();
  }, [instance, dispatch, compId]);

  return hasRendered.current;
};

export default useSetCtaListFromDB;
