import React, { useRef } from 'react';
import { Container } from './TopBarMenu.styles';

interface Props {
  items: React.ReactElement[];
}

const TopBarMenu: React.FC<Props> = ({ items }) => {
  const menuRef = useRef(null);

  return <Container ref={menuRef}>{items}</Container>;
};

export default TopBarMenu;
