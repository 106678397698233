import React, { useLayoutEffect, useState } from 'react';
import { Title } from '../../../styles/util';
import { MenuContainer, Row } from './ElementsMenu.styles';
import { SVGVariants } from '../../../enums';
import ElementMenuItem from '../ElementMenuItem/ElementMenuItem.component';
import { SVGVariation } from '../../../types';
import { convertArrayTo3d } from '../../../util/functions';

interface ElementType {
  premium: boolean;
  svg: string;
  alt: string;
  variant: SVGVariation;
}

const ElementsMenu: React.FC = () => {
  const [elementItems, setElementItems] = useState<ElementType[][]>([]);

  useLayoutEffect(() => {
    if (SVGVariants) {
      const result: ElementType[][] = convertArrayTo3d(SVGVariants);
      setElementItems(result);
    }
  }, []);

  return (
    <MenuContainer data-testid='button-menu'>
      <Title>Elements</Title>
      {elementItems.length > 0 &&
        elementItems.map((row) => (
          <Row>
            {row.map((item, index) => {
              return (
                <ElementMenuItem
                  key={JSON.stringify(item.variant) + index}
                  variant={item.variant}
                  svg={item.svg}
                  alt={item.alt}
                  premium={item.premium}
                />
              );
            })}
          </Row>
        ))}
    </MenuContainer>
  );
};

export default ElementsMenu;
