import React from 'react';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { ButtonStyles } from './TextIconButton.styles';
import { addElement } from '../../../redux/elementSlice';
import {
  TextBtn1,
  TextBtn2,
  TextBtn3,
  TextBtn4,
  TextBtn5,
  TextBtn6,
  TextBtn7,
  TextBtn8,
} from '../../../ButtonEnums';
import { v4 as uuidv4 } from 'uuid';
import { Style } from '../../../types';
import useElements from '../../../hooks/useElements';

interface Props {
  style: Style;
  children?: React.ReactNode;
  type: number;
}

const TextIconButton: React.FC<Props> = ({ style, children, type }) => {
  const dispatch = useAppDispatch();
  const elements = useElements().present;

  const handleAddButton = () => {
    switch (type) {
      case 1:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn1,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn1.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn1.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 2:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn2,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn2.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn2.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 3:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn3,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn3.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn3.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 4:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn4,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn4.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn4.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 5:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn5,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn5.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn5.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 6:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn6,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn6.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn6.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 7:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn7,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn7.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn7.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      case 8:
        dispatch(
          addElement({
            selected: [
              {
                ...TextBtn8,
                id: uuidv4(),
                style: {
                  desktop: {
                    ...TextBtn8.style.desktop,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                  mobile: {
                    ...TextBtn8.style.mobile,
                    zIndex: {
                      value: elements.length + 2,
                    },
                  },
                },
              },
            ],
          })
        );
        break;
      default:
        return;
    }
  };

  return (
    <ButtonStyles onClick={handleAddButton} btnStyle={style}>
      {children}
    </ButtonStyles>
  );
};

export default TextIconButton;
