import React from 'react';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import SmartButtonOutlinedIcon from '@mui/icons-material/SmartButtonOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {
  setActiveSidebarView,
  setShowSidebar,
} from '../../../redux/sidebarViewSlice';
import { SidebarMenuStyles, MenuButton } from './SidebarMenu.styles';
import useSidebarView from '../../../hooks/useSidebarView';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { handleOpenBillingPage } from '../../../util/functions';

interface MenuItem {
  icon: any;
  id: string;
  label: string;
}

type MenuItems = MenuItem[];

const SidebarMenu: React.FC = () => {
  const menuItems: MenuItems = [
    { icon: TableChartOutlinedIcon, id: '1', label: 'Templates' },
    { icon: WidgetsIcon, id: '8', label: 'Elements' },
    { icon: ImageSearchOutlinedIcon, id: '2', label: 'Images' },
    { icon: TitleOutlinedIcon, id: '3', label: 'Text' },
    { icon: SmartButtonOutlinedIcon, id: '4', label: 'Buttons' },
    { icon: ImageOutlinedIcon, id: '5', label: 'Background' },
    { icon: CalendarTodayIcon, id: '7', label: 'Schedule' },
  ];

  const sidebarView = useSidebarView();
  const dispatch = useAppDispatch();

  const toggleActiveSidebarView = (index: number) => {
    if (index === 9) {
      dispatch(setActiveSidebarView({ id: index }));
      dispatch(setShowSidebar(false));
    } else {
      dispatch(setActiveSidebarView({ id: menuItems[index].id }));
      dispatch(setShowSidebar(true));
    }
  };

  const handleClick = (index: number) => {
    toggleActiveSidebarView(index);
  };

  return (
    <SidebarMenuStyles data-testid='sidebar-menu'>
      <div>
        {menuItems.map((item, index) => (
          <MenuButton
            view={String(sidebarView)}
            id={item.id}
            key={item.label}
            onClick={() => handleClick(index)}>
            <item.icon
              style={{
                fontSize: 28,
                color: 'inherit',
                marginBottom: '2px',
              }}
            />
            {item.label}
          </MenuButton>
        ))}
      </div>
      <div>
        <MenuButton
          view={String(sidebarView)}
          id='9'
          onClick={() => handleClick(9)}>
          <HelpOutlineOutlinedIcon style={{ fontSize: 28, color: 'inherit' }} />
          Help
        </MenuButton>
        <MenuButton id={'10'} onClick={handleOpenBillingPage}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M4.04901 5.80188L7.85401 9.14988L11.244 4.97588C11.3389 4.85919 11.459 4.76542 11.5952 4.70155C11.7313 4.63768 11.8802 4.60536 12.0306 4.60699C12.181 4.60863 12.3291 4.64417 12.4639 4.71099C12.5987 4.7778 12.7166 4.87415 12.809 4.99288L16.044 9.14888L19.972 5.75288C20.1263 5.61979 20.3175 5.53699 20.5201 5.51556C20.7227 5.49413 20.927 5.5351 21.1057 5.63298C21.2844 5.73085 21.4289 5.88097 21.5199 6.06324C21.6109 6.24551 21.6441 6.45123 21.615 6.65288L20.115 16.9999H3.92201L2.39901 6.69988C2.36876 6.49711 2.40156 6.28995 2.49296 6.10645C2.58437 5.92294 2.72996 5.77197 2.91003 5.67397C3.0901 5.57597 3.29594 5.53568 3.49966 5.55856C3.70339 5.58144 3.89517 5.66638 4.04901 5.80188ZM4.00001 17.9999H20V18.9999C20 19.2651 19.8947 19.5194 19.7071 19.707C19.5196 19.8945 19.2652 19.9999 19 19.9999H5.00001C4.73479 19.9999 4.48044 19.8945 4.2929 19.707C4.10537 19.5194 4.00001 19.2651 4.00001 18.9999V17.9999Z'
              fill='white'
            />
          </svg>
          Upgrade
        </MenuButton>
      </div>
    </SidebarMenuStyles>
  );
};

export default SidebarMenu;
