import styled from 'styled-components';

export const Tooltip = styled.div`
  padding: ${(props) => props.theme.spacing[4]};
  background: #8c21af;
  border: 1px solid ${(props) => props.theme.color.gray200};
  border-radius: 5px;
  position: absolute;
  color: #fff;
  align-items: center;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: ${(props) => props.theme.shadow[3]};
  z-index: 1000;
  display: flex;
  gap: ${(props) => props.theme.spacing[4]};
`;
