import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 30%;
  transform: translate(0, -50%);
`;

export const Status = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  font-style: italic;
  color: #fff;
`;
