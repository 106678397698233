import React from 'react';
import { Text, Container } from './TrialStatus.styles';
import { handleOpenBillingPage } from '../../../util/functions';
import Button from '../../Settings/Button/Button.component';

interface Props {
  daysRemaining: number | null;
}

const TrialStatus: React.FC<Props> = ({ daysRemaining }) => {
  return (
    <Container>
      <Text>
        {daysRemaining && daysRemaining > 0
          ? `${daysRemaining} days remaining of free trial`
          : `Your free trial has expired`}
      </Text>
      <Button
        label='Upgrade Now'
        variant='tertiary'
        style={{
          backgroundColor: 'rgb(255,217,142)',
          color: '#00113D',
          marginBottom: 0,
        }}
        onClick={handleOpenBillingPage}
      />
    </Container>
  );
};

export default TrialStatus;
