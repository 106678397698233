import { useEffect, useLayoutEffect, useState } from "react";
import { CalendarEvent, CalendarEvents, CtaList } from "../types";
import { useAppSelector } from "../redux/reduxHooks";

const usePendingEvents = () => {
  const [pending, setPending] = useState<CalendarEvents>([]);
  const eventAdded = useAppSelector((state) => state.calendar.value.eventAdded);

  const setPendingEvents = () => {
    const eventString = sessionStorage.getItem("scheduleList");

    if (eventString) {
      const events = JSON.parse(eventString) as CtaList;

      const scheduledCtas: CalendarEvents = [];

      if (events?.length > 0)
        events.forEach((item) => {
          let tmpEvent = {} as CalendarEvent;
          if (item.schedule.start && item.schedule.end) {
            tmpEvent.start = new Date(item.schedule.start);
            tmpEvent.end = new Date(item.schedule.end);

            tmpEvent.notes = "Schedule pending. Publish site to save schedule.";
            tmpEvent.id = item.id;
            tmpEvent.title = item.title + " (PENDING)";
            tmpEvent.color = "orange";
            tmpEvent.textColor = "#222";
            tmpEvent.groupId = "pending";
            scheduledCtas.push(tmpEvent);
          }
        });

      setPending(scheduledCtas);
    }
  };

  useLayoutEffect(() => {
    setPendingEvents();
  }, [eventAdded]);

  useEffect(() => {
    window.addEventListener("storage", setPendingEvents);

    return () => {
      window.removeEventListener("storage", setPendingEvents);
    };
  }, []);

  return pending;
};

export default usePendingEvents;
