import styled from 'styled-components';
import { PlanId } from '../../../types';

export const Card = styled.div<{ variation: PlanId }>`
  width: 31.5rem;
  transition: margin-right 150ms ease-in-out;
  height: 47rem;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.variation === 'professional'
      ? '0px 4px 15px 2px #6B86CB'
      : props.theme.shadow[4]};
  background: ${(props) =>
    props.variation === 'professional' ? '#293D70' : props.theme.color.white};
  color: ${(props) =>
    props.variation === 'professional'
      ? props.theme.color.white
      : props.theme.color.text};
  padding: ${(props) => props.theme.spacing[6]};
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[7]};
`;

export const PlanTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export const Price = styled.span`
  font-size: 3.6rem;
  font-weight: 700;
`;

export const Month = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
  padding-left: 0.3rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export const Subheading = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: ${(props) => props.theme.spacing[5]};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[3]};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100% !important;
`;
