import styled from 'styled-components';

export const LetterSpacingButton = styled.button<{ show: boolean }>`
  -webkit-transition: background-color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  border-radius: 2px;
  padding: 0.6rem;
  cursor: pointer;
  position: relative;
  border: none;
  background-color: ${(props) =>
    props.show ? props.theme.color.gray200 : '#FFF'};

  &:hover {
    background-color: ${(props) =>
      props.show ? props.theme.color.gray200 : props.theme.color.gray100};
  }
`;

export const InputContainer = styled.div<{ responsiveMenu: boolean }>`
  position: absolute;
  top: ${(props) => (props.responsiveMenu ? '9rem' : '4.5rem')};
  right: 0;
  padding: 2rem;
  border-radius: 3px;
  background: #fff;
  box-shadow: ${(props) => props.theme.shadow[2]};
  cursor: default;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

export const Flex = styled.div`
  display: flex;
`;
