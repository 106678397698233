import { useEffect, useState } from 'react';

import { CtaList, DashboardTab } from '../../../types';

export const usePageIndex = () => {
  const [pages, setPages] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);

  useEffect(() => {
    if (pageIndex > pages) {
      setPageIndex(pages);
    }
  }, [pages, pageIndex]);

  return { pageIndex, setPageIndex, setPages, pages };
};

export const useActiveTab = (
  activeCtas: CtaList,
  drafts: CtaList,
  upcoming: CtaList,
  expired: CtaList,
  itemsVisible: number,
  setPages: React.Dispatch<React.SetStateAction<number>>
) => {
  const [activeTab, setActiveTab] = useState<DashboardTab>('upcoming');

  // Set the number of pages in carousel
  // # total items / # items visible = # pages
  useEffect(() => {
    switch (activeTab) {
      case 'active':
        setPages(Math.ceil(activeCtas.length / itemsVisible));
        break;
      case 'upcoming':
        setPages(Math.ceil(upcoming.length / itemsVisible));
        break;
      case 'drafts':
        setPages(Math.ceil(drafts.length / itemsVisible));
        break;
      case 'expired':
        setPages(Math.ceil(expired.length / itemsVisible));
        break;
      default:
        break;
    }
  }, [
    activeTab,
    activeCtas,
    drafts,
    upcoming,
    expired,
    itemsVisible,
    setPages,
  ]);

  return { setActiveTab, activeTab };
};

// Sets the number of items (carousel items) visible based on the window width.
// Used to calculate the number of slides in carousel
export const useItemsVisible = (windowWidth: number) => {
  const [itemsVisible, setItemsVisible] = useState<number>(0);

  useEffect(() => {
    if (windowWidth < 1100) {
      setItemsVisible(1);
    } else if (windowWidth < 1430 && windowWidth >= 1000) {
      setItemsVisible(2);
    } else if (windowWidth < 1760 && windowWidth >= 1430) {
      setItemsVisible(3);
    } else if (windowWidth < 2090 && windowWidth >= 1760) {
      setItemsVisible(4);
    } else if (windowWidth >= 2090) {
      setItemsVisible(5);
    }
  }, [windowWidth]);

  return { itemsVisible };
};

export const useSetCarouselWidth = (
  frameRef: React.RefObject<HTMLDivElement>,
  windowWidth: number
) => {
  const [frameWidth, setFrameWidth] = useState<number>(0);

  useEffect(() => {
    if (frameRef.current) {
      setFrameWidth(frameRef.current.offsetWidth);
    }
  }, [windowWidth, setFrameWidth]);

  return { frameWidth };
};
