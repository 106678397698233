import React from 'react';
import { LinkList, Link } from './SidebarLinks.styles';
import { DashboardView } from '../../../types';
import useUser from '../../../hooks/useUser';

interface Props {
  currentView: DashboardView;
  setCurrentView: (view: DashboardView) => void;
}

const SidebarLinks: React.FC<Props> = ({ currentView, setCurrentView }) => {
  const handleViewChange = (view: DashboardView) => {
    setCurrentView(view);
  };

  return (
    <LinkList>
      <Link
        view={currentView}
        name='announcements'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleViewChange('announcements');
        }}
        onClick={() => handleViewChange('announcements')}>
        My Content
      </Link>

      <Link
        view={currentView}
        name='subscription'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleViewChange('subscription');
        }}
        onClick={() => handleViewChange('subscription')}>
        Subscription
      </Link>
      <Link
        view={currentView}
        name='tutorials'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleViewChange('tutorials');
        }}
        onClick={() => handleViewChange('tutorials')}>
        Tutorials
      </Link>
      <Link
        view={currentView}
        name='support'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleViewChange('support');
        }}
        onClick={() => handleViewChange('support')}>
        Support
      </Link>
    </LinkList>
  );
};

export default SidebarLinks;
