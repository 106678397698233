import React, { useState } from 'react';
import {
  TemplateStyles,
  MenuButton,
  Cover,
  Image,
  CrownContainer,
  Overlay,
} from './Template.styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TemplateContextMenu from '../TemplateContextMenu/TemplateContextMenu.component';
import Crown from '../../General/Crown/Crown.component';
import useUser from '../../../hooks/useUser';
import useRestrictUser from '../../../hooks/useRestrictUser';

interface Props {
  src: string;
  alt: string;
  id: number;
  premium: boolean;
}

const Template: React.FC<Props> = ({ id, src, alt, premium }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const user = useUser();
  const restricted = useRestrictUser(user);

  return (
    <TemplateStyles>
      <MenuButton
        aria-label={'More options'}
        title={'More options'}
        onClick={() => setShowMenu(!showMenu)}
        disabled={restricted && premium && true}>
        <MoreVertIcon fontSize={'large'} style={{ color: 'inherit' }} />
      </MenuButton>
      {restricted && premium && (
        <CrownContainer>
          <Crown />
        </CrownContainer>
      )}
      <Image style={{ borderRadius: '5px' }} src={src} alt={alt} />
      {showMenu && (
        <TemplateContextMenu setShowMenu={setShowMenu} templateId={id} />
      )}
      {showMenu && <Cover onClick={() => setShowMenu(false)} />}
      {restricted && premium && <Overlay />}
    </TemplateStyles>
  );
};

export default Template;
