import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const UpperSection = styled.div`
  height: 378px;
  overflow-y: auto;
  padding: 0 ${(props) => props.theme.spacing[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LowerSection = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  padding: ${(props) => props.theme.spacing[4]};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.color.settings.gray100};
`;

export const LinkText = styled.span`
  cursor: pointer;
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.settings.blue500};
  text-align: center;
  margin-bottom: 1.8rem;
`;
