import React, { useCallback } from 'react';
import {
  Card,
  PlanTitle,
  Price,
  Month,
  Row,
  Subheading,
  Text,
  FeatureContainer,
  ButtonContainer,
} from './PricingCard.styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuButton from '../../DesignStudio/MenuButton/MenuButton.component';
import { PlanId } from '../../../types';

const basicFeatures = [
  'Schedule up to 2 designs',
  'Up to 10,000 views per month',
  'Basic templates',
  'Limited access to design features',
];
const proFeatures = [
  'Everything in basic',
  'Schedule up to 30 designs',
  'Up to 50,000 views per month',
  'Premium templates',
  'Premium design features',
];

const enterpriseFeatures = [
  'Everything in Professional',
  'Unlimited scheduled designs',
  'Unlimited views per month',
];

interface Props {
  variation: PlanId;
  planId?: PlanId;
}

const PricingCard: React.FC<Props> = ({ variation, planId }) => {
  const renderTitle = () => {
    switch (variation) {
      case 'basic':
        return 'Basic';
      case 'professional':
        return 'Professional';
      case 'enterprise':
        return 'Enterprise';
      default:
        return;
    }
  };

  const renderPrice = () => {
    switch (variation) {
      case 'basic':
        return '$2.99';
      case 'professional':
        return '$8.99';
      case 'enterprise':
        return '$24.99';
      default:
        return;
    }
  };

  const renderSubheading = () => {
    switch (variation) {
      case 'basic':
        return 'Ideal for small sites and light usage.';
      case 'professional':
        return 'Best for small businesses and access to all features.';
      case 'enterprise':
        return 'Best for high traffic sites and power-users.';
      default:
        return;
    }
  };

  const renderFeatures = useCallback(() => {
    switch (variation) {
      case 'basic':
        return basicFeatures.map((feat) => (
          <Feature key={feat} content={feat} />
        ));
      case 'professional':
        return proFeatures.map((feat) => <Feature key={feat} content={feat} />);
      case 'enterprise':
        return enterpriseFeatures.map((feat) => (
          <Feature key={feat} content={feat} />
        ));
      default:
        return;
    }
  }, [variation]);

  function openInNewTab() {
    const w = window.open(
      'https://www.wix.com/app-market/content-scheduler-by-oceanapps',
      '_blank'
    );
    if (w) {
      w.focus();
    }
  }

  const renderButton = () => {
    switch (variation) {
      case 'basic':
        return (
          <MenuButton
            onClick={openInNewTab}
            isDisabled={planId === 'basic' && true}
            label={planId !== 'basic' ? 'Get Basic' : 'Current Plan'}
            variant={'secondary'}
            style={{ fontWeight: '700', width: '15rem' }}
          />
        );
      case 'professional':
        return (
          <MenuButton
            onClick={openInNewTab}
            label={
              planId !== 'professional' ? 'Get Professional' : 'Current Plan'
            }
            isDisabled={planId === 'professional' && true}
            variant={'secondary'}
            style={{
              fontWeight: '700',
              width: '15rem',
            }}
          />
        );
      case 'enterprise':
        return (
          <MenuButton
            onClick={openInNewTab}
            label={planId !== 'enterprise' ? 'Get Enterprise' : 'Current Plan'}
            isDisabled={planId === 'professional' && true}
            variant={'secondary'}
            style={{
              fontWeight: '700',
              width: '15rem',
            }}
          />
        );
      default:
        return;
    }
  };

  return (
    <Card variation={variation}>
      <PlanTitle>{renderTitle()}</PlanTitle>
      <Row>
        <Price>{renderPrice()}</Price>
        <Month>/month</Month>
      </Row>
      <Subheading>{renderSubheading()}</Subheading>
      {renderFeatures()}
      <ButtonContainer>{renderButton()}</ButtonContainer>
    </Card>
  );
};

export default PricingCard;

const Feature: React.FC<{ content: string }> = ({ content }) => {
  return (
    <FeatureContainer>
      <CheckCircleIcon style={{ marginRight: '1rem' }} fontSize={'large'} />
      <Text style={{ marginBottom: '0' }}>{content}</Text>
    </FeatureContainer>
  );
};
