import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import {
  ContentList,
  ContentListItem,
  ElementIds,
  Pos,
  s3Params,
  ScheduleList,
  Style,
} from '../types';
import { setError } from '../redux/calendarSlice';
import { removeElement } from '../redux/elementSlice';
import { setCurrentCtaId, setStatus } from '../redux/ctaSlice';
import { setCurrentComponent } from '../redux/currentComponentSlice';
import { Wix } from './Wix';
import {
  cafeTemplate,
  cityTemplate,
  cliffTemplate,
  comoTemplate,
  joshuaTreeTemplate,
  otterTemplate,
  pumpkinTemplate,
  templateFive,
  templateFour,
  templateOne,
  templateSix,
  templateThree,
  waveTemplate,
} from '../templateEnums';
import { DAY_IN_MS, NEW_CTA } from '../enums';
import { createNewCTA, updateCtaScheduleInDB } from './services';
import { setActiveSidebarView } from '../redux/sidebarViewSlice';

export const handleClose = async (): Promise<void> => {
  const compIdFromStorage = localStorage.getItem('compId');

  if (compIdFromStorage) {
    Wix.Settings.refreshAppByCompIds(compIdFromStorage);
  } else {
    Wix.Settings.refreshAppByCompIds(Wix.Utils.getOrigCompId());
  }

  localStorage.removeItem('openStudio');
  localStorage.removeItem('compId');
  localStorage.removeItem('contentId');
  localStorage.removeItem('schedule');

  Wix.Settings.closeWindow({ target: 'ALL' });

  const url =
    process.env.REACT_APP_ENV === 'staging'
      ? 'https://staging.contentscheduler.app/publish-notice'
      : 'https://contentscheduler.app/publish-notice'; // Production URL
  Wix.Settings.openModal(url, 400, 207, () => {});
};

export const logError = (error) => {
  console.error('error type: ', error.type);
  console.error(error.message);
};

// Source: https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind
// Ensures that TypeError will be thrown if argument isn't found.
// Used with Array.find
export const ensure = <T>(
  argument: T | undefined | null,
  message: string = 'Value not found.'
): T => {
  if (argument === undefined || argument === null) {
    console.log('type error!', argument);
    throw new TypeError(message);
  }

  return argument;
};

/*
setPos: Pos is passed to <Draggable /> to set the position (x,y) of the element. It is converted to translate(x, y).
scale: From the useZoom hook. Number between 0 and 1. 
*/

export const handleResize = (
  e: React.MouseEvent<HTMLDivElement>,
  myRef: React.RefObject<HTMLDivElement | HTMLButtonElement>,
  dispatch: Function,
  resize: ActionCreatorWithPayload<{
    width: number;
    height: number;
    top: number;
    left: number;
    editingMode: string;
    undo: boolean;
    id: string;
  }>,
  id: string,
  editingMode: string,
  scale: number,
  rect: any,
  isTypography = false,
  setBounds?: any
) => {
  e.stopPropagation();

  const ratio = 1 / scale;

  let targetPos: Pos = { x: 0, y: 0 };

  let prevX = e.clientX;
  let prevY = e.clientY;
  const regx = /(-?[0-9]+\.?[0-9]*)/g;

  const mouseMove = (event: MouseEvent) => {
    if (myRef.current) {
      const matches: RegExpMatchArray | null =
        myRef.current.style.transform.match(regx);

      // targetPos is the current pos (x,y) pull from the translate(x,y) style generated by getStyles() and used for positioning a <Draggable /> component.
      // We dispatch it onMouseUp to set the final position of the element in redux.
      if (matches) {
        targetPos = {
          x: parseFloat(matches[0]),
          y: parseFloat(matches[1]),
        };
      }

      const width = parseFloat(myRef.current.style.width);
      const height = parseFloat(myRef.current.style.height);

      const target = e.target as Element;

      if (target.classList.contains('se')) {
        myRef.current.style.width =
          width + (event.pageX - prevX) * ratio + 'px';
        myRef.current.style.height =
          height + (event.pageY - prevY) * ratio + 'px';
      } else if (target.classList.contains('side-handle')) {
        myRef.current.style.width =
          width + (event.pageX - prevX) * ratio + 'px';
      }
    }

    prevY = event.pageY;
    prevX = event.pageX;
  };

  const mouseUp = () => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', mouseUp);

    if (myRef.current) {
      const width = parseFloat(myRef.current.style.width);
      const height = parseFloat(myRef.current.style.height);

      if (setBounds && myRef.current) {
        const elementRect = myRef.current.getBoundingClientRect();

        setBounds({
          top: 0 - elementRect.height + 20,
          bottom: rect.height - 20,
          left: 0 - elementRect.width + 20,
          right: rect.width - 20,
        });
      }

      if ((!isNaN(width) && !isNaN(height)) || isTypography) {
        dispatch(
          resize({
            id,
            editingMode,
            top: targetPos.y,
            left: targetPos.x,
            width,
            height,
            undo: true,
          })
        );
      } else {
        console.error(
          `TypeError: width(${width}) and height(${height}) must be numbers.`
        );
      }
    }
  };

  window.addEventListener('mousemove', mouseMove);
  window.addEventListener('mouseup', mouseUp);
};

export const setAlert = (msg, typ, dispatch) => {
  if (msg && typ && dispatch) {
    dispatch(setStatus({ message: msg, type: typ }));

    setTimeout(
      () => dispatch(setStatus({ message: undefined, type: undefined })),
      10000
    );
  }
};

export const setScheduleError = (msg, dispatch) => {
  dispatch(setError({ message: msg }));

  setTimeout(() => {
    dispatch(setError({ message: '' }));
  }, 5000);
};

export const getItemStyles = (initialOffset, currentOffset) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  let { x, y } = currentOffset;

  const transform = `translate3d(${x}px, ${y}px, 0)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

export const getShapeStyles = (
  styles: Style,
  site,
  transform?: React.CSSProperties['transform']
) => {
  return {
    top: site ? styles?.top?.value : undefined,
    left: site ? styles?.left?.value : undefined,
    position: 'absolute',
    display: styles.display?.value,
    height: styles.height?.value + 'px',
    width: styles.width?.value + 'px',
    transform: `${transform} ${
      styles?.transform?.value ? styles.transform.value : ''
    }`,
    borderTopLeftRadius: styles.borderTopLeftRadius?.value + '%',
    borderTopRightRadius: styles.borderTopRightRadius?.value + '%',
    borderBottomLeftRadius: styles.borderBottomLeftRadius?.value + '%',
    borderBottomRightRadius: styles.borderBottomRightRadius?.value + '%',
    zIndex: styles.zIndex?.value,
  };
};

// In order to use hover styles, we cant use inline styles. Instead, we use this function on buttons to set the position with transform, and the height and width to enable the use of ref.current.style.height/width when resizing.
export const getButtonStyles = (
  styles: Style,
  transform: React.CSSProperties['transform']
) => {
  return {
    top: undefined,
    left: undefined,
    display: styles.display?.value,
    height: styles.height?.value + 'px',
    width: styles.width?.value + 'px',
    transform: `${transform} ${
      styles?.transform?.value ? styles.transform.value : ''
    }`,
  };
};

export const getImageStyles = (
  styles: Style,
  transform: React.CSSProperties['transform']
) => {
  return {
    position: styles.position?.value ? styles.position.value : 'absolute',
    top: undefined,
    left: undefined,
    display: styles.display?.value,
    borderColor: styles.borderColor?.value,
    borderStyle: styles.borderStyle?.value,
    borderWidth:
      typeof styles.borderWidth?.value !== 'undefined'
        ? styles.borderWidth.value / 10 + 'rem'
        : '3',

    height:
      typeof styles.height?.value === 'string'
        ? styles.height.value
        : typeof styles.height?.value !== 'undefined'
        ? styles.height.value + 'px'
        : '',
    width:
      typeof styles.width?.value === 'string'
        ? styles.width.value
        : typeof styles.width?.value !== 'undefined'
        ? styles.width.value + 'px'
        : '',
    objectFit: styles?.objectFit?.value,
    zIndex: styles?.zIndex?.value,
    transform: `${transform} ${
      styles?.transform?.value ? styles.transform.value : ''
    }`,
    boxShadow: styles?.boxShadow?.value,
  };
};

export const getStyles = (
  styles: Style,
  transform: React.CSSProperties['transform'],
  isTypography = false
) => {
  return {
    position: styles.position?.value ? styles.position.value : 'absolute',

    top: undefined,
    left: undefined,
    display: styles.display?.value,
    fontSize: styles.fontSize?.value && styles.fontSize.value / 10 + 'rem',
    color: styles.color?.value,
    background: styles.background?.value,
    fontStyle: styles.fontStyle?.value,
    textDecoration: styles.textDecoration?.value,
    textAlign: styles.textAlign?.value,
    fontFamily: styles.fontFamily?.value,
    lineHeight: styles.lineHeight?.value,
    fontWeight: styles.fontWeight?.value,
    borderColor: styles.borderColor?.value,
    borderStyle: styles.borderStyle?.value,
    borderWidth:
      typeof styles.borderWidth?.value !== 'undefined'
        ? styles.borderWidth.value / 10 + 'rem'
        : '3',

    borderTopLeftRadius:
      typeof styles.borderTopLeftRadius?.value !== 'undefined'
        ? styles.borderTopLeftRadius + '%'
        : undefined,
    borderBottomLeftRadius:
      typeof styles.borderBottomLeftRadius?.value !== 'undefined'
        ? styles.borderBottomLeftRadius + '%'
        : undefined,
    borderTopRightRadius:
      typeof styles.borderTopRightRadius?.value !== 'undefined'
        ? styles.borderTopRightRadius + '%'
        : undefined,
    borderBottomRightRadius:
      typeof styles.borderBottomRightRadius?.value !== 'undefined'
        ? styles.borderBottomRightRadius + '%'
        : undefined,
    paddingLeft:
      styles?.paddingLeft?.value && styles.paddingLeft.value / 10 + 'rem',
    paddingRight:
      styles?.paddingRight?.value && styles.paddingRight.value / 10 + 'rem',
    paddingTop:
      styles?.paddingTop?.value && styles.paddingTop.value / 10 + 'rem',
    paddingBottom:
      styles?.paddingBottom?.value && styles.paddingBottom.value / 10 + 'rem',

    height: isTypography
      ? 'auto'
      : typeof styles.height?.value === 'string'
      ? styles.height.value
      : typeof styles.height?.value !== 'undefined'
      ? styles.height.value + 'px'
      : '',
    width:
      typeof styles.width?.value === 'string'
        ? styles.width.value
        : typeof styles.width?.value !== 'undefined'
        ? styles.width.value + 'px'
        : '',
    objectFit: styles?.objectFit?.value,
    letterSpacing: styles?.letterSpacing?.value,
    zIndex: styles?.zIndex?.value,
    textTransform: styles?.textTransform?.value,
    transform: `${transform} ${
      styles?.transform?.value ? styles.transform.value : ''
    }`,
    boxShadow: styles?.boxShadow?.value,
  };
};

export const handleBackspace = (
  e: React.KeyboardEvent,
  dispatch: Function,
  selected: ElementIds
) => {
  if (e.key === 'Backspace') {
    dispatch(removeElement({ selected }));
    dispatch(setCurrentComponent({ id: null, type: null }));
  }
};

export const mergeRefs = (refs, element) => {
  refs.forEach((ref) => {
    if (typeof ref === 'function') ref(element);
    else if (ref != null) ref.current = element;
  });
};

export const queryActiveFontLinks = () => {
  const links = document.querySelectorAll('[data-type="active-font"]');

  const linkFamilies: string[] = [];

  links.forEach((link) => {
    const castLink = link as HTMLElement;
    if (castLink.dataset.family) linkFamilies.push(castLink.dataset.family);
  });

  return linkFamilies;
};

export const handleOpenBillingPage = () => {
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    Wix.Dashboard.openBillingPage();
  }
};

export const utcToLocal = (timestamp: number): number => {
  return timestamp + new Date().getTimezoneOffset();
};

export const pushImageToLocalStorage = (params: s3Params, ctaId: string) => {
  const result = localStorage.getItem('thumbnails');
  let data;
  if (result) {
    data = JSON.parse(result);
  } else {
    data = [];
  }
  data = data.filter((item) => item.id !== ctaId);
  data.push({ id: ctaId, data: params });
  data = JSON.stringify(data);
  localStorage.setItem('thumbnails', data);
};

export const formatUtcToDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return (
    ('00' + (date.getMonth() + 1)).slice(-2) +
    '/' +
    ('00' + date.getDate()).slice(-2) +
    '/' +
    date.getFullYear() +
    ' ' +
    ('00' + date.getHours()).slice(-2) +
    ':' +
    ('00' + date.getMinutes()).slice(-2)
  );
};

export const openCtaInEditorFromDashboard = (
  contentId: string,
  compId: string,
  instance: string,
  schedule?: boolean
) => {
  if (contentId) localStorage.setItem('contentId', contentId);
  if (compId) localStorage.setItem('compId', compId);

  localStorage.setItem('openStudio', 'true');

  const testUrl = `/design?instance=${instance}&cta-id=${contentId}`;

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    Wix.Dashboard.getEditorUrl((url) => {
      window.open(url, '_blank');
    });
  } else {
    const w = window.open(testUrl, '_blank');
    if (w) {
      w.focus();
    }
  }
};

export const scheduleFromDashboard = (
  contentId: string,
  compId: string,
  instance: string
) => {
  if (contentId) localStorage.setItem('contentId', contentId);
  if (compId) localStorage.setItem('compId', compId);
  localStorage.setItem('schedule', 'true');
  localStorage.setItem('openStudio', 'true');

  const testUrl = `/design?instance=${instance}&cta-id=${contentId}&view=schedule`;

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    Wix.Dashboard.getEditorUrl((url) => {
      window.open(url, '_blank');
    });
  } else {
    const w = window.open(testUrl, '_blank');
    if (w) {
      w.focus();
    }
    console.log('removing schedule');
    localStorage.removeItem('schedule');
  }
};

export const getTemplateStyles = (
  templateId: number
): { width: number; height: number } => {
  let styles: { width: number; height: number } = { width: 0, height: 0 };
  let template;
  switch (templateId) {
    case 1:
      template = templateOne.find((entry) => entry.type === 'cta');
      break;
    case 3:
      template = templateThree.find((entry) => entry.type === 'cta');
      break;
    case 4:
      template = templateFour.find((entry) => entry.type === 'cta');
      break;
    case 5:
      template = templateFive.find((entry) => entry.type === 'cta');
      break;
    case 6:
      template = templateSix.find((entry) => entry.type === 'cta');
      break;
    case 7:
      template = waveTemplate.find((entry) => entry.type === 'cta');
      break;
    case 8:
      template = cliffTemplate.find((entry) => entry.type === 'cta');
      break;
    case 9:
      template = joshuaTreeTemplate.find((entry) => entry.type === 'cta');
      break;
    case 10:
      template = otterTemplate.find((entry) => entry.type === 'cta');
      break;
    case 11:
      template = pumpkinTemplate.find((entry) => entry.type === 'cta');
      break;
    case 12:
      template = comoTemplate.find((entry) => entry.type === 'cta');
      break;
    case 13:
      template = cityTemplate.find((entry) => entry.type === 'cta');
      break;
    case 14:
      template = cafeTemplate.find((entry) => entry.type === 'cta');
      break;
    default:
      break;
  }

  const desktop = template.style.desktop;

  if (desktop.width?.value) styles.width = desktop.width.value;
  if (desktop.height?.value) styles.height = desktop.height.value;

  return styles;
};

export const updateSchedule = async (
  instance: string,
) => {
  if (instance) {
    const scheduleListString = sessionStorage.getItem('scheduleList');
    if (scheduleListString) {
      const scheduleList = JSON.parse(scheduleListString);

      if (scheduleList.length > 0)
        await updateCtaScheduleInDB( instance,scheduleList);

      sessionStorage.removeItem('scheduleList');
    }
  }
};

export const addToContentList = (instance, id: string, compId: string) => {
  let contentList: ContentList | null | string = null;
  const contentListItem: ContentListItem = { id: id, compId };
  contentList = localStorage.getItem('contentList');

  if (contentList) {
    contentList = JSON.parse(contentList) as ContentList;

    if (!contentList.some((item) => item.id === contentListItem.id)) {
      contentList.push(contentListItem);
      localStorage.setItem('contentList', JSON.stringify(contentList));
    }
  } else {
    contentList = [] as ContentList;
    contentList.push(contentListItem);
    localStorage.setItem('contentList', JSON.stringify(contentList));
  }
};

export const daysRemainingOfTrial = (expiration: number): number => {
  if (expiration) {
    let timeLeft = expiration - new Date().getTime();
    return Math.ceil(timeLeft / DAY_IN_MS);
  }

  return 0;
};

export const convertArrayTo3d = (list: any[]): any[][] => {
  let tmpList: any[] = [];
  const newList: any[] = [];
  for (let i = 0; i < list.length; i++) {
    if (tmpList.length < 3) {
      tmpList.push(list[i]);
    } else {
      newList.push(tmpList);
      tmpList = [list[i]];
    }
    if (i === list.length - 1) {
      newList.push(tmpList);
    }
  }

  return newList;
};

export const getScheduleFromStorage = () => {
  let scheduleListString: string | null =
    sessionStorage.getItem('scheduleList');

  if (scheduleListString) {
    return JSON.parse(scheduleListString) as ScheduleList;
  }
};

export const handleDesignEvergreen = async (
  instance: string,
  compId: string,
  openModal,
  dispatch,
  navigate?
) => {
  if (instance && compId && dispatch) {
    const evergreen = {
      ...NEW_CTA,
      isEvergreen: true,
      title: 'My Evergreen content',
    };

    try {
      const ctaId = await createNewCTA(
        instance,
        compId,
        null,
        evergreen,
        dispatch,
        null
      );

      const url =
        process.env.REACT_APP_ENV === 'staging'
          ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`
          : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`; // Production URL
      const testUrl = `/design?instance=${instance}&cta-id=${ctaId}`;

      if (
        (process.env.NODE_ENV === 'production' ||
          process.env.REACT_APP_ENV === 'staging') &&
        openModal
      ) {
        Wix.Settings.openModal(
          url,
          '85%',
          '85%',
          'Content Scheduler - Design Studio',
          () => handleClose()
        );
      } else if (process.env.NODE_ENV === 'development' && openModal) {
        const w = window.open(testUrl, '_blank');
        if (w) {
          w.focus();
        }
      } else if (ctaId && navigate) {
        dispatch(setCurrentCtaId({ ctaId }));
        navigate(`/design?instance=${instance}&cta-id=${ctaId}`);
        dispatch(setActiveSidebarView({ id: 1 }));
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }
};

export const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};
