import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: auto;
  padding: ${(props) => props.theme.spacing[3]};
`;

export const Title = styled.h2`
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing[6]};
  margin-top: ${(props) => props.theme.spacing[5]};
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 1230px) {
    flex-direction: row;
    justify-content: center;
    & > *:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing[3]};
    }
  }

  @media screen and (min-width: 1290px) {
    & > *:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing[7]};
    }
  }
`;
