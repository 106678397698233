import React, { useEffect, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OptionsMenu from '../OptionsMenu/OptionsMenu.component';
import { useAppDispatch } from '../../../redux/reduxHooks';
import {
  setCTAId,
  setCTATitle,
  setShowModal,
} from '../../../redux/calendarSlice';
import { resetView } from '../../../redux/viewSlice';
import { setCtaList, setCurrentCtaId } from '../../../redux/ctaSlice';
import { setActiveSidebarView } from '../../../redux/sidebarViewSlice';
import useGetInstance from '../../../hooks/useGetInstance';
import useView from '../../../hooks/useView';
import { useNavigate } from 'react-router-dom';
import {
  IconContainer,
  Icon,
  Title,
  CalendarMenuItemStyles,
} from './CalendarMenuItem.styles';
import { getAllCtasFromDB, updateCtaInDB } from '../../../util/services';
import { CtaList, CtaUpdate } from '../../../types';
import useGetCompId from '../../../hooks/useGetCompId';

interface Props {
  title: string;
  ctaId: string;
  testId: string;
}

interface Pos {
  x: number;
  y: number;
}

const CalendarMenuItem: React.FC<Props> = ({ title, ctaId, testId }) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState<boolean>(false);
  const [pos, setPos] = useState<Pos>({} as Pos);

  const optionsIconRef = useRef<HTMLButtonElement | null>(null);
  const titleRef = useRef<HTMLSpanElement | null>(null);

  const dispatch = useAppDispatch();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const view = useView();
  const navigate = useNavigate();

  const handleOptionsClick: React.MouseEventHandler = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    setShowOptionsMenu(!showOptionsMenu);
    dispatch(setCTAId({ id: ctaId }));
    dispatch(setCTATitle({ title }));
    setPos({ x: e.clientX, y: e.clientY });
  };

  const handleSetTitle: React.FocusEventHandler = async (
    e: React.FocusEvent<HTMLSpanElement>
  ): Promise<void> => {
    e.currentTarget.contentEditable = 'false';
    if (e.target.innerText !== '') {
      try {
        const cta: CtaUpdate = {
          id: ctaId,
          title: e.target.innerText,
        };

        await updateCtaInDB( instance, compId, cta);
        const result: CtaList | void = await getAllCtasFromDB(
          instance,
          undefined,
          compId
        );

        if (result) dispatch(setCtaList({ ctaList: result }));
      } catch (error: unknown) {
        if (error instanceof Error) console.error(error.message);
        return;
      }
    } else {
      e.target.innerText = title;
    }
  };

  const handleTitleKeyDown: React.KeyboardEventHandler = (
    e: React.KeyboardEvent<HTMLSpanElement>
  ) => {
    if (e.key === 'Enter' && e.currentTarget.contentEditable !== 'true') {
      e.currentTarget.contentEditable = 'true';
    } else if (e.key === 'Enter' && titleRef.current) {
      titleRef.current.blur();
    } else if (e.key === 'Escape' && titleRef.current) {
      const target = e.target as HTMLSpanElement;
      target.innerText = '';
      titleRef.current.blur();
    }
  };

  const handleEditCta = () => {
    dispatch(setCurrentCtaId({ ctaId }));
    navigate(`/design?instance=${instance}&cta-id=${ctaId}`);
    dispatch(setActiveSidebarView({ id: 1 }));
  };

  const handleOpenModal: React.MouseEventHandler = () => {
    dispatch(setCTAId({ id: ctaId }));
    dispatch(setCTATitle({ title }));
    dispatch(setShowModal({ showModal: true }));
  };

  useEffect(() => {
    if (view.view === 'schedule') {
      navigate(`/design?instance=${instance}&cta-id=${ctaId}`);
      dispatch(setShowModal({ showModal: true }));
      dispatch(resetView());
    }
  }, [view, dispatch, navigate, ctaId, instance]);

  return (
    <CalendarMenuItemStyles
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleEditCta();
      }}
      onDoubleClick={handleEditCta}
      data-testid={testId}>
      <Title
        onKeyDown={handleTitleKeyDown}
        onDoubleClick={(e) => {
          if (e.currentTarget.contentEditable === 'true') {
            e.stopPropagation();
          }
        }}
        onBlur={handleSetTitle}
        tabIndex={0}
        ref={titleRef}>
        {title}
      </Title>

      <IconContainer>
        <Icon onClick={handleOpenModal} data-testid='schedule-icon'>
          <AccessTimeIcon style={{ fontSize: 24 }} />
        </Icon>
        <Icon
          onClick={handleOptionsClick}
          onDoubleClick={(e) => e.stopPropagation()}
          data-testid='icon-container'
          ref={optionsIconRef}
          active={showOptionsMenu}>
          <MoreVertIcon style={{ fontSize: 24 }} data-testid='more-vert-icon' />
        </Icon>

        {showOptionsMenu && (
          <OptionsMenu
            pos={pos}
            ctaId={ctaId}
            setShowOptionsMenu={setShowOptionsMenu}
            titleRef={titleRef}
          />
        )}
      </IconContainer>
    </CalendarMenuItemStyles>
  );
};

export default CalendarMenuItem;
