import React, { useLayoutEffect, useState } from 'react';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem.component';
import {
  ContextMenuStyles,
  MenuItemsContainerUpper,
  MenuItemsContainerLower,
} from './ContextMenu.styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Pos } from '../../../types';

interface Props {
  ctaId: string;
  closeMenu: React.MouseEventHandler;
  titleRef: React.RefObject<HTMLSpanElement>;
  pos: Pos;
}

interface MenuItem {
  id: number;
  label: string;
  icon: any;
}

const upperMenuItems: MenuItem[] = [
  { id: 1, label: 'Edit', icon: EditIcon },
  { id: 2, label: 'Schedule', icon: WatchLaterIcon },

  {
    id: 3,
    label: 'Preview',
    icon: VisibilityIcon,
  },
  { id: 4, label: 'Set Evergreen', icon: CheckCircleIcon },
  { id: 5, label: 'Rename', icon: DriveFileRenameOutlineRoundedIcon },
  { id: 6, label: 'Duplicate', icon: FileCopyIcon },
];

const lowerMenuItems: MenuItem[] = [
  { id: 7, label: 'Delete', icon: DeleteIcon },
];

const ContextMenu: React.FC<Props> = ({ ctaId, closeMenu, titleRef, pos }) => {
  const [innerHeight, setInnerHeight] = useState<number>(0);
  const [menuDirection, setMenuDirection] = useState<'up' | 'down'>('down');

  useLayoutEffect(() => {
    setInnerHeight(window.innerHeight);
  }, []);

  useLayoutEffect(() => {
    if (pos.y + 281 > innerHeight) {
      setMenuDirection('up');
    } else {
      setMenuDirection('down');
    }
  }, [innerHeight, pos.y]);

  return (
    <ContextMenuStyles
      pos={pos}
      direction={menuDirection}
      data-testid='context-menu'>
      <MenuItemsContainerUpper>
        {upperMenuItems.map((item) => (
          <ContextMenuItem
            label={item.label}
            key={item.label + '_' + item.id}
            Icon={item.icon}
            id={item.id}
            closeMenu={closeMenu}
            ctaId={ctaId}
            titleRef={titleRef}
          />
        ))}
      </MenuItemsContainerUpper>
      <MenuItemsContainerLower>
        {lowerMenuItems.map((item) => (
          <ContextMenuItem
            label={item.label}
            key={item.label + '_' + item.id}
            Icon={item.icon}
            id={item.id}
            closeMenu={closeMenu}
            ctaId={ctaId}
            titleRef={titleRef}
          />
        ))}
      </MenuItemsContainerLower>
    </ContextMenuStyles>
  );
};

export default ContextMenu;
