import styled from 'styled-components';

export const Slider = styled.div.attrs<{
  pageIndex: number;
  frameWidth: number;
}>((props) => ({
  style: {
    transform: `${
      props.pageIndex > 1
        ? `translateX(${-(props.pageIndex - 1) * props.frameWidth}px)`
        : 'translateX(0)'
    }`,
  },
}))<{ pageIndex: number; frameWidth: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: inherit;
  transition: transform 250ms ease-in-out;

  & > .announcement {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }
`;
