import { BackgroundImageStyles, Container } from './BackgroundImage.styles';
import { BackgroundImg, Style } from '../../../types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { getImageStyles } from '../../../util/functions';

interface Props {
  opacity: number | undefined;
  elStyle: Style;
  img: BackgroundImg;
  style?: React.CSSProperties;
  media: 'desktop' | 'mobile';
  id?: string;
}

const BackgroundImage: React.FC<Props> = ({
  opacity,
  elStyle,
  img,
  style,
  media,
  id,
}) => {
  const [image, setImage] = useState<{
    src: string;
    top: number;
    left: number;
  }>({ src: '', top: 0, left: 0 });

  const imageRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    if (media === 'desktop') {
      setImage(img.desktop);
    } else if (media === 'mobile') {
      setImage(img.mobile);
    }
  }, [img, media]);

  return (
    <Container
      id={id}
      style={{
        ...(getImageStyles(elStyle, style?.transform) as React.CSSProperties),
        width: '100%',
        height: '100%',
      }}>
      <BackgroundImageStyles
        draggable={false}
        ref={imageRef}
        opacity={opacity}
        elStyle={elStyle}
        src={image.src}
        media={media}
        // heightProperty={heightProperty}
        // widthProperty={widthProperty}
        pos={{ top: image.top, left: image.left }}
        // imgDimensions={imageDimensions}
      />
    </Container>
  );
};

export default BackgroundImage;
