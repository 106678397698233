import { v4 as uuidv4 } from 'uuid';
import { Template } from './types';

const tmpId1a = uuidv4();
const tmpId1b = uuidv4();
const tmpId1c = uuidv4();
const tmpId1d = uuidv4();

export const templateOne: Template = [
  {
    id: tmpId1a,
    type: 'typography',
    content: 'Design Course',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 108 },
        left: { value: 56 },
        color: { value: '#202020', default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1, default: true },
        width: { value: 375 },
        height: { value: 55 },
        zIndex: { value: 2 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 207 },
        left: { value: 9 },
        color: { value: '#202020', default: true },
        fontSize: { value: 40, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1, default: true },
        width: { value: 303 },
        height: { value: 48 },
        zIndex: { value: 2 },
      },
    },
  },
  {
    id: tmpId1b,
    type: 'typography',
    content:
      'Sign up for our course to learn the latest design trends and techniques. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium, turpis consequat pretium vel purus. Magna et eu cursus sodales sed sit adipiscing cursus.',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 178 },
        left: { value: 58 },

        background: { value: 'transparent', default: true },
        color: { value: '#202020', default: true },
        fontSize: { value: 16, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1.5, default: true },
        width: { value: 400 },
        height: { value: 120 },
        zIndex: { value: 2 },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 267 },
        left: { value: 22 },

        background: { value: 'transparent', default: true },
        color: { value: '#202020', default: true },
        fontSize: { value: 14, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1.5, default: true },
        width: { value: 276 },
        height: { value: 136 },
        zIndex: { value: 2 },
      },
    },
  },
  {
    id: tmpId1c,
    type: 'button',
    content: 'Request Information',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 335 },
        left: { value: 60 },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: 'rgba(50, 50, 50, 1)',
          default: true,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#202020',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 185 },
        height: { value: 50 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#555',
            default: true,
          },
          color: {
            value: '#FFF',
            default: true,
          },
          borderStyle: {
            value: 'none',
            default: true,
          },
          borderColor: {
            value: '#202020',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 0,
            default: true,
          },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
        },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 416 },
        left: { value: 82 },
        fontSize: { value: 14, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: 'rgba(50, 50, 50, 1)',
          default: true,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#202020',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 157 },
        height: { value: 42 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#555',
            default: true,
          },
          color: {
            value: '#FFF',
            default: true,
          },
          borderStyle: {
            value: 'none',
            default: true,
          },
          borderColor: {
            value: '#202020',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 0,
            default: true,
          },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: tmpId1d,
    type: 'image',
    style: {
      desktop: {
        height: { value: 398 },
        width: { value: 398 },
        objectFit: { value: 'cover' },
        zIndex: { value: 2 },
        position: { value: 'absolute' },
        top: { value: 46 },
        left: { value: 521 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 25 },
        borderBottomLeftRadius: { value: 5 },
        borderTopRightRadius: { value: 5 },
        borderBottomRightRadius: { value: 25 },
      },

      mobile: {
        height: { value: 184 },
        width: { value: 329 },
        objectFit: { value: 'cover' },
        zIndex: { value: 2 },
        position: { value: 'absolute' },
        top: { value: -7 },
        left: { value: -4 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
      },
    },

    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/pattern1.jpg',
    alt: 'design pattern',
  },
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    type: 'cta',
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
  },
];

export const templateTwo: Template = [
  {
    id: uuidv4(),
    type: 'typography',
    content: 'CHRIS AND CHARLIE',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 68 },
        left: { value: 400 },
        color: { value: '#202020', default: true },
        fontSize: { value: 52, default: true },
        fontFamily: { value: 'Josefin Sans' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 270 },
        height: { value: 270 },
        zIndex: { value: 2 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 68 },
        left: { value: 400 },
        color: { value: '#202020', default: true },
        fontSize: { value: 52, default: true },
        fontFamily: { value: 'Josefin Sans' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 270 },
        height: { value: 270 },
        zIndex: { value: 2 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content:
      'INVITE YOU TO WITNESS THEIR MARRIAGE AT TREMEZZO LAKE COMO, ITALY ON 5TH MAY, 2022 AT 5:30PM',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 140 },
        left: { value: 400 },
        fontFamily: { value: 'Cormorant Garamond' },
        background: { value: 'transparent', default: true },
        color: { value: '#202020', default: true },
        fontSize: { value: 15, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.5, default: true },
        width: { value: 270 },
        height: { value: 270 },
        zIndex: { value: 2 },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 140 },
        left: { value: 400 },
        fontFamily: { value: 'Cormorant Garamond' },
        background: { value: 'transparent', default: true },
        color: { value: '#202020', default: true },
        fontSize: { value: 15, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.5, default: true },
        width: { value: 270 },
        height: { value: 270 },
        zIndex: { value: 2 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'RSVP',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 268 },
        left: { value: 58.8 },
        fontFamily: { value: 'Cormorant Garamond' },
        fontSize: { value: 15, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: 'rgba(50, 50, 50, 1)',
          default: true,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#202020',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 185 },
        height: { value: 50 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#444',
            default: true,
          },
          color: {
            value: '#FFF',
            default: true,
          },
          borderStyle: {
            value: 'none',
            default: true,
          },
          borderColor: {
            value: '#202020',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 0,
            default: true,
          },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
        },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 268 },
        left: { value: 58.8 },
        fontFamily: { value: 'Cormorant Garamond' },
        fontSize: { value: 15, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: 'rgba(50, 50, 50, 1)',
          default: true,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#202020',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 185 },
        height: { value: 50 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#444',
            default: true,
          },
          color: {
            value: '#FFF',
            default: true,
          },
          borderStyle: {
            value: 'none',
            default: true,
          },
          borderColor: {
            value: '#202020',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 0,
            default: true,
          },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: uuidv4(),
    style: {
      desktop: {
        height: { value: 400 },
        objectFit: { value: 'cover' },
        zIndex: { value: 2 },
        position: { value: 'absolute' },
        top: { value: 0 },
        left: { value: 120 },
        opacity: { value: 1 },
      },

      mobile: {
        height: { value: 400 },
        objectFit: { value: 'cover' },
        zIndex: { value: 2 },
        position: { value: 'absolute' },
        top: { value: 0 },
        left: { value: 120 },
        opacity: { value: 1 },
      },
    },
    type: 'image',
    src: 'https://res.cloudinary.com/ocean-apps-llc/image/upload/v1654649308/Asset_1_szytmm.png',
    alt: 'Bride and groom stepping into chapel',
  },
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    type: 'cta',
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
  },
];

export const templateThree: Template = [
  {
    id: uuidv4(),
    type: 'typography',
    content: '15 best hikes',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 310 },
        left: { value: 55 },
        color: { value: '#FFFFFF', default: true },
        fontSize: { value: 34, default: true },
        fontFamily: { value: 'Arimo' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 400 },
        height: { value: 41 },
        zIndex: { value: 3 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 64 },
        left: { value: 47 },
        color: { value: '#FFFFFF', default: true },
        fontSize: { value: 24, default: true },
        fontFamily: { value: 'Arimo' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 226 },
        height: { value: 33 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Joshua Tree',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 338 },
        left: { value: 56 },
        fontFamily: { value: 'Anton' },
        background: { value: 'transparent', default: true },
        color: { value: '#fff', default: true },
        fontSize: { value: 85, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.5, default: true },
        width: { value: 446 },
        height: { value: 118 },
        zIndex: { value: 3 },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 92 },
        left: { value: 29 },
        fontFamily: { value: 'Anton' },
        background: { value: 'transparent', default: true },
        color: { value: '#fff', default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.5, default: true },
        width: { value: 263 },
        height: { value: 67 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'Start Exploring',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 357 },
        left: { value: 681 },
        fontFamily: { value: 'Arimo' },
        fontSize: { value: 20, default: true },
        fontWeight: { value: 'bold', default: true },
        color: {
          value: 'rgb(24, 40, 37)',
          default: true,
        },
        background: {
          value: 'rgba(255, 255, 255, 1)',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 230 },
        height: { value: 52 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {},
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 384 },
        left: { value: 45 },
        fontFamily: { value: 'Arimo' },
        fontSize: { value: 18, default: true },
        fontWeight: { value: 'bold', default: true },
        color: {
          value: 'rgb(24, 40, 37)',
          default: true,
        },
        background: {
          value: 'rgba(255, 255, 255, 1)',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 230 },
        height: { value: 52 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {},
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rectangle',
    style: {
      desktop: {
        width: { value: 980 },
        height: { value: 218 },
        position: { value: 'absolute' },
        top: { value: 274 },
        left: { value: 0 },
        zIndex: { value: 2 },
        opacity: { value: 0.4 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: '#282828', default: true },
      },
      mobile: {
        width: { value: 320 },
        height: { value: 168 },
        position: { value: 'absolute' },
        top: { value: 30 },
        left: { value: 0 },
        zIndex: { value: 2 },
        opacity: { value: 0.4 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: '#282828', default: true },
      },
    },
  },

  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/joshuatree.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/joshuatree.jpg',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
];

export const templateFour: Template = [
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Annual Beach Update',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 99 },
        left: { value: 213 },
        color: { value: '#FFFFFF', default: true },
        fontSize: { value: 46, default: true },
        fontFamily: { value: 'Poppins' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 554 },
        height: { value: 54 },
        zIndex: { value: 3 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 152 },
        left: { value: 22 },
        color: { value: '#FFFFFF', default: true },
        fontSize: { value: 20, default: true },
        fontFamily: { value: 'Poppins' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 276 },
        height: { value: 29 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: "Life's a beach",
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 151 },
        left: { value: 131 },
        fontFamily: { value: 'Yellowtail' },
        background: { value: 'transparent', default: true },
        color: { value: '#fff', default: true },
        fontSize: { value: 87, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 2.5, default: true },
        lineHeight: { value: 1.5, default: true },
        textTransform: { value: 'uppercase', default: true },
        width: { value: 718 },
        height: { value: 118 },
        zIndex: { value: 3 },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 185 },
        left: { value: 3 },
        fontFamily: { value: 'Yellowtail' },
        background: { value: 'transparent', default: true },
        color: { value: '#fff', default: true },
        fontSize: { value: 37, default: true },
        fontStyle: { value: 'normal', default: true },
        textTransform: { value: 'uppercase', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 315 },
        height: { value: 50 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'Read our beach report',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 335 },
        left: { value: 332 },
        fontFamily: { value: 'Poppins' },
        fontSize: { value: 20, default: true },
        fontWeight: { value: 'bold', default: true },
        color: {
          value: 'rgb(24, 40, 37)',
          default: true,
        },
        background: {
          value: 'rgba(255, 255, 255, 1)',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 316 },
        height: { value: 56 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {},
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 300 },
        left: { value: 38 },
        fontFamily: { value: 'Poppins' },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'bold', default: true },
        color: {
          value: 'rgb(24, 40, 37)',
          default: true,
        },
        background: {
          value: 'rgba(255, 255, 255, 1)',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 245 },
        height: { value: 48 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {},
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },

  {
    id: '',
    style: {
      desktop: {
        background: { value: 'rgb(0, 0, 0)', default: true },
        opacity: { value: 0.9 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: 'rgb(0, 0, 0)', default: true },
        opacity: { value: 0.9 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/beach.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/beach.jpg',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
];

export const templateFive: Template = [
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Mountain Seminar',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 45 },
        left: { value: 39 },
        color: { value: 'rgb(0, 0, 0)', default: true },
        fontSize: { value: 48, default: true },
        fontFamily: { value: 'DM Serif Display' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1, default: true },
        width: { value: 260 },
        height: { value: 102 },
        zIndex: { value: 3 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 60 },
        left: { value: 22 },
        color: { value: 'rgb(0, 0, 0)', default: true },
        fontSize: { value: 28, default: true },
        fontFamily: { value: 'DM Serif Display' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1, default: true },
        width: { value: 276 },
        height: { value: 32 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'July 10th, 2022',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 69 },
        left: { value: 730 },
        fontFamily: { value: 'Open Sans' },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(0, 0, 0)', default: true },
        fontSize: { value: 22, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 2.5, default: true },
        lineHeight: { value: 1.5, default: true },
        textTransform: { value: 'uppercase', default: true },
        width: { value: 212 },
        height: { value: 39 },
        zIndex: { value: 3 },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 35 },
        left: { value: 88 },
        fontFamily: { value: 'Open Sans' },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(0, 0, 0)', default: true },
        fontSize: { value: 14, default: true },
        fontStyle: { value: 'normal', default: true },
        textTransform: { value: 'uppercase', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 145 },
        height: { value: 23 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'RSVP Today',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 328 },
        left: { value: 393 },
        fontFamily: { value: 'Open Sans' },
        fontSize: { value: 20, default: true },
        fontWeight: { value: 'bold', default: true },
        color: {
          value: 'rgb(255, 255, 255)',
          default: true,
        },
        background: {
          value: 'rgba(0, 88, 180, 1)',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 5,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 195 },
        height: { value: 50 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {},
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 343 },
        left: { value: 89 },
        fontFamily: { value: 'Poppins' },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'bold', default: true },
        color: {
          value: 'rgb(255,255,255)',
          default: true,
        },
        background: {
          value: 'rgba(0, 88, 180, 1)',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 5,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 142 },
        height: { value: 43 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {},
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: uuidv4(),
    type: 'image',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 110 },
        left: { value: 54 },
        width: { value: 874 },
        height: { value: 336 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
      },
      mobile: {
        position: { value: 'absolute' },
        top: { value: 111 },
        left: { value: 36 },
        width: { value: 249 },
        height: { value: 356 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
      },
    },
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/mountain.jpg',
    alt: 'Mountain range',
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Presented by the Mountain Pioneers',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 394 },
        left: { value: 214 },
        fontFamily: { value: 'Open Sans' },
        background: { value: 'transparent', default: true },
        color: { value: '#fff', default: true },
        fontSize: { value: 24, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.5, default: true },
        textTransform: { value: 'uppercase', default: true },
        width: { value: 552 },
        height: { value: 39 },
        zIndex: { value: 3 },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 405 },
        left: { value: 58 },
        fontFamily: { value: 'Open Sans' },
        background: { value: 'transparent', default: true },
        color: { value: '#fff', default: true },
        fontSize: { value: 16, default: true },
        fontStyle: { value: 'normal', default: true },
        textTransform: { value: 'uppercase', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 1.5, default: true },
        lineHeight: { value: 1.2, default: true },
        width: { value: 204 },
        height: { value: 44 },
        zIndex: { value: 3 },
      },
    },
  },

  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
];

export const templateSix: Template = [
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Discover More',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 119 },
        left: { value: 306 },
        color: { value: 'rgb(255,255,255)', default: true },
        fontSize: { value: 76, default: true },
        fontFamily: { value: 'Bebas Neue' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1, default: true },
        width: { value: 368 },
        height: { value: 69 },
        zIndex: { value: 3 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 127 },
        left: { value: 22 },
        color: { value: 'rgb(255,255,255)', default: true },
        fontSize: { value: 56, default: true },
        fontFamily: { value: 'Bebas Neue' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1, default: true },
        width: { value: 276 },
        height: { value: 55 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'On Our Channel',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 183 },
        left: { value: 265 },
        fontFamily: { value: 'Bebas Neue' },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(255,255,255)', default: true },
        fontSize: { value: 76, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1.5, default: true },
        textTransform: { value: 'uppercase', default: true },
        width: { value: 451 },
        height: { value: 92 },
        zIndex: { value: 3 },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 185 },
        left: { value: 7 },
        color: { value: 'rgb(255,255,255)', default: true },
        fontSize: { value: 56, default: true },
        fontFamily: { value: 'Bebas Neue' },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        letterSpacing: { value: 0, default: true },
        lineHeight: { value: 1, default: true },
        width: { value: 307 },
        height: { value: 55 },
        zIndex: { value: 3 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'Subscribe Now',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 320 },
        left: { value: 379 },
        fontFamily: { value: 'Poppins' },
        fontSize: { value: 20, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFFFFF',
          default: true,
        },
        background: {
          value: '#d1551a',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 50,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 223 },
        height: { value: 50 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
          key: 'Tailwind 6',
          default: true,
        },

        hover: {},
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 324 },
        left: { value: 49 },
        fontFamily: { value: 'Poppins' },
        fontSize: { value: 20, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFFFFF',
          default: true,
        },
        background: {
          value: '#d1551a',
          default: true,
        },
        textTransform: { value: 'uppercase', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFF',
          default: true,
        },
        borderRadius: {
          value: 50,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 223 },
        height: { value: 50 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
          key: 'Tailwind 6',
          default: true,
        },

        hover: {},
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#000000', default: true },
        opacity: { value: 0.9 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#000000', default: true },
        opacity: { value: 0.9 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/mountain_lg.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/mountain_lg.jpg',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
];

export const blankTemplate: Template = [
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    type: 'cta',
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
  },
];

export const waveTemplate: Template = [
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/beach_background.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/beach_background.jpg',
        top: 0,
        left: 0,
      },
    },

    type: 'cta',
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameSmall',
    title: 'small circle frame graphic',
    style: {
      desktop: {
        width: { value: 328 },
        height: { value: 328 },
        position: { value: 'absolute' },
        top: { value: 81 },
        left: { value: 91 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
      mobile: {
        width: { value: 161 },
        height: { value: 161 },
        position: { value: 'absolute' },
        top: { value: 212 },
        left: { value: 80 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'fullCircle',
    title: 'Circle graphic',
    style: {
      desktop: {
        width: { value: 389 },
        height: { value: 318 },
        position: { value: 'absolute' },
        top: { value: 86 },
        left: { value: 60 },
        zIndex: { value: 3 },
        opacity: { value: 0.33 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
      mobile: {
        width: { value: 161 },
        height: { value: 161 },
        position: { value: 'absolute' },
        top: { value: 212 },
        left: { value: 80 },
        zIndex: { value: 2 },
        opacity: { value: 0.33 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'image',
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/cliff_small.jpg',
    alt: 'A beautiful cliff with waves crashing against it',
    style: {
      desktop: {
        width: { value: 292 },
        height: { value: 292 },
        position: { value: 'absolute' },
        top: { value: 99 },
        left: { value: 108 },
        zIndex: { value: 4 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 50 },
        borderTopRightRadius: { value: 50 },
        borderBottomLeftRadius: { value: 50 },
        borderBottomRightRadius: { value: 50 },
      },
      mobile: {
        width: { value: 138 },
        height: { value: 138 },
        position: { value: 'absolute' },
        top: { value: 224 },
        left: { value: 91 },
        zIndex: { value: 4 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 50 },
        borderTopRightRadius: { value: 50 },
        borderBottomLeftRadius: { value: 50 },
        borderBottomRightRadius: { value: 50 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Protecting',
    style: {
      desktop: {
        width: { value: 355 },
        height: { value: 73 },
        position: { value: 'absolute' },
        top: { value: 157 },
        left: { value: 524 },
        zIndex: { value: 5 },
        opacity: { value: 1 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(191, 202, 197)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 72, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Staatliches' },
        letterSpacing: { value: 3.7, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
      mobile: {
        width: { value: 259 },
        height: { value: 62 },
        position: { value: 'absolute' },
        top: { value: 78 },
        left: { value: 31 },
        zIndex: { value: 5 },
        opacity: { value: 1 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(255, 255, 255)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Staatliches' },
        letterSpacing: { value: 3.7, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'our shores',
    style: {
      desktop: {
        width: { value: 369 },
        height: { value: 83 },
        position: { value: 'absolute' },
        top: { value: 232 },
        left: { value: 524 },
        zIndex: { value: 5 },
        opacity: { value: 1 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(191, 202, 197)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 72, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Staatliches' },
        letterSpacing: { value: 3.7, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
      mobile: {
        width: { value: 266 },
        height: { value: 60 },
        position: { value: 'absolute' },
        top: { value: 134 },
        left: { value: 27 },
        zIndex: { value: 5 },
        opacity: { value: 1 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(255, 255, 255)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Staatliches' },
        letterSpacing: { value: 3.7, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'our mission',
    style: {
      desktop: {
        width: { value: 375 },
        height: { value: 44 },
        position: { value: 'absolute' },
        top: { value: 110 },
        left: { value: 524 },
        zIndex: { value: 7 },
        opacity: { value: 1 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(255, 255, 255)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 36, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 12.3, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
      mobile: {
        width: { value: 302 },
        height: { value: 33 },
        position: { value: 'absolute' },
        top: { value: 35 },
        left: { value: 11 },
        zIndex: { value: 7 },
        opacity: { value: 1 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(77, 65, 49)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 26, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 8.3, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'learn more',
    style: {
      desktop: {
        width: { value: 358 },
        height: { value: 53 },
        position: { value: 'absolute' },
        top: { value: 327 },
        left: { value: 524 },
        zIndex: { value: 9 },
        opacity: { value: 1 },
        background: { value: 'rgb(77, 65, 49)', default: true },
        color: { value: 'rgb(255, 255, 255)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 24, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'none', default: true },
        borderColor: { value: '#000000', default: true },
        borderRadius: { value: 5, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 3.3, default: true },
        textTransform: { value: 'uppercase', default: true },
        boxShadow: {
          value:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'none', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 5, default: true },
          boxShadow: {
            value:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            key: 'none',
            default: true,
          },
          background: { value: 'rgb(77, 65, 49)', default: true },
          color: { value: 'rgb(255,255,255)', default: true },
        },
      },
      mobile: {
        width: { value: 242 },
        height: { value: 48 },
        position: { value: 'absolute' },
        top: { value: 417 },
        left: { value: 39 },
        zIndex: { value: 9 },
        opacity: { value: 1 },
        background: { value: 'rgb(77, 65, 49)', default: true },
        color: { value: 'rgb(255, 255, 255)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 20, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'none', default: true },
        borderColor: { value: '#000000', default: true },
        borderRadius: { value: 5, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 3.3, default: true },
        textTransform: { value: 'uppercase', default: true },
        boxShadow: {
          value:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'none', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 5, default: true },
          boxShadow: {
            value:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            key: 'none',
            default: true,
          },
          background: { value: 'rgb(77, 65, 49)', default: true },
          color: { value: 'rgb(255,255,255)', default: true },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
];

export const cliffTemplate: Template = [
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/boardwalk.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/boardwalk.jpg',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'offsetSquareRight',
    title: 'square with right side sloping down towards the right',
    style: {
      desktop: {
        width: { value: 828 },
        height: { value: 593 },
        position: { value: 'absolute' },
        top: { value: -37 },
        left: { value: -275 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(1, 28, 80)', default: true },
      },
      mobile: {
        width: { value: 828 },
        height: { value: 593 },
        position: { value: 'absolute' },
        top: { value: -46 },
        left: { value: -315 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(1, 28, 80)', default: true },
      },
    },
  },

  {
    id: uuidv4(),
    type: 'typography',
    content: 'Our 2022 Goals',
    style: {
      desktop: {
        width: { value: 244 },
        height: { value: 247 },
        position: { value: 'absolute' },
        top: { value: 55 },
        left: { value: 71 },
        zIndex: { value: 4 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(116, 178, 122)', default: true },
        lineHeight: { value: 1.1, default: true },
        fontSize: { value: 76, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Viga' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
      mobile: {
        width: { value: 237 },
        height: { value: 218 },
        position: { value: 'absolute' },
        top: { value: 77 },
        left: { value: 42 },
        zIndex: { value: 4 },
        background: { value: 'transparent', default: true },
        color: { value: 'rgb(116, 178, 122)', default: true },
        lineHeight: { value: 1.1, default: true },
        fontSize: { value: 66, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Viga' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
    },
  },

  {
    id: uuidv4(),
    type: 'button',
    content: 'Read Them All',
    style: {
      desktop: {
        width: { value: 200 },
        height: { value: 50 },
        position: { value: 'absolute' },
        top: { value: 371 },
        left: { value: 75 },
        zIndex: { value: 9 },
        opacity: { value: 1 },
        background: { value: 'rgb(232, 190, 105)', default: true },
        color: { value: 'rgb(100, 69, 63)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 18, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'none', default: true },
        borderColor: { value: '#000000', default: true },
        borderRadius: { value: 5, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Lato' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        boxShadow: {
          value:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'none', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 5, default: true },
          boxShadow: {
            value:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            key: 'none',
            default: true,
          },
          background: { value: 'rgb(232, 190, 105)', default: true },
          color: { value: 'rgb(100, 69, 63)', default: true },
        },
      },
      mobile: {
        width: { value: 226 },
        height: { value: 50 },
        position: { value: 'absolute' },
        top: { value: 370 },
        left: { value: 47 },
        zIndex: { value: 9 },
        opacity: { value: 1 },
        background: { value: 'rgb(232, 190, 105)', default: true },
        color: { value: 'rgb(100, 69, 63)', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 18, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'none', default: true },
        borderColor: { value: '#000000', default: true },
        borderRadius: { value: 5, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Lato' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        boxShadow: {
          value:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'none', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 5, default: true },
          boxShadow: {
            value:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            key: 'none',
            default: true,
          },
          background: { value: 'rgb(232, 190, 105)', default: true },
          color: { value: 'rgb(100, 69, 63)', default: true },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
];

export const joshuaTreeTemplate: Template = [
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/joshuatree2.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/joshuatree2.jpg',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
  {
    id: uuidv4(),
    type: 'button',
    content: 'Start Exploring',
    style: {
      desktop: {
        width: { value: 200 },
        height: { value: 50 },
        position: { value: 'absolute' },
        top: { value: 368 },
        left: { value: 543 },
        zIndex: { value: 9 },
        opacity: { value: 1 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 18, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'none', default: true },
        borderColor: { value: '#000000', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Poppins' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        boxShadow: {
          value:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'none', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 0, default: true },
          boxShadow: {
            value:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            key: 'none',
            default: true,
          },
          background: { value: 'rgb(255, 255, 255)', default: true },
          color: { value: '#222222', default: true },
        },
      },
      mobile: {
        width: { value: 200 },
        height: { value: 50 },
        position: { value: 'absolute' },
        top: { value: 399 },
        left: { value: 60 },
        zIndex: { value: 9 },
        opacity: { value: 1 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 18, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'none', default: true },
        borderColor: { value: '#000000', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Poppins' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        boxShadow: {
          value:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'none', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 0, default: true },
          boxShadow: {
            value:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            key: 'none',
            default: true,
          },
          background: { value: 'rgb(255, 255, 255)', default: true },
          color: { value: '#222222', default: true },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'clipmaskBG1',
    title: 'Square with angled, textured left side',
    style: {
      desktop: {
        width: { value: 708 },
        height: { value: 500 },
        position: { value: 'absolute' },
        top: { value: -5 },
        left: { value: 408 },
        zIndex: { value: 4 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(161, 198, 169)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 708 },
        height: { value: 500 },
        position: { value: 'absolute' },
        top: { value: -5 },
        left: { value: 408 },
        zIndex: { value: 4 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(161, 198, 169)', default: true },
        display: { value: 'none' },
      },
    },
  },

  {
    id: uuidv4(),
    type: 'typography',
    content: 'Explorer',
    style: {
      desktop: {
        width: { value: 331 },
        height: { value: 67 },
        position: { value: 'absolute' },
        top: { value: 74 },
        left: { value: 548 },
        zIndex: { value: 7 },
        background: { value: 'transparent', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 60, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Paytone One' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 278 },
        height: { value: 59 },
        position: { value: 'absolute' },
        top: { value: 53 },
        left: { value: 24 },
        zIndex: { value: 7 },
        background: { value: 'transparent', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Paytone One' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Tips',
    style: {
      desktop: {
        width: { value: 331 },
        height: { value: 67 },
        position: { value: 'absolute' },
        top: { value: 158 },
        left: { value: 548 },
        zIndex: { value: 7 },
        background: { value: 'transparent', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 60, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Paytone One' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 121 },
        height: { value: 55 },
        position: { value: 'absolute' },
        top: { value: 123 },
        left: { value: 102 },
        zIndex: { value: 7 },
        background: { value: 'transparent', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Paytone One' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Joshua Tree National Park',
    style: {
      desktop: {
        width: { value: 360 },
        height: { value: 106 },
        position: { value: 'absolute' },
        top: { value: 243 },
        left: { value: 538 },
        zIndex: { value: 7 },
        background: { value: 'transparent', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Poppins' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 306 },
        height: { value: 87 },
        position: { value: 'absolute' },
        top: { value: 264 },
        left: { value: 7 },
        zIndex: { value: 7 },
        background: { value: 'transparent', default: true },
        color: { value: '#222222', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 40, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Poppins' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rectangle',
    title: 'Rectangle',
    style: {
      desktop: {
        width: { value: 344 },
        height: { value: 75 },
        position: { value: 'absolute' },
        top: { value: 72 },
        left: { value: 539 },
        zIndex: { value: 6 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 295 },
        height: { value: 62 },
        position: { value: 'absolute' },
        top: { value: 51 },
        left: { value: 13 },
        zIndex: { value: 6 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'wavyMobile',
    title: 'Wavy background',
    style: {
      desktop: {
        width: { value: 344 },
        height: { value: 75 },
        position: { value: 'absolute' },
        top: { value: 72 },
        left: { value: 539 },
        zIndex: { value: 6 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'none' },
      },
      mobile: {
        width: { value: 344 },
        height: { value: 308 },
        position: { value: 'absolute' },
        top: { value: 226 },
        left: { value: -12 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(161, 198, 169)', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rectangle',
    title: 'Rectangle',
    style: {
      desktop: {
        width: { value: 158 },
        height: { value: 70 },
        position: { value: 'absolute' },
        top: { value: 157 },
        left: { value: 539 },
        zIndex: { value: 6 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 138 },
        height: { value: 60 },
        position: { value: 'absolute' },
        top: { value: 120 },
        left: { value: 91 },
        zIndex: { value: 6 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
    },
  },
];

export const otterTemplate: Template = [
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/otter2.jpg',
        top: -245,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
    type: 'cta',
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rectangle',
    title: 'rectangle',
    style: {
      desktop: {
        width: { value: 985 },
        height: { value: 213 },
        position: { value: 'absolute' },
        top: { value: 286 },
        left: { value: -3 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
      mobile: {
        width: { value: 382 },
        height: { value: 309 },
        position: { value: 'absolute' },
        top: { value: 196 },
        left: { value: -31 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
    },
  },

  {
    id: uuidv4(),
    type: 'typography',
    content: 'The Otter Project',
    style: {
      desktop: {
        width: { value: 258 },
        height: { value: 133 },
        position: { value: 'absolute' },
        top: { value: 320 },
        left: { value: 42 },
        zIndex: { value: 4 },
        background: { value: 'transparent', default: true },
        color: { value: '#333333', default: true },
        lineHeight: { value: 1.1, default: true },
        fontSize: { value: 60, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Anton' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
      mobile: {
        width: { value: 231 },
        height: { value: 103 },
        position: { value: 'absolute' },
        top: { value: 224 },
        left: { value: 45 },
        zIndex: { value: 4 },
        background: { value: 'transparent', default: true },
        color: { value: '#333333', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Anton' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'uppercase', default: true },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Cleaner Oceans. Happier Otters.',
    style: {
      desktop: {
        width: { value: 520 },
        height: { value: 43 },
        position: { value: 'absolute' },
        top: { value: 322 },
        left: { value: 424 },
        zIndex: { value: 5 },
        background: { value: 'transparent', default: true },
        color: { value: '#333333', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 36, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
      },
      mobile: {
        width: { value: 202 },
        height: { value: 57 },
        position: { value: 'absolute' },
        top: { value: 336 },
        left: { value: 59 },
        zIndex: { value: 5 },
        background: { value: 'transparent', default: true },
        color: { value: '#333333', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 24, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
      },
    },
  },

  {
    id: uuidv4(),
    type: 'button',
    content: 'Add Your Name',
    style: {
      desktop: {
        width: { value: 182 },
        height: { value: 47 },
        position: { value: 'absolute' },
        top: { value: 400 },
        left: { value: 755 },
        zIndex: { value: 4 },
        opacity: { value: 1 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        color: { value: '#333333', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 16, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: '#333333', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'solid', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 0, default: true },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
          background: { value: '#333333', default: true },
          color: { value: '#FFFFFF', default: true },
        },
      },
      mobile: {
        width: { value: 216 },
        height: { value: 45 },
        position: { value: 'absolute' },
        top: { value: 413 },
        left: { value: 49 },
        zIndex: { value: 4 },
        opacity: { value: 1 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        color: { value: '#333333', default: true },
        lineHeight: { value: 1.2, default: true },
        fontSize: { value: 16, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: '#333333', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 20 },
        paddingRight: { value: 20 },
        paddingTop: { value: 12 },
        paddingBottom: { value: 12 },
        fontFamily: { value: 'Roboto' },
        letterSpacing: { value: 0, default: true },
        textTransform: { value: 'none', default: true },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },
        hover: {
          borderStyle: { value: 'solid', default: true },
          borderWidth: { value: 1, default: true },
          borderRadius: { value: 0, default: true },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
          background: { value: '#333333', default: true },
          color: { value: '#FFFFFF', default: true },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: uuidv4(),
    type: 'image',
    style: {
      desktop: {
        height: { value: 398 },
        width: { value: 398 },
        objectFit: { value: 'cover' },
        zIndex: { value: 2 },
        position: { value: 'absolute' },
        top: { value: 46 },
        left: { value: 521 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 25 },
        borderBottomLeftRadius: { value: 5 },
        borderTopRightRadius: { value: 5 },
        borderBottomRightRadius: { value: 25 },
        display: { value: 'none' },
      },

      mobile: {
        height: { value: 205 },
        width: { value: 336 },
        objectFit: { value: 'cover' },
        zIndex: { value: 2 },
        position: { value: 'absolute' },
        top: { value: -2 },
        left: { value: -8 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
    },

    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/otter2.jpg',
    alt: 'Otters',
  },
];

export const pumpkinTemplate: Template = [
  {
    type: 'rawSvg',
    variation: 'pumpkins',
    title: 'Pumpkins',
    id: uuidv4(),
    style: {
      desktop: {
        width: { value: 574 },
        height: { value: 340 },
        position: { value: 'absolute' },
        top: { value: 5 },
        left: { value: 203 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
      },
      mobile: {
        width: { value: 318 },
        height: { value: 248 },
        position: { value: 'absolute' },
        top: { value: 31 },
        left: { value: 1 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
      },
    },
  },
  {
    type: 'typography',
    content: 'Fall Festival',
    id: uuidv4(),
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 254 },
        left: { value: 286 },
        color: { value: 'rgb(194, 74, 33)', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 67, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        width: { value: 410 },
        height: { value: 74 },
        zIndex: { value: 4 },
        fontFamily: { value: 'Abril Fatface' },
      },
      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 242 },
        left: { value: 4 },
        color: { value: 'rgb(194, 74, 33)', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        width: { value: 313 },
        height: { value: 57 },
        zIndex: { value: 4 },
        fontFamily: { value: 'Abril Fatface' },
      },
    },
  },
  {
    type: 'typography',
    content: 'Friday, October 28th',
    id: uuidv4(),
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 334 },
        left: { value: 234 },
        color: { value: 'rgb(194, 74, 33)', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        width: { value: 512 },
        height: { value: 62 },
        zIndex: { value: 3 },
        fontFamily: { value: 'Assistant' },
      },
      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 314 },
        left: { value: 44 },
        color: { value: 'rgb(194, 74, 33)', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 38, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        width: { value: 233 },
        height: { value: 85 },
        zIndex: { value: 3 },
        fontFamily: { value: 'Assistant' },
      },
    },
  },
  {
    type: 'cta',
    id: uuidv4(),
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
    style: {
      desktop: {
        background: {
          value: 'rgb(240, 223, 200)',
          default: true,
        },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },
      mobile: {
        background: {
          value: 'rgb(240, 223, 200)',
          default: true,
        },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
  },
];

export const comoTemplate: Template = [
  {
    type: 'typography',
    content: 'Exclusive Access',
    id: uuidv4(),
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 261 },
        left: { value: 707 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'right', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        width: { value: 224 },
        height: { value: 102 },
        zIndex: { value: 3 },
        fontFamily: { value: 'Roboto' },
      },
      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 122 },
        left: { value: 27 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 57, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        width: { value: 266 },
        height: { value: 123 },
        zIndex: { value: 3 },
        fontFamily: { value: 'Roboto' },
      },
    },
  },
  {
    id: uuidv4(),
    content: 'Sign Up',
    type: 'button',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 388 },
        left: { value: 786 },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: 'rgb(255, 166, 15)',
          default: false,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFFFFF',
          default: true,
        },
        borderRadius: {
          value: 75,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 142 },
        height: { value: 40 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#FFFFFF',
            default: true,
          },
          color: {
            value: '#222222',
            default: true,
          },
          borderStyle: {
            value: 'solid',
            default: true,
          },
          borderColor: {
            value: '#FFFFFF',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 75,
            default: true,
          },
          boxShadow: {
            value: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
            key: 'none',
            default: true,
          },
        },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 314 },
        left: { value: 66 },
        fontSize: { value: 20, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: 'rgb(255, 166, 15)',
          default: false,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#FFFFFF',
          default: true,
        },
        borderRadius: {
          value: 75,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 189 },
        height: { value: 55 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#FFFFFF',
            default: true,
          },
          color: {
            value: '#222222',
            default: true,
          },
          borderStyle: {
            value: 'solid',
            default: true,
          },
          borderColor: {
            value: '#FFFFFF',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 75,
            default: true,
          },
          boxShadow: {
            value: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
            key: 'none',
            default: true,
          },
        },
      },
    },
    link: { url: '', options: '_blank' },
  },
  {
    type: 'cta',
    id: uuidv4(),
    backgroundImg: {
      desktop: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/como.jpg',
        top: 0,
        left: 0,
      },
      mobile: {
        src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/como.jpg',
        top: 0,
        left: 0,
      },
    },
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
  },
];

export const cityTemplate: Template = [
  {
    type: 'image',
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/pacific.jpg',
    alt: 'City street',
    id: uuidv4(),
    style: {
      desktop: {
        objectFit: { value: 'cover' },
        position: { value: 'absolute' },
        top: { value: -40 },
        left: { value: 494 },
        borderRadius: { value: 0, default: true },
        opacity: { value: 0.78 },
        width: { value: 624 },
        height: { value: 570 },
        zIndex: { value: 2 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
      },
      mobile: {
        objectFit: { value: 'cover' },
        position: { value: 'absolute' },
        top: { value: -173 },
        left: { value: -48 },
        borderRadius: { value: 0, default: true },
        opacity: { value: 0.78 },
        width: { value: 416 },
        height: { value: 554 },
        zIndex: { value: 2 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rhombus',
    title: 'Rhombus frame',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: -44 },
        left: { value: -146 },
        background: { value: 'rgb(23, 63, 63)', default: true },
        opacity: { value: 1 },
        width: { value: 735 },
        height: { value: 642 },
        zIndex: { value: 9 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
      mobile: {
        position: { value: 'absolute' },
        top: { value: 213 },
        left: { value: -106 },
        background: { value: 'rgb(23, 63, 63)', default: true },
        opacity: { value: 1 },
        width: { value: 532 },
        height: { value: 459 },
        zIndex: { value: 9 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Support Our Town',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 71 },
        left: { value: 75 },
        color: { value: 'rgb(255, 242, 166)', default: true },
        fontSize: { value: 70, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1, default: true },
        width: { value: 300 },
        height: { value: 222 },
        zIndex: { value: 10 },
        display: { value: 'block' },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 230 },
        left: { value: 22 },
        color: { value: 'rgb(255, 242, 166)', default: true },
        fontSize: { value: 44, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1, default: true },
        width: { value: 276 },
        height: { value: 93 },
        zIndex: { value: 10 },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Donate tonight to meet our goal!',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 296 },
        left: { value: 75 },
        color: { value: 'rgb(255, 255, 255)', default: true },
        fontSize: { value: 28, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1, default: true },
        width: { value: 500 },
        height: { value: 42 },
        zIndex: { value: 10 },
        display: { value: 'block' },
      },

      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 333 },
        left: { value: 44 },
        color: { value: 'rgb(255, 255, 255)', default: true },
        fontSize: { value: 18, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        paddingLeft: { value: 0 },
        paddingRight: { value: 0 },
        paddingTop: { value: 0 },
        paddingBottom: { value: 0 },
        lineHeight: { value: 1, default: true },
        width: { value: 231 },
        height: { value: 43 },
        zIndex: { value: 10 },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    content: 'Donate',
    type: 'button',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 368 },
        left: { value: 79 },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#111827',
          default: true,
        },
        background: {
          value: '#FFFFFF',
          default: false,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'solid',
          default: true,
        },
        borderColor: {
          value: 'rgb(209,213,219)',
          default: true,
        },
        borderRadius: {
          value: 52,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 142 },
        height: { value: 40 },
        zIndex: { value: 10 },
        opacity: { value: 1 },
        boxShadow: {
          value:
            '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: 'rgb(249,250,251)',
            default: true,
          },
          color: {
            value: '#111827',
            default: true,
          },
          borderStyle: {
            value: 'solid',
            default: true,
          },
          borderColor: {
            value: 'rgb(209,213,219)',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 75,
            default: true,
          },
          boxShadow: {
            value:
              '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            key: 'none',
            default: true,
          },
        },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 407 },
        left: { value: 35 },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#111827',
          default: true,
        },
        background: {
          value: '#FFFFFF',
          default: false,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'solid',
          default: true,
        },
        borderColor: {
          value: 'rgb(209,213,219)',
          default: true,
        },
        borderRadius: {
          value: 52,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 250 },
        height: { value: 46 },
        zIndex: { value: 10 },
        opacity: { value: 1 },
        boxShadow: {
          value:
            '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: 'rgb(249,250,251)',
            default: true,
          },
          color: {
            value: '#111827',
            default: true,
          },
          borderStyle: {
            value: 'solid',
            default: true,
          },
          borderColor: {
            value: 'rgb(209,213,219)',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 75,
            default: true,
          },
          boxShadow: {
            value:
              '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            key: 'none',
            default: true,
          },
        },
      },
    },
    link: { url: '', options: '_blank' },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rhombus',
    title: 'Rhombus frame',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: -7 },
        left: { value: -128 },
        background: { value: 'rgb(48, 124, 124)', default: true },
        opacity: { value: 1 },
        width: { value: 735 },
        height: { value: 642 },
        zIndex: { value: 4 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
      mobile: {
        position: { value: 'absolute' },
        top: { value: 204 },
        left: { value: -113 },
        background: { value: 'rgb(48, 124, 124)', default: true },
        opacity: { value: 1 },
        width: { value: 522 },
        height: { value: 344 },
        zIndex: { value: 4 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rhombus',
    title: 'Rhombus frame',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: -6 },
        left: { value: -105 },
        background: { value: 'rgb(65, 169, 169)', default: true },
        opacity: { value: 1 },
        width: { value: 735 },
        height: { value: 642 },
        zIndex: { value: 3 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
      mobile: {
        position: { value: 'absolute' },
        top: { value: 195 },
        left: { value: -101 },
        background: { value: 'rgb(65, 169, 169)', default: true },
        opacity: { value: 1 },
        width: { value: 522 },
        height: { value: 344 },
        zIndex: { value: 3 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        display: { value: 'block' },
      },
    },
  },
  {
    type: 'cta',
    id: uuidv4(),
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
    style: {
      desktop: {
        background: { value: 'rgb(65, 169, 169)', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: 'rgb(65, 169, 169)', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
  },
];

export const cafeTemplate: Template = [
  {
    id: uuidv4(),
    type: 'button',
    content: 'Reserve a Table',
    style: {
      desktop: {
        position: { value: 'absolute' },
        top: { value: 313 },
        left: { value: 174 },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: '#9CBB7A',
          default: false,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#202020',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 251 },
        height: { value: 50 },
        zIndex: { value: 16 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: 'rgba(156, 187, 122, 0.84)',
            default: false,
          },
          color: {
            value: '#FFF',
            default: true,
          },
          borderStyle: {
            value: 'none',
            default: true,
          },
          borderColor: {
            value: '#202020',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 0,
            default: true,
          },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
        },
      },

      mobile: {
        position: { value: 'absolute' },
        top: { value: 399 },
        left: { value: 53 },
        fontSize: { value: 16, default: true },
        fontWeight: { value: 'normal', default: true },
        color: {
          value: '#FFF',
          default: true,
        },
        background: {
          value: '#9CBB7A',
          default: false,
        },

        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: {
          value: 1,
          default: true,
        },
        borderStyle: {
          value: 'none',
          default: true,
        },
        borderColor: {
          value: '#202020',
          default: true,
        },
        borderRadius: {
          value: 0,
          default: true,
        },

        lineHeight: { value: 1.2, default: true },
        width: { value: 214 },
        height: { value: 50 },
        zIndex: { value: 16 },
        opacity: { value: 1 },
        boxShadow: {
          value: 'none',
          key: 'none',
          default: true,
        },

        hover: {
          background: {
            value: '#9CBB7A',
            default: false,
          },
          color: {
            value: '#FFF',
            default: true,
          },
          borderStyle: {
            value: 'none',
            default: true,
          },
          borderColor: {
            value: '#202020',
            default: true,
          },
          borderWidth: {
            value: 1,
            default: true,
          },
          borderRadius: {
            value: 0,
            default: true,
          },
          boxShadow: {
            value: 'none',
            key: 'none',
            default: true,
          },
        },
      },
    },
    link: {
      url: '',
      options: '_blank',
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: '8th Street Cafe',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 113 },
        left: { value: 116 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 60, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        width: { value: 385 },
        height: { value: 58 },
        textTransform: { value: 'none', default: true },
        fontFamily: { value: 'Cormorant' },
        zIndex: { value: 6 },
      },
      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 37 },
        left: { value: 39 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 50, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'bold', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'left', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        width: { value: 215 },
        height: { value: 101 },
        textTransform: { value: 'none', default: true },
        fontFamily: { value: 'Cormorant' },
        zIndex: { value: 6 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Join us for brunch every Sunday!',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 199 },
        left: { value: 166 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1.1, default: true },
        fontSize: { value: 24, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        width: { value: 276 },
        height: { value: 58 },
        textTransform: { value: 'none', default: true },
        fontFamily: { value: 'Inter' },
        zIndex: { value: 5 },
      },
      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 287 },
        left: { value: 53 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1.1, default: true },
        fontSize: { value: 24, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        width: { value: 215 },
        height: { value: 58 },
        textTransform: { value: 'none', default: true },
        fontFamily: { value: 'Inter' },
        zIndex: { value: 5 },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'typography',
    content: 'Outdoor Patio | Heated Seats',
    style: {
      desktop: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 354 },
        left: { value: 585 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 16, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        width: { value: 242 },
        height: { value: 27 },
        textTransform: { value: 'none', default: true },
        fontFamily: { value: 'Inter' },
        zIndex: { value: 7 },
      },
      mobile: {
        background: { value: 'transparent', default: true },
        position: { value: 'absolute' },
        top: { value: 313 },
        left: { value: 75 },
        color: { value: '#FFFFFF', default: true },
        lineHeight: { value: 1, default: true },
        fontSize: { value: 16, default: true },
        fontStyle: { value: 'normal', default: true },
        fontWeight: { value: 'normal', default: true },
        textDecoration: { value: 'none', default: true },
        textAlign: { value: 'center', default: true },
        borderWidth: { value: 1, default: true },
        borderStyle: { value: 'solid', default: true },
        borderColor: { value: 'transparent', default: true },
        borderRadius: { value: 0, default: true },
        width: { value: 170 },
        height: { value: 70 },
        textTransform: { value: 'none', default: true },
        fontFamily: { value: 'Inter' },
        zIndex: { value: 7 },
        display: { value: 'none' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'image',
    style: {
      desktop: {
        height: { value: 164 },
        width: { value: 164 },
        objectFit: { value: 'cover' },
        zIndex: { value: 4 },
        position: { value: 'absolute' },
        top: { value: 131 },
        left: { value: 562 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 50 },
        borderBottomLeftRadius: { value: 50 },
        borderTopRightRadius: { value: 50 },
        borderBottomRightRadius: { value: 50 },
      },

      mobile: {
        height: { value: 124 },
        width: { value: 124 },
        objectFit: { value: 'cover' },
        zIndex: { value: 4 },
        position: { value: 'absolute' },
        top: { value: 123 },
        left: { value: 143 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 50 },
        borderBottomLeftRadius: { value: 50 },
        borderTopRightRadius: { value: 50 },
        borderBottomRightRadius: { value: 50 },
        display: { value: 'none' },
      },
    },

    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/pastry.jpg',
    alt: 'Delicious looking pastry on a plate',
  },
  {
    id: uuidv4(),
    type: 'image',
    style: {
      desktop: {
        height: { value: 166 },
        width: { value: 166 },
        objectFit: { value: 'cover' },
        zIndex: { value: 4 },
        position: { value: 'absolute' },
        top: { value: 157 },
        left: { value: 685 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 50 },
        borderBottomLeftRadius: { value: 50 },
        borderTopRightRadius: { value: 50 },
        borderBottomRightRadius: { value: 50 },
      },

      mobile: {
        height: { value: 124 },
        width: { value: 124 },
        objectFit: { value: 'cover' },
        zIndex: { value: 6 },
        position: { value: 'absolute' },
        top: { value: 128 },
        left: { value: 155 },
        opacity: { value: 1 },
        borderRadius: { value: 0, default: true },
        borderTopLeftRadius: { value: 50 },
        borderBottomLeftRadius: { value: 50 },
        borderTopRightRadius: { value: 50 },
        borderBottomRightRadius: { value: 50 },
        display: { value: 'block' },
      },
    },

    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/avocado_toast.jpg',
    alt: 'Avocado toast',
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameMedium',
    style: {
      desktop: {
        width: { value: 189 },
        height: { value: 140 },
        position: { value: 'absolute' },
        top: { value: 118 },
        left: { value: 551 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(156, 187, 122)', default: true },
      },
      mobile: {
        width: { value: 189 },
        height: { value: 140 },
        position: { value: 'absolute' },
        top: { value: 118 },
        left: { value: 200 },
        zIndex: { value: 3 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(156, 187, 122)', default: true },
        display: { value: 'none' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameMedium',
    style: {
      desktop: {
        width: { value: 189 },
        height: { value: 140 },
        position: { value: 'absolute' },
        top: { value: 145 },
        left: { value: 672 },
        zIndex: { value: 8 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
      },
      mobile: {
        width: { value: 127 },
        height: { value: 127 },
        position: { value: 'absolute' },
        top: { value: 126 },
        left: { value: 152 },
        zIndex: { value: 8 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'rectangle',
    style: {
      desktop: {
        width: { value: 940 },
        height: { value: 450 },
        position: { value: 'absolute' },
        top: { value: 20 },
        left: { value: 20 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(51, 51, 51)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 280 },
        height: { value: 460 },
        position: { value: 'absolute' },
        top: { value: 20 },
        left: { value: 20 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(51, 51, 51)', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameLarge',
    style: {
      desktop: {
        width: { value: 200 },
        height: { value: 200 },
        position: { value: 'absolute' },
        top: { value: -71 },
        left: { value: 857 },
        zIndex: { value: 13 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 200 },
        height: { value: 200 },
        position: { value: 'absolute' },
        top: { value: 170 },
        left: { value: 89 },
        zIndex: { value: 13 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'none' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameLarge',
    style: {
      desktop: {
        width: { value: 60 },
        height: { value: 60 },
        position: { value: 'absolute' },
        top: { value: 437 },
        left: { value: 923 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 60 },
        height: { value: 60 },
        position: { value: 'absolute' },
        top: { value: 300 },
        left: { value: 200 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'none' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameLarge',
    style: {
      desktop: {
        width: { value: 200 },
        height: { value: 200 },
        position: { value: 'absolute' },
        top: { value: 357 },
        left: { value: -73 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
      mobile: {
        width: { value: 160 },
        height: { value: 160 },
        position: { value: 'absolute' },
        top: { value: -80 },
        left: { value: 248 },
        zIndex: { value: 2 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'none' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameLarge',
    style: {
      desktop: {
        width: { value: 200 },
        height: { value: 200 },
        position: { value: 'absolute' },
        top: { value: 10 },
        left: { value: 10 },
        zIndex: { value: 15 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'none' },
      },
      mobile: {
        width: { value: 110 },
        height: { value: 110 },
        position: { value: 'absolute' },
        top: { value: -49 },
        left: { value: 262 },
        zIndex: { value: 15 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: uuidv4(),
    type: 'rawSvg',
    variation: 'circleFrameLarge',
    style: {
      desktop: {
        width: { value: 200 },
        height: { value: 200 },
        position: { value: 'absolute' },
        top: { value: 30 },
        left: { value: 30 },
        zIndex: { value: 15 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'none' },
      },
      mobile: {
        width: { value: 110 },
        height: { value: 110 },
        position: { value: 'absolute' },
        top: { value: 429 },
        left: { value: -50 },
        zIndex: { value: 15 },
        opacity: { value: 1 },
        borderTopLeftRadius: { value: 0 },
        borderTopRightRadius: { value: 0 },
        borderBottomLeftRadius: { value: 0 },
        borderBottomRightRadius: { value: 0 },
        background: { value: 'rgb(255, 255, 255)', default: true },
        display: { value: 'block' },
      },
    },
  },
  {
    id: '',
    style: {
      desktop: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 490 },
        width: { value: 980 },
      },

      mobile: {
        background: { value: '#FFF', default: true },
        opacity: { value: 1 },
        height: { value: 500 },
        width: { value: 320 },
      },
    },
    type: 'cta',
    backgroundImg: {
      desktop: {
        src: '',
        top: 0,
        left: 0,
      },
      mobile: {
        src: '',
        top: 0,
        left: 0,
      },
    },
  },
];

export const templateList = [
  {
    id: 7,
    template: waveTemplate,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/shores_thumbnail.png',
    alt: 'Wave template',
    premium: true,
  },
  {
    id: 3,
    template: templateThree,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/joshua_tree_thumbnail.jpg',
    alt: 'Joshua Tree template one',
    premium: true,
  },

  {
    id: 5,
    template: templateFive,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/mountain_thumbnail.jpg',
    alt: 'Mountain template one',
    premium: false,
  },
  {
    id: 6,
    template: templateSix,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/mountain_thumbnail_two.jpg',
    alt: 'Mountain template two',
    premium: false,
  },

  {
    id: 8,
    template: cliffTemplate,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/boardwalk_thumbnail.jpg',
    alt: 'Cliff template',
    premium: true,
  },
  {
    id: 9,
    template: joshuaTreeTemplate,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/explorertips_thumbnail.jpg',
    alt: 'Joshua Tree template two',
    premium: true,
  },
  {
    id: 10,
    template: otterTemplate,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/otter_thumbnail.jpg',
    alt: 'Otter template',
    premium: true,
  },
  {
    id: 11,
    template: pumpkinTemplate,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/pumpkin_thumbnail.jpg',
    alt: 'Pumpkin template',
    premium: false,
  },
  {
    id: 12,
    template: comoTemplate,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/como_thumbnail.jpg',
    alt: 'Lake Como template',
    premium: false,
  },

  {
    id: 1,
    template: templateOne,
    src: 'https://oceanapps.nyc3.cdn.digitaloceanspaces.com/template_images/template-1-thumbnail.jpg',
    alt: 'Design course template',
    premium: false,
  },
];
