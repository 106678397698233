import React, { useEffect, useState } from 'react';
import {
  Container,
  BackButton,
  Center,
  Text,
  Card,
  Bold,
  List,
  ListItem,
} from './EvergreenView.styles';
import { SectionHeader } from '../../../styles/settings/components';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import useGetCtas from '../../../hooks/useGetCtas';
import Evergreen from '../Evergreen/Evergreen.component';
import Button from '../Button/Button.component';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCompId from '../../../hooks/useGetCompId';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { handleDesignEvergreen } from '../../../util/functions';

interface Props {
  setView: (value: number | ((prevVar: number) => number)) => void;
}

interface EvergreenContent {
  id: string;
  isActive: boolean;
  isEvergreen: boolean;
  title: string;
}

const EvergreenView: React.FC<Props> = ({ setView }) => {
  const [evergreenContent, setEvergreenContent] = useState<EvergreenContent>(
    {} as EvergreenContent
  );
  const contentList = useGetCtas();
  const compId = useGetCompId();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const evergreen = contentList.find((entry) => entry.isEvergreen);
    if (evergreen) {
      setEvergreenContent(evergreen);
    } else {
      setEvergreenContent({} as EvergreenContent);
    }
  }, [setEvergreenContent, contentList]);

  const instance = useGetInstance();

  return (
    <Container>
      <BackButton onClick={() => setView(4)}>
        <KeyboardArrowLeftIcon style={{ marginRight: '.5rem' }} />
        Back
      </BackButton>
      <SectionHeader style={{ borderTop: '1px solid rgb(209 213 219)' }}>
        Evergreen Content
      </SectionHeader>
      {Object.keys(evergreenContent).length > 0 ? (
        <Evergreen
          key={evergreenContent.id}
          isActive={evergreenContent.isActive}
          isEvergreen={evergreenContent.isEvergreen}
          ctaId={evergreenContent.id}
          ctaTitle={evergreenContent.title}
        />
      ) : (
        <Center>
          <Text>You haven't set any Evergreen content yet</Text>
          <Button
            variant='primary'
            label='Design Evergreen Content'
            onClick={() =>
              handleDesignEvergreen(
                instance,
                compId,
                true,
                dispatch
              )
            }
          />
        </Center>
      )}
      <Card>
        <Bold>How to use Evergreen feature:</Bold>
        <List>
          <ListItem>
            Design content that will continue to help your business get
            conversions in between big campaigns or events.
          </ListItem>
          <ListItem>
            Select 'My Content' in the sidebar and find the content block that
            you wish to use as your Evergreen content. Click the options menu
            and select 'Set Evergreen'.
          </ListItem>
          <ListItem>
            Once set, Content Scheduler will feature your Evergreen content in
            between your scheduled content.
          </ListItem>
        </List>
      </Card>
    </Container>
  );
};

export default EvergreenView;
