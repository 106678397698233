import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const calendarSlice = createSlice({
  name: "calendarSlice",
  initialState: {
    value: {
      showModal: false,
      title: "",
      id: "",
      error: "",
      eventAdded: 0,
    },
  },
  reducers: {
    setShowModal: (state, action: PayloadAction<{ showModal: boolean }>) => {
      state.value.showModal = action.payload.showModal;
    },
    setError: (state, action: PayloadAction<{ message: string }>) => {
      state.value.error = action.payload.message;
    },
    setCTATitle: (state, action: PayloadAction<{ title: string }>) => {
      if (action.payload.title) {
        state.value.title = action.payload.title;
      }
    },
    setCTAId: (state, action: PayloadAction<{ id: string }>) => {
      if (action.payload.id) {
        state.value.id = action.payload.id;
      }
    },
    eventAdded: (state) => {
      state.value.eventAdded = state.value.eventAdded + 1;
    },
  },
});

export const { setShowModal, setCTATitle, setCTAId, setError, eventAdded } =
  calendarSlice.actions;

export default calendarSlice.reducer;
