import React, { useCallback, useEffect, useRef, useState } from 'react';
import Calendar from '../Calendar/Calendar.component';
import CTA from '../CTA/CTA.component';
import TopBar from '../TopBar/TopBar.component';
import ScheduleModal from '../ScheduleModal/ScheduleModal.component';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { StageStyles, BackdropStyles, CalendarContainer } from './Stage.styles';
import useGetStatusAlert from '../../../hooks/useGetStatusAlert';
import useView from '../../../hooks/useView';
import useQuery from '../../../hooks/useQuery';
import useGetCtas from '../../../hooks/useGetCtas';
import { setActiveSidebarView } from '../../../redux/sidebarViewSlice';
import { setCTAId, setCTATitle } from '../../../redux/calendarSlice';
import useGetCurrentCta from '../../../hooks/useGetCurrentCta';
import useSidebarView from '../../../hooks/useSidebarView';
import useShowSidebar from '../../../hooks/useShowSidebar';
import useGetEditingMode from '../../../hooks/useGetEditingMode';
import useZoom from '../../../hooks/useZoom';
import useAutoSave from '../../../hooks/useAutoSave';
import ConfirmModal from '../ConfirmModal/ConfirmModal.component';
import Help from '../Help/Help.component';
import StatusAlert from '../StatusAlert/StatusAlert.component';

const Stage = () => {
  const showModal = useAppSelector((state) => state.calendar.value.showModal);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const currentViewId = useSidebarView();
  const status = useGetStatusAlert();
  const view = useView();
  const dispatch = useAppDispatch();
  const ctaList = useGetCtas();
  const ctaRef = useRef(null);
  const query = useQuery();
  const showSidebar = useShowSidebar();
  const editingMode = useGetEditingMode();
  const currentCta = useGetCurrentCta();
  useAutoSave(ctaRef);

  // Stage rect.width & height. Used for automatically settings zoom value based on available space
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(0);
  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(0);
  const [ctaWidth, setCtaWidth] = useState<number>(0);
  const [ctaHeight, setCtaHeight] = useState<number>(0);
  const [queryResult, setQueryResult] = useState<string | null>(null);
  const [ctaId, setCtaId] = useState<string>('');
  useZoom(ctaWidth, ctaHeight, windowInnerWidth, windowInnerHeight);

  // Set window width and height on load and when window is resized.
  const resizeEvent = useCallback(() => {
    if (showSidebar) {
      setWindowInnerWidth(window.innerWidth - 350);
    } else {
      setWindowInnerWidth(window.innerWidth);
    }
    setWindowInnerHeight(window.innerHeight);
  }, [showSidebar]);

  // Trigger resize event when showSidebar changes
  useEffect(() => {
    resizeEvent();
  }, [showSidebar, resizeEvent]);

  // Trigger resize event on window resize
  useEffect(() => {
    window.addEventListener('resize', resizeEvent);
    return () => window.removeEventListener('resize', resizeEvent);
  });

  // Set ctaHeight and ctaWidth based on currentCta
  useEffect(() => {
    if (currentCta) {
      switch (editingMode) {
        case 'desktop':
          if (
            typeof currentCta.editor.style.desktop.width?.value === 'string'
          ) {
            setCtaWidth(parseInt(currentCta.editor.style.desktop.width.value));
          } else if (
            typeof currentCta.editor.style.desktop.width?.value === 'number'
          ) {
            setCtaWidth(currentCta.editor.style.desktop.width.value);
          }
          if (
            typeof currentCta.editor.style.desktop.height?.value === 'string'
          ) {
            setCtaHeight(
              parseInt(currentCta.editor.style.desktop.height.value)
            );
          } else if (
            typeof currentCta.editor.style.desktop.height?.value === 'number'
          ) {
            setCtaHeight(currentCta.editor.style.desktop.height.value);
          }
          break;

        case 'mobile':
          if (typeof currentCta.editor.style.mobile.width?.value === 'string') {
            setCtaWidth(parseInt(currentCta.editor.style.mobile.width.value));
          } else if (
            typeof currentCta.editor.style.mobile.width?.value === 'number'
          ) {
            setCtaWidth(currentCta.editor.style.mobile.width.value);
          }
          if (
            typeof currentCta.editor.style.mobile.height?.value === 'string'
          ) {
            setCtaHeight(parseInt(currentCta.editor.style.mobile.height.value));
          } else if (
            typeof currentCta.editor.style.mobile.height?.value === 'number'
          ) {
            setCtaHeight(currentCta.editor.style.mobile.height.value);
          }
          break;
        default:
          return;
      }
    }
  }, [currentCta, editingMode]);

  // Set CTAId and CTATitle based on currentCta.
  // Used for setting CTAId and CTATitle for ScheduleModal component.
  useEffect(() => {
    if (view.view === 'schedule' && ctaId && ctaList) {
      const cta = ctaList.find((cta) => cta.id === ctaId);

      if (cta) {
        dispatch(setCTAId({ id: ctaId }));
        dispatch(setCTATitle({ title: cta.title }));
        dispatch(setActiveSidebarView({ id: 7 }));
      }
    }
  }, [view, dispatch, ctaId, ctaList]);

  // Get query result from query string
  useEffect(() => {
    setQueryResult(query.get('cta-id'));
  }, [query]);

  // Set CtaId based on query result
  useEffect(() => {
    if (queryResult) {
      setCtaId(queryResult);
    }
  }, [queryResult]);

  return (
    <StageStyles view={currentViewId} data-testid='stage'>
      {currentViewId === 7 ? (
        <>
          {status.message && <StatusAlert />}
          <CalendarContainer>
            <Calendar />
          </CalendarContainer>

          {showModal && (
            <ScheduleModal handleShowModal={() => setShowConfirmModal(true)} />
          )}
          {showConfirmModal && (
            <ConfirmModal
              handleCloseModal={() => setShowConfirmModal(false)}
              message={
                'Your schedule has been queued. Publish your site from the Wix editor to save your schedule.'
              }
            />
          )}
        </>
      ) : currentViewId === 9 ? (
        <Help />
      ) : (
        <>
          <TopBar />

          {status.message && <StatusAlert />}
          <BackdropStyles data-testid='backdrop'>
            {currentCta && currentCta.id && <CTA ref={ctaRef} />}
            {/* <ZoomInput /> For now I'm going to comment this out due to time constraints. We need to implement some additional features for this to be a good user experience. For a later update. */}
          </BackdropStyles>
        </>
      )}
    </StageStyles>
  );
};

export default Stage;
