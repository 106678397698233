import styled from 'styled-components';

export const Container = styled.div<{
  offsetWidth: number;
  offsetHeight: number;
  position: { x: string; y: string };
  x: number;
  y: number;
}>`
  padding: 2rem;
  box-shadow: ${(props) => props.theme.shadow[2]};
  background: #fff;
  font-size: 1.2rem;
  border-radius: 3px;
  position: fixed;
  top: ${(props) =>
    props.position.y === 'top'
      ? props.y + 'px'
      : props.y - props.offsetHeight + 'px'};
  left: ${(props) =>
    props.position.x === 'left'
      ? props.x + 'px'
      : props.x - props.offsetWidth + 'px'};
  z-index: 100;
  border: 1px solid #f5f5f5;
`;

export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.color.black};

  display: block;
`;

export const Notes = styled.p`
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 1rem;
  font-style: italic;
  width: 250px;
`;

export const Schedule = styled.span`
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 0.8rem;
`;
