import React from 'react';
import { Container } from './Tooltip.styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Tooltip: React.FC<Props> = ({ children, style }) => {
  return (
    <Container style={style}>
      <ArrowDropUpIcon
        style={{
          position: 'absolute',
          left: '63%',
          top: '-2.7rem',
          fontSize: '50px',
          color: 'rgb(75 85 99)',
        }}
      />
      {children}
    </Container>
  );
};

export default Tooltip;
