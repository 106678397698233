import { useAppDispatch } from "../redux/reduxHooks";
import { setInstance } from "../redux/ctaSlice";
import { useEffect } from "react";
import useQuery from "./useQuery";

const useSetInstance = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  console.log("query", query);
  const instance = query.get("instance");

  useEffect(() => {
    if (instance) {
      dispatch(setInstance({ instance }));
    }
  }, [instance, dispatch]);
};

export default useSetInstance;
