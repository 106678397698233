import React, { useRef, useState } from 'react';
import {
  Container,
  Header,
  HeaderTitle,
  Frame,
  PrevButton,
  NextButton,
  Row,
  IconButton,
  Flex,
} from './CarouselContainer.styles';
import Tabs from '../Tabs/Tabs.component';
import AnnouncementCarousel from '../AnnouncementCarousel/AnnouncementCarousel.component';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useGetCtas from '../../../hooks/useGetCtas';
import useCategories from '../../../hooks/useCategories';
import { DashboardTab, Pos } from '../../../types';
import OptionsMenu from '../../DesignStudio/OptionsMenu/OptionsMenu.component';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { setCompId, setContentId } from '../../../redux/dashboardSlice';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  useActiveTab,
  usePageIndex,
  useItemsVisible,
  useSetCarouselWidth,
} from './CarouselContainer.logic';
import useWindowWidth from '../../../hooks/useWindowWidth';

interface Props {
  compId: string;
  alias: string;
}

const CarouselContainer: React.FC<Props> = ({ compId, alias }) => {
  const frameRef = useRef<HTMLDivElement>(null);
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);

  const [showCarousel, setShowCarousel] = useState<boolean>(true);
  const [pos, setPos] = useState<Pos>({ x: 0, y: 0 });
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  const dispatch = useAppDispatch();
  const ctaId = useAppSelector((state) => state.calendar.value.id);
  const ctaList = useGetCtas();
  const [activeCtas, drafts, upcoming, expired] = useCategories(
    compId,
    ctaList
  );
  const { windowWidth } = useWindowWidth();
  const { itemsVisible } = useItemsVisible(windowWidth);
  const { frameWidth } = useSetCarouselWidth(frameRef, windowWidth);
  const { pageIndex, setPageIndex, pages, setPages } = usePageIndex();
  const { setActiveTab, activeTab } = useActiveTab(
    activeCtas,
    drafts,
    upcoming,
    expired,
    itemsVisible,
    setPages
  );

  const onChange = (key: DashboardTab) => {
    setActiveTab(key);
  };

  const handleNext = () => {
    if (pageIndex < pages) {
      setPageIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (pageIndex > 1) {
      setPageIndex((prev) => prev - 1);
    }
  };

  const handleOptionsClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    contentId: string,
    compId: string
  ) => {
    e.stopPropagation();
    setShowOptionsMenu(!showOptionsMenu);
    setPos({ x: e.clientX, y: e.clientY });
    dispatch(setContentId({ contentId }));
    dispatch(setCompId({ compId }));
  };

  const titleRef = useRef<HTMLSpanElement | null>(null);

  return (
    <Container>
      <Header>
        <HeaderTitle>{alias}</HeaderTitle>
        <Flex>
          <Tabs
            defaultActiveKey={'upcoming'}
            activeKey={activeTab}
            onChange={onChange}
            style={{ marginBottom: '-1.8rem' }}
          />
          <IconButton
            aria-label={'show/hide carousel'}
            onClick={() => setShowCarousel(!showCarousel)}>
            {showCarousel ? (
              <RemoveCircleOutlineIcon
                fontSize={'large'}
                style={{ color: 'inherit' }}
              />
            ) : (
              <AddCircleOutlineIcon
                fontSize={'large'}
                style={{ color: 'inherit' }}
              />
            )}
          </IconButton>
        </Flex>
      </Header>

      <Row show={showCarousel}>
        <PrevButton
          ref={prevBtnRef}
          aria-label={'Previous'}
          onClick={handlePrev}>
          <ChevronLeftIcon style={{ fontSize: '36px', color: '#00113D' }} />
        </PrevButton>
        <Frame ref={frameRef}>
          <AnnouncementCarousel
            activeCtas={activeCtas}
            drafts={drafts}
            upcoming={upcoming}
            expired={expired}
            activeTab={activeTab}
            pageIndex={pageIndex}
            frameWidth={frameWidth}
            handleOptionsClick={handleOptionsClick}
            titleRef={titleRef}
          />
        </Frame>
        <NextButton ref={nextBtnRef} aria-label={'Next'} onClick={handleNext}>
          <ChevronRightIcon style={{ fontSize: '36px', color: '#00113D' }} />
        </NextButton>
      </Row>

      {showOptionsMenu && (
        <OptionsMenu
          setShowOptionsMenu={setShowOptionsMenu}
          ctaId={ctaId}
          pos={pos}
          titleRef={titleRef}
        />
      )}
    </Container>
  );
};

export default CarouselContainer;
