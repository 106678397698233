import React from 'react';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem.component';
import {
  ContextMenuStyles,
  MenuItemsContainerUpper,
  MenuItemsContainerLower,
} from './EvergreenContextMenu.styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';

interface Props {
  ctaId: string;
  closeMenu: React.MouseEventHandler;
  menuPosition: string;
  titleRef: React.RefObject<HTMLSpanElement>;
}

interface MenuItem {
  id: number;
  label: string;
  icon: any;
}

const upperMenuItems: MenuItem[] = [
  { id: 1, label: 'Edit', icon: EditIcon },

  {
    id: 3,
    label: 'Preview',
    icon: VisibilityIcon,
  },

  { id: 5, label: 'Rename', icon: DriveFileRenameOutlineRoundedIcon },
];

const lowerMenuItems: MenuItem[] = [
  { id: 7, label: 'Delete', icon: DeleteIcon },
];

const EvergreenContextMenu: React.FC<Props> = ({
  ctaId,
  closeMenu,
  menuPosition,
  titleRef,
}) => {
  return (
    <ContextMenuStyles menuPosition={menuPosition} data-testid='context-menu'>
      <MenuItemsContainerUpper>
        {upperMenuItems.map((item) => (
          <ContextMenuItem
            label={item.label}
            key={item.label + '_' + item.id}
            Icon={item.icon}
            id={item.id}
            closeMenu={closeMenu}
            ctaId={ctaId}
            titleRef={titleRef}
          />
        ))}
      </MenuItemsContainerUpper>
      <MenuItemsContainerLower>
        {lowerMenuItems.map((item) => (
          <ContextMenuItem
            label={item.label}
            key={item.label + '_' + item.id}
            Icon={item.icon}
            id={item.id}
            closeMenu={closeMenu}
            ctaId={ctaId}
            titleRef={titleRef}
          />
        ))}
      </MenuItemsContainerLower>
    </ContextMenuStyles>
  );
};

export default EvergreenContextMenu;
