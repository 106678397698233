import React, { useEffect, useState } from 'react';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCtas from '../../../hooks/useGetCtas';
import { ContextMenuItemStyles } from './ContextMenuItem.styles';
import {
  addCtaToDB,
  deleteCta,
  getAllCtasFromDB,
  setEvergreenInDb,
} from '../../../util/services';
import { handleClose } from '../../../util/functions';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setCtaList } from '../../../redux/ctaSlice';
import { Wix } from '../../../util/Wix';
import useGetCompId from '../../../hooks/useGetCompId';
import useUser from '../../../hooks/useUser';
import useTrialExpiration from '../../../hooks/useTrialExpiration';
import Crown from '../../General/Crown/Crown.component';
import usePlanId from '../../../hooks/usePlanId';
import Tooltip from '../Tooltip/Tooltip.component';

interface Props {
  id: number;
  ctaId: string;
  label: string;
  Icon: any;
  closeMenu: React.MouseEventHandler;
  titleRef: React.RefObject<HTMLSpanElement>;
}

const ContextMenuItem: React.FC<Props> = ({
  id,
  ctaId,
  label,
  Icon,
  closeMenu,
  titleRef,
}) => {
  const instance = useGetInstance();
  const compId = useGetCompId();
  const dispatch = useAppDispatch();
  const ctaList = useGetCtas();
  const user = useUser();
  const isTrialExpired = useTrialExpiration(user);
  const planId = usePlanId();
  const [showTooltip, setShowTooltip] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (planId === 'basic' && id === 4) {
      setDisableButton(true);
    }
  }, [planId, id]);

  const editUrl =
    process.env.REACT_APP_ENV === 'staging'
      ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`
      : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}`;
  const testEditUrl = `/design?instance=${instance}&cta-id=${ctaId}`;

  const scheduleUrl =
    process.env.REACT_APP_ENV === 'staging'
      ? `https://staging.contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}&view=schedule`
      : `https://contentscheduler.app/design?instance=${instance}&cta-id=${ctaId}&view=schedule`;
  const testScheduleUrl = `/design?instance=${instance}&cta-id=${ctaId}&view=schedule`;

  const handleClick = async (e) => {
    closeMenu(e);
    switch (label) {
      case 'Edit':
        if (
          process.env.NODE_ENV === 'production' ||
          process.env.REACT_APP_ENV === 'staging'
        ) {
          Wix.Settings.openModal(
            editUrl,
            '85%',
            '85%',
            'Content Scheduler - Design Studio',
            () => handleClose()
          );
        } else {
          const w = window.open(testEditUrl, '_blank');
          if (w) {
            w.focus();
          }
        }

        break;
      case 'Schedule':
        if (
          process.env.NODE_ENV === 'production' ||
          process.env.REACT_APP_ENV === 'staging'
        ) {
          Wix.Settings.openModal(
            scheduleUrl,
            '85%',
            '85%',
            'Content Scheduler - Design Studio',
            () => handleClose()
          );
        } else {
          const w = window.open(testScheduleUrl, '_blank');
          if (w) {
            w.focus();
          }
        }

        break;
      case 'Preview':
        if (
          process.env.NODE_ENV === 'production' ||
          process.env.REACT_APP_ENV === 'staging'
        ) {
          Wix.Settings.refreshAppByCompIds(Wix.Utils.getOrigCompId(), {
            previewId: ctaId,
          });
          Wix.Settings.closeWindow({ target: 'ALL' });
        }

        break;
      case 'Set Evergreen':
        if (
          ((planId === null && !isTrialExpired) ||
            planId === 'professional' ||
            planId === 'enterprise')
        ) {
          try {
            await setEvergreenInDb(instance, compId, ctaId);
          } catch (error) {
            console.error(error);
          }

          try {
            const result = await getAllCtasFromDB(
              instance,
              undefined,
              compId
            );
            if (
              process.env.NODE_ENV === 'production' ||
              process.env.REACT_APP_ENV === 'staging'
            ) {
              Wix.Settings.refreshAppByCompIds(Wix.Utils.getOrigCompId());
            }
            if (result) {
              dispatch(setCtaList({ ctaList: result }));
            }
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case 'Rename':
        if (titleRef.current) {
          titleRef.current.contentEditable = 'true';
          titleRef.current.focus();
        }

        break;
      case 'Duplicate':
        // copy target cta from ctaList
        const targetCta = JSON.parse(
          JSON.stringify(ctaList.find((cta) => cta.id === ctaId))
        );
        // delete _id
        targetCta._id = undefined;
        targetCta.id = undefined;
        targetCta.title = targetCta.title + '(copy)';

        // set evergreen to false
        targetCta.isEvergreen = false;
        targetCta.isActive = false;

        targetCta.schedule.start = '';
        targetCta.schedule.end = '';
        // push to database

          try {
            await addCtaToDB(instance, compId, targetCta);
            const result = await getAllCtasFromDB(
              instance,
              undefined,
              compId
            );

            if (result) {
              dispatch(setCtaList({ ctaList: result }));
            }
          } catch (error: unknown) {
            if (error instanceof Error) {
              console.error(error.message);
            }
          }

        break;
      case 'Delete':
          try {
            await deleteCta(instance, compId, ctaId);

            const result = await getAllCtasFromDB(
              instance,
              undefined,
              compId
            );

            if (result) {
              dispatch(setCtaList({ ctaList: result }));
            }

            Wix.Settings.refreshAppByCompIds(Wix.Utils.getOrigCompId());
          } catch (error: unknown) {
            if (error instanceof Error) {
              console.error(error.message);
            }
          }

        break;
      default:
        console.log('Error: Invalid Menu ID');
    }
  };

  const handleMouseOver = () => {
    if (disableButton) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <ContextMenuItemStyles
      disable={disableButton}
      btnId={id}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}>
      <Icon
        style={{
          fontSize: 18,
          color: 'inherit',
          marginRight: '1.2rem',
        }}
      />
      {label}
      {disableButton && (
        <Crown
          style={{
            height: '1.6rem',
            width: '1.6rem',
            padding: '0',
            marginLeft: '.6rem',
          }}
        />
      )}
      {showTooltip && (
        <Tooltip style={{ top: '7rem', width: '20rem' }}>
          {'Upgrade to professional to run Evergreen Campaigns.'}
        </Tooltip>
      )}
    </ContextMenuItemStyles>
  );
};

export default ContextMenuItem;
