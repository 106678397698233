import styled from 'styled-components';
import { Style } from '../../../types';

export const Container = styled.div.attrs<{ zoom: number }>((props) => ({
  style: {
    transform: `scale(${props.zoom})`,
  },
}))<{ zoom: number }>`
  margin-top: -30px;
  width: fit-content;
  height: fit-content;
`;

export const CTAContainer = styled.div.attrs<{ elStyle: Style }>((props) => ({
  style: {
    height: props.elStyle?.height?.value || '40rem',
    width: props.elStyle?.width?.value || '98rem',
    boxShadow: props.elStyle?.boxShadow?.value || props.theme.shadow[2],
    opacity: '1',
  },
}))<{
  style?: React.CSSProperties;
  elStyle: Style;
  showVerticalGuideline?: boolean;
  showHorizontalGuideline?: boolean;
  loading?: boolean;
}>`
  border-radius: 0px;
  overflow: hidden;
  background: ${(props) =>
    props.loading ? '#FFFFFF' : props.elStyle.background?.value};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: red;
    transform: translate(0, -50%);
    display: ${(props) => (props.showHorizontalGuideline ? 'auto' : 'none')};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 100;
    left: 50%;
    height: 100%;
    background: red;
    width: 2px;
    display: ${(props) => (props.showVerticalGuideline ? 'auto' : 'none')};
    transform: translate(-50%, 0);
  }
`;
