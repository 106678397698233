import styled from 'styled-components';

export const TypographyStyles = styled.div<{
  cursor: string;
  isSelected: boolean;
}>`
  padding: 0;
  margin: 0;
  cursor: ${(props) => (props.cursor === 'move' ? 'move' : 'text')};
  user-select: text;
  position: relative;
  outline: none;

  &::before {
    content: '';
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 2px solid
      ${(props) => (props.isSelected ? '#4980f7' : 'transparent')};
  }
`;

export const Content = styled.p`
  margin-bottom: 0;
`;
