import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: {
    value: {
      contentId: "",
      compId: "",
    },
  },
  reducers: {
    setContentId: (state, action: PayloadAction<{ contentId: string }>) => {
      if (action.payload.contentId) {
        state.value.contentId = action.payload.contentId;
      }
    },
    setCompId: (state, action: PayloadAction<{ compId: string }>) => {
      if (action.payload.compId) {
        state.value.compId = action.payload.compId;
      }
    },
  },
});

export const { setContentId, setCompId } = dashboardSlice.actions;

export default dashboardSlice.reducer;
