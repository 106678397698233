import { useEffect, useState } from "react";
import useQuery from "./useQuery";

const useGetOrigCompId = () => {
  const [origCompId, setOrigCompId] = useState<string | null | -1>(null);
  const query = useQuery();
  const param = query.get("originCompId");

  useEffect(() => {
    if (param) {
      setOrigCompId(param);
    } else {
      setOrigCompId(-1);
    }
  }, [param]);

  return origCompId;
};

export default useGetOrigCompId;
