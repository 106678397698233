import React, { useLayoutEffect, useState } from 'react';
import { ShapeContainer } from './RawSVG.styles';
import { Style, SVGVariation } from '../../../types';
import { getShapeStyles } from '../../../util/functions';
import FullCircle from '../../DesignStudio/RawSvg/FullCircle';
import Rectangle from '../../DesignStudio/RawSvg/Rectangle';
import SquareFrame from '../../DesignStudio/RawSvg/SquareFrame';
import CircleFrameSmall from '../../DesignStudio/RawSvg/CircleFrameSmall';
import CircleFrameMedium from '../../DesignStudio/RawSvg/CircleFrameMedium';
import CircleFrameLarge from '../../DesignStudio/RawSvg/CircleFrameLarge';
import Rhombus from '../../DesignStudio/RawSvg/Rhombus';
import StylishFrame from '../../DesignStudio/RawSvg/StylishFrame';
import RightArrow from '../../DesignStudio/RawSvg/RightArrow';
import OffsetSquareRight from '../../DesignStudio/RawSvg/OffsetSquareRight';
import ClipmaskBG1 from '../../DesignStudio/RawSvg/ClipmaskBG1';
import WavyMobile from '../../DesignStudio/RawSvg/WavyMobile';
import Pumpkins from '../../DesignStudio/RawSvg/Pumpkins';

interface Props {
  id: string;
  elstyle: Style;
  variation: SVGVariation;
  site?: boolean;
}

const Svg: React.FC<Props> = ({ id, elstyle, variation, site }) => {
  const [svgStyle, setSvgStyle] = useState<{
    fill: string | undefined;
    opacity?: number;
  }>({
    fill: '#222222',
    opacity: 1,
  });

  useLayoutEffect(() => {
    if (elstyle) {
      setSvgStyle({
        fill: elstyle?.background?.value,
        opacity: elstyle?.opacity?.value,
      });
    }
  }, [elstyle]);

  const renderShape = () => {
    switch (variation) {
      case 'fullCircle':
        return <FullCircle svgStyle={svgStyle} />;
      case 'rectangle':
        return <Rectangle svgStyle={svgStyle} />;
      case 'squareFrame':
        return <SquareFrame svgStyle={svgStyle} />;
      case 'circleFrameSmall':
        return <CircleFrameSmall svgStyle={svgStyle} />;
      case 'circleFrameMedium':
        return <CircleFrameMedium svgStyle={svgStyle} />;
      case 'circleFrameLarge':
        return <CircleFrameLarge svgStyle={svgStyle} />;
      case 'rhombus':
        return <Rhombus svgStyle={svgStyle} />;
      case 'stylishFrame':
        return <StylishFrame svgStyle={svgStyle} />;
      case 'rightArrow':
        return <RightArrow svgStyle={svgStyle} />;
      case 'offsetSquareRight':
        return <OffsetSquareRight svgStyle={svgStyle} />;
      case 'clipmaskBG1':
        return <ClipmaskBG1 svgStyle={svgStyle} />;
      case 'wavyMobile':
        return <WavyMobile svgStyle={svgStyle} />;
      case 'pumpkins':
        return <Pumpkins svgStyle={svgStyle} />;
      default:
        return undefined;
    }
  };

  return (
    <ShapeContainer
      id={id}
      data-testid='shape'
      aria-hidden={'true'}
      style={{
        ...(getShapeStyles(elstyle, site) as React.CSSProperties),
      }}>
      {renderShape()}
    </ShapeContainer>
  );
};

export default Svg;
