import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import EditIcon from '@mui/icons-material/Edit';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Cover,
  ContextMenuStyles,
  MenuItemsUpper,
  MenuItemsLower,
  MenuItem,
} from './OptionsMenu.styles';
import { theme } from '../../../styles/theme';
import { useNavigate } from 'react-router-dom';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCurrentCta from '../../../hooks/useGetCurrentCta';
import useGetCtas from '../../../hooks/useGetCtas';
import {
  setCtaList,
  setCurrentCta,
  setCurrentCtaId,
} from '../../../redux/ctaSlice';
import { setShowModal } from '../../../redux/calendarSlice';
import { setActiveSidebarView } from '../../../redux/sidebarViewSlice';
import {
  addCtaToDB,
  deleteCta,
  getAllCtasFromDB,
  setEvergreenInDb,
} from '../../../util/services';
import { Cta, Pos } from '../../../types';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useGetCompId from '../../../hooks/useGetCompId';
import {
  openCtaInEditorFromDashboard,
  scheduleFromDashboard,
} from '../../../util/functions';
import useGetDashboardContentId from '../../../hooks/useGetDashboardContentId';
import useGetDashboardCompId from '../../../hooks/useGetDashboardCompId';
import Crown from '../../General/Crown/Crown.component';
import Tooltip from '../../Settings/Tooltip/Tooltip.component';
import usePlanId from '../../../hooks/usePlanId';

interface Props {
  setShowOptionsMenu: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  ctaId: string;
  titleRef?: React.RefObject<HTMLSpanElement>;
  pos: Pos;
  style?: React.CSSProperties;
}

const OptionsMenu: React.FC<Props> = ({
  setShowOptionsMenu,
  ctaId,
  titleRef,
  pos,
  style,
}) => {
  const navigate = useNavigate();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const ctaList = useGetCtas();
  const currentCta = useGetCurrentCta();
  const [menuPosition, setMenuPosition] = useState<string>('');
  const [offsetWidth, setOffsetWidth] = useState<number>(0);
  const [offsetHeight, setOffsetHeight] = useState<number>(0);
  const deleteIconRef = useRef<SVGSVGElement>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const pathname = window.location.pathname;
  const contentId = useGetDashboardContentId();
  const dashboardCompId = useGetDashboardCompId();
  const [showTooltip, setShowTooltip] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const planId = usePlanId();

  useEffect(() => {
    if (planId === 'basic') {
      setDisableButton(true);
    }
  }, [planId]);

  const handleDeleteHover = () => {
    if (deleteIconRef.current) deleteIconRef.current.style.color = '#f3576d';
  };
  const handleDeleteLeave = () => {
    if (deleteIconRef.current)
      deleteIconRef.current.style.color = theme.color.gray600;
  };

  const dispatch = useAppDispatch();

  const handleSchedule = () => {
    setShowOptionsMenu(false);
    if (window.location.pathname.match(/design/gi)) {
      dispatch(setShowModal({ showModal: true }));
    } else {
      scheduleFromDashboard(contentId, dashboardCompId, instance);
    }
  };

  const handleEdit = () => {
    if (pathname.match(/dashboard/gi)) {
      openCtaInEditorFromDashboard(contentId, dashboardCompId, instance);
    } else {
      dispatch(setCurrentCtaId({ ctaId }));
      navigate(`/design?instance=${instance}&cta-id=${ctaId}`);
      dispatch(setActiveSidebarView({ id: 1 }));
    }
  };

  const handleSetTitle = () => {
    setShowOptionsMenu(false);

    if (titleRef && titleRef.current) {
      titleRef.current.contentEditable = 'true';
      titleRef.current.focus();
    }
  };

  const handleSetEvergreen = async () => {
    setShowOptionsMenu(false);
    let result;
      if (pathname.match(/dashboard/gi)) {
        await setEvergreenInDb(
          instance,
          dashboardCompId,
          contentId
        );
        result = await getAllCtasFromDB(
          instance,
          undefined,
          dashboardCompId
        );
      } else {
        await setEvergreenInDb(instance,compId, ctaId);
        result = await getAllCtasFromDB(
          instance,
          undefined,
          compId
        );
      }


    if (result) {
      dispatch(setCtaList({ ctaList: result }));
    }
  };

  const handleDuplicate = async () => {
    // copy target cta from ctaList
    setShowOptionsMenu(false);
    if (contentId) {
      ctaId = contentId;
    }
    let targetCta = ctaList.find((cta) => cta.id === ctaId);

    targetCta = JSON.parse(JSON.stringify(targetCta));

    if (targetCta) {
      // delete _id
      // @ts-ignore - _id is not a valid property.
      targetCta._id = undefined;
      targetCta.id = '';

      // append (copy) to title
      targetCta.title = targetCta.title + '(copy)';

      // set evergreen to false
      targetCta.isEvergreen = false;
      targetCta.isActive = false;

      // clear schedule
      targetCta.schedule.start = 0;
      targetCta.schedule.end = 0;
    }
    // push to database

    setShowOptionsMenu(false);
    let result;

      if (pathname.match(/dashboard/gi)) {
        if (targetCta)
          await addCtaToDB(instance, dashboardCompId, targetCta);
        result = await getAllCtasFromDB(
          instance,
          undefined,
          dashboardCompId
        );
      } else {
        if (targetCta)
          await addCtaToDB( instance,compId, targetCta);
        result = await getAllCtasFromDB(
          instance,
          undefined,
          compId
        );
      }

      if (result) {
        dispatch(setCtaList({ ctaList: result }));
      }

  };

  const handleDelete = async () => {
    let result;
    setShowOptionsMenu(false);
      if (window.location.pathname.match(/dashboard/gi)) {
        await deleteCta( instance, dashboardCompId, contentId);
        result = await getAllCtasFromDB(
          instance,
          undefined,
          dashboardCompId
        );
      } else {
        await deleteCta(instance, compId, ctaId);
        result = await getAllCtasFromDB(
          instance,
          undefined,
          compId
        );
      }


    if (result && result.length > 0) {
      dispatch(setCtaList({ ctaList: result }));
    } else if (window.location.pathname.match(/design/gi)) {
      dispatch(setCtaList({ ctaList: [] }));
      dispatch(setCurrentCtaId({ ctaId: '' }));
      dispatch(setCurrentCta({ currentCta: null }));
      navigate(`/design?instance=${instance}`);
    } else {
      dispatch(setCtaList({ ctaList: [] }));
    }

    if (
      window.location.pathname.match(/design/gi) &&
      ctaId === currentCta?.id
    ) {
      let min = 0;
      let lastUpdatedCta: Cta | null = null;

      if (result) {
        for (let i = 0; i < result.length; i++) {
          if (result[i].updatedAt > min) {
            lastUpdatedCta = result[i];
            min = result[i].updatedAt;
          }
        }

        if (lastUpdatedCta?.id) {
          const cta = ctaList.find((cta) => ctaId === cta.id);

          if (cta) dispatch(setCurrentCta({ currentCta: cta }));

          dispatch(setCurrentCtaId({ ctaId: lastUpdatedCta.id }));
          navigate(`/design?instance=${instance}&cta-id=${lastUpdatedCta.id}`);
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (
      menuRef.current &&
      pos.x + menuRef.current.offsetWidth > window.innerWidth
    ) {
      setMenuPosition('right');
    } else {
      setMenuPosition('left');
    }
  }, [menuRef, pos.x]);

  useLayoutEffect(() => {
    if (
      menuRef.current &&
      pos.y + menuRef.current.offsetHeight > window.innerHeight
    ) {
      setMenuPosition('top');
    } else {
      setMenuPosition('bottom');
    }
  }, [menuRef, pos.y]);

  useLayoutEffect(() => {
    if (menuRef.current) {
      setOffsetWidth(menuRef.current.offsetWidth);
      setOffsetHeight(menuRef.current.offsetHeight);
    }
  }, [menuRef]);

  const handleMouseOver = () => {
    if (disableButton) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <ContextMenuStyles
      pos={pos}
      style={style}
      ref={menuRef}
      offsetWidth={offsetWidth}
      offsetHeight={offsetHeight}
      menuPosition={menuPosition}
      data-testid='options-menu'>
      <MenuItemsUpper>
        <MenuItem
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSchedule();
            }
          }}
          onClick={handleSchedule}>
          <WatchLaterIcon
            style={{
              fontSize: 18,
              color: theme.color.gray600,
              marginRight: '1.2rem',
            }}
          />
          Schedule
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSetEvergreen();
            }
          }}
          onClick={handleSetEvergreen}
          disableButton={disableButton}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}>
          <CheckCircleIcon
            style={{
              fontSize: 18,
              color: theme.color.gray600,
              marginRight: '1.2rem',
            }}
          />
          Set Evergreen
          {disableButton && (
            <Crown
              style={{
                height: '1.6rem',
                width: '1.6rem',
                padding: '0',
                marginLeft: '.6rem',
              }}
            />
          )}
          {showTooltip && (
            <Tooltip style={{ top: '11rem', width: '20rem' }}>
              {'Upgrade to professional to run Evergreen Campaigns.'}
            </Tooltip>
          )}
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEdit();
            }
          }}
          onClick={handleEdit}>
          <EditIcon
            style={{
              fontSize: 18,
              color: theme.color.gray600,
              marginRight: '1.2rem',
            }}
          />
          Edit
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSetTitle();
            }
          }}
          onClick={handleSetTitle}>
          <DriveFileRenameOutlineRoundedIcon
            style={{
              fontSize: 18,
              color: theme.color.gray600,
              marginRight: '1.2rem',
            }}
          />
          Rename
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleDuplicate();
            }
          }}
          onClick={handleDuplicate}>
          <FileCopyIcon
            style={{
              fontSize: 18,
              color: theme.color.gray600,
              marginRight: '1.2rem',
            }}
          />
          Duplicate
        </MenuItem>
      </MenuItemsUpper>
      <MenuItemsLower>
        <MenuItem
          id='delete'
          onClick={handleDelete}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleDelete();
            }
          }}
          onMouseOver={handleDeleteHover}
          onMouseLeave={handleDeleteLeave}>
          <DeleteIcon
            ref={deleteIconRef}
            style={
              {
                fontSize: 18,
                marginRight: '1.2rem',
                color: theme.color.gray600,
              } as React.CSSProperties
            }
            id='deleteIcon'
          />
          Delete
        </MenuItem>
      </MenuItemsLower>
      <Cover
        data-testid='cover'
        onClick={() => setShowOptionsMenu(false)}></Cover>
    </ContextMenuStyles>
  );
};

export default OptionsMenu;
