import styled from 'styled-components';

export const InputContainer = styled.div`
  position: absolute;
  top: 4.5rem;
  right: 0;
  padding: 2rem;
  border-radius: 3px;
  background: #fff;
  box-shadow: ${(props) => props.theme.shadow[2]};
  cursor: default;
  display: flex;
  flex-direction: column;
  z-index: 100;

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[3]};
  }
`;

export const VisibilityButtonContainer = styled.button<{ show: boolean }>`
  -webkit-transition: background-color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  border-radius: 2px;
  padding: 0.6rem;
  cursor: pointer;
  position: relative;
  border: none;
  background-color: ${(props) =>
    props.show ? props.theme.color.gray200 : '#FFF'};

  &:hover {
    background-color: ${(props) =>
      props.show ? props.theme.color.gray200 : props.theme.color.gray100};
  }
`;

export const Row = styled.div`
  display: flex;
  white-space: nowrap;
`;

export const Label = styled.label`
  margin-left: ${(props) => props.theme.spacing[3]};
`;
