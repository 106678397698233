import styled from 'styled-components';
import { DashboardView } from '../../../types';

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const Link = styled.li<{
  view: DashboardView;
  name: DashboardView;
}>`
  font-size: 1.4rem;
  padding-left: ${(props) => props.theme.spacing[5]};
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.color.white};
  cursor: ${(props) => (props.view === props.name ? 'default' : 'pointer')};
  background-color: ${(props) =>
    props.view === props.name && props.view === 'design'
      ? props.theme.color.blue700
      : props.view === props.name
      ? props.theme.color.blue900
      : 'transparent'};
  &:hover {
    background-color: ${(props) =>
      props.view !== props.name && props.theme.color.blue700};
  }
`;
