import styled from 'styled-components';

export const TextMenuStyles = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: calc(35rem - 7.1rem);
  padding: 2rem;
  font-size: 1.2rem;
`;
