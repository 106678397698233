import React, { useEffect, useState } from 'react';
import { CTAContainer } from './MobileWidget.styles';
import useSetUser from '../../hooks/useSetUser';
import useSetInstance from '../../hooks/useSetInstance';
import useGetActiveCta from '../../hooks/useGetActiveCta';
import CTA from '../../components/Widget/CTA/CTA.component';
import useLoading from '../../hooks/useLoading';
import useAppendFontScripts from '../../hooks/useAppendFontScripts';
import { Cta } from '../../types';
import useGetInstance from '../../hooks/useGetInstance';
import useSetCompId from '../../hooks/useSetCompId';
import useGetCompId from '../../hooks/useGetCompId';
import useSetPlanId from '../../hooks/useSetPlanId';
import { Wix } from '../../util/Wix';
import SkeletonComponent from '../../components/General/SkeletonComponent/SkeletonComponent.component';
import useHideWidget from '../../hooks/useHideWidget';

const MobileWidget: React.FC = () => {
  const media = 'mobile';
  const [currentCta, setCurrentCta] = useState<Cta>({} as Cta);
  const [wixEditMode, setWixEditMode] = useState<string>('editor');

  useSetInstance();
  useSetCompId();
  useAppendFontScripts(currentCta as Cta);
  useHideWidget();

  const instance = useGetInstance();
  const compId = useGetCompId();
  useSetPlanId(instance);
  const loading = useLoading();
  const activeCta = useGetActiveCta(instance, compId);
  useSetUser(instance);

  useEffect(() => {
    setWixEditMode(Wix.Utils.getViewMode());
  }, []);

  useEffect(() => {
    if (activeCta.id) {
      setCurrentCta(activeCta);
    }
  }, [activeCta]);

  if (loading) {
    return <SkeletonComponent />;
  } else {
    // Display only live data
    if (activeCta && 'id' in activeCta) {
      return (
        <CTA currentCta={activeCta} wixEditMode={wixEditMode} media={media} />
      );
    } else {
      return <CTAContainer data-testid='widget'></CTAContainer>;
    }
  }
};

export default MobileWidget;
