import styled from 'styled-components';

export const TemplateStyles = styled.li`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.color.gray300};
  padding: ${(props) => props.theme.spacing[3]};
  position: relative;
  border-radius: 5px;
`;

export const MenuButton = styled.button`
  display: flex;
  align-content: center;
  position: absolute;
  height: 3.2rem;
  width: 3.2rem;
  border: none;
  border-radius: 50%;
  background: ${(props) => props.theme.color.settings.blue300};
  right: ${(props) => props.theme.spacing[2]};
  top: ${(props) => props.theme.spacing[2]};
  box-shadow: ${(props) => props.theme.shadow[2]};
  cursor: pointer;
  color: ${(props) => props.theme.color.black};

  &:hover {
    background: ${(props) => props.theme.color.settings.blue400};
  }
`;

export const Cover = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`;

export const Image = styled.img`
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.gray50};
`;

export const CrownContainer = styled.div`
  position: absolute;
  top: 9px;
  left: 9px;
  z-index: 100;
`;

export const Overlay = styled.div`
  z-index: 99;
  background: #000;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
`;
