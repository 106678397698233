import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  box-shadow: ${(props) => props.theme.shadow[5]};
  background: #fff;
  z-index: 100;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Button = styled.button`
  padding: 0.5rem 1.2rem;
  font-size: 1.3rem;
  border-radius: 5px;
  background: #d46b08;
  border: none;
  color: #fff;
  cursor: pointer;
`;
