import React from 'react';
import { Container, TemplatesList } from './TemplatesView.styles';
import Template from '../Template/Template.component';
import { templateList } from '../../../templateEnums';
import { SectionHeader } from '../../../styles/settings/components';

const TemplatesView: React.FC = () => {
  return (
    <Container>
      <SectionHeader>Professional Templates</SectionHeader>

      <TemplatesList>
        {templateList
          .sort((a, b) => Number(a.premium) - Number(b.premium))
          .map((item) => (
            <Template
              key={item.src}
              alt={item.alt}
              id={item.id}
              src={item.src}
              premium={item.premium}
            />
          ))}
      </TemplatesList>
    </Container>
  );
};

export default TemplatesView;
