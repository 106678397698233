import styled from 'styled-components';

export const IconContainer = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: inherit;
`;

export const Icon = styled.button<{ active?: boolean }>`
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  background-color: ${(props) =>
    props.active ? 'rgba(0,0,0,0.1)' : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  width: 17rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
`;

export const CalendarMenuItemStyles = styled.div`
  width: 100%;
  padding: 0.8rem 2rem;
  font-size: 1.4rem;
  background-color: transparent;
  color: ${(props) => props.theme.color.black};

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: transform 0.1s ease-in-out;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray100};

  &:hover {
    background-color: ${(props) => props.theme.color.gray50};
    border-bottom: 1px solid transparent;
  }
`;
