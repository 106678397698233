import styled from 'styled-components';

export const ImageContainer = styled.div<{
  cursor: string;
  isSelected: boolean;
}>`
  cursor: ${(props) => (props.cursor === 'move' ? 'move' : 'pointer')};
  width: inherit;
  height: inherit;
  &::before {
    content: '';
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 2px solid
      ${(props) => (props.isSelected ? '#4980f7' : 'transparent')};
  }
`;
