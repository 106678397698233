import React from 'react';
import { ImageStyles } from './Image.styles';
import { Style } from '../../../types';

interface Props {
  id: string;
  elstyle: Style;
  alt: string | undefined;
  src: string | undefined;
}

const Image: React.FC<Props> = ({ id, elstyle, alt, src }) => {
  return <ImageStyles id={id} elstyle={elstyle} src={src} alt={alt} />;
};

export default Image;
