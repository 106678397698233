import styled from 'styled-components';
import { ThemeColor } from '../../../types';

export const CustomColorStyles = styled.div<{
  selectedColor: ThemeColor | {};
  color: string;
  id: string;
}>`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: 3px solid white;
  background: ${(props) => props.color};
  position: relative;
  cursor: pointer;
  color: ${(props) => props.theme.color.red500};

  &:hover {
    border-color: ${(props) => props.theme.color.btnPrimary};
  }
`;

export const DeleteIcon = styled.div`
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
