import React, { useEffect, useRef } from 'react';
import { Container } from './CalendarCard.styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import useForceUpdate from '../../../hooks/useForceUpdate';
import useGetCtas from '../../../hooks/useGetCtas';
import useCalendarEvents from '../../../hooks/useCalendarEvents';

const CalendarCard = () => {
  const forceUpdate = useForceUpdate();
  const hasRendered = useRef(false);
  const ctaList = useGetCtas();
  const events = useCalendarEvents(ctaList);

  useEffect(() => {
    if (!hasRendered.current) {
      setTimeout(() => forceUpdate(), 100);
    }

    return () => {
      hasRendered.current = true;
    };
  }, [forceUpdate]);

  return (
    <Container>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        height='100%'
        fixedWeekCount={true}
        events={events}
      />
    </Container>
  );
};

export default CalendarCard;
