import React from 'react';
import { Container, Text } from './UpgradeAppModal.styles';
import MenuButton from '../../DesignStudio/MenuButton/MenuButton.component';
import { handleOpenBillingPage } from '../../../util/functions';
import { Cover } from '../../../styles/util';

interface Props {
  setShowModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setConfirmed: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const UpgradeAppModal: React.FC<Props> = ({ setShowModal, setConfirmed }) => {
  return (
    <>
      <Container>
        <Text>Upgrade to a paid plan to keep using this app</Text>
        <MenuButton
          label={'Upgrade Now'}
          variant={'secondary'}
          onClick={() => handleOpenBillingPage()}
        />
      </Container>
      <Cover
        onClick={() => {
          setConfirmed(true);
          setShowModal(false);
        }}
        style={{ background: 'rgba(0,0,0,0.5)', zIndex: 99 }}
      />
    </>
  );
};

export default UpgradeAppModal;
