import React, { useEffect, useState } from 'react';
import {
  Container,
  BackButton,
  SaveButton,
  InputContainer,
  Rotate,
} from './NicknameView.styles';
import { SectionHeader } from '../../../styles/settings/components';
import { Input } from 'antd';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import useGetCompId from '../../../hooks/useGetCompId';
import { CompId } from '../../../types';
import { updateAlias } from '../../../util/services';
import useGetInstance from '../../../hooks/useGetInstance';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import useGetCompIdsFromDB from '../../../hooks/useGetCompIdsFromDB';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
  setView: (value: number | ((prevVar: number) => number)) => void;
}

const NicknameView: React.FC<Props> = ({ setView }) => {
  const [value, setValue] = useState<CompId>({ id: '', alias: '' });
  const [loading, setLoading] = useState<{
    state: boolean;
    success: boolean | null;
  }>({ state: false, success: null });
  const compId = useGetCompId();
  const instance = useGetInstance();
  const compIds = useGetCompIdsFromDB(instance);

  useEffect(() => {
    if (compId && compIds.length > 0) {
      const id = compIds.find((entry) => entry.id === compId);

      if (id) setValue(id);
    }
  }, [compId, compIds]);

  const handleChange: React.KeyboardEventHandler<HTMLInputElement> = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setValue((prev) => ({ ...prev, alias: e.target.value }));
  };

  const handleSave = async () => {
    if (instance && value.id.length > 0) {
      try {
        setLoading((prev) => ({ ...prev, state: true }));
        const controller = new AbortController();
        await updateAlias(instance, value, controller);
        setTimeout(() => {
          setLoading({ state: false, success: true });
        }, 1000);
      } catch (e) {
        setLoading({ state: false, success: false });
        console.error(`Error updating alias. ${value.alias} Error: ${e}`);
      }
    }
  };

  useEffect(() => {
    if (loading.success === true) {
      setTimeout(() => {
        setLoading((prev) => ({ ...prev, success: null }));
      }, 3000);
    }
  }, [loading]);

  const renderButtonContent = () => {
    if (loading.state) {
      return (
        <Rotate>
          <AutorenewIcon />
        </Rotate>
      );
    } else if (!loading.state && loading.success === true) {
      return <CheckIcon style={{ color: 'green' }} />;
    } else if (!loading.state && loading.success === false) {
      return <CancelIcon style={{ color: 'red' }} />;
    } else {
      return 'Save';
    }
  };
  return (
    <Container>
      <BackButton onClick={() => setView(4)}>
        <KeyboardArrowLeftIcon style={{ marginRight: '.5rem' }} />
        Back
      </BackButton>
      <SectionHeader style={{ borderTop: '1px solid rgb(209 213 219)' }}>
        Widget Nickname
      </SectionHeader>

      <InputContainer>
        <Input
          value={value.alias}
          onChange={handleChange}
          id={'nickname-input'}
          placeholder={'Enter nickname'}
        />
        <SaveButton
          loading={loading}
          aria-label={'Save'}
          onClick={handleSave}
          style={{ margin: '0' }}>
          {renderButtonContent()}
        </SaveButton>
      </InputContainer>
    </Container>
  );
};

export default NicknameView;
