import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cta, CtaList } from "../types";

interface CtaState {
  currentCtaId: string;
  ctas: CtaList | [];
  currentCta: Cta | null;
  previewCta: Cta;
  status: {
    message: string | undefined;
    type: "success" | "info" | "warning" | "error" | undefined;
  };
  instance: string;
  compId: string;
}

const initialState: CtaState = {
  currentCtaId: "",
  ctas: [],
  currentCta: {
    id: "",
    ownerId: "",
    compId: "",
    title: "",
    schedule: {
      start: 0,
      end: 0,
    },
    live: {
      elements: [],
      style: {
        desktop: {},

        mobile: {},
      },
      backgroundImg: {
        desktop: {
          src: "",
          top: 0,
          left: 0,
        },
        mobile: {
          src: "",
          top: 0,
          left: 0,
        },
      },
    },
    editor: {
      elements: [],
      style: {
        desktop: {},

        mobile: {},
      },
      backgroundImg: {
        desktop: {
          src: "",
          top: 0,
          left: 0,
        },
        mobile: {
          src: "",
          top: 0,
          left: 0,
        },
      },
    },
    thumbnailUrl: "",
    isActive: false,
    isEvergreen: false,
    createdAt: 0,
    updatedAt: 0,
    notes: "",
  },
  previewCta: {
    id: "",
    ownerId: "",
    compId: "",
    title: "",
    schedule: {
      start: 0,
      end: 0,
    },
    live: {
      elements: [],
      style: {
        desktop: {},

        mobile: {},
      },
      backgroundImg: {
        desktop: {
          src: "",
          top: 0,
          left: 0,
        },
        mobile: {
          src: "",
          top: 0,
          left: 0,
        },
      },
    },
    editor: {
      elements: [],
      style: {
        desktop: {},

        mobile: {},
      },
      backgroundImg: {
        desktop: {
          src: "",
          top: 0,
          left: 0,
        },
        mobile: {
          src: "",
          top: 0,
          left: 0,
        },
      },
    },
    thumbnailUrl: "",
    isActive: false,
    isEvergreen: false,
    createdAt: 0,
    updatedAt: 0,
    notes: "",
  },
  status: { message: undefined, type: undefined },
  instance:
    process.env.NODE_ENV === "production" ||
    process.env.REACT_APP_ENV === "staging"
      ? ""
      : (process.env.REACT_APP_INSTANCE_ID as string),
  compId:
    process.env.NODE_ENV === "production" ||
    process.env.REACT_APP_ENV === "staging"
      ? ""
      : (process.env.REACT_APP_COMP_ID as string),
};

export const ctaSlice = createSlice({
  name: "ctaSlice",
  initialState,
  reducers: {
    setPreviewCta: (state, action: PayloadAction<{ previewCta: Cta }>) => {
      state.previewCta = action.payload.previewCta;
    },
    setStatus: (
      state,
      action: PayloadAction<{
        message: string | undefined;
        type: "success" | "info" | "warning" | "error" | undefined;
      }>
    ) => {
      state.status.message = action.payload.message;
      state.status.type = action.payload.type;
    },
    setInstance: (state, action: PayloadAction<{ instance: string }>) => {
      if (action.payload.instance) {
        state.instance = action.payload.instance;
      }
    },
    setCompId: (state, action: PayloadAction<{ compId: string }>) => {
      if (action.payload.compId) {
        state.compId = action.payload.compId;
      }
    },
    setCurrentCta: (
      state,
      action: PayloadAction<{ currentCta: Cta | null }>
    ) => {
      const { currentCta } = action.payload;

      state.currentCta = currentCta;
    },
    setCtaList: (state, action: PayloadAction<{ ctaList: CtaList }>) => {
      state.ctas = action.payload.ctaList;
    },
    setCurrentCtaId: (state, action: PayloadAction<{ ctaId: string }>) => {
      if (action.payload.ctaId) {
        state.currentCtaId = action.payload.ctaId;
      }
    },
  },
});

export const {
  setStatus,
  setPreviewCta,
  setInstance,
  setCtaList,
  setCurrentCta,
  setCurrentCtaId,
  setCompId,
} = ctaSlice.actions;

export default ctaSlice.reducer;
