import styled from 'styled-components';

export const ErrorContainer = styled.div`
  padding: 0 0.5rem;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  margin-top: -1rem;
`;

export const ErrorStyles = styled.span`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.red500};
  font-weight: 500;
`;

export const IconStyles = styled.div`
  color: ${(props) => props.theme.color.red500};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
