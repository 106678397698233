import { CustomColors, Theme } from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  theme: Theme;
  custom: CustomColors;
  zoom: number;
}

const initialState: ThemeState = {
  theme: [
    { name: "color_11", value: "#FFFFFF", reference: "color-1" },
    { name: "color_12", value: "#E8E6E6", reference: "color-2" },
    { name: "color_13", value: "#C7C7C7", reference: "color-3" },
    { name: "color_14", value: "#757575", reference: "color-4" },
    { name: "color_15", value: "#000000", reference: "color-5" },
    { name: "color_16", value: "#4A9183", reference: "color-6" },
    { name: "color_17", value: "#35685E", reference: "color-7" },
    { name: "color_18", value: "#014235", reference: "color-8" },
    { name: "color_19", value: "#1D2927", reference: "color-9" },
    { name: "color_20", value: "#000000", reference: "color-10" },
    { name: "color_21", value: "#DCE3F5", reference: "color-11" },
    { name: "color_22", value: "#C0CBED", reference: "color-12" },
    { name: "color_23", value: "#97ADED", reference: "color-13" },
    { name: "color_24", value: "#4B5676", reference: "color-14" },
    { name: "color_25", value: "#262B3B", reference: "color-15" },
    { name: "color_26", value: "#E1C1BC", reference: "color-16" },
    { name: "color_27", value: "#C29992", reference: "color-17" },
    { name: "color_28", value: "#A45F53", reference: "color-18" },
    { name: "color_29", value: "#6D3F37", reference: "color-19" },
    { name: "color_30", value: "#37201C", reference: "color-20" },
    { name: "color_31", value: "#FFFDF7", reference: "color-21" },
    { name: "color_32", value: "#FAF5EB", reference: "color-22" },
    { name: "color_33", value: "#F4EBD7", reference: "color-23" },
    { name: "color_34", value: "#C3BCAC", reference: "color-24" },
    { name: "color_35", value: "#7A766C", reference: "color-25" },
  ],
  custom: [],
  zoom: 1,
};

export const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<{ themeColors: Theme }>) => {
      if (action.payload.themeColors.length > 0) {
        state.theme = [...action.payload.themeColors.slice(5)];
      }
    },
    setCustomColors: (
      state,
      action: PayloadAction<{ customColors: CustomColors }>
    ) => {
      state.custom = [...action.payload.customColors];
    },
    setZoom: (state, action: PayloadAction<{ zoom: number }>) => {
      const { zoom } = action.payload;
      let zoomValue = zoom;
      if (zoomValue > 1) zoomValue = 1;
      state.zoom = zoomValue;
    },
  },
});

export const { setTheme, setCustomColors, setZoom } = themeSlice.actions;

export default themeSlice.reducer;
