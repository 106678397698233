import styled from 'styled-components';
import { Pos } from '../../../types';

export const Container = styled.div<{ pos?: Pos }>`
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`;
