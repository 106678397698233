import React from 'react';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { redo, setEditingMode, undo } from '../../../redux/elementSlice';
import useElements from '../../../hooks/useElements';
import useGetEditingMode from '../../../hooks/useGetEditingMode';
import useGetCurrentCtaId from '../../../hooks/useGetCurrentCtaId';
import {
  PreviewButtonStyles,
  HeaderContainer,
  Separator,
  HeaderIcon,
  IconsContainer,
} from './Header.styles';
import MenuButton from '../MenuButton/MenuButton.component';
import { Wix } from '../../../util/Wix';
import useUnsavedStatus from '../../../hooks/useUnsavedStatus';
import { updateCtaInDB } from '../../../util/services';
import useGetCurrentCta from '../../../hooks/useGetCurrentCta';
import useGetInstance from '../../../hooks/useGetInstance';
import SavedStatus from '../SavedStatus/SavedStatus.component';
import useGetCompId from '../../../hooks/useGetCompId';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';

const Header = () => {
  const dispatch = useAppDispatch();

  const editingMode = useGetEditingMode();
  const elements = useElements();
  const ctaId = useGetCurrentCtaId();
  const currentCta = useGetCurrentCta();
  const instance = useGetInstance();
  const compId = useGetCompId();

  const handleUndo = () => {
    if (elements.past.length > 0) dispatch(undo());
  };

  const handleRedo = () => {
    if (elements.future.length > 0) dispatch(redo());
  };

  const handleSetDesktop = () => {
    dispatch(setEditingMode({ editingMode: 'desktop' }));
  };

  const handleSetMobile = () => {
    dispatch(setEditingMode({ editingMode: 'mobile' }));
  };

  const handlePreview = async () => {
    if (currentCta && compId)
      await updateCtaInDB(instance, compId, currentCta);

    Wix.Settings.refreshAppByCompIds(Wix.Utils.getOrigCompId(), {
      previewId: ctaId,
    });
    Wix.Settings.closeWindow({ target: 'ALL' });
  };

  return (
    <HeaderContainer data-testid='header'>
      <SavedStatus isUnsaved={useUnsavedStatus()} />
      <IconsContainer>
        <HeaderIcon onClick={handleUndo} aria-label='Undo'>
          <UndoIcon style={{ color: '#FFF', fontSize: '28px' }} />
        </HeaderIcon>
        <HeaderIcon onClick={handleRedo} aria-label='Redo'>
          <RedoIcon style={{ color: '#FFF', fontSize: '28px' }} />
        </HeaderIcon>
      </IconsContainer>
      <Separator />
      <IconsContainer>
        <HeaderIcon
          id='desktop-button'
          onClick={handleSetDesktop}
          editingMode={editingMode}
          aria-label='Desktop mode'>
          <DesktopMacOutlinedIcon style={{ color: '#FFF', fontSize: '28px' }} />
        </HeaderIcon>

        <HeaderIcon
          id='mobile-button'
          onClick={handleSetMobile}
          editingMode={editingMode}
          aria-label='Mobile'>
          <PhoneIphoneIcon style={{ color: '#FFF', fontSize: '28px' }} />
        </HeaderIcon>
      </IconsContainer>
      <MenuButton
        variant='primary'
        label='Preview'
        style={PreviewButtonStyles}
        onClick={handlePreview}
      />
    </HeaderContainer>
  );
};

export default Header;
