import React, { useState } from 'react';
import {
  AnnouncementModalContainer,
  Header,
  Headline,
  Footer,
} from '../AnnouncementModal/AnnouncementModal.styles';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../styles/theme';
import MenuButton from '../../DesignStudio/MenuButton/MenuButton.component';
import AnnouncementModalSelect from '../AnnouncementModalSelect/AnnouncementModalSelect.component';
import { createNewCTA } from '../../../util/services';
import { NEW_CTA } from '../../../enums';
import useGetInstance from '../../../hooks/useGetInstance';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { openCtaInEditorFromDashboard } from '../../../util/functions';

interface Props {
  closeModal: () => void;
}

const AnnouncementModal: React.FC<Props> = ({ closeModal }) => {
  const [value, setValue] = useState<string | null>(null);
  const instance = useGetInstance();
  const dispatch = useAppDispatch();

  const handleNewAnnouncement = async () => {
    let ctaId: string | void;
    if (value) {
      ctaId = await createNewCTA(
        instance,
        value,
        null,
        NEW_CTA,
        dispatch
      );

      if (ctaId) openCtaInEditorFromDashboard(ctaId, value, instance);
      closeModal();
    }
  };

  return (
    <AnnouncementModalContainer>
      <Header>
        <Headline>
          Which instance of Content Scheduler do you want to add this
          announcement to?
        </Headline>
        <MenuButton
          onClick={closeModal}
          aria-label={'close'}
          variant={'text'}
          style={{
            padding: '.2rem',
            height: '3.4rem',
            background: theme.color.gray100,
            border: 'none',
          }}
          icon={
            <CloseIcon
              style={{
                fontSize: '30px',
                color: theme.color.gray600,
              }}
            />
          }
        />
      </Header>
      <AnnouncementModalSelect setValue={setValue} value={value} />
      <Footer>
        <MenuButton label={'Cancel'} variant={'text'} onClick={closeModal} />
        <MenuButton
          label={'Create Announcement'}
          variant={'secondary'}
          onClick={handleNewAnnouncement}
        />
      </Footer>
    </AnnouncementModalContainer>
  );
};

export default AnnouncementModal;
