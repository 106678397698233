import React from 'react';
import useGetCtas from '../../../hooks/useGetCtas';
import CalendarMenuItem from '../CalendarMenuItem/CalendarMenuItem.component';
import {
  Section,
  SectionTitle,
  DividerContainer,
  CalendarMenuStyles,
} from './CalendarMenu.styles';
import useCategories from '../../../hooks/useCategories';
import useGetCompId from '../../../hooks/useGetCompId';

const CalendarMenu = () => {
  const ctaList = useGetCtas();
  const compId = useGetCompId();

  const [activeCtas, drafts, upcoming, expired] = useCategories(
    compId,
    ctaList
  );

  return (
    <CalendarMenuStyles data-testid='calendar-menu'>
      {activeCtas.filter((cta) => cta.isActive).length > 0 && (
        <Section>
          <DividerContainer>
            <SectionTitle>Active</SectionTitle>
          </DividerContainer>

          {activeCtas
            .filter((cta) => cta.isActive)
            .map((cta, i) => {
              return (
                <CalendarMenuItem
                  title={cta.title}
                  key={cta.id}
                  ctaId={cta.id}
                  testId={'calendar-menu-item'}
                />
              );
            })}
        </Section>
      )}
      {activeCtas.filter((cta) => cta.isEvergreen).length > 0 && (
        <Section>
          <DividerContainer>
            <SectionTitle>Evergreen</SectionTitle>
          </DividerContainer>

          {activeCtas
            .filter((cta) => cta.isEvergreen)
            .map((cta, i) => {
              return (
                <CalendarMenuItem
                  title={cta.title}
                  key={cta.id}
                  ctaId={cta.id}
                  testId={'calendar-menu-item'}
                />
              );
            })}
        </Section>
      )}
      {upcoming.length > 0 && (
        <Section>
          <DividerContainer>
            <SectionTitle>Upcoming</SectionTitle>
          </DividerContainer>
          {upcoming.map((cta, i) => {
            return (
              <CalendarMenuItem
                title={cta.title}
                key={cta.id}
                ctaId={cta.id}
                testId={'calendar-menu-item'}
              />
            );
          })}
        </Section>
      )}
      {drafts.length > 0 && (
        <Section>
          <DividerContainer>
            <SectionTitle>Drafts</SectionTitle>
          </DividerContainer>

          {drafts.map((cta, i) => {
            return (
              <CalendarMenuItem
                title={cta.title}
                key={cta.id}
                ctaId={cta.id}
                testId={'calendar-menu-item'}
              />
            );
          })}
        </Section>
      )}

      {expired.length > 0 && (
        <Section>
          <DividerContainer>
            <SectionTitle>Expired</SectionTitle>
          </DividerContainer>
          {expired.map((cta, i) => {
            return (
              <CalendarMenuItem
                title={cta.title}
                key={cta.id}
                ctaId={cta.id}
                testId={'calendar-menu-item'}
              />
            );
          })}
        </Section>
      )}
    </CalendarMenuStyles>
  );
};

export default CalendarMenu;
