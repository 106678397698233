import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  position: relative;
`;

export const OptionsLabel = styled.label`
  padding-left: 1.2rem;
`;

export const InputFormat = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const IconContainer = styled.button`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3rem;
  width: 2.6rem;
  border: none;
  border-radius: 0 2px 2px 0;
  background-color: ${({ theme }) => theme.color.btnPrimary};
  margin-left: -2.6rem;
  cursor: pointer;
  position: relative;
  z-index: 10;
`;

export const Options = styled.div`
  background-color: #fff;
  position: absolute;
  top: 70px;
  right: 0;
  border-radius: 5px;
  z-index: 10;
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.theme.shadow[2]};
`;

export const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Checkbox = styled.input``;
