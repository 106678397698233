import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import React from 'react';
import { Container } from './WarningIcon.styles';
import { Pos } from '../../../types';

interface Props {
  style?: React.CSSProperties;
  pos?: Pos;
}

const WarningIcon: React.FC<Props> = ({ style, pos }) => {
  return (
    <Container pos={pos} style={style}>
      <PriorityHighIcon style={{ color: '#FFF', fontSize: '12px' }} />
    </Container>
  );
};

export default WarningIcon;
