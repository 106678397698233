import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.button<{ showContextMenu?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  border: none;
  background: ${(props) =>
    props.showContextMenu ? props.theme.color.settings.blue200 : 'transparent'};
  &:hover {
    background: ${(props) => props.theme.color.settings.blue200};
  }
`;

export const Title = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CtaItemStyle = styled.div.attrs<{
  active?: string | null;
  ctaId: string;
}>((props) => ({
  style: {
    background:
      props.active === props.ctaId
        ? props.theme.color.settings.blue100
        : '#FFF',
  },
}))<{ active?: string | null; ctaId: string }>`
  width: 30rem;
  height: 6.3rem;
  border-bottom: 1px solid #ddd;
  color: #325166;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  cursor: pointer;
  position: relative;
`;
