import styled from 'styled-components';

export const CTAContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: ${(props) => props.theme.spacing[5]};
`;

export const Text = styled.h2`
  z-index: 1;
  font-size: 2rem;
  width: 70%;
  text-align: center;
`;

export const ResourceNotFoundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  z-index: 0;
`;
