import React, { useState } from 'react';
import { SidebarMenuItemStyle } from './SidebarMenuItem.styles';
import { handleOpenBillingPage } from '../../../util/functions';
import WarningIcon from '../WarningIcon/WarningIcon.component';
import DisabledInfoBubble from '../DisabledInfoBubble/DisabledInfoBubble.component';

interface Props {
  active: number;
  handleActive: Function;
  label: string;
  id: number;
  disabled: boolean;
}

const SidebarMenuItem: React.FC<Props> = ({
  active,
  handleActive,
  label,
  id,
  disabled,
}) => {
  const [showDisabledInfo, setShowDisabledInfo] = useState<boolean>(false);

  const handleClick = () => {
    if (label === 'Upgrade') {
      handleOpenBillingPage();
    } else {
      handleActive(id);
    }
  };

  const handleMouseOver = () => {
    if (disabled && id === 1) {
      setShowDisabledInfo(true);
    }
  };

  const handleMouseLeave = () => {
    if (disabled && id === 1) {
      setShowDisabledInfo(false);
    }
  };

  return (
    <SidebarMenuItemStyle
      disabled={disabled}
      data-testid='sidebar-menu-item'
      active={active}
      itemId={id}
      onClick={handleClick}
      label={label}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      >
      {showDisabledInfo && <DisabledInfoBubble style={{ top: 40, left: 0 }} />}
      {disabled && id === 1 && <WarningIcon />}
      {label}
    </SidebarMenuItemStyle>
  );
};

export default SidebarMenuItem;
