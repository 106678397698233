import React, { useState } from 'react';
import { Row, Main, Title } from './AnnouncementView.styles';
import MenuButton from '../../../DesignStudio/MenuButton/MenuButton.component';
import CalendarCard from '../../CalendarCard/CalendarCard.component';
import CarouselContainer from '../../CarouselContainer/CarouselContainer.component';
import { useAppDispatch } from '../../../../redux/reduxHooks';
import { NEW_CTA } from '../../../../enums';
import { createNewCTA } from '../../../../util/services';
import useGetInstance from '../../../../hooks/useGetInstance';
import Modal from '../../Modal/Modal.component';
import AnnouncementModal from '../../AnnouncementModal/AnnouncementModal.component';
import { openCtaInEditorFromDashboard } from '../../../../util/functions';
import useGetCompIdsFromDB from '../../../../hooks/useGetCompIdsFromDB';
import { CompId } from '../../../../types';
import useUser from '../../../../hooks/useUser';
import useDisableApp from '../../../../hooks/useDisableApp';
import UpgradeAppModal from '../../UpgradeAppModal/UpgradeAppModal.component';
import { useUpgradeModal } from './AnnouncementView.logic';

const AnnouncementView: React.FC = () => {
  const [showAnnouncementModal, setShowAnnouncementModal] =
    useState<boolean>(false);

  const instance = useGetInstance();
  const dispatch = useAppDispatch();
  const compIds = useGetCompIdsFromDB(instance);
  const user = useUser();
  const disabled = useDisableApp(user);
  const { showUpgradeModal, setShowUpgradeModal, setMessageConfirmed } =
    useUpgradeModal(disabled);

  const handleNewAnnouncement = async () => {
    let compId: CompId = { id: '', alias: '' };

      if (compIds.length === 1) {
        compId = compIds[0];
      } else if (compIds.length > 1) {
        setShowAnnouncementModal(true);
        return;
      }

      const ctaId = await createNewCTA(
        instance,
        compId.id,
        null,
        NEW_CTA,
        dispatch
      );

      if (ctaId) openCtaInEditorFromDashboard(ctaId, compId.id, instance);

  };

  return (
    <Main>
      <Row>
        <Title>My Content</Title>
        {disabled && showUpgradeModal && (
          <UpgradeAppModal
            setConfirmed={setMessageConfirmed}
            setShowModal={setShowUpgradeModal}
          />
        )}
        <MenuButton
          label='Create New Content'
          pill={true}
          isDisabled={disabled}
          variant={'primary'}
          showWarningIcon={disabled}
          onClick={handleNewAnnouncement}
          style={{ height: '4rem' }}
        />
      </Row>

      {compIds &&
        compIds.length > 0 &&
        compIds
          .slice(0)
          .reverse()
          .map((compId) => (
            <CarouselContainer
              key={compId.id}
              compId={compId.id}
              alias={compId.alias}
            />
          ))}
      <CalendarCard />

      {showAnnouncementModal && (
        <Modal
          closeModal={() => setShowAnnouncementModal(false)}
          children={
            <AnnouncementModal
              closeModal={() => setShowAnnouncementModal(false)}
            />
          }
        />
      )}
    </Main>
  );
};

export default AnnouncementView;
