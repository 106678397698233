import styled from 'styled-components';

export const SEHandle = styled.div<{ showHandle: boolean }>`
  display: ${(props) => (props.showHandle ? 'inline-block' : 'none')};
  position: absolute;
  height: 12px;
  width: 12px;
  border: 1px solid ${(props) => props.theme.color.gray500};
  border-radius: 3px;
  bottom: -5px;
  right: -5px;
  z-index: 99;
  cursor: nwse-resize;
  opacity: 1 !important;
  background: ${(props) => props.theme.color.settings.blue300};

  &:hover {
    background: ${(props) => props.theme.color.btnPrimaryHover};
  }
`;

export const SideHandle = styled.div<{ showHandle: boolean }>`
  display: ${(props) => (props.showHandle ? 'inline-block' : 'none')};
  position: absolute;
  height: 32px;
  width: 9px;
  border: 1px solid ${(props) => props.theme.color.gray500};
  border-radius: 5px;
  top: 50%;
  right: -4px;
  transform: translateY(-50%);
  z-index: 99;
  cursor: e-resize;
  opacity: 1 !important;
  background: ${(props) => props.theme.color.settings.blue300};

  &:hover {
    background: ${(props) => props.theme.color.btnPrimaryHover};
  }
`;
