import React from 'react';
import { Container } from './VideoModal.styles';

interface Props {
  iframe: React.ReactNode;
}

const VideoModal: React.FC<Props> = ({ iframe }) => {
  return <Container>{iframe}</Container>;
};

export default VideoModal;
