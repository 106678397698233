import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  CTAContainer,
  Text,
  ResourceNotFoundImage,
  InnerContainer,
} from './EditorWidget.styles';
import useSetInstance from '../../hooks/useSetInstance';
import useGetActiveCta from '../../hooks/useGetActiveCta';
import useGetPreviewCta from '../../hooks/useGetPreviewCta';
import CTA from '../../components/Widget/CTA/CTA.component';
import useLoading from '../../hooks/useLoading';
import { Wix } from '../../util/Wix';
import useAppendFontScripts from '../../hooks/useAppendFontScripts';
import ResourceNotFound from '../../assets/resource-not-found.svg';
import { Cta } from '../../types';
import useGetInstance from '../../hooks/useGetInstance';
import useGetViewMode from '../../hooks/useGetViewMode';
import useSetCompId from '../../hooks/useSetCompId';
import useGetCompId from '../../hooks/useGetCompId';
import { useLocation } from 'react-router-dom';
import useGetOrigCompId from '../../hooks/useGetOrigCompId';
import useAddCompId from '../../hooks/useAddCompId';
import useDuplicateWidget from '../../hooks/useDuplicateWidget';
import useDuplicateInstance from '../../hooks/useDuplicateInstance';
import { updateSchedule } from '../../util/functions';
import useSetUser from '../../hooks/useSetUser';
import { updateLiveData, updateThemeColors } from '../../util/services';
import { useAppDispatch } from '../../redux/reduxHooks';
import useDisableApp from '../../hooks/useDisableApp';
import useUser from '../../hooks/useUser';
import UpgradeAppTooltip from '../../components/Dashboard/UpgradeAppTooltip/UpgradeAppTooltip.component';
import useSetPlanId from '../../hooks/useSetPlanId';
import SkeletonComponent from '../../components/General/SkeletonComponent/SkeletonComponent.component';
import useForceUpdate from '../../hooks/useForceUpdate';
import useSetCompSettings from '../../hooks/useSetCompSettings';

interface Props {}

const EditorWidget: React.FC<Props> = () => {
  const media = 'desktop';
  const [currentCta, setCurrentCta] = useState<Cta | null>(null);
  const [wixEditMode, setWixEditMode] = useState<string>('editor');
  const viewMode = useGetViewMode();
  const dispatch = useAppDispatch();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setWixEditMode(Wix.Utils.getViewMode());
  }, []);

  useSetInstance();
  useSetCompId();
  useAppendFontScripts(currentCta as Cta);
  const location = useLocation();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const origCompId = useGetOrigCompId();
  const loading = useLoading();
  const activeCta = useGetActiveCta(instance, compId);
  const previewCta = useGetPreviewCta(instance, compId, location);
  useSetUser(instance);
  const user = useUser();
  useAddCompId(instance, compId, origCompId, viewMode);
  useDuplicateWidget(instance, compId, origCompId, viewMode);
  useDuplicateInstance(instance, compId, origCompId, viewMode);
  useSetPlanId(instance);
  useSetCompSettings(instance, compId);
  const disabled = useDisableApp(user);
  // Store currentCta (preview or active) in state
  useLayoutEffect(() => {
    if (previewCta.id) {
      setCurrentCta(previewCta);
    } else if (activeCta.id) {
      setCurrentCta(activeCta);
    }
  }, [previewCta, activeCta]);

  const publishEvent = useCallback(async () => {
    if (instance) {
      await updateSchedule(instance);
      await updateLiveData(instance);
      forceUpdate();
    }
  }, [forceUpdate, instance]);

  useEffect(() => {
    Wix.addEventListener(Wix.Events.SITE_PUBLISHED, publishEvent);

    return () => {
      Wix.removeEventListener(Wix.Events.SITE_PUBLISHED, publishEvent);
    };
  }, [instance, publishEvent]);

  useEffect(() => {
    const controller = new AbortController();

    Wix.addEventListener(Wix.Events.THEME_CHANGE, (event) => {
        updateThemeColors(event.siteColors, instance, controller);
    });
  }, [instance, dispatch]);

  if (loading) {
    return <SkeletonComponent />;
  } else if (disabled) {
    return (
      <CTAContainer data-testid='widget'>
        <UpgradeAppTooltip text={'Sign up for a paid plan to use this app'} />
        <InnerContainer>
          <Text>Oops! Your subscription has expired!</Text>
          <ResourceNotFoundImage
            src={ResourceNotFound}
            alt='No resource found'
          />
        </InnerContainer>
      </CTAContainer>
    );
  } else {
    if (currentCta?.id) {
      return (
        <CTA currentCta={currentCta} wixEditMode={wixEditMode} media={media} />
      );
    } else {
      return (
        <CTAContainer data-testid='widget'>
          <InnerContainer>
            <Text>
              Oops! You don't have anything scheduled. Double-click this window
              to open the settings panel and create new content!
            </Text>
            <ResourceNotFoundImage
              src={ResourceNotFound}
              alt='No resource found'
            />
          </InnerContainer>
        </CTAContainer>
      );
    }
  }
};

export default EditorWidget;
