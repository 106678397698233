import styled from 'styled-components';
import { ThemeColor } from '../../../types';

export const PopoverContainer = styled.div<{
  label?: string;
  top: number;
}>`
  width: 250px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: ${(props) => props.theme.shadow[2]};
  position: absolute;
  top: ${(props) => props.top}px;
  left: 0;
  z-index: 10;
  user-select: none;
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export const ThemeSwatchContainer = styled.div`
  padding: 2rem;
`;

export const CustomSwatchContainer = styled.div`
  padding: 2rem;
  padding-top: 0.8rem;
`;

export const SwatchGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;

  & > *:nth-child(5n + 5) {
    border-radius: 0 2px 2px 0;
  }

  & > *:nth-child(5n + 1) {
    border-radius: 2px 0 0 2px;
  }
`;

export const CustomSwatchGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.6rem;
`;

export const ThemeColorStyles = styled.div<{
  color: string;
  selectedColor: ThemeColor | { reference: null };
}>`
  height: 20px;
  width: 20%;

  background: ${(props) => props.color};
  border: ${(props) =>
    props.color === '#FFFFFF' || props.color === 'rgba(255, 255, 255, 1)'
      ? '1px solid #eee'
      : '1px solid transparent'};

  cursor: pointer;

  transform: ${(props) =>
    props.selectedColor.reference === props.id ? 'scaleY(1.2)' : ''};

  &:hover {
    transform: scaleY(1.2);

    box-shadow: ${(props) => props.theme.shadow[2]};
  }
`;

export const Title = styled.span`
  margin-bottom: 0.8rem;
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
`;

export const CustomColorButton = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: 3px solid white;
  background: ${(props) => props.theme.color.gray200};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: ${(props) => props.theme.color.btnPrimary};
  }
`;

export const PickerContainer = styled.div`
  position: absolute;
  top: 36%;
  left: 0;
  z-index: 100;
  width: 200px;
  background: #fff;
  box-shadow: ${(props) => props.theme.shadow[2]};
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PickerStyle = styled.div.attrs<{ background: string }>(
  (props) => ({
    style: {
      background: props.background,
    },
  })
)<{ background: string; showColorPicker: boolean }>`
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.color.gray300};
  cursor: pointer;

  outline: ${(props) =>
    props.showColorPicker ? `2px solid ${props.theme.color.btnPrimary}` : ''};
`;
