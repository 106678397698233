import styled from 'styled-components';

export const StageStyles = styled.div<{ view: number }>`
  height: calc(100vh - 4.8rem);
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: ${(props) => (props.view === 9 ? 'auto' : 'hidden')};
`;

export const BackdropStyles = styled.div`
  background-color: ${({ theme }) => theme.color.backdrop};
  height: calc(100% - 4rem);
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CalendarContainer = styled.div`
  font-size: 1.6rem;
  width: 100%;
  height: 100%;
  padding: 5rem;
  color: ${({ theme }) => theme.color.gray900};
  position: relative;
  z-index: 0;
`;
