import axios from "axios";
import { setCtaList } from "../redux/ctaSlice";
import { logError, setAlert } from "./functions";
import {
  ClientInstance,
  CompId,
  Cta,
  CtaUpdate,
  CustomColors,
  PlanId,
  ScheduleList,
  Theme,
  ThemeColors,
} from "../types";

// Pass compId to get all CTA's for that compId
// Omit compId to get all CTA's for all compIds
export const getAllCtasFromDB = async (
  instance: string,
  controller?: AbortController,
  compId?: string
): Promise<Cta[] | void> => {
    try {
      let response;
      if (instance) {
        if (controller) {
          response = await axios.post(
            "/api/cta/all",
            {
              instance,
              compId,

            },
            {
              signal: controller.signal,
            }
          );
        } else {
          response = await axios.post("/api/cta/all", {
            instance,
            compId,

          });
        }
      }

      return response.data.ctaList;
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message === "canceled") {
          return;
        } else {
          console.log(error.message);
        }
      }
    }

};

export const getActiveCtaFromDB = async (
  instance: string,
  compId: string,
  controller?: AbortController
): Promise<Cta | false> => {
  if (instance && compId) {
    try {
      let response;
      if (controller) {
        response = await axios.post(
          "/api/cta/get-active-or-evergreen",
          {
            instance,
            compId,
          },
          { signal: controller.signal }
        );
      } else {
        response = await axios.post("/api/cta/get-active-or-evergreen", {
          instance,
          compId,
        });
      }

      return response.data.cta;
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (!error.message.match(/404/gi)) console.error(error.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const getEvergreenCta = async (
  instance: string,
  compId: string,
  controller?: AbortController
): Promise<Cta | false> => {
  if (instance && compId) {
    try {
      let response;
      if (controller) {
        response = await axios.post(
          "/api/cta/get-evergreen",
          {
            instance,
            compId,
          },
          { signal: controller.signal }
        );
      } else {
        response = await axios.post("/api/cta/get-evergreen", {
          instance,
          compId,
        });
      }

      return response.data.cta;
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const addCtaToDB = async (
  instance: string,
  compId: string,
  ctaData: object,
  controller?: AbortController
): Promise<{ ctaId: string } | false> => {
  if (instance && compId && ctaData) {
    try {
      let result;
      if (controller) {
        result = await axios.post(
          "/api/cta",
          {
            instance,
            ctaData,
            compId,
          },
          { signal: controller.signal }
        );
      } else {
        result = await axios.post("/api/cta", {
          instance,
          ctaData,
          compId,
        });
      }

      return result.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const updateCtaInDB = async (
  instance: string,
  compId: string,
  currentCta: CtaUpdate,
  controller?: AbortController
): Promise<boolean> => {
  if (instance && compId && currentCta) {
    try {
      if (controller) {
        await axios.put(
          "/api/cta/update/",
          {
            instance,
            id: currentCta.id,
            currentCta,
            compId,
          },
          { signal: controller.signal }
        );
      } else {
        await axios.put("/api/cta/update/", {
          instance,
          id: currentCta.id,
          currentCta,
          compId,
        });
      }

      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const updateLiveData = async (
  instance: string,
) => {
  if (instance) {
    try {
      await axios.put("/api/cta/update-live", {
        instance,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }
};

export const updateCtaScheduleInDB = async (
  instance: string,
  scheduleList: ScheduleList,
  controller?: AbortController
): Promise<boolean> => {
  if (instance && scheduleList) {
    try {
      if (controller) {
        await axios.put(
          "/api/cta/update-schedule/",
          {
            instance,
            scheduleList,
          },
          { signal: controller.signal }
        );
        return true;
      } else {
        await axios.put("/api/cta/update-schedule/", {
          instance,
          scheduleList,
        });
        return true;
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const setEvergreenInDb = async (
  instance: string,
  compId: string,
  ctaId: string,
  controller?: AbortController
): Promise<void> => {
  if (instance && compId && ctaId) {
    try {
      if (controller) {
        await axios.put(
          "/api/cta/evergreen",
          {
            instance,
            compId,
            id: ctaId,
          },
          { signal: controller.signal }
        );
      } else {
        await axios.put("/api/cta/evergreen", {
          instance,
          compId,
          id: ctaId,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }
};

export const deleteCta = async (
  instance: string,
  compId: string,
  ctaId: string
): Promise<void> => {
  if (instance && compId && ctaId) {
    try {
      await axios.delete("/api/cta/delete/", {
        data: {
          instance,
          id: ctaId,
          compId,
        },
      });
    } catch (error) {
      logError(error);
      return;
    }

    try {
      await axios.put("/api/instance/delete-cta/", {
        instance,
        id: ctaId,
        compId,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }
};

export const createNewCTA = async (
  instance: string,
  compId: string,
  currentCta: Cta | null = null,
  newCta: Cta,
  dispatch: Function,
  navigate: Function | null = null
): Promise<string | void> => {
  // save user data
  if (currentCta && instance && compId) {
    try {
      await updateCtaInDB(instance, compId, currentCta);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setAlert(
          "Oops! There was an error saving your data, so we aborted creating a new CTA. Please try again in a few moments.",
          "warning",
          dispatch
        );
      }
    }
  }

  if (newCta && instance && compId) {
    // create new cta in database
    try {
      const result = await addCtaToDB(instance, compId, newCta);
      setAlert("Congrats! You've created a new design!", "success", dispatch);

      let ctaList = await getAllCtasFromDB(
        instance,
        undefined,
        compId
      );
      if (ctaList) {
        dispatch(setCtaList({ ctaList }));
      } else {
        setTimeout(async () => {
          ctaList = await getAllCtasFromDB(
            instance,
            undefined,
            compId
          );
          if (ctaList) {
            dispatch(setCtaList({ ctaList }));
          }
        }, 1000);
      }

      if (navigate) {
        if (result !== false) {
          navigate(`/design?instance=${instance}&cta-id=${result.ctaId}`);
        }
      }

      if (result) {
        return result.ctaId;
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
        setAlert(
          "Oops! There was an error creating a new CTA. Please try again in a few moments.",
          "success",
          dispatch
        );
      }
    }
  }
};

export const getCtaById = async (
  instance: string,
  compId: string,
  ctaId: string,
  controller?: AbortController
): Promise<Cta | false> => {
  if (instance && compId && ctaId) {
    try {
      let response;

      if (controller) {
        response = await axios.post(
          "/api/cta/get-cta",
          {
            instance,
            compId,
            ctaId,
          },
          { signal: controller.signal }
        );
      } else {
        response = await axios.post("/api/cta/get-cta", {
          instance,
          compId,
          ctaId,
        });
      }

      return response.data.cta;
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
      console.error("Error getting cta by ID");
    }
  }
  return false;
};

export const addCustomColor = async (
  color: { value: string; id: string },
  instance: string,
): Promise<void> => {
  if (instance && color)
    try {
      await axios.post("/api/instance/add-custom-color", {
        instance,
        color,
      });
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
};

export const deleteCustomColor = async (
  id: string,
  instance: string,
): Promise<void> => {
  if (instance && id)
    try {
      await axios.put("/api/instance/delete-custom-color", {
        instance,
        id,
      });
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
};

export const updateThemeColors = async (
  themeColors: ThemeColors,
  instance: string,
  controller?: AbortController
): Promise<void> => {
  if (instance && themeColors ) {
    try {
      if (controller) {
        await axios.post(
          "/api/instance/update-theme-colors",
          {
            instance,
            themeColors,
          },
          { signal: controller.signal }
        );
      } else {
        await axios.post("/api/instance/update-theme-colors", {
          instance,
          themeColors,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
  }
};

export const getThemeColors = async (
  instance: string,
  controller?: AbortController
): Promise<{ data: Theme } | void> => {
  if (instance) {
    try {
      if (controller) {
        return await axios.post(
          "/api/instance/get-theme-colors",
          {
            instance,
          },
          { signal: controller.signal }
        );
      } else {
        return await axios.post("/api/instance/get-theme-colors", {
          instance,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message === "canceled") return;
        console.error(error.message);
      }
    }
  }
};

export const getCustomColors = async (
  instance: string,
  controller?: AbortController
): Promise<{ data: CustomColors } | void> => {
  if (instance) {
    try {
      if (controller) {
        return await axios.post(
          "/api/instance/get-custom-colors",
          {
            instance,
          },
          { signal: controller.signal }
        );
      } else {
        return await axios.post("/api/instance/get-custom-colors", {
          instance,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message === "canceled") return;
        console.error(error.message);
      }
    }
  }
};

export const addCompIdToInstance = async (
  instance: string,
  controller: AbortController,
  compId: string
): Promise<void> => {
  if (instance && compId)
    try {
      await axios.post(
        "/api/instance/add-compid",
        { instance, compId },
        { signal: controller.signal }
      );
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
};

export const getCompIds = async (
  instance: string,
  controller?: AbortController
) => {
  try {
    let response;
    if (instance) {
      if (controller) {
        response = await axios.post(
          "/api/instance/compIds",
          {
            instance,
          },
          { signal: controller.signal }
        );
      } else {
        response = await axios.post("/api/instance/compIds", {
          instance,
        });
      }
    }

    return response.data;
  } catch (error: unknown) {
    if (error instanceof Error && error.message !== "canceled")
      console.error(error.message);
    console.error("Error fetching compIds");
  }
};

export const duplicateWidget = async (
  instance: string,
  controller: AbortController,
  compId: string,
  origCompId: string
): Promise<void> => {
  if (instance && compId && origCompId)
    try {
      await axios.post(
        "/api/instance/duplicate-widget",
        { instance, compId, origCompId },
        { signal: controller.signal }
      );
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
};

export const duplicateInstance = async (
  instance: string,
  compId: string,
  controller: AbortController
): Promise<void> => {
  if (instance && compId)
    try {
      await axios.post(
        "/api/instance/duplicate-instance",
        { instance, compId },
        { signal: controller.signal }
      );
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
};

export const updateAlias = async (
  instance: string,
  compId: CompId,
  controller: AbortController
) => {
  if (instance && compId )
    try {
      await axios.post(
        "/api/instance/update-alias",
        { instance, compId},
        { signal: controller.signal }
      );
    } catch (error: unknown) {
      if (error instanceof Error && error.message !== "canceled")
        console.error(error.message);
    }
};

export const getClientInstance = async (
  instance: string,
  controller: AbortController
): Promise<ClientInstance | null> => {
  let result = null;
  if (instance && controller)
    try {
      result = await axios.post(
        "/auth/client-instance",
        { instance },
        { signal: controller.signal }
      );
    } catch (e) {
      console.error(`Error verifying user plan: ${e}`);
    }
  return result;
};

export const getUser = async (
  instance: string,
  controller: AbortController
) => {
  if (instance) {
    try {
      return await axios.post(
        "/api/instance/get-instance",
        { instance },
        { signal: controller.signal }
      );
    } catch (e) {
      console.error(`Error getting user: ${e}`);
    }
  }
};

export const getPlanId = async (
  instance: string,
  controller
): Promise<PlanId | undefined> => {
  if (instance && controller)
    try {
      const result = await axios.post(
        "/api/instance/get-plan-id",
        { instance },
        { signal: controller.signal }
      );
      return result.data;
    } catch (e) {
      console.error(`Error getting planId: ${e}`);
    }
};


export const verifyToken = async (token): Promise<{ data: string | null }> => {
  if (token) {
    try {
      return await axios.post("/auth/verify-token", { token });
    } catch (e: unknown) {
      if (e instanceof Error)
        console.error("Error verifying token: ", e.message);

      return { data: null };
    }
  } else {
    return { data: null };
  }
};

