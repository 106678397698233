import styled from 'styled-components';
import { Theme } from '../../../types';

export const ThemeBtnOne = styled.button<{ themeValues: Theme }>`
  height: 4rem;
  width: 14.2rem;
  background: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-5');
    if (col) return col.value;
  }};
  color: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-1');
    if (col) return col.value;
  }};
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-5');
      if (col) return col.value;
    }};
  transition: all 200ms ease-in-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-2');
      if (col) return col.value;
    }};
    color: ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-5');
      if (col) return col.value;
    }};
  }
`;

export const ThemeBtnTwo = styled.button<{ themeValues: Theme }>`
  height: 4rem;
  width: 14.2rem;
  background: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-2');
    if (col) return col.value;
  }};
  color: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-5');
    if (col) return col.value;
  }};
  font-size: 1.6rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  user-select: none;
  border: none;

  &:hover {
    color: ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-4');
      if (col) return col.value;
    }};
  }
`;

export const ThemeBtnThree = styled.button<{ themeValues: Theme }>`
  height: 4rem;
  width: 14.2rem;
  background: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-2');
    if (col) return col.value;
  }};
  color: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-8');
    if (col) return col.value;
  }};
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  border: 1px solid
    ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-8');
      if (col) return col.value;
    }};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-2');
      if (col) return col.value;
    }};
    background: ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-8');
      if (col) return col.value;
    }};
  }
`;

export const ThemeBtnFour = styled.button<{ themeValues: Theme }>`
  height: 4rem;
  width: 14.2rem;
  background: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-8');
    if (col) return col.value;
  }};
  color: ${({ themeValues }) => {
    const col = themeValues.find((val) => val.reference === 'color-2');
    if (col) return col.value;
  }};
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  border: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${({ themeValues }) => {
      const col = themeValues.find((val) => val.reference === 'color-7');
      if (col) return col.value;
    }};
  }
`;
