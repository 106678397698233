import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const Message = styled.p`
  font-size: 2rem;
  font-weight: bold;

  text-align: center;
  width: 100%;
  z-index: 2;

  @media screen and (min-width: 1100px) {
    min-width: 45rem;
    max-width: 45rem;
  }
`;
