import { Container, BackgroundImageStyles } from './BackgroundImage.styles';
import { BackgroundImg, Style } from '../../../types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import useZoom from '../../../hooks/useZoom';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { getImageStyles } from '../../../util/functions';
import useCurrentComponent from '../../../hooks/useCurrentComponent';
import { setActiveSidebarView } from '../../../redux/sidebarViewSlice';

interface Props {
  opacity: number | undefined;
  elStyle: Style;
  img: BackgroundImg;
  style?: React.CSSProperties;
  media: 'desktop' | 'mobile';
}

const BackgroundImage: React.FC<Props> = ({
  opacity,
  elStyle,
  img,
  style,
  media,
}) => {
  const [image, setImage] = useState<{
    src: string;
    top: number;
    left: number;
  }>({ src: '', top: 0, left: 0 });

  const zoom = useZoom();
  const nodeRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();
  const currentComponent = useCurrentComponent();

  const handleMouseDown = () => {
    if (currentComponent.type !== 'cta') {
      dispatch(setActiveSidebarView({ id: 5 }));
    }
  };

  useLayoutEffect(() => {
    if (media === 'desktop') {
      setImage(img.desktop);
    } else if (media === 'mobile') {
      setImage(img.mobile);
    }
  }, [img, media]);

  return (
    <Draggable
      disabled={true}
      nodeRef={nodeRef}
      bounds={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      scale={zoom}
      onMouseDown={handleMouseDown}>
      <Container
        ref={nodeRef}
        style={{
          ...(getImageStyles(elStyle, style?.transform) as React.CSSProperties),
          height: '100%',
          width: '100%',
        }}>
        <BackgroundImageStyles
          draggable={false}
          ref={imageRef}
          opacity={opacity}
          elStyle={elStyle}
          src={image.src}
          media={media}
        />
      </Container>
    </Draggable>
  );
};

export default BackgroundImage;
