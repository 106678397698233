import { useAppDispatch } from "../redux/reduxHooks";
import { setCompId } from "../redux/ctaSlice";
import { Wix } from "../util/Wix";
import { useEffect } from "react";

const useSetCompId = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      (process.env.NODE_ENV === "production" ||
        process.env.REACT_APP_ENV === "staging") &&
      (window.location.pathname.match(/widget/gi) ||
        window.location.pathname.match(/mobile/gi))
    ) {
      dispatch(setCompId({ compId: Wix.Utils.getCompId() }));
    } else if (
      (process.env.NODE_ENV === "production" ||
        process.env.REACT_APP_ENV === "staging") &&
      !window.location.pathname.match(/widget/gi)
    ) {
      dispatch(setCompId({ compId: Wix.Utils.getOrigCompId() }));
    } else {
      dispatch(setCompId({ compId: process.env.REACT_APP_COMP_ID as string }));
    }
  }, [dispatch]);
};

export default useSetCompId;
