import React, { useLayoutEffect, useState } from 'react';
import { Container, Text, LinkText } from './UpgradeInfo.styles';
import {
  daysRemainingOfTrial,
  handleOpenBillingPage,
} from '../../../util/functions';
import useUser from '../../../hooks/useUser';
import Button from '../Button/Button.component';
import usePlanId from '../../../hooks/usePlanId';

const UpgradeInfo = () => {
  const planId = usePlanId();
  const user = useUser();
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (user?.trialExpiration) {
      const result = daysRemainingOfTrial(user?.trialExpiration);
      if (result) {
        setDaysRemaining(result);
      }
    }
  }, [user]);

  const renderInfo = () => {
    if (planId === null && daysRemaining !== null) {
      return (
        <>
          {daysRemaining >= 0 ? (
            <Text>{daysRemaining} days remaining of free trial.</Text>
          ) : (
            <Text>Your trial has expired. </Text>
          )}
          <Button
            label='Upgrade Now'
            variant='tertiary'
            onClick={handleOpenBillingPage}
          />
        </>
      );
    } else if (planId === 'basic') {
      return (
        <>
          <LinkText onClick={handleOpenBillingPage}>
            Get more templates and unlimited scheduled content!
          </LinkText>
          <Button
            label='Upgrade Now'
            variant='tertiary'
            onClick={handleOpenBillingPage}
          />
        </>
      );
    }
  };

  return <Container>{renderInfo()}</Container>;
};

export default UpgradeInfo;
