import styled from 'styled-components';
import { Style } from '../../../types';

export const Container = styled.div``;

export const BackgroundImageStyles = styled.img.attrs<{
  elStyle: Style;
  opacity: number | undefined;
  src: string;
  media: 'desktop' | 'mobile';
}>((props) => ({
  style: {
    opacity: typeof props.opacity === 'number' ? props.opacity : 1,
  },
  src: props.src,
}))<{
  elStyle: Style;
  opacity: number | undefined;
  media: 'desktop' | 'mobile';
}>`
  z-index: 1;
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;
