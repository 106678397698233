import React from 'react';
import {
  Container,
  Iframe,
  Section,
  Title,
  Text,
  ImageContainer,
  Image,
} from './SupportView.styles';
import Laptop from '../../../../assets/laptopworker.png';

function SupportView() {
  return (
    <Container>
      <Iframe
        id='zsfeedbackFrame'
        name='zsfeedbackFrame'
        src='https://desk.zoho.com/support/fbw?formType=AdvancedWebForm&fbwId=edbsn0d841b2e5d66e0b456ff723f5fb3a195633433456a8ba972cfa6fd7ec44ab430&xnQsjsdp=edbsn63b0015781c05a4c3f753175b280cc6c&mode=showNewWidget&displayType=iframe'></Iframe>
      <Section>
        <Title>Still need help?</Title>
        <Text>
          You can always contact us at{' '}
          <a href={'mailto:support@oceanapps.com'}>support@oceanapps.com</a>.
          We'll get back to you as soon as possible.
        </Text>
        <ImageContainer>
          <Image src={Laptop} alt={'Person working on laptop'} />
        </ImageContainer>
      </Section>
    </Container>
  );
}

export default SupportView;
