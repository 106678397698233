import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[6]};

  @media screen and (min-width: 900px) {
    justify-content: space-between;
  }
`;

export const Main = styled.div`
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing[8]};
`;

export const Title = styled.h2`
  font-size: 3.6rem;
  font-weight: bold;
  color: ${(props) => props.theme.color.blue900};
  margin-bottom: ${(props) => props.theme.spacing[4]};

  @media screen and (min-width: 900px) {
    margin-bottom: 0;
  }
`;
