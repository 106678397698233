import { useEffect, useState } from "react";
import { EditingMode, Element, Style } from "../types";
import useGetInstance from "./useGetInstance";
import useGetCompId from "./useGetCompId";
import useGetCompSettings from "./useGetCompSettings";
import useBuildCompSettings from "./useBuildCompSettings";

const useSetElStyle = (canvas: Element, editingMode: EditingMode) => {
  const [elStyle, setElStyle] = useState<Style>({});
  const instance = useGetInstance();
  const compId = useGetCompId();
  const compSettingsVals = useGetCompSettings(instance, compId);
  const compSettings = useBuildCompSettings(compSettingsVals);

  useEffect(() => {
    if (canvas && Object.keys(canvas).length > 0 && editingMode) {
      switch (editingMode) {
        case "desktop":
          if (
            compSettings.height &&
            compSettings.width &&
            compSettings.boxShadow
          ) {
            setElStyle((prev) => ({
              ...prev,
              ...(canvas.style.desktop as Style),
              height: { value: compSettings.height },
              width: { value: compSettings.width },
              boxShadow: { value: compSettings.boxShadow, default: true },
            }));
          } else {
            setElStyle((prev) => ({
              ...prev,
              ...(canvas.style.desktop as Style),
            }));
          }

          break;

        case "mobile":
          if (compSettings.boxShadow) {
            setElStyle((prev) => ({
              ...prev,
              ...(canvas.style.mobile as Style),
              boxShadow: { value: compSettings.boxShadow, default: true },
            }));
          } else {
            setElStyle((prev) => ({
              ...prev,
              ...(canvas.style.mobile as Style),
            }));
          }

          break;
        default:
          break;
      }
    }
  }, [canvas, editingMode, compSettings]);

  return elStyle;
};

export default useSetElStyle;
