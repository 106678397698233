import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CTAContainer, InnerContainer } from './CTA.styles';
import BackgroundColor from '../../DesignStudio/BackgroundColor/BackgroundColor.component';
import { buttonMap, imageMap, shapeMap, svgMap, typographyMap } from '../util';
import { Cta } from '../../../types';
import useGetViewMode from '../../../hooks/useGetViewMode';
import useWidthRatio from '../../../hooks/useWidthRatio';
import BackgroundImage from '../BackgroundImage/BackgroundImage.component';
import { Wix } from '../../../util/Wix';
import useDisableApp from '../../../hooks/useDisableApp';
import useUser from '../../../hooks/useUser';
import Watermark from '../../DesignStudio/Watermark/Watermark.component';
import useCanvasStyle from '../../../hooks/useCanvasStyle';

interface Props {
  media: 'desktop' | 'mobile';
  currentCta: Cta;
  wixEditMode?: string;
  category?: 'active' | 'drafts' | 'upcoming' | 'expired';
  preview?: boolean;
}

const CTA: React.FC<Props> = ({
  preview,
  media,
  currentCta,
  wixEditMode,
  category,
}) => {
  const [parentWidth, setParentWidth] = useState<number>(0);
console.log('currentCta: ', currentCta)
  const viewMode = useGetViewMode();
  const user = useUser();
  const disabled = useDisableApp(user);
  const scale = useWidthRatio(parentWidth);
  const ctaStyle = useCanvasStyle(category, currentCta, preview);

  const live = () => {
    console.log('typography', currentCta)
    return (
      <>
        {currentCta?.live?.elements &&
          currentCta.live.elements.length > 0 &&
          currentCta.live.elements
            .filter((el) => el.type === 'typography')
            .map((el) => typographyMap(el, media))}

        {currentCta?.live?.elements &&
          currentCta.live.elements.length > 0 &&
          currentCta.live.elements
            .filter((el) => el.type === 'button')
            .map((el) => buttonMap(el, media))}

        {currentCta?.live?.elements &&
          currentCta.live.elements.length > 0 &&
          currentCta.live.elements
            .filter((el) => el.type === 'image')
            .map((el) => imageMap(el, media))}

        {currentCta?.live?.elements &&
          currentCta.live.elements.length > 0 &&
          currentCta.live.elements
            .filter((el) => el.type === 'imageSvg')
            .map((el) => svgMap(el, media))}

        {currentCta?.live?.elements &&
          currentCta.live.elements.length > 0 &&
          currentCta.live.elements
            .filter((el) => el.type === 'rawSvg')
            .map((el) => shapeMap(el, media, true))}
      </>
    );
  };

  const editor = () => {
    return (
      <>
        {currentCta?.editor?.elements &&
          currentCta.editor.elements.length > 0 &&
          currentCta.editor.elements
            .filter((el) => el.type === 'typography')
            .map((el) => typographyMap(el, media))}
        {currentCta?.editor?.elements &&
          currentCta.editor.elements.length > 0 &&
          currentCta.editor.elements
            .filter((el) => el.type === 'button')
            .map((el) => buttonMap(el, media))}
        {currentCta?.editor?.elements &&
          currentCta.editor.elements.length > 0 &&
          currentCta.editor.elements
            .filter((el) => el.type === 'image')
            .map((el) => imageMap(el, media))}
        {currentCta?.editor?.elements &&
          currentCta.editor.elements.length > 0 &&
          currentCta.editor.elements
            .filter((el) => el.type === 'imageSvg')
            .map((el) => svgMap(el, media))}
        {currentCta?.editor?.elements &&
          currentCta.editor.elements.length > 0 &&
          currentCta.editor.elements
            .filter((el) => el.type === 'rawSvg')
            .map((el) => shapeMap(el, media, true))}
      </>
    );
  };

  useEffect(() => {
    if (ctaStyle?.width) setParentWidth(ctaStyle.width.value as number);
  }, [ctaStyle, viewMode, wixEditMode]);

  useLayoutEffect(() => {
    if (
      (process.env.NODE_ENV === 'production' ||
        process.env.REACT_APP_ENV === 'staging') &&
      Object.keys(Wix).length > 0 &&
      typeof ctaStyle.height?.value === 'number' &&
      ctaStyle.height.value !== 0
    ) {
      Wix.setHeight(
        scale <= 1 ? ctaStyle.height.value * scale : ctaStyle.height.value,
        { overflow: false }
      );
    }
  }, [ctaStyle, scale]);

  if (viewMode === 'editor' || preview) {
    return (
      <CTAContainer
        category={category}
        scale={scale}
        data-testid='widget'
        elstyle={ctaStyle}>
        {ctaStyle.background && (
          <BackgroundColor color={ctaStyle.background?.value} />
        )}
        {currentCta.editor?.backgroundImg.desktop.src && (
          <BackgroundImage
            img={currentCta.editor.backgroundImg}
            elStyle={ctaStyle}
            opacity={ctaStyle.opacity?.value}
            media={media}
          />
        )}
        {category ? (
          editor()
        ) : (
          <InnerContainer
            category={category}
            width={ctaStyle.width?.value}
            height={ctaStyle.height?.value}>
            {editor()}
          </InnerContainer>
        )}
      </CTAContainer>
    );
  } else if (
    category === 'active' ||
    category === 'upcoming' ||
    category === 'expired' ||
    viewMode !== null
  ) {
    return (
      <CTAContainer
        category={category}
        scale={scale}
        data-testid='widget'
        elstyle={ctaStyle}
        id={'cta-container'}>
        {ctaStyle.background && (
          <BackgroundColor
            id={'background-color'}
            color={ctaStyle.background?.value}
          />
        )}
        {currentCta.live?.backgroundImg.desktop.src && (
          <BackgroundImage
            id={'background-image'}
            img={currentCta.live.backgroundImg}
            elStyle={ctaStyle}
            opacity={ctaStyle.opacity?.value}
            media={media}
          />
        )}
        {category ? (
          live()
        ) : (
          <InnerContainer
            id={'inner-container'}
            category={category}
            width={ctaStyle.width?.value}
            height={ctaStyle.height?.value}>

            {live()}
          </InnerContainer>
        )}
        {disabled && <Watermark />}
      </CTAContainer>
    );
  } else if (category === 'drafts') {

    return (
      <CTAContainer
        category={category}
        scale={scale}
        data-testid='widget'
        elstyle={ctaStyle}
        id={'cta-container'}>
        {ctaStyle.background && (
          <BackgroundColor
            id={'background-color'}
            color={ctaStyle.background?.value}
          />
        )}
        {currentCta.live?.backgroundImg.desktop.src && (
          <BackgroundImage
            id={'background-image'}
            img={currentCta.live.backgroundImg}
            elStyle={ctaStyle}
            opacity={ctaStyle.opacity?.value}
            media={media}
          />
        )}
        {currentCta.live.elements.length > 0 ? live() : editor()}
      </CTAContainer>
    );
  } else {
    return <CTAContainer
        category={category}
        scale={scale}
        data-testid='widget'
        elstyle={ctaStyle}
        id={'cta-container'}>
      {ctaStyle.background && (
          <BackgroundColor
              id={'background-color'}
              color={ctaStyle.background?.value}
          />
      )}
      {currentCta.live?.backgroundImg.desktop.src && (
          <BackgroundImage
              id={'background-image'}
              img={currentCta.live.backgroundImg}
              elStyle={ctaStyle}
              opacity={ctaStyle.opacity?.value}
              media={media}
          />
      )}
      {category ? (
          live()
      ) : (
          <InnerContainer
              id={'inner-container'}
              category={category}
              width={ctaStyle.width?.value}
              height={ctaStyle.height?.value}>
            {live()}
          </InnerContainer>
      )}
      {disabled && <Watermark />}
    </CTAContainer>;
  }
};

export default CTA;
