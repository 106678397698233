import React, { useCallback, useLayoutEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Cover } from '../../../styles/util';
import {
  InputContainer,
  VisibilityButtonContainer,
  Row,
  Label,
} from './VisibilityButton.styles';
import useTargetElement from '../../../hooks/useTargetElement';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { desktopDisplay, mobileDisplay } from '../../../redux/elementSlice';

interface Props {
  style?: React.CSSProperties;
}

const VisibilityButton: React.FC<Props> = ({ style }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [desktopChecked, setDesktopChecked] = useState(true);
  const [mobileChecked, setMobileChecked] = useState(true);
  const [disableDesktop, setDisableDesktop] = useState(false);
  const [disableMobile, setDisableMobile] = useState(false);
  const targetElement = useTargetElement();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (
      targetElement?.style?.desktop?.display?.value === 'block' ||
      typeof targetElement?.style?.desktop?.display?.value === 'undefined'
    ) {
      setDesktopChecked(true);
    } else {
      setDesktopChecked(false);
    }
  }, [targetElement]);

  useLayoutEffect(() => {
    if (
      targetElement?.style?.mobile?.display?.value === 'block' ||
      typeof targetElement?.style?.mobile?.display?.value === 'undefined'
    ) {
      setMobileChecked(true);
    } else {
      setMobileChecked(false);
    }
  }, [targetElement]);

  const handleDesktopCheck = useCallback(() => {
    if (desktopChecked && targetElement) {
      dispatch(
        desktopDisplay({ id: targetElement.id, undo: true, display: 'none' })
      );
    } else if (targetElement) {
      dispatch(
        desktopDisplay({ id: targetElement.id, undo: true, display: 'block' })
      );
    }

    setDesktopChecked(!desktopChecked);
  }, [dispatch, desktopChecked, targetElement]);

  const handleMobileCheck = useCallback(() => {
    if (mobileChecked && targetElement) {
      dispatch(
        mobileDisplay({ id: targetElement.id, undo: true, display: 'none' })
      );
    } else if (targetElement) {
      dispatch(
        mobileDisplay({ id: targetElement.id, undo: true, display: 'block' })
      );
    }

    setMobileChecked(!mobileChecked);
  }, [dispatch, targetElement, mobileChecked]);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  useLayoutEffect(() => {
    if (desktopChecked) {
      setDisableMobile(false);
    } else {
      setDisableMobile(true);
    }
  }, [desktopChecked]);

  useLayoutEffect(() => {
    if (mobileChecked) {
      setDisableDesktop(false);
    } else {
      setDisableDesktop(true);
    }
  }, [mobileChecked]);

  return (
    <VisibilityButtonContainer
      aria-label={'Element Visibility'}
      title={'Element Visibility'}
      show={showMenu}
      style={style}>
      <VisibilityIcon onClick={handleShowMenu} fontSize={'large'} />
      {showMenu && (
        <>
          <Cover onClick={() => setShowMenu(false)} />
          <InputContainer>
            <Row>
              <input
                disabled={disableDesktop}
                id={'showOnDesktop'}
                type={'checkbox'}
                checked={desktopChecked}
                onChange={handleDesktopCheck}
              />
              <Label htmlFor={'showOnDesktop'}>Show on Desktop</Label>
            </Row>
            <Row>
              <input
                disabled={disableMobile}
                id={'showOnMobile'}
                type={'checkbox'}
                checked={mobileChecked}
                onChange={handleMobileCheck}
              />
              <Label htmlFor={'showOnMobile'}>Show on Mobile</Label>
            </Row>
          </InputContainer>
        </>
      )}
    </VisibilityButtonContainer>
  );
};

export default VisibilityButton;
