import React from 'react';
import { ModalContainer, Cover } from './Modal.styles';

interface Props {
  closeModal: (boolean) => void;
  children?: React.ReactNode;
}

const Modal: React.FC<Props> = ({ closeModal, children }) => {
  return (
    <>
      <ModalContainer>{children}</ModalContainer>
      <Cover onClick={closeModal} />
    </>
  );
};

export default Modal;
