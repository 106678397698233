import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/reduxHooks';
import useCurrentComponent from '../../../hooks/useCurrentComponent';
import { handleResize } from '../../../util/functions';
import { resize } from '../../../redux/elementSlice';
import useGetEditingMode from '../../../hooks/useGetEditingMode';
import useZoom from '../../../hooks/useZoom';
import { SEHandle, SideHandle } from './ResizeHandles.styles';

interface Props {
  myRef: React.RefObject<HTMLDivElement | HTMLButtonElement>;
  id: string;
  setPos: (
    value: (prevPos: { x: number; y: number }) => { x: number; y: number }
  ) => void;
  pos: any;
  setBounds: any;
  rect: any;
  setClientHeight?: React.Dispatch<React.SetStateAction<number>>;
  setDisabled?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  type?: 'typography';
}

const ResizeHandles: React.FC<Props> = ({
  myRef,
  id,
  setPos,
  pos,
  setBounds,
  rect,
  setClientHeight,

  type,
}) => {
  const dispatch = useAppDispatch();
  const currentComponent = useCurrentComponent();
  const editingMode = useGetEditingMode();
  const scale = useZoom();
  const [showHandles, setShowHandles] = useState<boolean>(false);

  useEffect(() => {
    if (currentComponent.id === id) {
      setShowHandles(true);
    } else {
      setShowHandles(false);
    }
  }, [currentComponent, id]);

  const resizeCallback = useCallback(
    (e) => {
      if (setClientHeight) setClientHeight(0);

      handleResize(
        e,
        myRef,
        dispatch,
        resize,
        id,
        editingMode,
        scale,
        rect,
        type === 'typography',
        setBounds
      );
    },
    [
      dispatch,
      id,
      editingMode,
      scale,
      myRef,
      setBounds,
      rect,
      type,
      setClientHeight,
    ]
  );

  return (
    <>
      {type !== 'typography' && (
        <SEHandle
          showHandle={showHandles}
          onMouseDown={(e) => resizeCallback(e)}
          className='se'
          contentEditable='false'></SEHandle>
      )}
      {type === 'typography' && (
        <SideHandle
          showHandle={showHandles}
          onMouseDown={(e) => resizeCallback(e)}
          contentEditable='false'
          className={'side-handle'}></SideHandle>
      )}
    </>
  );
};

export default ResizeHandles;
