import React from 'react';
import { StatusContainer, Status } from './SavedStatus.styles';

interface Props {
  isUnsaved: boolean;
}

const SavedStatus: React.FC<Props> = ({ isUnsaved }) => {
  return (
    <StatusContainer data-testid='saved-status'>
      <Status>{isUnsaved ? 'Saving...' : 'Saved'}</Status>
    </StatusContainer>
  );
};

export default SavedStatus;
