import { useEffect, useState } from 'react';
import OA from '../services/InstanceServices';

interface Settings {
  layout: number;
  boxShadow: number;
}

const useGetCompSettings = (instance: string, compId: string) => {
  const [settings, setSettings] = useState<Settings | undefined>();

  useEffect(() => {
    OA.getCompSettings(instance, compId)
      .then((result) => {
        setSettings(result?.data.settings);
      })
      .catch((e) => {
        console.error('Error fetching comp settings', e.message);
      });
  }, [compId, instance]);

  return settings;
};

export default useGetCompSettings;
