import React from 'react';
import {
  InfoBubbleContainer,
  InfoTitle,
  InfoSchedule,
} from './InfoBubble.styles';
import { CtaList, CtaUpdate } from '../../../types';
import { getAllCtasFromDB, updateCtaInDB } from '../../../util/services';
import { setCtaList } from '../../../redux/ctaSlice';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { useFormatDate } from './InfoBubble.logic';

interface Props {
  title: string;
  schedule: {
    start: number;
    end: number;
  };
  ref: any;
  contentId: string;
  compId: string;
  instance: string;
}

const InfoBubble: React.FC<Props> = React.forwardRef(
  ({ title, schedule, contentId, compId, instance }, ref) => {
    const dispatch = useAppDispatch();
    const { formattedDate } = useFormatDate(schedule);

    const handleSetTitle: React.FocusEventHandler = async (
      e: React.FocusEvent<HTMLSpanElement>
    ): Promise<void> => {
      e.currentTarget.contentEditable = 'false';
      if (e.target.innerText !== '' ) {
        try {
          const cta: CtaUpdate = {
            id: contentId,
            title: e.target.innerText,
          };

          await updateCtaInDB(instance, compId, cta);
          const result: CtaList | void = await getAllCtasFromDB(
            instance,
            undefined,
            compId
          );

          if (result) dispatch(setCtaList({ ctaList: result }));
        } catch (error: unknown) {
          if (error instanceof Error) console.error(error.message);
          return;
        }
      } else {
        e.target.innerText = title;
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') e.target.blur();
    };

    return (
      <InfoBubbleContainer>
        <InfoTitle
          onBlur={handleSetTitle}
          onKeyDown={handleKeyDown}
          ref={ref as React.RefObject<HTMLSpanElement>}>
          {title}
        </InfoTitle>
        <InfoSchedule hasValue={!!formattedDate}>
          {formattedDate ? formattedDate : 'Not currently scheduled'}
        </InfoSchedule>
      </InfoBubbleContainer>
    );
  }
);

export default InfoBubble;
