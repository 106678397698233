// Handles either a fresh installation or an additional widget that is not duplicated.

import { useEffect, useState } from "react";
import { addCompIdToInstance } from "../util/services";

const useAddCompId = (
  instance: string,
  compId: string,
  origCompId: string | null | -1,
  viewMode: string | null | -1
) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    // If origCompId param exists, the widget was duplicated, and we want to prevent this from running.
    if (instance && compId && origCompId === -1 && viewMode === "editor") {
      setLoading(true);
      addCompIdToInstance(instance, controller, compId);

      return () => {
        setLoading(false);
        controller.abort();
      };
    }
  }, [instance, compId, origCompId, viewMode]);

  return loading;
};

export default useAddCompId;
