import { useEffect } from 'react';
import { useAppDispatch } from '../redux/reduxHooks';
import { setCurrentCtaId } from '../redux/ctaSlice';
import useQuery from './useQuery';

const useSetCurrentCtaId = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  let ctaId = query.get('cta-id');

  useEffect(() => {
    if (ctaId) dispatch(setCurrentCtaId({ ctaId }));
  }, [dispatch, ctaId]);
};

export default useSetCurrentCtaId;
