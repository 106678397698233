import React from 'react';
import { SelectContainer, SelectItem } from './AnnouncementModalSelect.styles';
import useGetCompIdsFromDB from '../../../hooks/useGetCompIdsFromDB';
import useGetInstance from '../../../hooks/useGetInstance';

interface Props {
  value: string | null;
  setValue: (
    value: string | null | ((prevVar: string | null) => string | null)
  ) => void;
}

const AnnouncementModalSelect: React.FC<Props> = ({ value, setValue }) => {
  const instance = useGetInstance();
  const compIds = useGetCompIdsFromDB(instance);

  const handleSelect = (e: React.MouseEvent, compId: string) => {
    e.stopPropagation();
    setValue(compId);
  };

  const handlePressEnter: React.KeyboardEventHandler<HTMLLIElement> = (
    e: React.KeyboardEvent
  ) => {
    if (e.key === 'Enter') {
      const target = e.target as HTMLLIElement;
      setValue(target.innerText);
    }
  };

  return (
    <SelectContainer
      role={'listbox'}
      aria-activedescendant={value as string}
      onClick={() => setValue(null)}>
      {compIds &&
        compIds.map((compId) => {
          return (
            <SelectItem
              role={'option'}
              aria-selected={value === compId.id}
              onKeyDown={handlePressEnter}
              active={value}
              id={compId.id}
              onClick={(e) => handleSelect(e, compId.id)}
              key={compId.id}
              tabIndex={0}>
              {compId.alias}
            </SelectItem>
          );
        })}
    </SelectContainer>
  );
};

export default AnnouncementModalSelect;
