import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-bottom: ${(props) => props.theme.spacing[3]};
  }
`;

export const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
`;

export const Section = styled.div``;
