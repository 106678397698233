import React, { useLayoutEffect, useState } from 'react';
import {
  DashboardContainer,
  Sidebar,
  Logo,
  LogoTitle,
  ViewContainer,
} from './Dashboard.styles';
import AnnouncementView from '../../components/Dashboard/views/AnnouncementView';
import LogoIcon from '../../assets/logo.png';
import SidebarLinks from '../../components/Dashboard/SidebarLinks/SidebarLinks.component';
import { DashboardView } from '../../types';
import useSetInstance from '../../hooks/useSetInstance';
import useGetInstance from '../../hooks/useGetInstance';
import useSetCtaListFromDB from '../../hooks/useSetCtaListFromDB';
import SubscriptionView from '../../components/Dashboard/views/SubscriptionView';
import useSetUser from '../../hooks/useSetUser';
import TrialStatus from '../../components/Dashboard/TrialStatus/TrialStatus.component';
import useUser from '../../hooks/useUser';
import { daysRemainingOfTrial } from '../../util/functions';
import useSetPlanId from '../../hooks/useSetPlanId';
import usePlanId from '../../hooks/usePlanId';
import TutorialsView from '../../components/Dashboard/views/TutorialsView';
import SupportView from '../../components/Dashboard/views/SupportView';

const Dashboard: React.FC = () => {
  const [currentView, setCurrentView] =
    useState<DashboardView>('announcements');
  useSetInstance();
  const instance = useGetInstance();
  useSetCtaListFromDB(instance);
  useSetUser(instance);
  useSetPlanId(instance);
  const user = useUser();
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
  const planId = usePlanId();

  useLayoutEffect(() => {
    if (user?.trialExpiration) {
      const result = daysRemainingOfTrial(user.trialExpiration);
      if (result) {
        setDaysRemaining(result);
      }
    }
  }, [user]);

  const renderView = () => {
      switch (currentView) {
        case 'announcements':
          return <AnnouncementView />;
        case 'subscription':
          return <SubscriptionView />;
        case 'tutorials':
          return <TutorialsView />;
        case 'support':
          return <SupportView />;
        default:
          return;
      }
  };

  return (
    <DashboardContainer>
      <Sidebar currentView={currentView}>
        <Logo src={LogoIcon} alt='Ocean Apps' />
        <LogoTitle currentView={currentView}>Content Scheduler</LogoTitle>
        <SidebarLinks
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
        {planId === null && <TrialStatus daysRemaining={daysRemaining} />}
      </Sidebar>
      <ViewContainer currentView={currentView}>
        {renderView()}
      </ViewContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
