import styled from 'styled-components';
import { Style } from '../../../types';

export const BackgroundImageStyles = styled.img.attrs<{
  elStyle: Style;
  opacity: number | undefined;
  src: string;
  media: 'desktop' | 'mobile';
  heightProperty?: null | string;
  widthProperty?: null | string;
  imgDimensions?: { height: number; width: number } | null;
}>((props) => ({
  style: {
    opacity: props.opacity ? props.opacity : 1,
    objectFit: (props.heightProperty || props.widthProperty) && 'cover',
  },
  src: props.src,
}))<{
  elStyle: Style;
  opacity: number | undefined;
  media: 'desktop' | 'mobile';
  heightProperty?: null | string;
  widthProperty?: null | string;
  pos: { top: number; left: number };
  imgDimensions?: { height: number; width: number } | null;
}>`
  z-index: 1;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div``;
