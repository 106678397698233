import React, { useRef, useState } from 'react';
import { Icon, Button } from './MenuButton.styles';
import DisabledInfoBubble from '../../Settings/DisabledInfoBubble/DisabledInfoBubble.component';
import WarningIcon from '../../Settings/WarningIcon/WarningIcon.component';

interface Props {
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'primary' | 'text' | 'secondary' | 'outline' | 'danger';
  isDisabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
  fullWidth?: boolean;
  pill?: boolean;
  icon?: any;
  active?: boolean;
  showWarningIcon?: boolean;
  type?: 'submit';
}

const MenuButton: React.FC<Props> = ({
  label,
  onClick,
  variant = 'primary',
  isDisabled = false,
  style,
  id,
  fullWidth,
  pill,
  icon,
  active,
  showWarningIcon,
  type,
}) => {
  const [showDisabledInfo, setShowDisabledInfo] = useState(false);

  const btnRef = useRef<HTMLButtonElement>(null);

  const handleMouseOver = (e) => {
    if (showWarningIcon) {
      setShowDisabledInfo(true);
    }
  };
  const handleMouseLeave = () => {
    if (showWarningIcon) {
      setShowDisabledInfo(false);
    }
  };

  return (
    <Button
      type={type}
      id={id}
      ref={btnRef}
      hasLabel={!!label}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      fullWidth={fullWidth}
      style={style}
      onClick={isDisabled || !onClick ? () => {} : onClick}
      variant={variant}
      pill={pill}
      isDisabled={isDisabled}
      disabled={isDisabled || showWarningIcon}
      active={active}>
      {showDisabledInfo && (
        <DisabledInfoBubble style={{ top: '45px', left: 0 }} />
      )}
      {showWarningIcon && <WarningIcon />}
      {icon && <Icon hasLabel={!!label}>{icon}</Icon>}
      {label}
    </Button>
  );
};

export default MenuButton;
