import styled from 'styled-components';

export const ButtonStyles = styled.button<{ variant: string }>`
  width: 20rem;
  height: 4rem;
  font-weight: 400;
  background-color: ${(props) =>
    props.variant === 'primary'
      ? props.theme.color.settings.blue500
      : props.variant === 'secondary'
      ? props.theme.color.settings.blue100
      : props.variant === 'tertiary'
      ? props.theme.color.settings.pink100
      : ''};
  color: ${(props) =>
    props.variant === 'primary'
      ? '#fff'
      : props.variant === 'secondary'
      ? props.theme.color.settings.blue500
      : props.variant === 'tertiary'
      ? props.theme.color.settings.pink200
      : ''};
  border-radius: 25px;
  box-shadow: ${({ theme }) => theme.shadow[2]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  user-select: none;
  margin-bottom: 1.8rem;
  transition: scale 0.1s ease-in-out, background 150ms ease-in-out;
  border: none;

  &:active {
    transform: scale(0.99);
  }

  &:hover {
    background: ${(props) =>
      props.variant === 'primary'
        ? props.theme.color.settings.blue400
        : props.variant === 'secondary'
        ? props.theme.color.settings.blue200
        : props.variant === 'tertiary'
        ? props.theme.color.settings.pink50
        : ''};
  }
`;
