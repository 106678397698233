import styled from 'styled-components';

export const Section = styled.div`
  width: 100%;
`;

export const SectionTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 0;
`;

export const DividerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding: 0.8rem 2rem;
  background-color: ${(props) => props.theme.color.gray50};
  border-bottom: 1px solid ${(props) => props.theme.color.gray200};
`;

export const CalendarMenuStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.2rem;

  overflow-y: auto;
  padding-bottom: 2rem;
`;
