import React, { useEffect, useState } from 'react';
import { Container, Column } from './SkeletonComponent.styles';
import { Wix } from '../../../util/Wix';
import { Skeleton } from 'antd';
import useLoading from '../../../hooks/useLoading';

const SkeletonComponent = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const loading = useLoading();

  useEffect(() => {
    Wix.getBoundingRectAndOffsets((data) => {
      setHeight(data.rect.height);
      setWidth(data.rect.width);
    });
  }, []);
  return (
    <Container width={width} height={height}>
      <Column>
        <Skeleton loading={loading} active />
        <Skeleton loading={loading} active />
        <Skeleton loading={loading} active />
      </Column>
    </Container>
  );
};

export default SkeletonComponent;
