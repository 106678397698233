import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing[5]};
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.text};
  padding-bottom: ${(props) => props.theme.spacing[3]};
`;
