import React from 'react';
import { Container, Text } from './ConfirmPublish.styles';
import MenuButton from '../../components/DesignStudio/MenuButton/MenuButton.component';
import { Wix } from '../../util/Wix';

const ConfirmPublish = () => {
  const handleClose = () => {
    Wix.closeWindow();
  };

  return (
    <Container>
      <Text>
        Note: Changes will be not reflected on your live site until you Publish
        through the Wix Editor.
      </Text>
      <MenuButton
        label={'Dismiss'}
        variant={'secondary'}
        onClick={handleClose}
      />
    </Container>
  );
};

export default ConfirmPublish;
