import styled from 'styled-components';
import { UID } from '../../../types';

export const SidebarMenuItemStyle = styled.button<{
  label: string;
  itemId: number;
  active: number;
}>`
  color: ${(props) =>
    props.label === 'Upgrade'
      ? props.theme.color.settings.pink200
      : props.active === props.itemId
      ? '#1a74c2'
      : '#325166'};
  font-size: 1.3rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.active === props.itemId ? '#D3EDFF' : 'transparent'};
  padding: 0.6rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  margin-top: ${(props) => props.theme.spacing[2]};
  margin-left: ${(props) => props.theme.spacing[2]};
  margin-bottom: 3.7px;
  border: none;
  position: relative;

`;
