import { useEffect, useState } from "react";
import { duplicateWidget } from "../util/services";

const useDuplicateWidget = (
  instance: string,
  compId: string,
  origCompId: string | null | -1,
  viewMode: string | null | -1
) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    // If origCompId param exists, the widget was duplicated, and we want to prevent this from running
    if (
      instance &&
      compId &&
      origCompId !== -1 &&
      origCompId !== null &&
      viewMode === "editor"
    ) {
      setLoading(true);
      duplicateWidget(instance, controller, compId, origCompId);

      return () => {
        setLoading(false);
        controller.abort();
      };
    }
  }, [instance, compId, origCompId, viewMode]);

  return loading;
};

export default useDuplicateWidget;
