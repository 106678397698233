import React from 'react';
import { Container, Logo, Text, LogoContainer } from './Watermark.styles';

const Watermark = () => {
  return (
    <Container>
      <LogoContainer>
        <Text>Powered by</Text>
        <a href={'https://www.oceanapps.com'}>
          <Logo>OceanApps</Logo>
        </a>
      </LogoContainer>
    </Container>
  );
};

export default Watermark;
