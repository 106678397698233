import React, { useLayoutEffect } from 'react';
import ButtonProperties from '../ButtonProperties/ButtonProperties.component';
import ButtonMenu from '../ButtonMenu/ButtonMenu.component';
import TemplateMenu from '../TemplateMenu/TemplateMenu.component';
import TextMenu from '../TextMenu/TextMenu.component';
import BackgroundMenu from '../BackgroundMenu/BackgroundMenu.component';
import MediaLibraryMenu from '../ImagesMenu/ImagesMenu.component';
import CalendarMenu from '../CalendarMenu/CalendarMenu.component';
import { SidebarStyles, SelectorContainer } from './Sidebar.styles';
import useSidebarView from '../../../hooks/useSidebarView';
import HideSidebarButton from '../HideSidebarButton/HideSidebarButton.component';
import useShowSidebar from '../../../hooks/useShowSidebar';
import ElementsMenu from '../ElementsMenu/ElementsMenu.component';
import AnnouncementSelector from '../AnnouncementSelector/AnnouncementSelector.component';
import { setShowSidebar } from '../../../redux/sidebarViewSlice';
import { useAppDispatch } from '../../../redux/reduxHooks';

const Sidebar: React.FC = () => {
  const sidebarView = useSidebarView();
  const showSidebar = useShowSidebar();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (sidebarView === 9) {
      dispatch(setShowSidebar(false));
    }
  }, [dispatch, sidebarView]);

  return (
    <SidebarStyles show={showSidebar} data-testid='sidebar'>
      <HideSidebarButton />
      <SelectorContainer>
        <AnnouncementSelector />
      </SelectorContainer>
      {sidebarView === 1 && <TemplateMenu />}
      {sidebarView === 2 && <MediaLibraryMenu />}
      {sidebarView === 3 && <TextMenu />}
      {sidebarView === 4 && <ButtonMenu />}
      {sidebarView === 5 && <BackgroundMenu />}
      {sidebarView === 6 && <ButtonProperties />}
      {sidebarView === 7 && <CalendarMenu />}
      {sidebarView === 8 && <ElementsMenu />}
    </SidebarStyles>
  );
};

export default Sidebar;
