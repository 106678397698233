import React, { useRef, useState, useLayoutEffect } from 'react';
import { Container, Title, Notes, Schedule } from './CalendarPopover.styles';
import { PopoverEvent } from '../../../types';

const options: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};

interface Props {
  popoverEvent: PopoverEvent;
  x: number;
  y: number;
}

const CalendarPopover: React.FC<Props> = ({ popoverEvent, x, y }) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [popoverPosition, setPopoverPosition] = useState<{
    x: string;
    y: string;
  }>({ x: '', y: '' });
  const [offsetWidth, setOffsetWidth] = useState<number>(0);
  const [offsetHeight, setOffsetHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (
      popoverRef.current &&
      x + popoverRef.current.offsetWidth > window.innerWidth
    ) {
      setPopoverPosition((prev) => ({ ...prev, x: 'right' }));
    } else {
      setPopoverPosition((prev) => ({ ...prev, x: 'left' }));
    }
  }, [popoverRef, x]);

  useLayoutEffect(() => {
    if (
      popoverRef.current &&
      y + popoverRef.current.offsetHeight > window.innerHeight
    ) {
      setPopoverPosition((prev) => ({ ...prev, y: 'bottom' }));
    } else {
      setPopoverPosition((prev) => ({ ...prev, y: 'top' }));
    }
  }, [popoverRef, y]);

  useLayoutEffect(() => {
    if (popoverRef.current) {
      setOffsetWidth(popoverRef.current.offsetWidth);
      setOffsetHeight(popoverRef.current.offsetHeight);
    }
  }, [popoverRef]);

  return (
    <Container
      offsetWidth={offsetWidth}
      offsetHeight={offsetHeight}
      position={popoverPosition}
      x={x}
      y={y}
      ref={popoverRef}>
      <Title>{popoverEvent?.title}</Title>
      <Schedule>
        {popoverEvent.start.toLocaleString('en-US', options)} -{' '}
        {popoverEvent.end.toLocaleString('en-US', options)}
      </Schedule>
      <Notes>{popoverEvent?.extendedProps?.notes}</Notes>
    </Container>
  );
};

export default CalendarPopover;
