import styled from 'styled-components';

export const MenuContainer = styled.div.attrs<{ showSidebar: boolean }>(
  (props) => ({
    style: {
      opacity: props.showSidebar ? '1' : '0',
    },
  })
)<{ showSidebar: boolean }>`
  margin: 0 auto;
  width: calc(35rem - 7.1rem);
  height: 100%;
  padding: 2rem;
  font-size: 1.2rem;
  overflow-y: auto;
  transition: opacity 150ms ease-in-out;

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[4]};
  }
`;
