import styled from 'styled-components';

export const Container = styled.div`
  transition: height 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  width: 100%;

  @media screen and (min-width: 980px) {
    max-width: 98rem;
  }
`;

export const QuestionContainer = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background: ${(props) => props.bgColor};
  z-index: 1;
  color: ${(props) => props.theme.color.gray900};
  margin-bottom: ${(props) => props.theme.spacing[3]};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.color.blue500};
  }
`;

export const Question = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  padding-right: ${(props) => props.theme.spacing[3]};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AnswerContainer = styled.div<{ open: boolean; height: number }>`
  // //padding-bottom: ${(props) => props.theme.spacing[6]};
  transition: height 250ms ease-in-out;
  // //margin-top: ${(props) => props.theme.spacing[2]};
  overflow: hidden;
  height: ${(props) => (props.open ? props.height + 'px' : '0')};
`;

export const Answer = styled.p`
  color: ${(props) => props.theme.color.gray700};
  margin-bottom: 0;
  font-weight: normal;
`;

export const BottomBorder = styled.div`
  content: '';
  height: 1px;
  background: ${(props) => props.theme.color.gray200};
  margin-top: ${(props) => props.theme.spacing[4]};
`;
