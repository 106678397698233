import styled from 'styled-components';

export const SidebarStyles = styled.div.attrs<{ show: boolean }>((props) => ({
  style: {
    maxWidth: props.show ? '40.7rem' : '0',
  },
}))<{ show: boolean }>`
  height: calc(100vh - 4.8rem);
  display: flex;
  flex-direction: column;
  background: #fff;
  border-right: ${(props) => `2px solid ${props.theme.color.gray200}`};
  position: relative;
  transition: all 150ms ease-in-out;
  max-width: 40.7rem;
`;

export const SelectorContainer = styled.div`
  height: 4.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
`;
