import { useEffect } from 'react';
import { setCurrentCta } from '../redux/ctaSlice';
import { setUnsaved } from '../redux/unsavedSlice';
import { updateCtaInDB } from '../util/services';
import useElements from './useElements';
import useGetCtas from './useGetCtas';
import useGetCurrentCtaId from './useGetCurrentCtaId';
import useGetInstance from './useGetInstance';
import useGetCurrentCta from './useGetCurrentCta';
import { useAppDispatch } from '../redux/reduxHooks';
import useGetCompId from './useGetCompId';

const AUTOSAVE_INTERVAL: number = 500;

// Component updates are saved to ctaList in useUpdateCtaList

const useAutoSave = (ctaRef) => {
  const currentCta = useGetCurrentCta();
  const ctaId = useGetCurrentCtaId();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const elements = useElements().present;
  const dispatch = useAppDispatch();
  const ctaList = useGetCtas();

  // Updates CTA in DB with currentCta
  // Timeout clears itself when user makes a change within one second
  useEffect(() => {
    const controller = new AbortController();
    const timer = setTimeout(() => {
      if (
        currentCta &&
        Object.keys(currentCta).length > 0 &&
        instance

      ) {
        updateCtaInDB(instance, compId, currentCta, controller);
      }
      dispatch(setUnsaved(false));
    }, AUTOSAVE_INTERVAL);

    return () => {
      controller.abort();
      clearTimeout(timer);
    };
  }, [currentCta, instance, dispatch, compId]);

  useEffect(() => {
    if (ctaList.length > 0 && ctaId) {
      const currentCta = ctaList.find((cta) => ctaId === cta.id);
      if (currentCta) {
        dispatch(setCurrentCta({ currentCta }));
      }
    } else {
      dispatch(setCurrentCta({ currentCta: null }));
    }
  }, [ctaList, dispatch, ctaId]);

  useEffect(() => {
    dispatch(setUnsaved(true));
  }, [elements, dispatch]);
};

export default useAutoSave;
