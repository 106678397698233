import { useEffect } from "react";
import { getPlanId } from "../util/services";
import { setPlanId } from "../redux/userSlice";
import { useAppDispatch } from "../redux/reduxHooks";

const useSetPlanId = (instance: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (instance) {
      const controller = new AbortController();
      getPlanId(instance, controller).then((data) => {
        if (data) {
          dispatch(setPlanId({ planId: data }));
        }
      });
    }
  }, [dispatch, instance]);
};

export default useSetPlanId;
