import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing[4]};
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 15;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[2]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing[4]};
  padding-left: ${(props) => props.theme.spacing[6]};
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 0;
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[6]};

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[4]};
  }
`;

export const Footer = styled.div`
  background-color: ${(props) => props.theme.color.gray100};
  border-radius: 0 0 5px 5px;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[5]};
  display: flex;

  align-items: center;
  padding: ${(props) => props.theme.spacing[4]};
`;
