import styled from 'styled-components';

export const SvgContainer = styled.div<{ cursor: string; isSelected: boolean }>`
  cursor: ${(props) => (props.cursor === 'move' ? 'move' : 'pointer')};

  &::before {
    content: '';
    position: absolute;
    border-radius: 0;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    border: 2px solid
      ${(props) => (props.isSelected ? '#4980f7' : 'transparent')};
  }
`;
