import styled from 'styled-components';

export const ViewContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.settings.text};
`;

export const Label = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.color.settings.text};
`;

export const CreateCTAButton = styled.button`
  width: 100%;
  height: 6rem;
  border: none;
  background: ${(props) => props.theme.color.settings.blue200};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.color.settings.blue150};
  }

  &:active {
    background: ${(props) => props.theme.color.settings.blue200};
  }
`;

export const Icon = styled.div<{ showContextMenu?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  border: none;
  background-color: ${({ showContextMenu }) =>
    showContextMenu ? '#D3EDFF' : 'transparent'};
`;
