import { useEffect } from 'react';
import { Wix } from '../util/Wix';
import useGetActiveCta from './useGetActiveCta';
import useGetCompId from './useGetCompId';
import useGetInstance from './useGetInstance';

const useHideWidget = () => {
  const instance = useGetInstance();
  const compId = useGetCompId();
  const activeCta = useGetActiveCta(instance, compId);

  useEffect(() => {
    if (Object.keys(activeCta).length === 0) {
      Wix.setHeight(1, { overflow: false });
    }
  }, [activeCta]);
};

export default useHideWidget;
