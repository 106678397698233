import styled from 'styled-components';

export const Container = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[4]};
  border-top: 1px solid ${({ theme }) => theme.color.settings.gray100};
`;

export const Text = styled.p`
  color: ${(props) => props.theme.color.settings.pink200};
  font-size: 1.4rem;
`;

export const LinkText = styled.span`
  cursor: pointer;
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.settings.blue500};
  text-align: center;
  margin-bottom: 1.8rem;
`;
