import * as ReactDOM from 'react-dom';
import React from 'react';
import App from './App';
import './styles/main.scss';
import { Provider } from 'react-redux';
import store from './redux/store';
import { theme } from './styles/theme';
import { ThemeProvider } from 'styled-components';

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  root
);
