import React from 'react';
import { Container } from './Help.styles';
import TutorialsView from '../../Dashboard/views/TutorialsView/TutorialsView.component';
import SupportView from '../../Dashboard/views/SupportView/SupportView.component';

const Help = () => {
  return (
    <Container>
      <TutorialsView />
      <SupportView />
    </Container>
  );
};

export default Help;
