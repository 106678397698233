import React, { useEffect, useState } from 'react';
import { eventAdded, setShowModal } from '../../../redux/calendarSlice';
import { useDispatch } from 'react-redux';
import DatePicker from '../DatePicker/DatePicker.component';
import CloseIcon from '@mui/icons-material/Close';
import MenuButton from '../MenuButton/MenuButton.component';
import useGetCalendarInfo from '../../../hooks/useGetCalendarInfo';
import useGetCtas from '../../../hooks/useGetCtas';
import useGetScheduleError from '../../../hooks/useGetScheduleError';
import {
  getScheduleFromStorage,
  logError,
  setAlert,
  setScheduleError,
} from '../../../util/functions';
import {
  Flex,
  Modal,
  Header,
  Title,
  Backdrop,
  PickerContainer,
  Footer,
} from './ScheduleModal.styles';
import ScheduleError from '../ScheduleError/ScheduleError.component';
import { Input } from 'antd';
import { Cta, ScheduleList, ScheduleListItem } from '../../../types';
import moment from 'moment';
import { theme } from '../../../styles/theme';
import { getAllCtasFromDB, updateCtaInDB } from '../../../util/services';
import useGetInstance from '../../../hooks/useGetInstance';
import useGetCompId from '../../../hooks/useGetCompId';
import { setCtaList } from '../../../redux/ctaSlice';
import useUser from '../../../hooks/useUser';
import useCategories from '../../../hooks/useCategories';
import useRestrictUser from '../../../hooks/useRestrictUser';
import usePlanId from '../../../hooks/usePlanId';

const { TextArea } = Input;

interface Props {
  handleShowModal: () => void;
}

const ScheduleModal: React.FC<Props> = ({ handleShowModal }) => {
  const [scheduleChanged, setScheduleChanged] = useState<boolean>(false);
  const error = useGetScheduleError();
  const dispatch = useDispatch();
  const ctaToBeScheduled = useGetCalendarInfo();
  const instance = useGetInstance();
  const compId = useGetCompId();
  const user = useUser();
  const restricted = useRestrictUser(user);
  const planId = usePlanId();
  const ctaList = useGetCtas();
  const [cta, setCta] = useState<Cta>({} as Cta);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [, , upcoming] = useCategories(compId, ctaList);
  const [notesChanged, setNotesChanged] = useState<boolean>(false);
  const [sessionStorageSchedule, setSessionStorageSchedule] =
    useState<ScheduleList>([]);

  const [dateTime, setDateTime] = useState<
    [moment.Moment, moment.Moment] | null
  >(null);
  const [notes, setNotes] = useState<string>(cta?.notes || '');

  const storageEvent = () => {
    const schedule = getScheduleFromStorage();

    if (schedule) {
      setSessionStorageSchedule(schedule);
    }
  };

  useEffect(() => {
    storageEvent();
    window.addEventListener('storage', storageEvent);

    return () => {
      window.removeEventListener('storage', storageEvent);
    };
  }, []);

  useEffect(() => {
    if (dateTime !== null && dateTime.length > 0) {
      setStartTime(cta.schedule.start);
      setEndTime(cta.schedule.end);
    } else {
      setStartTime(0);
      setEndTime(0);
    }
  }, [dateTime, cta]);

  useEffect(() => {
    let ct = ctaList.find((ct) => ct.id === ctaToBeScheduled.id);
    if (ct) setCta(ct);
    if (ct?.notes) setNotes(ct.notes);
  }, [ctaList, ctaToBeScheduled.id]);

  const handleCloseModal = () => {
    dispatch(setShowModal({ showModal: false }));
    setScheduleError('', dispatch);
  };

  const handleSchedule = async () => {
    let title = '';
    let noConflict = true;
    // Check start time for conflicts

    if (cta.isEvergreen) {
      setScheduleError(
        "Oops! You can't schedule your evergreen content.",
        dispatch
      );
      return;
    }

    const currentTime = new Date().getTime();
    if (endTime < currentTime) {
      setScheduleError(
        'A time traveler! Your end time is in the past.',
        dispatch
      );
      return;
    }

    // Is there an existing scheduleList from this session?
    let scheduleListString: string | null =
      sessionStorage.getItem('scheduleList');
    let scheduleList: ScheduleList = [];
    // There is an existing scheduleList
    if (scheduleListString) {
      // Parse existing scheduleList
      scheduleList = JSON.parse(scheduleListString) as ScheduleList;
    }
    // Check if user has hit limit N on scheduled content
    let LIMIT = 999;
    if (planId === 'basic') {
      LIMIT = 1;
    } else if (planId === 'professional') {
      LIMIT = 30;
    }

    if (noConflict && restricted) {
      const scheduledFromCurrentSession = scheduleList.filter(
        (item) =>
          item.id !== ctaToBeScheduled.id &&
          (item.schedule.start > currentTime ||
            (item.schedule.start < currentTime &&
              item.schedule.end > currentTime))
      ) as ScheduleList;
      const filteredUpcoming = upcoming.filter(
        (item) => item.id !== ctaToBeScheduled.id
      );

      if (
        scheduledFromCurrentSession.length + filteredUpcoming.length >= LIMIT &&
        startTime &&
        endTime
      ) {
        setScheduleError(
          "Oops! You've reached the limit of scheduled content for your plan. Upgrade your plan now to schedule more content and continue growing your business.",
          dispatch
        );
        return;
      }
    }

    // Iterate over scheduleList
    if (scheduleList.length > 0) {
      noConflict = scheduleList
        .filter((item) => item.id !== ctaToBeScheduled.id)
        .every((item) => {
          // Does start time conflict with an item from scheduleList?

          if (
            startTime >= item.schedule.start &&
            startTime < item.schedule.end &&
            item.id !== cta.id
          ) {
            // Start time conflicts with an item from scheduleList
            title = item.title;
            console.log('we have a conflict with start time');
            return false;
          } else if (!startTime && !endTime) {
            return true;
          } else {
            return true;
          }
        });

      // Next check end time for conflicts
      if (noConflict) {
        noConflict = scheduleList.every((item) => {
          if (
            endTime > item.schedule.start &&
            endTime < item.schedule.end &&
            item.id !== cta!.id
          ) {
            title = item.title;
            console.log('we have a conflict with end time');
            return false;
          } else if (!startTime && !endTime) {
            return true;
          } else {
            return true;
          }
        });
      }
    }

    if (noConflict && ctaList.length > 0) {
      // Iterate over ctaList to check for conflict, as we did with scheduleList
      noConflict = ctaList.every((item) => {
        if (
          startTime >= item.schedule.start &&
          startTime < item.schedule.end &&
          item.id !== cta!.id
        ) {
          title = item.title;
          console.log('we have a conflict with start time');
          return false;
        } else if (!startTime && !endTime) {
          return true;
        } else {
          return true;
        }
      });

      // If start time doesn't conflict with existing schedules, check end time
      if (noConflict) {
        noConflict = ctaList.every((item) => {
          if (
            endTime > item.schedule.start &&
            endTime < item.schedule.end &&
            item.id !== cta!.id
          ) {
            title = item.title;
            console.log('we have a conflict with end time');
            return false;
          } else if (!startTime && !endTime) {
            return true;
          } else {
            return true;
          }
        });
      }
    }

    if (noConflict) {
      setScheduleError('', dispatch);
      if (Object.keys(cta).length > 0)
        try {
          const scheduleItem: ScheduleListItem = {
            id: cta.id,
            compId: cta.compId,
            title: cta.title,
            schedule: { start: startTime, end: endTime },
          };

          if (scheduleList) {
            scheduleList = scheduleList as ScheduleList;

            scheduleList = scheduleList.filter(
              (item) => item.id !== ctaToBeScheduled.id
            );
            console.log('pushing to scheduleList');
            scheduleList.push(scheduleItem);
          } else {
            scheduleList = [] as ScheduleList;
            scheduleList.push(scheduleItem);
          }

          sessionStorage.setItem('scheduleList', JSON.stringify(scheduleList));
        } catch (error) {
          logError(error);
          setAlert(
            'Oops! Something went wrong when saving your data. Please try again in a moment.',
            'warning',
            dispatch
          );
          return;
        }
      handleCloseModal();
      handleShowModal();
    } else {
      setScheduleError(`This schedule conflicts with ${title}`, dispatch);
    }
  };

  const handleDone = async () => {
    if (scheduleChanged) {
      await handleSchedule();
      // Update notes
      await updateCtaInDB(instance,  compId, { id: cta.id, notes });

      let ctaList = await getAllCtasFromDB(
        instance,
        undefined,
        compId
      );
      if (ctaList) {
        dispatch(setCtaList({ ctaList }));
      }

      dispatch(eventAdded());
    } else if (notesChanged) {
      await updateCtaInDB( instance,  compId, { id: cta.id, notes });
      let ctaList = await getAllCtasFromDB(
        instance,
        undefined,
        compId
      );
      if (ctaList) {
        dispatch(setCtaList({ ctaList }));
      }
      handleCloseModal();
    }
  };

  // Initialize dateTime array with start and end times (live schedule)
  useEffect(() => {
    if (Object.keys(cta).length > 0) {
      if (cta.schedule.start && cta.schedule.end) {
        setDateTime([moment(cta.schedule.start), moment(cta.schedule.end)]);
      }
    }
  }, [cta]);

  // Initialize dateTime array with start and end times (pending schedule)
  useEffect(() => {
    if (
      sessionStorageSchedule.some((item) => item.id === ctaToBeScheduled.id)
    ) {
      const content = sessionStorageSchedule.find(
        (item) => item.id === ctaToBeScheduled.id
      );

      if (content?.schedule?.start && content?.schedule?.end) {
        setDateTime([
          moment(content.schedule.start),
          moment(content.schedule.end),
        ]);
      }
    }
  }, [sessionStorageSchedule, ctaToBeScheduled.id]);

  const handleNoteChange = (e) => {
    setCta((prev) => ({ ...prev, notes: e.target.value }));
    setNotes(e.target.value);
    setNotesChanged(true);
  };

  const handleCancelPending = () => {
    let schedule = getScheduleFromStorage();

    if (schedule) {
      schedule = schedule.filter((item) => item.id !== ctaToBeScheduled.id);
      sessionStorage.setItem('scheduleList', JSON.stringify(schedule));
      window.dispatchEvent(new Event('storage'));
      const updatedSchedule = getScheduleFromStorage();

      if (updatedSchedule) setSessionStorageSchedule(updatedSchedule);
      handleCloseModal();
    }
  };

  return (
    <>
      <Modal data-testid='schedule-modal'>
        <Header>
          <Title>{ctaToBeScheduled.title}</Title>
          <MenuButton
            onClick={handleCloseModal}
            aria-label={'Close'}
            variant={'text'}
            style={{
              padding: '.2rem',
              height: '3.4rem',
              background: theme.color.gray100,
              border: 'none',
            }}
            icon={
              <CloseIcon
                style={{
                  fontSize: '30px',
                  color: theme.color.gray600,
                }}
              />
            }
          />
        </Header>
        <PickerContainer>
          <DatePicker
            id='start-end-time'
            setScheduleChanged={setScheduleChanged}
            scheduleChanged={scheduleChanged}
            status={error}
            value={dateTime}
            setValue={setDateTime}
            setSessionStorageSchedule={setSessionStorageSchedule}
            ctaId={ctaToBeScheduled.id}
            sessionStorageSchedule={sessionStorageSchedule}
            setCta={setCta}
            data-testid='start-end-time'
          />

          {error && <ScheduleError />}
          <TextArea
            rows={6}
            aria-label={'Notes'}
            placeholder='Notes'
            value={notes}
            onChange={handleNoteChange}
          />
        </PickerContainer>
        <Footer>
          {sessionStorageSchedule.length > 0 &&
            sessionStorageSchedule.some(
              (item) => item.id === ctaToBeScheduled.id
            ) && (
              <MenuButton
                variant={'danger'}
                onClick={handleCancelPending}
                label={'Cancel Pending'}
              />
            )}
          <Flex>
            <MenuButton
              variant='text'
              onClick={handleCloseModal}
              label={'Cancel'}
            />

            <MenuButton
              variant='secondary'
              onClick={handleDone}
              isDisabled={!scheduleChanged && !notesChanged}
              label={'Done'}
            />
          </Flex>
        </Footer>
      </Modal>

      <Backdrop onClick={handleCloseModal}></Backdrop>
    </>
  );
};

export default ScheduleModal;
