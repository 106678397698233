import styled from 'styled-components';
import { Style } from '../../../types';

export const ButtonStyles = styled.button<{
  elstyle: Style;
}>`
  font-family: ${(props) => props.elstyle.fontFamily?.value};
  display: ${(props) => props.elstyle.display?.value};
  background: ${(props) => props.elstyle.background?.value};
  border-color: ${(props) => props.elstyle.borderColor?.value};
  border-radius: ${(props) => props.elstyle.borderRadius?.value}px;
  border-width: ${(props) => props.elstyle.borderWidth?.value}px;
  border-style: ${(props) => props.elstyle.borderStyle?.value};
  background-image: ${(props) => props.elstyle.backgroundImage?.value};
  color: ${(props) => props.elstyle.color?.value};
  font-size: ${(props) => props.elstyle.fontSize?.value}px;
  font-weight: ${(props) => props.elstyle.fontWeight?.value};
  line-height: ${(props) => props.elstyle.lineHeight?.value};
  text-align: ${(props) => props.elstyle.textAlign?.value};
  text-decoration: ${(props) => props.elstyle.textDecoration?.value};
  letter-spacing: ${(props) => props.elstyle.letterSpacing?.value}px;
  text-transform: ${(props) => props.elstyle.textTransform?.value};
  position: absolute;
  top: ${(props) => props.elstyle.top?.value}px;
  left: ${(props) => props.elstyle.left?.value && props.elstyle.left.value}px;
  width: ${(props) =>
    typeof props.elstyle.width?.value === 'string'
      ? props.elstyle.width?.value
      : props.elstyle.width?.value + 'px'};
  height: ${(props) =>
    typeof props.elstyle.height?.value === 'string'
      ? props.elstyle.height?.value
      : props.elstyle.height?.value + 'px'};
  z-index: ${(props) => props.elstyle.zIndex?.value};
  transform: ${(props) => props.elstyle.transform?.value};
  cursor: pointer;
  user-select: none;
  transition: all 250ms ease-in-out;
  box-shadow: ${(props) => props.elstyle.boxShadow?.value};

  &:hover {
    background: ${(props) => props.elstyle.hover?.background?.value};
    border-color: ${(props) => props.elstyle.hover?.borderColor?.value};
    border-radius: ${(props) => props.elstyle.hover?.borderRadius?.value}px;
    background-image: ${(props) => props.elstyle.backgroundImage?.value};
    border-width: ${(props) => props.elstyle.hover?.borderWidth?.value}px;
    border-style: ${(props) => props.elstyle.hover?.borderStyle?.value};
    color: ${(props) => props.elstyle.hover?.color?.value};
    font-size: ${(props) => props.elstyle.hover?.fontSize?.value}px;
    font-weight: ${(props) => props.elstyle.hover?.fontWeight?.value};
    line-height: ${(props) => props.elstyle.hover?.lineHeight?.value};
    text-align: ${(props) => props.elstyle.hover?.textAlign?.value};
    text-decoration: ${(props) => props.elstyle.hover?.textDecoration?.value};
    box-shadow: ${(props) => props.elstyle.hover?.boxShadow?.value};
    letter-spacing: ${(props) => props.elstyle.hover?.letterSpacing?.value}px;
    transform: ${(props) => props.elstyle.hover?.transform?.value};
  }
`;
