import styled from 'styled-components';

export const Container = styled.div`
  padding: ${(props) => props.theme.spacing[6]};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (min-width: 980px) {
    max-width: 98rem;
  }
`;

export const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: bold;
  width: 100%;
  text-align: left;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  color: ${(props) => props.theme.color.gray900};

  @media screen and (min-width: 768px) {
    font-size: 3.2rem;
    margin-bottom: ${(props) => props.theme.spacing[5]};
  }

  @media screen and (min-width: 980px) {
    font-size: 4rem;
    margin-bottom: ${(props) => props.theme.spacing[6]};
  }

  @media screen and (min-width: 1200px) {
    font-size: 4.8rem;
    margin-bottom: ${(props) => props.theme.spacing[7]};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[6]};
  margin-bottom: ${(props) => props.theme.spacing[9]};
`;

export const FAQContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
