import styled from 'styled-components';

export const CTAContainer = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  width: 100%;
  opacity: 0;
`;
