import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: inherit;
`;
