import styled from 'styled-components';

export const MenuContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadow[2]};
  position: absolute;
  top: 45px;
  left: 62px;
  padding: ${({ theme }) => theme.spacing[4]};
  width: 28rem;
  z-index: 100;
  overflow: auto;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
