import styled from 'styled-components';

export const SettingsContainer = styled.div`
  width: 40.4rem;
  min-height: 52.8rem;
  display: flex;
  border-radius: 5px;
`;

export const SettingsRightColumn = styled.div`
  width: 30rem;
  height: 52.8rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 5px 5px 0;
`;

export const Sidebar = styled.div`
  min-height: 100%;
  min-width: 11rem;
  background-color: #f6fbff;
  border-radius: 5px 0 0 5px;
`;

export const SideBarMenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const Divider = styled.div`
  margin: 1rem 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.color.settings.blue200};
`;

export const Upper = styled.div``;

export const Lower = styled.div`
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing[4]};
`;
