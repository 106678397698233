import styled from 'styled-components';
import { Pos } from '../../../types';

export const ContextMenuStyles = styled.div<{
  pos: Pos;
  direction: 'up' | 'down';
}>`
  height: fit-content;
  width: 16rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${(props) =>
    props.direction === 'down' ? props.pos.y + 'px' : props.pos.y - 281 + 'px'};
  left: ${(props) => props.pos.x - 170 + 'px'};
  background-color: #fff;
  z-index: 1000;
  box-shadow: ${(props) => props.theme.shadow[2]};
  border-radius: 5px;
`;

export const MenuItemsContainerUpper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #eeecec;

  & > :first-child {
    border-radius: 5px 5px 0 0;
  }
`;

export const MenuItemsContainerLower = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > :last-child {
    border-radius: 0 0 5px 5px;
  }
`;
