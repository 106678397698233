import React from 'react';
import PropTypes from 'prop-types';
import useGetTheme from '../../../hooks/useGetTheme';
import {
  ThemeBtnOne,
  ThemeBtnTwo,
  ThemeBtnThree,
  ThemeBtnFour,
} from './ThemedButton.styles';
import useAddThemeButton from '../../../hooks/useAddThemeButton';

interface Props {
  type: string;
  children?: React.ReactNode;
}

const ThemedButton: React.FC<Props> = ({ type, children }) => {
  const theme = useGetTheme();
  const handleAddButton = useAddThemeButton();

  switch (type) {
    case 'ThemeBtnOne':
      return (
        <ThemeBtnOne onClick={() => handleAddButton(1)} themeValues={theme}>
          {children}
        </ThemeBtnOne>
      );
    case 'ThemeBtnTwo':
      return (
        <ThemeBtnTwo onClick={() => handleAddButton(2)} themeValues={theme}>
          Click Me
        </ThemeBtnTwo>
      );
    case 'ThemeBtnThree':
      return (
        <ThemeBtnThree onClick={() => handleAddButton(3)} themeValues={theme}>
          Click Me
        </ThemeBtnThree>
      );
    case 'ThemeBtnFour':
      return (
        <ThemeBtnFour onClick={() => handleAddButton(4)} themeValues={theme}>
          Click Me
        </ThemeBtnFour>
      );
    default:
      return <></>;
  }
};

ThemedButton.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ThemedButton;
