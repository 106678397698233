import React, { useState } from 'react';
import { Cover } from '../../../styles/util';
import LinkIcon from '@mui/icons-material/Link';
import TextInputWithOptions from '../TextInputWithOptions/TextInputWithOptions.component';
import { MenuContainer, LinkButtonContainer } from './LinkIconBtn.styles';

interface Props {
  id?: string;
}

const LinkIconBtn: React.FC<Props> = ({ id }) => {
  const [showLinkMenu, setShowLinkMenu] = useState<boolean>(false);
  return (
    <LinkButtonContainer
      show={showLinkMenu}
      aria-label='Create link'
      title={'Create link'}
      id={id}>
      {showLinkMenu && (
        <>
          <Cover onClick={() => setShowLinkMenu(false)} />
          <MenuContainer>
            <TextInputWithOptions id={'typography-link'} label={'Link URL'} />
          </MenuContainer>
        </>
      )}
      <LinkIcon
        fontSize='large'
        onClick={() => setShowLinkMenu(!showLinkMenu)}
      />
    </LinkButtonContainer>
  );
};

export default LinkIconBtn;
