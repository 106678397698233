import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  font-size: 1.2rem;
  overflow-y: auto;
`;

export const Section = styled.div`
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.black};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.p`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray200};
  padding-bottom: 0.5rem;
  margin-bottom: 1.8rem;
`;

export const Row = styled.div`
  display: flex;
  padding-bottom: 1.8rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;
