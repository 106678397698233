import { useEffect, useState } from "react";
import { Rect, Style } from "../types";

const useGetElementRect = (elStyle: Style): Rect => {
  const [rect, setRect] = useState<Rect>({} as Rect);

  useEffect(() => {
    if (
      typeof elStyle.width?.value === "number" &&
      typeof elStyle.height?.value === "number"
    )
      setRect({ width: elStyle.width.value, height: elStyle.height.value });
  }, [elStyle]);

  return rect;
};

export default useGetElementRect;
