// Used to initialize compId settings:
// Instance.compIds.compId.settings = { layout: number, boxShadow: number }

import { useEffect } from "react";
import OA from "../services/InstanceServices";
import { UID } from "../types";

const useSetCompSettings = (
  instance: string,
  compId: string
) => {
  useEffect(() => {
    const controller = new AbortController();
      OA.updateCompSettings(instance, compId, controller);
  }, [instance, compId]);
};

export default useSetCompSettings;
