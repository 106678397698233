import styled from 'styled-components';

export const InputBarStyles = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
`;

export const NumberInputBarStart = {
  width: '25%',
  borderRadius: '3px 0 0 3px',
  borderRight: '0',
};

export const NumberInputBarEnd = {
  width: '25%',
  borderRadius: '0 3px 3px 0',
};

export const NumberInputBarInnerOne = {
  width: '25%',
  borderRight: 'none',
  borderRadius: '0',
};

export const NumberInputBarInnerTwo = {
  width: '25%',
  borderRight: 'none',
  borderRadius: '0',
};
