import React, { useEffect, useState } from 'react';
import { Container, UpperSection } from './SettingsView.styles';
import {
  Section,
  SectionHeader,
  Text,
} from '../../../styles/settings/components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '../Tooltip/Tooltip.component';
import UpgradeInfo from '../UpgradeInfo/UpgradeInfo.component';
import usePlanId from '../../../hooks/usePlanId';
import useGetInstance from '../../../hooks/useGetInstance';
import useSetPlanId from '../../../hooks/useSetPlanId';
import Crown from '../../General/Crown/Crown.component';

interface Props {
  setView: (value: number | ((prevVar: number) => number)) => void;
}

const SettingsView: React.FC<Props> = ({ setView }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showPremiumTooltip, setShowPremiumTooltip] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const instance = useGetInstance();
  useSetPlanId(instance);
  const planId = usePlanId();

  useEffect(() => {
    if (planId === 'basic') {
      setDisableButton(true);
    }
  }, [planId]);

  const handleMouseOver = () => {
    if (disableButton) {
      setShowPremiumTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const handleMouseLeave = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }

    if (showPremiumTooltip) {
      setShowPremiumTooltip(false);
    }
  };

  return (
    <Container>
      <UpperSection>
        <SectionHeader>Settings</SectionHeader>
        <Section
          onClick={() => setView(6)}
          aria-label={'Widget nickname'}
          role={'button'}>
          <Text style={{ marginBottom: '0' }}>Widget nickname</Text>
          <ArrowForwardIosIcon style={{ color: '#325166' }} />
        </Section>
        <Section
          onClick={() => setView(7)}
          aria-label={'Set evergreen content'}
          role={'button'}
          disableButton={disableButton}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}>
          <Text disableButton={disableButton} style={{ marginBottom: '0' }}>
            Set evergreen content
          </Text>
          <ArrowForwardIosIcon style={{ color: '#325166' }} />
          <div
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',
              zIndex: '20',
            }}>
            <InfoIcon
              style={{
                color: 'rgba(56,152,236,0.91)',
                fontSize: '18px',
              }}
            />
            {disableButton && (
              <Crown
                style={{
                  height: '2rem',
                  width: '2rem',
                  padding: '1px',
                  marginLeft: '.6rem',
                }}
              />
            )}
          </div>

          {showTooltip && (
            <Tooltip>
              <span>
                Evergreen content will display on your site when you have
                nothing else scheduled to display.
              </span>
            </Tooltip>
          )}
          {showPremiumTooltip && (
            <Tooltip style={{ top: '9rem', width: '20rem' }}>
              {'Upgrade to professional to run Evergreen Campaigns.'}
            </Tooltip>
          )}
        </Section>
      </UpperSection>
      {planId !== 'professional' && <UpgradeInfo />}
    </Container>
  );
};

export default SettingsView;
