import styled from 'styled-components';

export const Container = styled.div`
  padding: ${(props) => props.theme.spacing[6]};
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.text};
  background: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[3]};
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid ${(props) => props.theme.color.gray200};
`;

export const Text = styled.p`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;
