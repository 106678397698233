import styled from 'styled-components';

export const Container = styled.div`
  padding: 6rem ${(props) => props.theme.spacing[6]};
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  @media screen and (min-width: 980px) {
    max-width: 98rem;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[3]};

  z-index: 100;
  flex: 1 0 40rem;
  max-width: 61.4rem;

  @media screen and (min-width: 1186px) {
    margin-top: 0;
  }
`;

export const Title = styled.h3`
  font-size: 2.4rem;
  color: ${(props) => props.theme.color.gray900};
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.gray700};
`;

export const Iframe = styled.iframe`
  border: none;
  width: 48.4rem;
  height: 55rem;
  overflow: hidden;
  margin-bottom: 0;
  display: block;
  flex: 1 0 46rem;
  margin-right: ${(props) => props.theme.spacing[4]};
`;

export const Image = styled.img`
  border-radius: 5px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 20px;
  z-index: 10;
`;

export const ImageContainer = styled.div`
  position: relative;
`;
