import styled from 'styled-components';

export const ImagesMenuStyles = styled.div`
  height: 100%;
  width: calc(35rem - 7.1rem);
  padding: 2rem;
  font-size: 1.2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[4]};
  }
`;

export const ImageMenuItem = styled.img`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
`;
