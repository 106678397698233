import React from 'react';
import { Container, Image } from './BackgroundMenuItem.styles';
import { useAppDispatch } from '../../../redux/reduxHooks';
import { setBackgroundImage } from '../../../redux/elementSlice';
import useGetCurrentCtaId from '../../../hooks/useGetCurrentCtaId';

interface Props {
  src: string;
}

const BackgroundMenuItem: React.FC<Props> = ({ src }) => {
  const dispatch = useAppDispatch();
  const id = useGetCurrentCtaId();

  const handleClick = () => {
    dispatch(setBackgroundImage({ id, src }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      dispatch(setBackgroundImage({ id, src }));
    }
  };
  return (
    <Container
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      aria-label={'Add background image'}>
      <Image src={src} />
    </Container>
  );
};

export default BackgroundMenuItem;
