import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[6]};
  height: 100%;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[6]};
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[4]};
  }
`;

export const Text = styled.p`
  margin-bottom: 0;
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.settings.text};
`;
