import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(35rem - 7.1rem);
  padding: 2rem;
  font-size: 1.2rem;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;
