import React from "react";
import { Tabs as AntTabs } from "antd";

const { TabPane } = AntTabs;

interface Props {
  style?: React.CSSProperties;
  defaultActiveKey: string;
  activeKey: string;
  onChange: (key: string) => void;
}

const Tabs: React.FC<Props> = ({
  style,
  defaultActiveKey,
  activeKey,
  onChange,
}) => {
  return (
    <AntTabs
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      style={style}
      onChange={onChange}
    >
      <TabPane tab="Active" key="active"></TabPane>
      <TabPane tab="Upcoming" key="upcoming"></TabPane>
      <TabPane tab="Drafts" key="drafts"></TabPane>
      <TabPane tab="Expired" key="expired"></TabPane>
    </AntTabs>
  );
};

export default Tabs;
