import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  aspect-ratio: 16 / 9;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[5]};
  z-index: 1000;
`;
