import styled from 'styled-components';

export const Container = styled.div<{ height: number; width: number }>`
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  height: ${(props) => (props.height ? props.height + 'px' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing[8]};
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
