import styled from 'styled-components';

export const ContextMenuStyles = styled.div<{ menuPosition: string }>`
  height: fit-content;
  width: 16rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => (props.menuPosition === 'top' ? '1rem' : '')};
  bottom: ${(props) => (props.menuPosition === 'bottom' ? '1rem' : '')};
  left: 8rem;
  background-color: #fff;
  z-index: 1;
  box-shadow: ${(props) => props.theme.shadow[2]};
  border-radius: 5px;
`;

export const MenuItemsContainerUpper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #eeecec;

  & > :first-child {
    border-radius: 5px 5px 0 0;
  }
`;

export const MenuItemsContainerLower = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > :last-child {
    border-radius: 0 0 5px 5px;
  }
`;
