import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 30%;
  min-width: 25rem;
`;

export const Thumbnail = styled.img`
  aspect-ratio: 16/9;
  border-radius: 5px;
  margin-bottom: ${(props) => props.theme.spacing[3]};
  cursor: pointer;
`;

export const Title = styled.h3`
  margin-bottom: ${(props) => props.theme.spacing[2]};
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.color.gray900};
`;

export const Description = styled.p`
  font-size: 1.6rem;
  margin-bottom: 0;
  color: ${(props) => props.theme.color.gray700};
`;
